
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { EmailAddress } from '@/types';
import { useEventAsExternalGuestsStore } from '../store';
import { RegisterForEventAsExternalGuestCommand, Event } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<EmailAddress>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  comment: FormControl<string>;
}

@Component
export default class RegisterForEventAsExternalGuestDialog extends Vue {

  readonly store = useEventAsExternalGuestsStore();

  @Prop({ type: Object, required: true })
  readonly event!: Event;

  @Prop({ type: Boolean, required: true })
  readonly isDisabled!: boolean;

  readonly size = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail-Adresse',
          value: null,
          isRequired: true,
        },
        firstName: {
          label: 'Vorname',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        lastName: {
          label: 'Nachname',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        comment: {
          label: 'Kommentar',
          value: null,
          isRequired: this.event.isCommentRequiredOnRegistration,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RegisterForEventAsExternalGuestCommand = {
      eventId: this.event.eventId,
      emailAddress: formValues.emailAddress!,
      firstName: formValues.firstName!,
      lastName: formValues.lastName!,
      comment: formValues.comment,
    };

    this.store.registerForEventAsExternalGuest(command)
      .then(() => showSuccessMessage('Wir haben dir eine E-Mail zur Bestätigung deiner Anmeldung geschickt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
