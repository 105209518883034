
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { ReservationDisplayNameType } from '@/types';
import { FacilityReservationCalendarEvent } from '../types';
import { useBookingCalendarStore } from '../store';
import CancelFacilityReservationDialog from './cancel-facility-reservation-dialog.vue';
import UpdateFacilityReservationDialog from './update-facility-reservation-dialog.vue';

@Component({
  components: {
    UpdateFacilityReservationDialog,
    CancelFacilityReservationDialog,
  },
})
export default class FacilityReservationEvent extends Vue {

  readonly store = useBookingCalendarStore();

  @Prop({ type: Object, required: true })
  readonly calendarEvent!: FacilityReservationCalendarEvent;

  isMenuVisible = false;

  get areActionsVisible(): boolean {
    return this.calendarEvent.isUpdatableForUser || this.calendarEvent.isDeletableForUser;
  }

  get isUpdateVisible(): boolean {
    return this.calendarEvent.isUpdatableForUser;
  }

  get isCancelVisible(): boolean {
    return this.calendarEvent.isDeletableForUser;
  }

  get cssClasses(): string[] {
    const classes = [
      this.calendarEvent.facilityReservationActivity.color,
    ];

    if (this.calendarEvent.hasAccessToHorse) {
      classes.push('has-access-to-horse');
    }
    if (!!this.calendarEvent.comment) {
      classes.push('has-comment');
    }
    if (this.store.currentFacility?.facilityReservationConfiguration?.timeOptionInterval === this.calendarEvent.to.diff(
      this.calendarEvent.from,
      'minutes',
    )) {
      classes.push('single-interval');
    }

    return classes;
  }

  get isBookingDisplayNameTypeBooker(): boolean {
    return this.calendarEvent.reservationDisplayNameType === ReservationDisplayNameType.RESERVING_PERSON;
  }

  facilityReservationCanceled(): void {
    this.store.getCalendarEventsForFacility()
      .catch((error) => showErrorResponse(error));
  }

  hideMenu(): void {
    this.isMenuVisible = false;
  }

}
