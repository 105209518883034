import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import BoxPlanTabs from './components/box-plan-tabs.vue';

export const boxPlanManagementRoutes: RouteConfig[] = [
  {
    name: 'box-plan-management',
    path: 'boxenplan',
    component: BoxPlanTabs,
    meta: {
      title: 'Boxenplan',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresFeature: Feature.BOX_PLAN,
      requiresPermission: FarmManagerPermission.BOX_PLAN_READ,
    },
  },
];
