import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import PaddockPlanTabs from './components/paddock-plan-tabs.vue';
import ManagePaddockPlanPaddocks from './components/manage-paddock-plan-paddocks.vue';
import ManagePaddockPlanTimeRanges from './components/manage-paddock-plan-time-ranges.vue';
import PaddockPlan from './components/paddock-plan.vue';

export const paddockPlanManagementRoutes: RouteConfig[] = [
  {
    path: 'koppelplan',
    component: PaddockPlanTabs,
    children: [
      {
        name: 'paddock-plan-paddock-plan',
        path: '',
        component: PaddockPlan,
        meta: {
          title: 'Koppelplan',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.PADDOCK_PLAN,
          requiresPermission: FarmManagerPermission.PADDOCK_PLAN_READ,
        },
      },
      {
        name: 'paddock-plan-manage-paddocks',
        path: 'koppeln',
        component: ManagePaddockPlanPaddocks,
        meta: {
          title: 'Koppelplan',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.PADDOCK_PLAN,
          requiresPermission: FarmManagerPermission.PADDOCK_PLAN_READ,
        },
      },
      {
        name: 'paddock-plan-manage-time-ranges',
        path: 'zeitfenster',
        component: ManagePaddockPlanTimeRanges,
        meta: {
          title: 'Koppelplan',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.PADDOCK_PLAN,
          requiresPermission: FarmManagerPermission.PADDOCK_PLAN_READ,
        },
      },
    ],
    meta: {
      title: 'Koppelplan',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresFeature: Feature.PADDOCK_PLAN,
      requiresPermission: FarmManagerPermission.PADDOCK_PLAN_READ,
    },
  },
];
