import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import SurveyList from './components/survey-list.vue';
import WormingTreatmentDetailsLoadingWrapper from './components/survey-details-loading-wrapper.vue';

export const surveyManagementRoutes: RouteConfig[] = [
  {
    path: 'umfragen',
    component: {
      template: `<router-view />`,
    },
    children: [
      {
        name: 'survey-management-survey-list',
        path: '',
        component: SurveyList,
        meta: {
          title: 'Umfragen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.SURVEYS,
          requiresPermission: FarmManagerPermission.SURVEYS_READ,
        },
      },
      {
        name: 'manage-worming-treatments/worming-treatment-details',
        path: 'termine/details/:id',
        component: WormingTreatmentDetailsLoadingWrapper,
        meta: {
          title: 'Umfragen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.SURVEYS,
          requiresPermission: FarmManagerPermission.SURVEYS_READ,
          routeNameForBackButton: 'survey-management-survey-list',
        },
      },
    ],
  },
];
