import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import LaborServicesTabs from './components/labor-services-tabs.vue';
import LaborServiceSettings from './components/labor-service-settings.vue';
import LaborServiceSummary from './components/labor-service-summary.vue';
import HandledTimeEntries from './components/handled-time-entries.vue';
import UnhandledTimeEntries from './components/unhandled-time-entries.vue';

export const laborServicesManagementRoutes: RouteConfig[] = [
  {
    path: 'arbeitsdienst',
    component: LaborServicesTabs,
    children: [
      {
        // Warning - Used in push notifications
        name: 'labor-services-management-unhandled-time-entries',
        path: '',
        component: UnhandledTimeEntries,
        meta: {
          title: 'Arbeitsdienst',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LABOR_SERVICE,
          requiresPermission: FarmManagerPermission.LABOR_SERVICE_READ,
        },
      },
      {
        name: 'labor-services-management-handled-time-entries',
        path: 'behandelte-eintraege',
        component: HandledTimeEntries,
        meta: {
          title: 'Arbeitsdienst',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LABOR_SERVICE,
          requiresPermission: FarmManagerPermission.LABOR_SERVICE_READ,
        },
      },
      {
        name: 'labor-services-management-labor-service-summary',
        path: 'zusammenfassung',
        component: LaborServiceSummary,
        meta: {
          title: 'Arbeitsdienst',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LABOR_SERVICE,
          requiresPermission: FarmManagerPermission.LABOR_SERVICE_READ,
        },
      },
      {
        name: 'labor-services-management-labor-service-settings',
        path: 'konfiguration',
        component: LaborServiceSettings,
        meta: {
          title: 'Arbeitsdienst',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LABOR_SERVICE,
          requiresPermission: FarmManagerPermission.LABOR_SERVICE_READ,
        },
      },
    ],
    meta: {
      title: 'Arbeitsdienst',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresFeature: Feature.LABOR_SERVICE,
      requiresPermission: FarmManagerPermission.LABOR_SERVICE_READ,
    },
  },
];
