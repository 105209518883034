import { Time, ManipulateType, TimeFrame, MomentTimeFrame, Moment, Date } from '@/types';
import { DATE_FORMAT } from '@/helpers/data';
import { moment } from '@/helpers/moment';

export function enumerateTimes(startTime: Time, endTime: Time, amount: number, unit: ManipulateType): Time[] {
  const times = [];
  let previousDiff: number|null;
  let currentDiff: number|null;
  let time = startTime.clone();

  if (endTime.isBefore(startTime)) {
    do {
      times.push(time);
      previousDiff = time.diff(endTime);
      time = time.add(amount, unit);
      currentDiff = time.diff(endTime);
    } while (currentDiff > 0 && currentDiff > previousDiff);

    if (time.isMidnight) {
      times.push(time);
    }
  } else {
    do {
      times.push(time);
      previousDiff = time.diff(endTime);
      time = time.add(amount, unit);
      currentDiff = time.diff(endTime);
    } while (currentDiff <= 0 && currentDiff > previousDiff);
  }

  return times;
}

export function enumerateDays(from: Moment, to: Moment): Moment[] {
  const days: Moment[] = [];
  let day = from.clone();

  do {
    days.push(day);
    day = day.add(1, 'day');
  } while (day.isSameOrBefore(to));

  return days;
}

export function enumerateDates(from: Date, to: Date): Date[] {
  const days: Date[] = [];
  let day = from;

  do {
    days.push(day);
    day = day.add(1);
  } while (day.isBeforeOrEqualTo(to));

  return days;
}

export function momentTimeFrame(date: Moment, timeFrame: TimeFrame): MomentTimeFrame {
  const from = date
    .hour(timeFrame.timeFrom.hour)
    .minute(timeFrame.timeFrom.minute);

  let to = date
    .hour(timeFrame.timeTo.hour)
    .minute(timeFrame.timeTo.minute);

  if (timeFrame.timeTo.isMidnight) {
    to = to.add(1, 'day');
  }

  return {
    momentFrom: from,
    momentTo: to,
  };
}

export function momentTimeFrameFromDateAndTimeFrame(date: Date, timeFrame: TimeFrame): MomentTimeFrame {
  const from = moment(`${date.date} ${timeFrame.timeFrom.format()}`, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin');
  const to = timeFrame.timeTo.isMidnight
    ? moment(`${date.add(1).date} ${timeFrame.timeTo.format()}`, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin')
    : moment(`${date.date} ${timeFrame.timeTo.format()}`, 'YYYY-MM-DD HH:mm:ss', 'Europe/Berlin');

  return {
    momentFrom: from,
    momentTo: to,
  };

}

export function momentWithTime(date: Moment, time: Time): Moment {
  return date
    .hour(time.hour)
    .minute(time.minute)
    .second(time.second);
}

export function formattedDatePickerValueAsDate(datePickerValue: string): string {
  if (datePickerValue === '') {
    return '';
  }

  const today = moment();
  if (today.isSame(datePickerValue, 'day')) {
    return 'Heute';
  }

  const yesterday = today.subtract(1, 'day');
  if (yesterday.isSame(datePickerValue, 'day')) {
    return 'Gestern';
  }

  const dayBeforeYesterday = yesterday.subtract(1, 'day');
  if (dayBeforeYesterday.isSame(datePickerValue, 'day')) {
    return 'Vorgestern';
  }

  const tomorrow = today.add(1, 'day');
  if (tomorrow.isSame(datePickerValue, 'day')) {
    return 'Morgen';
  }

  const dayAfterTomorrow = tomorrow.add(1, 'day');
  if (dayAfterTomorrow.isSame(datePickerValue, 'day')) {
    return 'Übermorgen';
  }

  return moment(datePickerValue).format(DATE_FORMAT);
}
