import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateTaskAsManagerCommand, GetTasksGroupedByHorseAsManagerQuery, GetRecentTasksAsManagerQuery, GetTasksGroupedByUserAsManagerQuery, Horse, HorseWithTasks, RecentTasks, TasksGroupedByUser, User, GetOutdatedTasksAsManagerQuery, GetMyTasksAsManagerQuery, MyTasks, MarkTaskAsCompletedAsManagerCommand, UpdateTitleAndDetailsOfTaskAsManagerCommand, UpdateLastRepetitionOfTaskAsManagerCommand, DeleteTaskAsManagerCommand, AssignTaskToUserAsManagerCommand, UpdateExecutionDateOfTaskAsManagerCommand, OurTasks, GetOurTasksAsManagerQuery, OverdueTasks, UpdateTimeConfigurationOfTaskAsManagerCommand } from './types';

// -- Queries

export function getFarmManagersForAssignment(query: FarmAndUserProperties): Promise<User[]> {
  const url = `${apiUrl}/api/farm-tasks/get-farm-managers-for-assignment-as-manager-query`;
  return performApiRequest<User[]>({ url, method: 'POST', data: query });
}

export function getHorses(query: FarmAndUserProperties): Promise<Horse[]> {
  const url = `${apiUrl}/api/farm-tasks/get-horses-as-manager-query`;
  return performApiRequest<Horse[]>({ url, method: 'POST', data: query });
}

export function getTasksGroupedByUser(query: GetTasksGroupedByUserAsManagerQuery & FarmAndUserProperties): Promise<TasksGroupedByUser> {
  const url = `${apiUrl}/api/farm-tasks/get-tasks-grouped-by-user-as-manager-query`;
  return performApiRequest<TasksGroupedByUser>({ url, method: 'POST', data: query });
}

export function getTasksGroupedByHorse(query: GetTasksGroupedByHorseAsManagerQuery & FarmAndUserProperties): Promise<HorseWithTasks[]> {
  const url = `${apiUrl}/api/farm-tasks/get-tasks-grouped-by-horse-as-manager-query`;
  return performApiRequest<HorseWithTasks[]>({ url, method: 'POST', data: query });
}

export function getRecentTasks(query: GetRecentTasksAsManagerQuery & FarmAndUserProperties): Promise<RecentTasks> {
  const url = `${apiUrl}/api/farm-tasks/get-recent-tasks-as-manager-query`;
  return performApiRequest<RecentTasks>({ url, method: 'POST', data: query });
}

export function getOutdatedTasks(query: GetOutdatedTasksAsManagerQuery & FarmAndUserProperties): Promise<RecentTasks> {
  const url = `${apiUrl}/api/farm-tasks/get-outdated-tasks-as-manager-query`;
  return performApiRequest<RecentTasks>({ url, method: 'POST', data: query });
}

export function getMyTasks(query: GetMyTasksAsManagerQuery & FarmAndUserProperties): Promise<MyTasks> {
  const url = `${apiUrl}/api/farm-tasks/get-my-tasks-as-manager-query`;
  return performApiRequest<MyTasks>({ url, method: 'POST', data: query });
}

export function getOurTasks(query: GetOurTasksAsManagerQuery & FarmAndUserProperties): Promise<OurTasks> {
  const url = `${apiUrl}/api/farm-tasks/get-our-tasks-as-manager-query`;
  return performApiRequest<OurTasks>({ url, method: 'POST', data: query });
}

export function getOverdueTasks(query: FarmAndUserProperties): Promise<OverdueTasks> {
  const url = `${apiUrl}/api/farm-tasks/get-overdue-tasks-as-manager-query`;
  return performApiRequest<OverdueTasks>({ url, method: 'POST', data: query });
}

// -- Commands

export function createTask(command: CreateTaskAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/create-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markTaskAsCompleted(command: MarkTaskAsCompletedAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/mark-task-as-completed-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateTitleAndDetailsOfTask(command: UpdateTitleAndDetailsOfTaskAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/update-title-and-details-of-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateLastRepetitionOfTask(command: UpdateLastRepetitionOfTaskAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/update-last-repetition-of-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteTask(command: DeleteTaskAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/delete-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function assignTaskToUser(command: AssignTaskToUserAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/assign-task-to-user-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateExecutionDateOfTask(command: UpdateExecutionDateOfTaskAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/update-execution-date-of-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateTimeConfigurationOfTask(
  command: UpdateTimeConfigurationOfTaskAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/farm-tasks/update-time-configuration-of-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
