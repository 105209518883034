import { AuthenticatedUser } from '@/application/authentication/types';

export const LOCAL_STORAGE_FARM_ID = 'farm-id';
export const LOCAL_STORAGE_USER_ID = 'user-id';

export function isLocalStorageAvailable(): boolean {
  try {
    localStorage.setItem('local-storage-helper-check', '1');
    localStorage.removeItem('local-storage-helper-check');
    return true;
  } catch (e) {
    return false;
  }
}

export function setAuthenticationInLocalStorage(user: AuthenticatedUser): void {
  setLocalStorageItem(LOCAL_STORAGE_USER_ID, user.id);
  if (user.farm) {
    setLocalStorageItem(LOCAL_STORAGE_FARM_ID, user.farm.id);
  }
}

export function removeAuthenticationFromLocalStorage(): void {
  removeLocalStorageItem(LOCAL_STORAGE_USER_ID);
  removeLocalStorageItem(LOCAL_STORAGE_FARM_ID);
}

export function hasAuthenticationInLocalStorage(): boolean {
  return getLocalStorageItem(LOCAL_STORAGE_USER_ID) !== null
    && getLocalStorageItem(LOCAL_STORAGE_FARM_ID) !== null;
}

export function getLocalStorageItem(key: string): string|null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // We return null as a case where local storage is not available
    return null;
  }
}

export function setLocalStorageItem(key: string, value: string): void {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    // We don't handle a case where local storage is not available
  }
}

export function removeLocalStorageItem(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // We don't handle a case where local storage is not available
  }
}
