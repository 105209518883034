import { render, staticRenderFns } from "./latest-owners-chart.vue?vue&type=template&id=148a8825&scoped=true&"
import script from "./latest-owners-chart.vue?vue&type=script&setup=true&lang=ts&"
export * from "./latest-owners-chart.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./latest-owners-chart.vue?vue&type=style&index=0&id=148a8825&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "148a8825",
  null
  
)

export default component.exports