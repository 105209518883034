
import { Component, Vue } from 'vue-property-decorator';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { navigate } from '@/helpers/navigation-helpers';
import { constructForm, csvCompliantRule, Form, FormControl, FormControls, getFormValues, mandatoryCheckboxRule, maxLengthRule, registrationCodeRule } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useUserRegistrationStore } from '../store';
import { StartUserRegistrationCommand } from '../types';

interface Controls extends FormControls {
  email: FormControl<string>;
  registrationCode: FormControl<string>;
  isDataPolicyAccepted: FormControl<boolean>;
}

@Component
export default class UserRegistration extends Vue {

  readonly store = useUserRegistrationStore();

  readonly isNativeApplication = isNativeApplication;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        email: {
          label: 'E-Mail-Adresse',
          value: null,
          isRequired: true,
          rules: [
            csvCompliantRule(),
          ],
        },
        registrationCode: {
          label: 'Registrierungscode',
          value: this.$route.query.code
            ? (this.$route.query.code as string).toLowerCase()
            : null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
            registrationCodeRule(),
          ],
        },
        isDataPolicyAccepted: {
          label: 'Datenschutzbestimmungen',
          value: false,
          isRequired: true,
          rules: [
            mandatoryCheckboxRule(),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: StartUserRegistrationCommand = {
      emailAddress: formValues.email!,
      registrationCode: formValues.registrationCode!,
    };

    this.store.startUserRegistration(command)
      .then(() => showSuccessMessage(
        'Wir haben dir eine E-Mail geschickt über diese du die Registrierung abschließen kannst.',
        6000
      ))
      .then(() => this.redirectToRoot())
      .catch((error) => showErrorResponse(error));
  }

  redirectToRoot(): void {
    navigate({ name: 'root' });
  }

}
