
import { Component, Vue } from 'vue-property-decorator';
import { useAppStore } from '@/application/app/store';

@Component
export default class AppVersionHint extends Vue {

  readonly appStore = useAppStore();

  reload(): void {
    window.location.reload();
  }

}
