import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { createFeedType, disableFeedType, getFeedingOverview, getFeedTypes, getLatestFeedingSettingUpdates, hideFeedTypeOnBoxSign, showFeedTypeOnBoxSign, updateNameOfFeedType, updateOfferOfFeedType } from './service';
import { CreateFeedTypeAsManagerCommand, DisableFeedTypeAsManagerCommand, FeedType, HideFeedTypeOnBoxSignAsManagerCommand, HorseForLatestFeedingUpdate, HorseWithFeeding, ShowFeedTypeOnBoxSignAsManagerCommand, UpdateNameOfFeedTypeAsManagerCommand, UpdateOfferOfFeedTypeAsManagerCommand } from './types';

interface FeedingManagementState {
  feedTypes: FeedType[];
  feedingOverview: HorseWithFeeding[];
  feedingSettingUpdates: HorseForLatestFeedingUpdate[];

  getFeedTypesStatus: ActionStatus;
  getFeedingOverviewStatus: ActionStatus;
  getLatestFeedingSettingUpdatesStatus: ActionStatus;
  createFeedTypeStatus: ActionStatus;
  disableFeedTypeStatus: ActionStatus;
  updateNameOfFeedTypeStatus: ActionStatus;
  updateOfferOfFeedTypeStatus: ActionStatus;
  hideFeedTypeOnBoxSignStatus: ActionStatus;
  showFeedTypeOnBoxSignStatus: ActionStatus;
}

function initialState(): FeedingManagementState {
  return {
    feedTypes: [],
    feedingOverview: [],
    feedingSettingUpdates: [],

    getFeedTypesStatus: ActionStatus.None,
    getFeedingOverviewStatus: ActionStatus.None,
    getLatestFeedingSettingUpdatesStatus: ActionStatus.None,
    createFeedTypeStatus: ActionStatus.None,
    disableFeedTypeStatus: ActionStatus.None,
    updateNameOfFeedTypeStatus: ActionStatus.None,
    updateOfferOfFeedTypeStatus: ActionStatus.None,
    hideFeedTypeOnBoxSignStatus: ActionStatus.None,
    showFeedTypeOnBoxSignStatus: ActionStatus.None,
  };
}

export const useFeedingManagementStore = defineStore('feedingManagement', {
  state: (): FeedingManagementState => initialState(),
  getters: {
    isGetFeedTypesProcessing: (state: FeedingManagementState): boolean =>
      state.getFeedTypesStatus === ActionStatus.InProgress,
    isGetFeedingOverviewProcessing: (state: FeedingManagementState): boolean =>
      state.getFeedingOverviewStatus === ActionStatus.InProgress,
    isGetLatestFeedingSettingUpdatesProcessing: (state: FeedingManagementState): boolean =>
      state.getLatestFeedingSettingUpdatesStatus === ActionStatus.InProgress,
    isCreateFeedTypeProcessing: (state: FeedingManagementState): boolean =>
      state.createFeedTypeStatus === ActionStatus.InProgress,
    isDisableFeedTypeProcessing: (state: FeedingManagementState): boolean =>
      state.disableFeedTypeStatus === ActionStatus.InProgress,
    isUpdateNameOfFeedTypeProcessing: (state: FeedingManagementState): boolean =>
      state.updateNameOfFeedTypeStatus === ActionStatus.InProgress,
    isUpdateOfferOfFeedTypeProcessing: (state: FeedingManagementState): boolean =>
      state.updateOfferOfFeedTypeStatus === ActionStatus.InProgress,
    isHideFeedTypeOnBoxSignProcessing: (state: FeedingManagementState): boolean =>
      state.hideFeedTypeOnBoxSignStatus === ActionStatus.InProgress,
    isShowFeedTypeOnBoxSignProcessing: (state: FeedingManagementState): boolean =>
      state.showFeedTypeOnBoxSignStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getFeedTypes(): Promise<void> {
      const { getFeedTypesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFeedTypesStatus,
        () => getFeedTypes(attachFarmAndUserProperties({}))
          .then((feedTypes) => {
            this.feedTypes = feedTypes;
          })
      );
    },

    getFeedingOverview(): Promise<void> {
      const { getFeedingOverviewStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFeedingOverviewStatus,
        () => getFeedingOverview(attachFarmAndUserProperties({}))
          .then((feedingOverview) => {
            this.feedingOverview = feedingOverview;
          })
      );
    },

    getLatestFeedingSettingUpdates(): Promise<void> {
      const { getLatestFeedingSettingUpdatesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getLatestFeedingSettingUpdatesStatus,
        () => getLatestFeedingSettingUpdates(attachFarmAndUserProperties({}))
          .then((feedingSettingUpdates) => {
            this.feedingSettingUpdates = feedingSettingUpdates;
          })
      );
    },

    // -- Commands

    createFeedType(command: CreateFeedTypeAsManagerCommand): Promise<void> {
      const { createFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFeedTypeStatus,
        () => createFeedType(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypes())
          .then(() => useAuthenticationStore().getAuthentication()) // Feature feeding might be now visible for riders
      );
    },

    disableFeedType(command: DisableFeedTypeAsManagerCommand): Promise<void> {
      const { disableFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableFeedTypeStatus,
        () => disableFeedType(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypes())
      );
    },

    updateNameOfFeedType(command: UpdateNameOfFeedTypeAsManagerCommand): Promise<void> {
      const { updateNameOfFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameOfFeedTypeStatus,
        () => updateNameOfFeedType(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypes())
      );
    },

    updateOfferOfFeedType(command: UpdateOfferOfFeedTypeAsManagerCommand): Promise<void> {
      const { updateOfferOfFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOfferOfFeedTypeStatus,
        () => updateOfferOfFeedType(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypes())
      );
    },

    hideFeedTypeOnBoxSign(command: HideFeedTypeOnBoxSignAsManagerCommand): Promise<void> {
      const { hideFeedTypeOnBoxSignStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        hideFeedTypeOnBoxSignStatus,
        () => hideFeedTypeOnBoxSign(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypes())
      );
    },

    showFeedTypeOnBoxSign(command: ShowFeedTypeOnBoxSignAsManagerCommand): Promise<void> {
      const { showFeedTypeOnBoxSignStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        showFeedTypeOnBoxSignStatus,
        () => showFeedTypeOnBoxSign(attachFarmAndUserProperties(command))
          .then(() => this.getFeedTypes())
      );
    },

  },
});
