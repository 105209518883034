
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component
export default class SecondaryButton extends Vue {

  @Prop({ type: Object, default: null })
  readonly to!: Location | null;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isProcessing!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isExact!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isSmall!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isAlwaysShownInBlockMode!: boolean;

  get isBlock(): boolean {
    return this.isAlwaysShownInBlockMode
      || this.$vuetify.breakpoint.smAndDown;
  }

}
