
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatDate, formatTimeFrame } from '@/helpers';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { Date } from '@/types';
import { useEventAsExternalGuestsStore } from '../store';
import { Event } from '../types';
import RegisterForEventAsExternalGuestDialog from './register-for-event-as-external-guest-dialog.vue';

@Component({
  components: {
    RegisterForEventAsExternalGuestDialog,
  },
})
export default class EventPresentation extends Vue {

  readonly store = useEventAsExternalGuestsStore();
  readonly appStore = useAppStore();

  @Prop({ type: Object, required: true })
  readonly event!: Event;

  get participantsDescription(): string {
    return this.event.maxNumberOfParticipants !== null
      // eslint-disable-next-line max-len
      ? `${this.event.numberOfParticipants} von ${this.event.maxNumberOfParticipants} ${this.event.maxNumberOfParticipants === 1 ? 'Person' : 'Personen'} haben sich angemeldet.`
      // eslint-disable-next-line max-len
      : `${this.event.numberOfParticipants} ${this.event.numberOfParticipants === 1 ? 'Person' : 'Personen'} haben sich angemeldet.`;
  }

  get isCostVisible(): boolean {
    return this.event.cost !== null;
  }

  get isEventInThePast(): boolean {
    return Date.today().isAfter(this.event.date);
  }

  get isRegistrationLimitReached(): boolean {
    return this.event.maxNumberOfParticipants !== null
      && this.event.numberOfParticipants >= this.event.maxNumberOfParticipants;
  }

  get areRegistrationsVisible(): boolean {
    return this.hasRegistrations
      && (this.event.areRegistrationsPublic
        || this.event.areCommentsPublic
      );
  }

  get hasRegistrations(): boolean {
    return this.event.eventRegistrations.length > 0;
  }

  get isRegistrationButtonDisabled(): boolean {
    return this.isEventInThePast
      || (
        !!this.event.registrationDeadline
        && Date.today().isAfter(this.event.registrationDeadline)
      )
      || this.isRegistrationLimitReached;
  }

  get registrationAndCancellationDescription(): string {
    const registrationDeadline = this.event.registrationDeadline
      ? this.event.registrationDeadline
      : this.event.date;
    const cancellationDeadline = this.event.cancellationDeadline
      ? this.event.cancellationDeadline
      : this.event.date;

    // eslint-disable-next-line max-len
    return `Eine Anmeldung ist bis einschließlich ${formatDate(registrationDeadline!)} und eine Stornierung der Anmeldung ist bis einschließlich ${formatDate(cancellationDeadline!)} möglich.`;
  }

  mounted(): void {
    const appTitle = this.event.timeFrame
      ? `${formatDate(this.event.date)} - ${formatTimeFrame(this.event.timeFrame)}`
      : formatDate(this.event.date);

    this.appStore.updateTitle(appTitle)
      .catch((error) => showErrorResponse(error));
  }

}
