
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FilterBar extends Vue {

  get isActionSlotVisible(): boolean {
    return !!this.$slots.actions;
  }

}
