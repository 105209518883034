import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { ActiveHorseInformation, BoxSignGenerationProcessForAllHorses, DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand, DeleteHorseAsManagerWorkflow, GetActiveInformationForHorseAsManagerQuery, GetBoxSignAsManagerQuery, GetBoxSignsForAllHorsesAsManagerQuery, Horse, UpdateHorseNameAsManagerWorkflow } from './types';
import { deleteBoxSignGenerationProcessForAllHorses, deleteHorseAsFarmManager, generateBoxSignsForAllHorses, getActiveInformationForHorse, getBoxSign, getBoxSignGenerationProcessesForAllHorses, getBoxSignsForAllHorses, getHorses, updateHorseName } from './service';

interface HorseManagementState {
  horses: Horse[];
  activeHorseInformation: ActiveHorseInformation | null;
  boxSignGenerationProcessesForAllHorses: BoxSignGenerationProcessForAllHorses[];

  getHorsesStatus: ActionStatus;
  getActiveInformationForHorseStatus: ActionStatus;
  updateHorseNameStatus: ActionStatus;
  deleteHorseStatus: ActionStatus;
  getBoxSignStatus: ActionStatus;
  generateBoxSignsForAllHorsesStatus: ActionStatus;
  getBoxSignGenerationProcessesForAllHorsesStatus: ActionStatus;
  getBoxSignsForAllHorsesStatus: ActionStatus;
  deleteBoxSignGenerationProcessForAllHorsesStatus: ActionStatus;
}

function initialState(): HorseManagementState {
  return {
    horses: [],
    activeHorseInformation: null,
    boxSignGenerationProcessesForAllHorses: [],

    getHorsesStatus: ActionStatus.None,
    getActiveInformationForHorseStatus: ActionStatus.None,
    updateHorseNameStatus: ActionStatus.None,
    deleteHorseStatus: ActionStatus.None,
    getBoxSignStatus: ActionStatus.None,
    generateBoxSignsForAllHorsesStatus: ActionStatus.None,
    getBoxSignGenerationProcessesForAllHorsesStatus: ActionStatus.None,
    getBoxSignsForAllHorsesStatus: ActionStatus.None,
    deleteBoxSignGenerationProcessForAllHorsesStatus: ActionStatus.None,
  };
}

export const useHorseManagementStore = defineStore('horseManagement', {
  state: (): HorseManagementState => initialState(),
  getters: {
    isGetHorsesProcessing: (state: HorseManagementState): boolean =>
      state.getHorsesStatus === ActionStatus.InProgress,
    isGetActiveInformationForHorseProcessing: (state: HorseManagementState): boolean =>
      state.getActiveInformationForHorseStatus === ActionStatus.InProgress,
    isUpdateHorseNameProcessing: (state: HorseManagementState): boolean =>
      state.updateHorseNameStatus === ActionStatus.InProgress,
    isDeleteHorseProcessing: (state: HorseManagementState): boolean =>
      state.deleteHorseStatus === ActionStatus.InProgress,
    isGetBoxSignProcessing: (state: HorseManagementState): boolean =>
      state.getBoxSignStatus === ActionStatus.InProgress,
    isGenerateBoxSignsForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.generateBoxSignsForAllHorsesStatus === ActionStatus.InProgress,
    isGetBoxSignGenerationProcessesForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.getBoxSignGenerationProcessesForAllHorsesStatus === ActionStatus.InProgress,
    isGetBoxSignsForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.getBoxSignsForAllHorsesStatus === ActionStatus.InProgress,
    isDeleteBoxSignGenerationProcessForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.deleteBoxSignGenerationProcessForAllHorsesStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getHorses(): Promise<void> {
      const { getHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorsesStatus,
        () => getHorses(attachFarmAndUserProperties({}))
          .then(async (horses) => {
            this.horses = horses;
          })
      );
    },

    getActiveInformationForHorse(query: GetActiveInformationForHorseAsManagerQuery): Promise<void> {
      const { getActiveInformationForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getActiveInformationForHorseStatus,
        () => getActiveInformationForHorse(attachFarmAndUserProperties(query))
          .then(async (activeHorseInformation) => {
            this.activeHorseInformation = activeHorseInformation;
          })
      );
    },

    getBoxSign(query: GetBoxSignAsManagerQuery): Promise<FileResponse> {
      const { getBoxSignStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxSignStatus,
        () => getBoxSign(attachFarmAndUserProperties(query))
      );
    },

    getBoxSignGenerationProcessesForAllHorses(): Promise<void> {
      const { getBoxSignGenerationProcessesForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxSignGenerationProcessesForAllHorsesStatus,
        () => getBoxSignGenerationProcessesForAllHorses(attachFarmAndUserProperties({}))
          .then((boxSignGenerationProcessesForAllHorses) => {
            this.boxSignGenerationProcessesForAllHorses = boxSignGenerationProcessesForAllHorses;
          })
      );
    },

    getBoxSignsForAllHorses(query: GetBoxSignsForAllHorsesAsManagerQuery): Promise<FileResponse> {
      const { getBoxSignsForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxSignsForAllHorsesStatus,
        () => getBoxSignsForAllHorses(attachFarmAndUserProperties(query))
      );
    },

    // -- Commands

    updateHorseName(command: UpdateHorseNameAsManagerWorkflow): Promise<void> {
      const { updateHorseNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHorseNameStatus,
        () => updateHorseName(attachFarmAndUserProperties(command))
          .then(() => this.getHorses())
      );
    },

    deleteHorse(command: DeleteHorseAsManagerWorkflow): Promise<void> {
      const { deleteHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseStatus,
        () => deleteHorseAsFarmManager(attachFarmAndUserProperties(command))
          .then(() => this.getHorses())
      );
    },

    generateBoxSignsForAllHorses(): Promise<void> {
      const { generateBoxSignsForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        generateBoxSignsForAllHorsesStatus,
        () => generateBoxSignsForAllHorses(attachFarmAndUserProperties({}))
          .then(() => this.getBoxSignGenerationProcessesForAllHorses())
      );
    },

    deleteBoxSignGenerationProcessForAllHorses(command: DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand): Promise<void> {
      const { deleteBoxSignGenerationProcessForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteBoxSignGenerationProcessForAllHorsesStatus,
        () => deleteBoxSignGenerationProcessForAllHorses(attachFarmAndUserProperties(command))
          .then(() => this.getBoxSignGenerationProcessesForAllHorses())
      );
    },

  },
});
