import { Capacitor } from '@capacitor/core';
import { Platform } from '@/application/app/types';

export function isMobileSafari(): boolean {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOS = /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator/.test(navigator.userAgent) && !window.MSStream;

  return isSafari && isIOS;
}

export function isNativeApplication(): boolean {
  return Capacitor.isNativePlatform();
}

export function isIOS(): boolean {
  return Capacitor.getPlatform() === 'ios';
}

export function getNativePlatform(): Platform {
  return Capacitor.getPlatform() as Platform;
}
