import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { AdminNotification, MarkAdminNotificationAsSeenCommand } from './types';

// -- Queries

export function getUnseenAdminNotifications(query: FarmAndUserProperties): Promise<AdminNotification[]> {
  const url = `${apiUrl}/api/admin-notifications/get-unseen-admin-notifications-query`;
  return performApiRequest<AdminNotification[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function markAdminNotificationAsSeen(command: MarkAdminNotificationAsSeenCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/admin-notifications/mark-admin-notification-as-seen-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
