import { render, staticRenderFns } from "./delete-news-entry-dialog.vue?vue&type=template&id=813c9672&"
import script from "./delete-news-entry-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./delete-news-entry-dialog.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports