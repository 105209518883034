import { apiUrl } from '@/infrastructure/api-url-service';
import { performUnauthorizedApiRequest } from '@/infrastructure/api-requests';
import { PingQuery } from './types';

// -- Queries

export function checkMaintenanceModeStatus(query: PingQuery): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/maintenance/ping-query`;
  return performUnauthorizedApiRequest<void>({ url, method: 'POST', data: query });
}
