import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CSV, Month, NameOrderType, PersonId } from '@/types';
import { addConcentratedFeedType, createCreditNote, createCustomBooking, getConfiguration, getCreditNotes, getCustomBookings, getSettlementsForMonth, getSettlementsForMonthAsCSV, updateCreditNotePaymentMethod, updateCustomBookingPaymentMethod, createCustomBookings, createCreditNotes, getPersons, createCustomBookingService, getCustomBookingServices, updateNameOfCustomBookingService, updatePriceOfCustomBookingService, updateIdentificationForAccountingOfCustomBookingService, archiveCustomBookingService, assignCustomBookingService, assignCustomBookingServiceToPersons } from './service';
import { AddConcentratedFeedTypeAsManagerCommand, ArchiveCustomBookingServiceAsManagerCommand, AssignCustomBookingServiceAsManagerCommand, AssignCustomBookingServiceToPersonsAsManagerWorkflow, CreateCreditNoteAsManagerCommand, CreateCreditNotesAsManagerWorkflow, CreateCustomBookingAsManagerCommand, CreateCustomBookingsAsManagerWorkflow, CreateCustomBookingServiceAsManagerCommand, CreditNote, CustomBooking, CustomBookingService, GetCreditNotesAsManagerQuery, GetCustomBookingsAsManagerQuery, GetCustomBookingServicesAsManagerQuery, GetSettlementsForMonthAsCSVAsManagerQuery, GetSettlementsForMonthAsManagerQuery, LedgerConfiguration, PersonForFilter, SettlementsForMonth, UpdateCreditNotePaymentMethodAsManagerCommand, UpdateCustomBookingPaymentMethodAsManagerCommand, UpdateIdentificationForAccountingOfCustomBookingServiceAsManagerCommand, UpdateNameOfCustomBookingServiceAsManagerCommand, UpdatePriceOfCustomBookingServiceAsManagerCommand } from './types';

interface LedgerState {
  settlementsForMonth: SettlementsForMonth | null;
  customBookings: CustomBooking[];
  creditNotes: CreditNote[];
  persons: PersonForFilter[];
  configuration: LedgerConfiguration | null;
  selectedMonthForSettlements: Month | null;
  selectedPersonForSettlements: PersonId | null;
  selectedNameOrderType: NameOrderType;
  selectedMonthForCustomBookings: Month | null;
  selectedMonthForCreditNotes: Month | null;
  customBookingServices: CustomBookingService[] | null;
  searchForCustomBookingServices: string | null;

  getConfigurationStatus: ActionStatus;
  getCustomBookingsStatus: ActionStatus;
  getCreditNotesStatus: ActionStatus;
  updateCustomBookingPaymentMethodStatus: ActionStatus;
  updateCreditNotePaymentMethodStatus: ActionStatus;
  createCustomBookingStatus: ActionStatus;
  createCustomBookingsStatus: ActionStatus;
  createCreditNoteStatus: ActionStatus;
  createCreditNotesStatus: ActionStatus;
  addConcentratedFeedTypeStatus: ActionStatus;
  getSettlementsForMonthStatus: ActionStatus;
  getSettlementsForMonthAsCSVStatus: ActionStatus;
  getPersonsStatus: ActionStatus;
  createCustomBookingServiceStatus: ActionStatus;
  updateNameOfCustomBookingServiceStatus: ActionStatus;
  getCustomBookingServicesStatus: ActionStatus;
  updatePriceOfCustomBookingServiceStatus: ActionStatus;
  updateIdentificationForAccountingOfCustomBookingServiceStatus: ActionStatus;
  archiveCustomBookingServiceStatus: ActionStatus;
  assignCustomBookingServiceStatus: ActionStatus;
  assignCustomBookingServiceToPersonsStatus: ActionStatus;
}

function initialState(): LedgerState {
  return {
    settlementsForMonth: null,
    customBookings: [],
    creditNotes: [],
    persons: [],
    configuration: null,
    selectedMonthForSettlements: null,
    selectedPersonForSettlements: null,
    selectedNameOrderType: NameOrderType.FIRST_NAME_FIRST,
    selectedMonthForCustomBookings: null,
    selectedMonthForCreditNotes: null,
    customBookingServices: null,
    searchForCustomBookingServices: null,

    getConfigurationStatus: ActionStatus.None,
    getCustomBookingsStatus: ActionStatus.None,
    getCreditNotesStatus: ActionStatus.None,
    updateCustomBookingPaymentMethodStatus: ActionStatus.None,
    updateCreditNotePaymentMethodStatus: ActionStatus.None,
    createCustomBookingStatus: ActionStatus.None,
    createCustomBookingsStatus: ActionStatus.None,
    createCreditNoteStatus: ActionStatus.None,
    createCreditNotesStatus: ActionStatus.None,
    addConcentratedFeedTypeStatus: ActionStatus.None,
    getSettlementsForMonthStatus: ActionStatus.None,
    getSettlementsForMonthAsCSVStatus: ActionStatus.None,
    getPersonsStatus: ActionStatus.None,
    createCustomBookingServiceStatus: ActionStatus.None,
    updateNameOfCustomBookingServiceStatus: ActionStatus.None,
    getCustomBookingServicesStatus: ActionStatus.None,
    updatePriceOfCustomBookingServiceStatus: ActionStatus.None,
    updateIdentificationForAccountingOfCustomBookingServiceStatus: ActionStatus.None,
    archiveCustomBookingServiceStatus: ActionStatus.None,
    assignCustomBookingServiceStatus: ActionStatus.None,
    assignCustomBookingServiceToPersonsStatus: ActionStatus.None,
  };
}

export const useLedgerStore = defineStore('ledger', {
  state: (): LedgerState => initialState(),
  getters: {
    isGetConfigurationProcessing: (state: LedgerState): boolean =>
      state.getConfigurationStatus === ActionStatus.InProgress,
    isGetCustomBookingsProcessing: (state: LedgerState): boolean =>
      state.getCustomBookingsStatus === ActionStatus.InProgress,
    isGetCreditNotesProcessing: (state: LedgerState): boolean =>
      state.getCreditNotesStatus === ActionStatus.InProgress,
    isUpdateCustomBookingPaymentMethodProcessing: (state: LedgerState): boolean =>
      state.updateCustomBookingPaymentMethodStatus === ActionStatus.InProgress,
    isUpdateCreditNotePaymentMethodProcessing: (state: LedgerState): boolean =>
      state.updateCreditNotePaymentMethodStatus === ActionStatus.InProgress,
    isCreateCustomBookingProcessing: (state: LedgerState): boolean =>
      state.createCustomBookingStatus === ActionStatus.InProgress,
    isCreateCustomBookingsProcessing: (state: LedgerState): boolean =>
      state.createCustomBookingsStatus === ActionStatus.InProgress,
    isCreateCreditNoteProcessing: (state: LedgerState): boolean =>
      state.createCreditNoteStatus === ActionStatus.InProgress,
    isCreateCreditNotesProcessing: (state: LedgerState): boolean =>
      state.createCreditNotesStatus === ActionStatus.InProgress,
    isAddConcentratedFeedTypeProcessing: (state: LedgerState): boolean =>
      state.addConcentratedFeedTypeStatus === ActionStatus.InProgress,
    isGetSettlementsForMonthProcessing: (state: LedgerState): boolean =>
      state.getSettlementsForMonthStatus === ActionStatus.InProgress,
    isGetSettlementsForMonthAsCSVProcessing: (state: LedgerState): boolean =>
      state.getSettlementsForMonthAsCSVStatus === ActionStatus.InProgress,
    isGetPersonsProcessing: (state: LedgerState): boolean =>
      state.getPersonsStatus === ActionStatus.InProgress,
    isCreateCustomBookingServiceProcessing: (state: LedgerState): boolean =>
      state.createCustomBookingServiceStatus === ActionStatus.InProgress,
    isUpdateNameOfCustomBookingServiceProcessing: (state: LedgerState): boolean =>
      state.updateNameOfCustomBookingServiceStatus === ActionStatus.InProgress,
    isGetCustomBookingServicesProcessing: (state: LedgerState): boolean =>
      state.getCustomBookingServicesStatus === ActionStatus.InProgress,
    isUpdatePriceOfCustomBookingServiceProcessing: (state: LedgerState): boolean =>
      state.updatePriceOfCustomBookingServiceStatus === ActionStatus.InProgress,
    isUpdateIdentificationForAccountingOfCustomBookingServiceProcessing: (state: LedgerState): boolean =>
      state.updateIdentificationForAccountingOfCustomBookingServiceStatus === ActionStatus.InProgress,
    isArchiveCustomBookingServiceProcessing: (state: LedgerState): boolean =>
      state.archiveCustomBookingServiceStatus === ActionStatus.InProgress,
    isAssignCustomBookingServiceProcessing: (state: LedgerState): boolean =>
      state.assignCustomBookingServiceStatus === ActionStatus.InProgress,
    isAssignCustomBookingServiceToPersonsProcessing: (state: LedgerState): boolean =>
      state.assignCustomBookingServiceToPersonsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    initSelectedValuesForSettlements(
      selectedMonth: Month,
      selectedNameOrderType: NameOrderType
    ): Promise<void> {
      this.selectedMonthForSettlements = selectedMonth;
      this.selectedNameOrderType = selectedNameOrderType;

      return this.getSettlementsForMonth();
    },

    updateSelectedMonthForSettlements(selectedMonth: Month): Promise<void> {
      this.selectedMonthForSettlements = selectedMonth;

      return this.getSettlementsForMonth();
    },

    updateSelectedPersonForSettlements(selectedPerson: PersonId | null): Promise<void> {
      this.selectedPersonForSettlements = selectedPerson;

      return this.getSettlementsForMonth();
    },

    updateSelectedNameOrderType(nameOrderType: NameOrderType): Promise<void> {
      this.selectedNameOrderType = nameOrderType;

      return this.getSettlementsForMonth();
    },

    updateSelectedMonthForCustomBookings(selectedMonth: Month): Promise<void> {
      this.selectedMonthForCustomBookings = selectedMonth;

      return this.getCustomBookings();
    },

    updateSelectedMonthForCreditNotes(selectedMonth: Month): Promise<void> {
      this.selectedMonthForCreditNotes = selectedMonth;

      return this.getCreditNotes();
    },

    updateSearchForCustomBookingServices(searchForCustomBookingServices: string | null): Promise<void> {
      this.searchForCustomBookingServices = searchForCustomBookingServices;

      return this.getCustomBookingServices();
    },

    // -- Queries

    getConfiguration(): Promise<void> {
      const { getConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getConfigurationStatus,
        () => getConfiguration(attachFarmAndUserProperties({}))
          .then((configuration) => {
            this.configuration = configuration;
          })
      );
    },

    getCustomBookings(): Promise<void> {
      const query: GetCustomBookingsAsManagerQuery = {
        month: this.selectedMonthForCustomBookings!,
      };
      const { getCustomBookingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCustomBookingsStatus,
        () => getCustomBookings(attachFarmAndUserProperties(query))
          .then((customBookings) => {
            this.customBookings = customBookings;
          })
      );
    },

    getCreditNotes(): Promise<void> {
      const query: GetCreditNotesAsManagerQuery = {
        month: this.selectedMonthForCreditNotes!,
      };
      const { getCreditNotesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCreditNotesStatus,
        () => getCreditNotes(attachFarmAndUserProperties(query))
          .then((creditNotes) => {
            this.creditNotes = creditNotes;
          })
      );
    },

    getSettlementsForMonth(): Promise<void> {
      const query: GetSettlementsForMonthAsManagerQuery = {
        month: this.selectedMonthForSettlements!,
        filteredForPerson: this.selectedPersonForSettlements,
        nameOrderType: this.selectedNameOrderType,
      };
      const { getSettlementsForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSettlementsForMonthStatus,
        () => getSettlementsForMonth(attachFarmAndUserProperties(query))
          .then((settlementsForMonth) => {
            this.settlementsForMonth = settlementsForMonth;
          })
      );
    },

    getSettlementsForMonthAsCSV(): Promise<CSV> {
      const query: GetSettlementsForMonthAsCSVAsManagerQuery = {
        month: this.selectedMonthForSettlements!,
        nameOrderType: this.selectedNameOrderType,
      };
      const { getSettlementsForMonthAsCSVStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSettlementsForMonthAsCSVStatus,
        () => getSettlementsForMonthAsCSV(attachFarmAndUserProperties(query))
      );
    },

    getPersons(): Promise<void> {
      const { getPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonsStatus,
        () => getPersons(attachFarmAndUserProperties({}))
          .then((persons) => {
            this.persons = persons;
          })
      );
    },

    getCustomBookingServices(): Promise<void> {
      const query: GetCustomBookingServicesAsManagerQuery = {
        search: this.searchForCustomBookingServices,
      };
      const { getCustomBookingServicesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCustomBookingServicesStatus,
        () => getCustomBookingServices(attachFarmAndUserProperties(query))
          .then((customBookingServices) => {
            this.customBookingServices = customBookingServices;
          })
      );
    },

    // -- Commands

    addConcentratedFeedType(command: AddConcentratedFeedTypeAsManagerCommand): Promise<void> {
      const { addConcentratedFeedTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addConcentratedFeedTypeStatus,
        () => addConcentratedFeedType(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateCustomBookingPaymentMethod(command: UpdateCustomBookingPaymentMethodAsManagerCommand): Promise<void> {
      const { updateCustomBookingPaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCustomBookingPaymentMethodStatus,
        () => updateCustomBookingPaymentMethod(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    updateCreditNotePaymentMethod(command: UpdateCreditNotePaymentMethodAsManagerCommand): Promise<void> {
      const { updateCreditNotePaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCreditNotePaymentMethodStatus,
        () => updateCreditNotePaymentMethod(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
      );
    },

    createCustomBooking(command: CreateCustomBookingAsManagerCommand): Promise<void> {
      const { createCustomBookingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createCustomBookingStatus,
        () => createCustomBooking(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookings())
      );
    },

    createCustomBookings(command: CreateCustomBookingsAsManagerWorkflow): Promise<void> {
      const { createCustomBookingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createCustomBookingsStatus,
        () => createCustomBookings(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookings())
      );
    },

    createCreditNote(command: CreateCreditNoteAsManagerCommand): Promise<void> {
      const { createCreditNoteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createCreditNoteStatus,
        () => createCreditNote(attachFarmAndUserProperties(command))
          .then(() => this.getCreditNotes())
      );
    },

    createCreditNotes(command: CreateCreditNotesAsManagerWorkflow): Promise<void> {
      const { createCreditNotesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createCreditNotesStatus,
        () => createCreditNotes(attachFarmAndUserProperties(command))
          .then(() => this.getCreditNotes())
      );
    },

    createCustomBookingService(command: CreateCustomBookingServiceAsManagerCommand): Promise<void> {
      const { createCustomBookingServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createCustomBookingServiceStatus,
        () => createCustomBookingService(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookingServices())
      );
    },

    updateNameOfCustomBookingService(command: UpdateNameOfCustomBookingServiceAsManagerCommand): Promise<void> {
      const { updateNameOfCustomBookingServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameOfCustomBookingServiceStatus,
        () => updateNameOfCustomBookingService(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookingServices())
      );
    },

    updatePriceOfCustomBookingService(command: UpdatePriceOfCustomBookingServiceAsManagerCommand): Promise<void> {
      const { updatePriceOfCustomBookingServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePriceOfCustomBookingServiceStatus,
        () => updatePriceOfCustomBookingService(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookingServices())
      );
    },

    updateIdentificationForAccountingOfCustomBookingService(
      command: UpdateIdentificationForAccountingOfCustomBookingServiceAsManagerCommand
    ): Promise<void> {
      const { updateIdentificationForAccountingOfCustomBookingServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateIdentificationForAccountingOfCustomBookingServiceStatus,
        () => updateIdentificationForAccountingOfCustomBookingService(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookingServices())
      );
    },

    archiveCustomBookingService(command: ArchiveCustomBookingServiceAsManagerCommand): Promise<void> {
      const { archiveCustomBookingServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        archiveCustomBookingServiceStatus,
        () => archiveCustomBookingService(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookingServices())
      );
    },

    assignCustomBookingService(command: AssignCustomBookingServiceAsManagerCommand): Promise<void> {
      const { assignCustomBookingServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        assignCustomBookingServiceStatus,
        () => assignCustomBookingService(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookings())
      );
    },

    assignCustomBookingServiceToPersons(command: AssignCustomBookingServiceToPersonsAsManagerWorkflow): Promise<void> {
      const { assignCustomBookingServiceToPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        assignCustomBookingServiceToPersonsStatus,
        () => assignCustomBookingServiceToPersons(attachFarmAndUserProperties(command))
          .then(() => this.getCustomBookings())
      );
    },

  },
});
