import { render, staticRenderFns } from "./horse-details.vue?vue&type=template&id=62821492&scoped=true&"
import script from "./horse-details.vue?vue&type=script&setup=true&lang=ts&"
export * from "./horse-details.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./horse-details.vue?vue&type=style&index=0&id=62821492&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62821492",
  null
  
)

export default component.exports