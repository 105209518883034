
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class MultiTextFormControl extends Vue implements FormControlComponent<string[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string[]>;

  @Prop({ type: String, default: 'Eintrag hinzufügen' })
  readonly moreButtonText!: string;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: string[] = [];
  isValid = false;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  textChanged(text: string, index: number): void {
    this.internalValue[index] = text.trim();

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  addString(): void {
    this.internalValue.push('');

    internalValuesChanged(this);
  }

  removeString(index: number): void {
    this.internalValue.splice(index, 1);

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        [
          (value: string[]) => value
            .filter((value, index, array) => array.indexOf(value) === index)
            .length === value.length
          || 'Keine Wiederholungen erlaubt',
        ],
        this.internalValue,
      ),
    ];

    this.isValid = messages.length === 0;

    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ['']
      : this.formControl.value.map((string) => string.trim());
  }

  formValueFromInternalValues(): FormControlValue<string[]> {
    const trimmedValues = this.internalValue
      .map((string) => string.trim())
      .filter((string) => string.length > 0);

    return trimmedValues.length > 0
      ? trimmedValues
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
