
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { CalendarViewPreference } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { UpdatePreferredCalendarViewCommand } from '../types';
import { useBookingCalendarStore } from '../store';

interface Controls extends FormControls {
  calendarViewPreference: FormControl<CalendarViewPreference>;
}

@Component
export default class UpdatePreferredCalendarViewDialog extends Vue {

  readonly store = useBookingCalendarStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        calendarViewPreference: {
          label: 'Bevorzugte Ansicht',
          value: this.authenticationStore.user!.calendarViewPreference,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdatePreferredCalendarViewCommand = {
      calendarViewPreference: formValues.calendarViewPreference!,
    };
    this.store.updatePreferredCalendarView(command)
      .then(() => showSuccessMessage('Die bevorzugte Ansicht wurde angepasst.'))
      .then(() => this.$emit('preferred-calendar-view-was-updated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
