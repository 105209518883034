
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { RidingLessonRegistrationId, RidingLessonRegistrationType } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { CancelRidingLessonRegistrationCommand, RidingLessonCalendarEvent, RidingLessonRegistration } from '../types';
import { useBookingCalendarStore } from '../store';

interface Controls extends FormControls {
  ridingLessonRegistration: FormControl<RidingLessonRegistrationId>;
}

@Component
export default class CancelRidingLessonRegistrationDialog extends Vue {

  readonly store = useBookingCalendarStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly ridingLessonEvent!: RidingLessonCalendarEvent;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get hasMultipleOwnRegistrations(): boolean {
    return this.registrationsOfAuthenticatedUser.length > 1;
  }

  get registrationsOfAuthenticatedUser(): RidingLessonRegistration[] {
    return this.ridingLessonEvent.registrations
      .filter((registration) => registration.user.userId === this.authenticationStore.user!.id);
  }

  get isRegistrationSelectionVisible(): boolean {
    return this.hasMultipleOwnRegistrations;
  }

  get cancelQuestion(): string {
    const registration = this.registrationsOfAuthenticatedUser[0];
    if (!registration) {
      throw new Error('No registration found.');
    }

    return registration.type === RidingLessonRegistrationType.USER
      ? 'Möchtest du dich wirklich abmelden?'
      : `Möchtest du ${registration.nameOfAnotherPerson} wirklich abmelden?`;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible
        && this.registrationsOfAuthenticatedUser.length === 0
      ) {
        showErrorResponse('Du bist für diese Unterrichtsstunde nicht angemeldet.');
        this.closeDialog();
        return;
      }

      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const initialRegistration = this.registrationsOfAuthenticatedUser[0].ridingLessonRegistrationId;

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        ridingLessonRegistration: {
          label: 'Welche Anmeldung willst du stornieren?',
          value: initialRegistration,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CancelRidingLessonRegistrationCommand = {
      ridingLessonRegistrationId: formValues.ridingLessonRegistration!,
    };
    this.store.cancelRidingLessonRegistration(command)
      .then(() => showSuccessMessage('Du hast dich erfolgreich von der Unterrichtsstunde abgemeldet.'))
      .then(() => this.$emit('riding-lesson-registration-canceled'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error, 6000));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
