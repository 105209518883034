import { RouteConfig, RouteMeta } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import TaskTabs from './components/task-tabs.vue';
import NewTasksTable from './components/new-tasks-table.vue';
import WithdrawnTasks from './components/withdrawn-tasks.vue';
import RejectedTasks from './components/rejected-tasks.vue';
import CompletedTasks from './components/completed-tasks.vue';
import AssignedTasks from './components/assigned-tasks.vue';

const taskRouteMeta: RouteMeta = {
  title: 'Reiteraufgaben',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresFeature: Feature.RIDER_TASKS,
  requiresPermission: FarmManagerPermission.RIDER_TASKS_READ,
};

export const riderTaskManagementRoutes: RouteConfig[] = [
  {
    path: 'reiteraufgaben',
    component: TaskTabs,
    children: [
      {
        name: 'rider-task-management-task-tabs',
        path: '',
        redirect: { name: 'rider-task-management-new-tasks' },
      },
      {
        // Warning - Used in push notifications
        name: 'rider-task-management-new-tasks',
        path: 'neue-aufgaben',
        component: NewTasksTable,
        meta: taskRouteMeta,
      },
      {
        name: 'rider-task-management-assigned-tasks',
        path: 'zugewiesene-aufgaben',
        component: AssignedTasks,
        meta: taskRouteMeta,
      },
      {
        name: 'rider-task-management-completed-tasks',
        path: 'erledigte-aufgaben',
        component: CompletedTasks,
        meta: taskRouteMeta,
      },
      {
        name: 'rider-task-management-rejected-tasks',
        path: 'abgelehnte-aufgaben',
        component: RejectedTasks,
        meta: taskRouteMeta,
      },
      {
        // Warning - Used in push notifications
        name: 'rider-task-management-withdrawn-tasks',
        path: 'zurueckgezogene-aufgaben',
        component: WithdrawnTasks,
        meta: taskRouteMeta,
      },
    ],
    meta: taskRouteMeta,
  },
];
