import { render, staticRenderFns } from "./number-form-control.vue?vue&type=template&id=d40cfe56&scoped=true&"
import script from "./number-form-control.vue?vue&type=script&setup=true&lang=ts&"
export * from "./number-form-control.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./number-form-control.vue?vue&type=style&index=0&id=d40cfe56&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d40cfe56",
  null
  
)

export default component.exports