
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { InternalSnack } from '../types';
import { useSnackbarStore } from '../store';

const defaultTimeoutMap = {
  success: 4000,
  info: 5000,
  error: 6000,
};
const timeBetweenSnacks = 500;

/**
 * This components shows snacks added to the snackbar state. It pulls the first snack of a queue and displays it for the timeout defined in
 * the snack or with the default timeout depending on the snack type. After another timout (at the moment 0,25 seconds) the snack is removed
 * from the queue and the next is pulled. This is enough time for the disappearing snack to vanish.
 */

@Component
export default class Snackbar extends Vue {

  readonly store = useSnackbarStore();

  isSnackbarVisible = false;
  shownSnackId: string|null = null;

  get title(): string | null {
    return this.store.firstSnackInQueue?.title ?? null;
  }

  get message(): string | null {
    return this.store.firstSnackInQueue?.message ?? null;
  }

  get type(): string | null {
    return this.store.firstSnackInQueue?.type ?? null;
  }

  get isMultiline(): boolean {
    return !!this.store.firstSnackInQueue?.title;
  }

  get timeout(): number | null {
    if (!this.store.firstSnackInQueue) {
      return null;
    }

    return this.timeoutForSnack(this.store.firstSnackInQueue);
  }

  get showBadge(): boolean {
    return this.store.snackQueueLength > 1;
  }

  mounted(): void {
    watch(() => this.store.firstSnackInQueue, () => {
      const snack = this.store.firstSnackInQueue;
      // The watcher is also triggered when a new snack is added, but the first is still the same
      if (!snack || snack.id === this.shownSnackId) {
        return;
      }

      this.shownSnackId = snack.id;
      this.isSnackbarVisible = true;

      const timeoutForSnack = this.timeoutForSnack(snack);
      const timeoutForRemoval = timeoutForSnack + timeBetweenSnacks;

      setTimeout(() => {
        this.isSnackbarVisible = false;
      }, timeoutForSnack);

      setTimeout(() => {
        this.store.removeSnackFromQueue(this.store.firstSnackInQueue!);
      }, timeoutForRemoval);
    }, { immediate: true, deep: true });
  }

  close(): void {
    if (!this.store.firstSnackInQueue) {
      return;
    }

    this.isSnackbarVisible = false;
    this.store.removeSnackFromQueue(this.store.firstSnackInQueue);
  }

  timeoutForSnack(snack: InternalSnack): number {
    const defaultTimeout = defaultTimeoutMap[snack.type];
    return snack.timeout ?? defaultTimeout;
  }

}
