
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useMyStableStore } from '../store';
import PenExemptionsView from './pen-exemptions-view.vue';

@Component({
  components: {
    PenExemptionsView,
  },
})
export default class PenExemptionsLoadingWrapper extends Vue {

  readonly store = useMyStableStore();
  readonly appStore = useAppStore();

  mounted(): void {
    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Koppelausnahmen`)
      .catch((error) => showErrorResponse(error));

    this.store.getPenExemptionsForHorse()
      .catch((error) => showErrorResponse(error));
  }

}
