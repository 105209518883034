
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { FeedingSettingPayload, FeedType, UpdateFeedingSettingsCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  feedingSettings: FormControl<FeedingSettingPayload[]>;
}

@Component
export default class UpdateFeedingSettingsDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Array, required: true })
  readonly feedTypes!: FeedType[];

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const initialFeedingSettings: FeedingSettingPayload[] | null = this.store.feedingPlan!.feedingSettings.length > 0
      ? this.store.feedingPlan!.feedingSettings.map((feedingSetting) => ({
        feedTypeId: feedingSetting.feedTypeId,
        isSelectedInTheMorning: feedingSetting.isSelectedInTheMorning,
        isSelectedAtNoon: feedingSetting.isSelectedAtNoon,
        isSelectedInTheEvening: feedingSetting.isSelectedInTheEvening,
        amountMorning: feedingSetting.amountMorning,
        amountNoon: feedingSetting.amountNoon,
        amountEvening: feedingSetting.amountEvening,
      }))
      : null;

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        feedingSettings: {
          label: 'Fütterung',
          value: initialFeedingSettings,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFeedingSettingsCommand = {
      horseId: this.store.currentHorseId!,
      feedingSettings: formValues.feedingSettings!,
    };

    this.store.updateFeedingSettings(command)
      .then(() => showSuccessMessage('Heu-Fütterung wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
