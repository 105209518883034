export interface TouchPoint {
  x: number;
  y: number;
}

export interface TouchMovement {
  start: TouchPoint;
  end: TouchPoint;
}

export enum MovementDirection {
  'LEFT' = 'LEFT',
  'RIGHT' = 'RIGHT',
}

export function touchPointFromEvent(event: TouchEvent): TouchPoint {
  return {
    x: event.changedTouches[0].clientX,
    y: event.changedTouches[0].clientY,
  };
}

export function movementFromStartAndEnd(start: TouchPoint, end: TouchPoint): TouchMovement {
  return {
    start,
    end,
  };
}

export function movementDirection(movement: TouchMovement): MovementDirection | null {
  // Idea was taken from Vuetify https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/directives/touch/index.ts
  const dirRatio = 0.5;
  const minDistance = 16;

  const offsetX = movement.start.x - movement.end.x;
  const offsetY = movement.start.y - movement.end.y;

  // If horizontal movement was twice as much as vertical
  if (Math.abs(offsetY) < dirRatio * Math.abs(offsetX)) {
    const hasMovedLeft = (movement.end.x < movement.start.x - minDistance);
    if (hasMovedLeft) {
      return MovementDirection.LEFT;
    }

    const hasMovedRight = (movement.end.x > movement.start.x + minDistance);
    if (hasMovedRight) {
      return MovementDirection.RIGHT;
    }
  }

  return null;
}
