import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { EventId, NewsEntryId } from '@/types';
import { useDashboardStore } from '@/private/rider/dashboard/store';
import { registerForEvent, deleteEventRegistration, getEvents, markEventAsSeen, markNewsEntryAsSeen, getNewsEntries, getSurveys, answerSurvey, getUnansweredSurveysShownOnAppStart } from './service';
import { RegisterForEventCommand, DeleteEventRegistrationCommand, MarkEventAsSeenCommand, MarkNewsEntryAsSeenCommand, NewsEntry, Event, Survey, AnswerSurveyCommand } from './types';

interface PinboardState {
  newsEntries: NewsEntry[];
  currentNewsEntryId: NewsEntryId | null;
  events: Event[];
  currentEventId: EventId | null;
  surveys: Survey[];
  surveysShownOnAppStart: Survey[];

  getNewsEntriesStatus: ActionStatus;
  getEventsStatus: ActionStatus;
  markNewsEntryAsSeenStatus: ActionStatus;
  markEventAsSeenStatus: ActionStatus;
  registerForEventStatus: ActionStatus;
  deleteEventRegistrationStatus: ActionStatus;
  getSurveysStatus: ActionStatus;
  getUnansweredSurveysShownOnAppStartStatus: ActionStatus;
  answerSurveyStatus: ActionStatus;
}

function initialState(): PinboardState {
  return {
    newsEntries: [],
    currentNewsEntryId: null,
    events: [],
    currentEventId: null,
    surveys: [],
    surveysShownOnAppStart: [],

    getNewsEntriesStatus: ActionStatus.None,
    getEventsStatus: ActionStatus.None,
    markNewsEntryAsSeenStatus: ActionStatus.None,
    markEventAsSeenStatus: ActionStatus.None,
    registerForEventStatus: ActionStatus.None,
    deleteEventRegistrationStatus: ActionStatus.None,
    getSurveysStatus: ActionStatus.None,
    getUnansweredSurveysShownOnAppStartStatus: ActionStatus.None,
    answerSurveyStatus: ActionStatus.None,
  };
}

export const usePinboardStore = defineStore('pinboard', {
  state: (): PinboardState => initialState(),
  getters: {
    currentNewsEntry: (state: PinboardState): NewsEntry | null =>
      state.newsEntries
        .find((newsEntry) => newsEntry.newsEntryId === state.currentNewsEntryId) ?? null,
    currentEvent: (state: PinboardState): Event | null =>
      state.events
        .find((event) => event.eventId === state.currentEventId) ?? null,

    isGetNewsEntriesProcessing: (state: PinboardState): boolean =>
      state.getNewsEntriesStatus === ActionStatus.InProgress,
    isGetEventsProcessing: (state: PinboardState): boolean =>
      state.getEventsStatus === ActionStatus.InProgress,
    isMarkNewsEntryAsSeenProcessing: (state: PinboardState): boolean =>
      state.markNewsEntryAsSeenStatus === ActionStatus.InProgress,
    isMarkEventAsSeenProcessing: (state: PinboardState): boolean =>
      state.markEventAsSeenStatus === ActionStatus.InProgress,
    isRegisterForEventProcessing: (state: PinboardState): boolean =>
      state.registerForEventStatus === ActionStatus.InProgress,
    isDeleteEventRegistrationProcessing: (state: PinboardState): boolean =>
      state.deleteEventRegistrationStatus === ActionStatus.InProgress,
    isGetSurveysProcessing: (state: PinboardState): boolean =>
      state.getSurveysStatus === ActionStatus.InProgress,
    isAnswerSurveyProcessing: (state: PinboardState): boolean =>
      state.answerSurveyStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentEvent(eventId: EventId): Promise<void> {
      this.currentEventId = eventId;

      return this.getEvents();
    },

    updateCurrentNewsEntry(newsEntryId: NewsEntryId): Promise<void> {
      this.currentNewsEntryId = newsEntryId;

      return this.getNewsEntries();
    },

    resetCurrentEvent(): Promise<void> {
      this.currentEventId = null;

      return Promise.resolve();
    },

    resetCurrentNewsEntry(): Promise<void> {
      this.currentNewsEntryId = null;

      return Promise.resolve();
    },

    // -- Queries

    getNewsEntries(): Promise<void> {
      const { getNewsEntriesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNewsEntriesStatus,
        () => getNewsEntries(attachFarmAndUserProperties({}))
          .then((newsEntries) => {
            this.newsEntries = newsEntries;
          })
      );
    },

    getEvents(): Promise<void> {
      const { getEventsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEventsStatus,
        () => getEvents(attachFarmAndUserProperties({}))
          .then((announcements) => {
            this.events = announcements;
          })
      );
    },

    getSurveys(): Promise<void> {
      const { getSurveysStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSurveysStatus,
        () => getSurveys(attachFarmAndUserProperties({}))
          .then((surveys) => {
            this.surveys = surveys;
          })
      );
    },

    getUnansweredSurveysShownOnAppStart(): Promise<void> {
      const { getUnansweredSurveysShownOnAppStartStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUnansweredSurveysShownOnAppStartStatus,
        () => getUnansweredSurveysShownOnAppStart(attachFarmAndUserProperties({}))
          .then((surveysShownOnAppStart) => {
            this.surveysShownOnAppStart = surveysShownOnAppStart;
          })
      );
    },

    // -- Commands

    markNewsEntryAsSeen(command: MarkNewsEntryAsSeenCommand): Promise<void> {
      const { markNewsEntryAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markNewsEntryAsSeenStatus,
        () => markNewsEntryAsSeen(attachFarmAndUserProperties(command))
          .then(() => useDashboardStore().getNotificationStatus())
      );
    },

    markEventAsSeen(command: MarkEventAsSeenCommand): Promise<void> {
      const { markEventAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markEventAsSeenStatus,
        () => markEventAsSeen(attachFarmAndUserProperties(command))
          .then(() => useDashboardStore().getNotificationStatus())
      );
    },

    registerForEvent(command: RegisterForEventCommand): Promise<void> {
      const { registerForEventStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        registerForEventStatus,
        () => registerForEvent(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    deleteEventRegistration(command: DeleteEventRegistrationCommand): Promise<void> {
      const { deleteEventRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteEventRegistrationStatus,
        () => deleteEventRegistration(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    answerSurvey(command: AnswerSurveyCommand): Promise<void> {
      const { answerSurveyStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        answerSurveyStatus,
        () => answerSurvey(attachFarmAndUserProperties(command))
      );
    },

  },
});
