
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { RidingLessonRegistrationType } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class RidingLessonRegistrationTypeRadioFormControl
  extends Vue implements FormControlComponent<RidingLessonRegistrationType> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<RidingLessonRegistrationType>;

  @Prop({ type: Object, required: true })
  readonly ridingLessonRegistrationTypeTranslations!: Record<RidingLessonRegistrationType, string>;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: RidingLessonRegistrationType | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get items(): VuetifySelectItem<RidingLessonRegistrationType>[] {
    return Object.values(RidingLessonRegistrationType).map((value) => ({
      text: this.ridingLessonRegistrationTypeTranslations[value],
      value,
    }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<RidingLessonRegistrationType> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
