import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress } from '@/store';
import { Month, NameOrderType, PersonId } from '@/types';
import { getPersonsWithPaymentReceipts, markBankTransferForMonthAsNotPayed, markBankTransferForMonthAsPayed, markDirectDebitForMonthAsNotPayed, markDirectDebitForMonthAsPayed, updatePaymentReceiptNoteForMonth } from './service';
import { GetPersonsWithPaymentReceiptsAsManagerQuery, MarkBankTransferForMonthAsNotPayedAsManagerCommand, MarkBankTransferForMonthAsPayedAsManagerCommand, MarkDirectDebitForMonthAsNotPayedAsManagerCommand, MarkDirectDebitForMonthAsPayedAsManagerCommand, UpdatePaymentReceiptNoteForMonthAsManagerCommand, PersonsWithPaymentReceipts } from './types';

interface PaymentReceiptState {
  selectedMonth: Month | null;
  selectedPerson: PersonId | null;
  selectedNameOrderType: NameOrderType;
  personsWithPaymentReceipts: PersonsWithPaymentReceipts | null;

  getPersonsWithPaymentReceiptsStatus: ActionStatus;
  markBankTransferForMonthAsPayedStatus: ActionStatus;
  markDirectDebitForMonthAsPayedStatus: ActionStatus;
  markBankTransferForMonthAsNotPayedStatus: ActionStatus;
  markDirectDebitForMonthAsNotPayedStatus: ActionStatus;
  updatePaymentReceiptNoteForMonthStatus: ActionStatus;
}

function initialState(): PaymentReceiptState {
  return {
    selectedMonth: null,
    selectedPerson: null,
    selectedNameOrderType: NameOrderType.FIRST_NAME_FIRST,
    personsWithPaymentReceipts: null,

    getPersonsWithPaymentReceiptsStatus: ActionStatus.None,
    markBankTransferForMonthAsPayedStatus: ActionStatus.None,
    markDirectDebitForMonthAsPayedStatus: ActionStatus.None,
    markBankTransferForMonthAsNotPayedStatus: ActionStatus.None,
    markDirectDebitForMonthAsNotPayedStatus: ActionStatus.None,
    updatePaymentReceiptNoteForMonthStatus: ActionStatus.None,
  };
}

export const usePaymentReceiptStore = defineStore('paymentReceipt', {
  state: (): PaymentReceiptState => initialState(),
  getters: {
    isGetUsersWithPaymentReceiptsProcessing: (state: PaymentReceiptState): boolean =>
      state.getPersonsWithPaymentReceiptsStatus === ActionStatus.InProgress,
    isMarkBankTransferForMonthAsPayedProcessing: (state: PaymentReceiptState): boolean =>
      state.markBankTransferForMonthAsPayedStatus === ActionStatus.InProgress,
    isMarkDirectDebitForMonthAsPayedProcessing: (state: PaymentReceiptState): boolean =>
      state.markDirectDebitForMonthAsPayedStatus === ActionStatus.InProgress,
    isMarkBankTransferForMonthAsNotPayedProcessing: (state: PaymentReceiptState): boolean =>
      state.markBankTransferForMonthAsNotPayedStatus === ActionStatus.InProgress,
    isMarkDirectDebitForMonthAsNotPayedProcessing: (state: PaymentReceiptState): boolean =>
      state.markDirectDebitForMonthAsNotPayedStatus === ActionStatus.InProgress,
    isUpdatePaymentReceiptNoteForMonthProcessing: (state: PaymentReceiptState): boolean =>
      state.updatePaymentReceiptNoteForMonthStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    initSelectedValues(
      selectedMonth: Month,
      selectedNameOrderType: NameOrderType
    ): Promise<void> {
      this.selectedMonth = selectedMonth;
      this.selectedNameOrderType = selectedNameOrderType;

      return this.getPersonsWithPaymentReceipts();
    },

    updateSelectedMonth(selectedMonth: Month): Promise<void> {
      this.selectedMonth = selectedMonth;

      return this.getPersonsWithPaymentReceipts();
    },

    updateSelectedPerson(selectedPerson: PersonId | null): Promise<void> {
      this.selectedPerson = selectedPerson;

      return this.getPersonsWithPaymentReceipts();
    },

    updateNameOrderType(nameOrderType: NameOrderType): Promise<void> {
      this.selectedNameOrderType = nameOrderType;

      return this.getPersonsWithPaymentReceipts();
    },

    // -- Queries

    getPersonsWithPaymentReceipts(): Promise<void> {
      const query: GetPersonsWithPaymentReceiptsAsManagerQuery = {
        month: this.selectedMonth!,
        filteredForPerson: this.selectedPerson,
        nameOrderType: this.selectedNameOrderType,
      };
      const { getPersonsWithPaymentReceiptsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonsWithPaymentReceiptsStatus,
        () => getPersonsWithPaymentReceipts(attachFarmAndUserProperties(query))
          .then((personsWithPaymentReceipts) => {
            this.personsWithPaymentReceipts = personsWithPaymentReceipts;
          })
      );
    },

    // -- Commands

    markBankTransferForMonthAsPayed(command: MarkBankTransferForMonthAsPayedAsManagerCommand): Promise<void> {
      const { markBankTransferForMonthAsPayedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markBankTransferForMonthAsPayedStatus,
        () => markBankTransferForMonthAsPayed(attachFarmAndUserProperties(command))
          .then(() => this.getPersonsWithPaymentReceipts())
      );
    },

    markDirectDebitForMonthAsPayed(command: MarkDirectDebitForMonthAsPayedAsManagerCommand): Promise<void> {
      const { markDirectDebitForMonthAsPayedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markDirectDebitForMonthAsPayedStatus,
        () => markDirectDebitForMonthAsPayed(attachFarmAndUserProperties(command))
          .then(() => this.getPersonsWithPaymentReceipts())
      );
    },

    markBankTransferForMonthAsNotPayed(command: MarkBankTransferForMonthAsNotPayedAsManagerCommand): Promise<void> {
      const { markBankTransferForMonthAsNotPayedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markBankTransferForMonthAsNotPayedStatus,
        () => markBankTransferForMonthAsNotPayed(attachFarmAndUserProperties(command))
          .then(() => this.getPersonsWithPaymentReceipts())
      );
    },

    markDirectDebitForMonthAsNotPayed(command: MarkDirectDebitForMonthAsNotPayedAsManagerCommand): Promise<void> {
      const { markDirectDebitForMonthAsNotPayedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markDirectDebitForMonthAsNotPayedStatus,
        () => markDirectDebitForMonthAsNotPayed(attachFarmAndUserProperties(command))
          .then(() => this.getPersonsWithPaymentReceipts())
      );
    },

    updatePaymentReceiptNoteForMonth(command: UpdatePaymentReceiptNoteForMonthAsManagerCommand): Promise<void> {
      const { updatePaymentReceiptNoteForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePaymentReceiptNoteForMonthStatus,
        () => updatePaymentReceiptNoteForMonth(attachFarmAndUserProperties(command))
          .then(() => this.getPersonsWithPaymentReceipts())
      );
    },

  },
});
