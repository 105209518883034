
import { Component, Vue } from 'vue-property-decorator';
import { useAppStore } from '@/application/app/store';
import { ActionStatus } from '@/application/types';
import { errorMessageFromError } from '@/application/snackbar/service';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { useEventAsExternalGuestsStore } from '../store';
import { CancelEventRegistrationAsExternalGuestCommand } from '../types';

@Component
export default class EventRegistrationCancellationStart extends Vue {

  readonly store = useEventAsExternalGuestsStore();
  readonly appStore = useAppStore();

  errorMessage = '';

  get isSuccessVisible(): boolean {
    return this.store.cancelEventRegistrationAsExternalGuestStatus === ActionStatus.Successful;
  }

  get isFailureAlertVisible(): boolean {
    return this.store.cancelEventRegistrationAsExternalGuestStatus === ActionStatus.Failed;
  }

  get isRetryButtonVisible(): boolean {
    return this.store.cancelEventRegistrationAsExternalGuestStatus === ActionStatus.Failed
      && isNativeApplication();
  }

  mounted(): void {
    this.cancelEventRegistrationAsExternalGuest();
  }

  cancelEventRegistrationAsExternalGuest(): void {
    const command: CancelEventRegistrationAsExternalGuestCommand = {
      eventRegistrationId: this.$route.params.eventRegistrationId,
    };

    this.store.cancelEventRegistrationAsExternalGuest(command)
      .then(() => this.appStore.updateTitle('Stornierung gestartet'))
      .catch((error) => {
        this.appStore.updateTitle('Start fehlgeschlagen')
          .catch(() => {});
        this.errorMessage = errorMessageFromError(error);
      });
  }

}
