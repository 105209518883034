
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { useAppStore } from '@/application/app/store';
import { navigate } from '@/helpers/navigation-helpers';
import { useAuthenticationStore } from '@/application/authentication/store';

@Component
export default class AppTitle extends Vue {

  readonly appStore = useAppStore();
  readonly authenticationStore = useAuthenticationStore();

  isBackButtonVisible = false;
  hasNarrowLayout = false;

  get title(): string {
    return this.authenticationStore.wasInitialAuthenticationAttempted
      ? this.appStore.titleWithFallback
      : 'Anmeldung wird überprüft';
  }

  mounted(): void {
    watch(() => this.$route.path, () => {
      this.isBackButtonVisible = !!this.$route.meta?.routeNameForBackButton;
      this.hasNarrowLayout = !!this.$route.meta?.hasNarrowLayout;
    }, { immediate: true });
  }

  goBack(): void {
    navigate({ name: this.$route.meta?.routeNameForBackButton });
  }

}
