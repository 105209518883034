
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { VuetifySelectItem } from '@/application/types';
import { Horse } from '../types';
import { navigationItems } from '../helpers';

@Component
export default class HorseCard extends Vue {

  @Prop({ type: Object, required: true })
  readonly horse!: Horse;

  @Prop({ type: Boolean, required: true })
  readonly isOwnHorse!: boolean;

  get navigationItems(): (VuetifySelectItem<Location> & { key: string })[] {
    return navigationItems(this.horse, this.isOwnHorse);
  }

  navigateToDetails(): void {
    this.$router.push({ name: 'horse-details', params: { id: this.horse.horseId } });
  }

}
