
import { Component, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';

@Component
export default class ReservationNotPossibleInPastDialog extends Vue {

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  showDialog() {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
