
import { Component, Vue } from 'vue-property-decorator';
import { isLocalStorageAvailable } from '@/helpers/local-storage-helper';

@Component
export default class LocalStorageHint extends Vue {

  get isLocalStorageDisabled(): boolean {
    return !isLocalStorageAvailable();
  }

}
