import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole } from '@/types';
import ManageVaccines from './components/manage-vaccines.vue';

export const vaccineManagementRoutes: RouteConfig[] = [
  {
    name: 'vaccine-management-manage-vaccines',
    path: 'impfstoffe',
    component: ManageVaccines,
    meta: {
      title: 'Impfstoffe',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_ADMIN,
    },
  },
];
