
import { Component, Prop, Vue } from 'vue-property-decorator';
import { uuid } from '@/helpers';
import { DataListItem } from './types';

@Component
export default class DataList extends Vue {

  @Prop({ type: Array, required: true })
  readonly items!: DataListItem[];

  readonly listId = uuid();

}
