import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmDocument } from './types';

// -- Queries

export function getFarmDocuments(query: FarmAndUserProperties): Promise<FarmDocument[]> {
  const url = `${apiUrl}/api/farm-documents/get-farm-documents-query`;
  return performApiRequest<FarmDocument[]>({ url, method: 'POST', data: query });
}
