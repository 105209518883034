
import { watch } from 'vue';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { enumerateDates } from '@/helpers/date-helpers';
import { Weekday, Date, weekdayFromDate } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class DateMultiCheckboxesFormControl extends Vue implements FormControlComponent<Date[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Date[]>;

  @Prop({ type: Object, required: true })
  readonly from!: Date;

  @Prop({ type: Object, required: true })
  readonly to!: Date;

  @Prop({ type: Array, default: null })
  readonly restrictedWeekdays!: Weekday[] | null;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: Date[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  get dates(): Date[] {
    return enumerateDates(this.from, this.to)
      .filter((date) => this.restrictedWeekdays === null
        || this.restrictedWeekdays.includes(weekdayFromDate(date)));
  }

  get areAllCheckboxesChecked(): boolean {
    return this.internalValue.length === this.dates.length;
  }

  get areSomeCheckboxesChecked(): boolean {
    return this.internalValue.length > 0
      && this.internalValue.length !== this.dates.length;
  }

  mounted(): void {
    mountFormControl(this);

    watch(() => this.from, () => this.updateAllowedInternalValues());
    watch(() => this.to, () => this.updateAllowedInternalValues());
    watch(() => this.restrictedWeekdays, () => this.updateAllowedInternalValues());
  }

  updateAllowedInternalValues(): void {
    const countOfInternalValuesBeforeRemoval = this.internalValue.length;
    this.internalValue = this.dates.filter(
      (date) => this.internalValue.some((internalValueDate) => date.isEqualTo(internalValueDate))
    );

    if (countOfInternalValuesBeforeRemoval !== this.internalValue.length) {
      internalValuesChanged(this);
    }
  }

  selectionChanged(): void {
    this.focused();
    internalValuesChanged(this);
    this.blurred();
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  toggleAllChecked(): void {
    this.focused();
    if (this.areAllCheckboxesChecked) {
      this.internalValue = [];
    } else {
      this.internalValue = this.dates;
    }
    internalValuesChanged(this);
    this.blurred();
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value !== null
      ? this.formControl.value
      : [];
  }

  formValueFromInternalValues(): FormControlValue<Date[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
