import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole } from '@/types';
import ControlPanelComponent from './components/control-panel.vue';

export const controlPanelRoutes: RouteConfig[] = [
  {
    // -- Warning: Used in push notifications
    name: 'control-panel',
    path: '/kontrollzentrum',
    component: ControlPanelComponent,
    meta: {
      title: 'Kontrollzentrum',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
    },
  },
];
