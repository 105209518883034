import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { CancelEventRegistrationAsExternalGuestCommand, ConfirmEventRegistrationAsExternalGuestCommand, ConfirmEventRegistrationCancellationAsExternalGuestCommand, Event, GetEventAsGuestQuery, RegisterForEventAsExternalGuestCommand } from './types';

// -- Queries

export function getEventAsGuest(query: GetEventAsGuestQuery): Promise<Event> {
  const url = `${apiUrl}/api/unauthorized/events/get-event-as-guest-query`;
  return performApiRequest<Event>({ url, method: 'POST', data: query });
}

// -- Commands

export function registerForEventAsExternalGuest(command: RegisterForEventAsExternalGuestCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/events/register-for-event-as-external-guest-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function confirmEventRegistrationAsExternalGuest(command: ConfirmEventRegistrationAsExternalGuestCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/events/confirm-event-registration-as-external-guest-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function cancelEventRegistrationAsExternalGuest(command: CancelEventRegistrationAsExternalGuestCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/events/cancel-event-registration-as-external-guest-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function confirmEventRegistrationCancellationAsExternalGuest(
  command: ConfirmEventRegistrationCancellationAsExternalGuestCommand
): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/events/confirm-event-registration-cancellation-as-external-guest-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
