import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import ManageFarmDocuments from './components/manage-farm-documents.vue';

export const farmDocumentsManagementRoutes: RouteConfig[] = [
  {
    name: 'admin-manage-farm-documents',
    path: 'dokumente',
    component: ManageFarmDocuments,
    meta: {
      title: 'Dokumente',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresPermission: FarmManagerPermission.FARM_DOCUMENTS_READ,
    },
  },
];
