import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { AcceptPushNotificationsForDevice, Authentication, Farm, LoginCommand, RejectPushNotificationsForDeviceCommand, RequestPasswordResetCommand, ResetPasswordCommand } from './types';

// -- Queries

export function login(command: LoginCommand): Promise<Authentication> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/login`;
  return performApiRequest<Authentication>({ url, method: 'POST', data: command });
}

export function getAuthentication(): Promise<Authentication> {
  const url = `${apiUrl}/api/application/authentication/authentication`;
  return performApiRequest<Authentication>({ url, method: 'GET' });
}

// -- Commands

export function logout(): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/application/authentication/logout`;
  return performApiRequest({ url, method: 'POST' });
}

export function allowImpersonation(token: string): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/user-management/allow-impersonation-command`;
  return performApiRequest<void>({ url, method: 'POST', data: { token } });
}

export function requestPasswordReset(command: RequestPasswordResetCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/user-management/request-password-reset-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function resetPassword(command: ResetPasswordCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/user-management/reset-password-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function acceptPushNotificationsForDevice(command: AcceptPushNotificationsForDevice): Promise<Farm> {
  const url = `${apiUrl}/api/application/profile/accept-push-notifications-for-device-command`;
  return performApiRequest<Farm>({ url, method: 'POST', data: command });
}

export function rejectPushNotificationsForDevice(command: RejectPushNotificationsForDeviceCommand): Promise<Farm> {
  const url = `${apiUrl}/api/application/profile/reject-push-notifications-for-device-command`;
  return performApiRequest<Farm>({ url, method: 'POST', data: command });
}
