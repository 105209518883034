
import { Component, Prop, Vue } from 'vue-property-decorator';
import { dialogWidthTranslation } from '@/helpers/data';

type HighlightType = 'danger' | 'warning';

@Component
export default class ConfirmationDialog extends Vue {

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly buttonText?: string;

  @Prop({ type: String, default: null })
  readonly highlight!: HighlightType | null;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  @Prop({ type: String, default: 'small' })
  readonly size!: string;

  isDialogVisible = false;

  get dialogMaxWidth(): number {
    const dialogWidth = dialogWidthTranslation[this.size] || null;
    if (dialogWidth === null) {
      throw new Error('Invalid dialog size');
    }

    return dialogWidth;
  }

  confirmed(): void {
    this.isDialogVisible = false;
    this.$emit('confirmed');
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
