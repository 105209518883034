import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature } from '@/types';
import LaborServicesLoadingWrapper from './components/labor-services-loading-wrapper.vue';

export const laborServiceRoutes: RouteConfig[] = [
  {
    // Warning - Used in push notifications
    name: 'labor-service',
    path: '/arbeitsdienst',
    component: LaborServicesLoadingWrapper,
    meta: {
      title: 'Arbeitsdienst',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresFeature: Feature.LABOR_SERVICE,
    },
  },
];
