
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useEventAsExternalGuestsStore } from '../store';
import EventPresentation from './event-presentation.vue';

@Component({
  components: {
    EventPresentation,
  },
})
export default class CurrentEvent extends Vue {

  readonly store = useEventAsExternalGuestsStore();
  readonly authenticationStore = useAuthenticationStore();

  mounted(): void {
    this.store.updateCurrentEvent(this.$route.params.eventId)
      .then(() => {
        if (this.authenticationStore.isAuthenticated
          && this.authenticationStore.user!.farm!.id === this.store.currentEvent!.farmId
        ) {
          this.$router.push({ name: 'event-details', params: { id: this.store.currentEventId! } });
        }
      })
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetCurrentEvent()
      .catch((error) => showErrorResponse(error));
  }

}
