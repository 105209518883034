import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { Date, HorseId, UserId } from '@/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { createTask, getFarmManagersForAssignment, getHorses, getTasksGroupedByHorse, getRecentTasks, getTasksGroupedByUser, getOutdatedTasks, getMyTasks, markTaskAsCompleted, updateTitleAndDetailsOfTask, updateLastRepetitionOfTask, deleteTask, assignTaskToUser, updateExecutionDateOfTask, getOurTasks, getOverdueTasks, updateTimeConfigurationOfTask } from './service';
import { CreateTaskAsManagerCommand, GetTasksGroupedByHorseAsManagerQuery, GetRecentTasksAsManagerQuery, GetTasksGroupedByUserAsManagerQuery, Horse, HorseWithTasks, RecentTasks, TasksGroupedByUser, User, OutdatedTasks, GetOutdatedTasksAsManagerQuery, MyTasks, GetMyTasksAsManagerQuery, MarkTaskAsCompletedAsManagerCommand, UpdateTitleAndDetailsOfTaskAsManagerCommand, UpdateLastRepetitionOfTaskAsManagerCommand, DeleteTaskAsManagerCommand, AssignTaskToUserAsManagerCommand, UpdateExecutionDateOfTaskAsManagerCommand, OurTasks, GetOurTasksAsManagerQuery, OverdueTasks, UpdateTimeConfigurationOfTaskAsManagerCommand } from './types';

interface FarmTaskManagementState {
  farmManagers: User[];
  horses: Horse[];

  userForRecentTasks: UserId | null;
  horseForRecentTasks: HorseId | null;
  searchForRecentTasks: string | null;
  recentTasks: RecentTasks | null;
  limitForRecentTasks: number;

  userForOutdatedTasks: UserId | null;
  horseForOutdatedTasks: HorseId | null;
  searchForOutdatedTasks: string | null;
  outdatedTasks: OutdatedTasks | null;
  limitForOutdatedTasks: number;

  executionDateForGroupedByUser: Date | null;
  searchForGroupedByUser: string | null;
  tasksGroupedByUser: TasksGroupedByUser | null;

  executionDateForGroupedByHorse: Date | null;
  searchForGroupedByHorse: string | null;
  horseForGroupedByHorse: HorseId | null;
  horsesWithTasks: HorseWithTasks[] | null;

  executionDateForMyTasks: Date | null;
  myTasks: MyTasks | null;

  executionDateForOurTasks: Date | null;
  ourTasks: OurTasks | null;

  overdueTasks: OverdueTasks | null;

  createTaskStatus: ActionStatus;
  getFarmManagersForAssignmentStatus: ActionStatus;
  getHorsesStatus: ActionStatus;
  getTasksGroupedByUserStatus: ActionStatus;
  getTasksGroupedByHorseStatus: ActionStatus;
  getRecentTasksStatus: ActionStatus;
  getOutdatedTasksStatus: ActionStatus;
  getMyTasksStatus: ActionStatus;
  markTaskAsCompletedStatus: ActionStatus;
  updateTitleAndDetailsOfTaskStatus: ActionStatus;
  updateLastRepetitionOfTaskStatus: ActionStatus;
  deleteTaskStatus: ActionStatus;
  assignTaskToUserStatus: ActionStatus;
  updateExecutionDateOfTaskStatus: ActionStatus;
  getOurTasksStatus: ActionStatus;
  getOverdueTasksStatus: ActionStatus;
  updateTimeConfigurationOfTaskStatus: ActionStatus;
}

function initialState(): FarmTaskManagementState {
  return {
    farmManagers: [],
    horses: [],

    userForRecentTasks: null,
    horseForRecentTasks: null,
    searchForRecentTasks: null,
    recentTasks: null,
    limitForRecentTasks: 50,

    userForOutdatedTasks: null,
    horseForOutdatedTasks: null,
    searchForOutdatedTasks: null,
    outdatedTasks: null,
    limitForOutdatedTasks: 50,

    executionDateForGroupedByUser: null,
    searchForGroupedByUser: null,
    tasksGroupedByUser: null,

    executionDateForGroupedByHorse: null,
    searchForGroupedByHorse: null,
    horseForGroupedByHorse: null,
    horsesWithTasks: null,

    executionDateForMyTasks: null,
    myTasks: null,

    executionDateForOurTasks: null,
    ourTasks: null,

    overdueTasks: null,

    createTaskStatus: ActionStatus.None,
    getFarmManagersForAssignmentStatus: ActionStatus.None,
    getHorsesStatus: ActionStatus.None,
    getTasksGroupedByUserStatus: ActionStatus.None,
    getTasksGroupedByHorseStatus: ActionStatus.None,
    getRecentTasksStatus: ActionStatus.None,
    getOutdatedTasksStatus: ActionStatus.None,
    getMyTasksStatus: ActionStatus.None,
    markTaskAsCompletedStatus: ActionStatus.None,
    updateTitleAndDetailsOfTaskStatus: ActionStatus.None,
    updateLastRepetitionOfTaskStatus: ActionStatus.None,
    deleteTaskStatus: ActionStatus.None,
    assignTaskToUserStatus: ActionStatus.None,
    updateExecutionDateOfTaskStatus: ActionStatus.None,
    getOurTasksStatus: ActionStatus.None,
    getOverdueTasksStatus: ActionStatus.None,
    updateTimeConfigurationOfTaskStatus: ActionStatus.None,
  };
}

export const useFarmTaskManagementStore = defineStore('manageFarmTasks', {
  state: (): FarmTaskManagementState => initialState(),
  getters: {
    isCreateTaskProcessing: (state: FarmTaskManagementState): boolean =>
      state.createTaskStatus === ActionStatus.InProgress,
    isGetFarmManagersForAssignmentProcessing: (state: FarmTaskManagementState): boolean =>
      state.getFarmManagersForAssignmentStatus === ActionStatus.InProgress,
    isGetHorsesProcessing: (state: FarmTaskManagementState): boolean =>
      state.getHorsesStatus === ActionStatus.InProgress,
    isGetTasksGroupedByUserProcessing: (state: FarmTaskManagementState): boolean =>
      state.getTasksGroupedByUserStatus === ActionStatus.InProgress,
    isGetTasksGroupedByHorseProcessing: (state: FarmTaskManagementState): boolean =>
      state.getTasksGroupedByHorseStatus === ActionStatus.InProgress,
    isGetRecentTasksProcessing: (state: FarmTaskManagementState): boolean =>
      state.getRecentTasksStatus === ActionStatus.InProgress,
    isGetOutdatedTasksProcessing: (state: FarmTaskManagementState): boolean =>
      state.getOutdatedTasksStatus === ActionStatus.InProgress,
    isGetMyTasksProcessing: (state: FarmTaskManagementState): boolean =>
      state.getMyTasksStatus === ActionStatus.InProgress,
    isMarkTaskAsCompletedProcessing: (state: FarmTaskManagementState): boolean =>
      state.markTaskAsCompletedStatus === ActionStatus.InProgress,
    isUpdateTitleAndDetailsOfTaskProcessing: (state: FarmTaskManagementState): boolean =>
      state.updateTitleAndDetailsOfTaskStatus === ActionStatus.InProgress,
    isUpdateLastRepetitionOfTaskProcessing: (state: FarmTaskManagementState): boolean =>
      state.updateLastRepetitionOfTaskStatus === ActionStatus.InProgress,
    isDeleteTaskProcessing: (state: FarmTaskManagementState): boolean =>
      state.deleteTaskStatus === ActionStatus.InProgress,
    isAssignTaskToUserProcessing: (state: FarmTaskManagementState): boolean =>
      state.assignTaskToUserStatus === ActionStatus.InProgress,
    isUpdateExecutionDateOfTaskProcessing: (state: FarmTaskManagementState): boolean =>
      state.updateExecutionDateOfTaskStatus === ActionStatus.InProgress,
    isGetOurTasksProcessing: (state: FarmTaskManagementState): boolean =>
      state.getOurTasksStatus === ActionStatus.InProgress,
    isGetOverdueTasksProcessing: (state: FarmTaskManagementState): boolean =>
      state.getOverdueTasksStatus === ActionStatus.InProgress,
    isUpdateTimeConfigurationOfTaskProcessing: (state: FarmTaskManagementState): boolean =>
      state.updateTimeConfigurationOfTaskStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    // Grouped by user

    updateExecutionDateForGroupedByUser(date: Date): Promise<void> {
      this.executionDateForGroupedByUser = date;

      return this.getTasksGroupedByUser();
    },

    updateSearchForGroupedByUser(search: string | null): Promise<void> {
      this.searchForGroupedByUser = search;

      return this.getTasksGroupedByUser();
    },

    // Grouped by horse

    updateExecutionDateForGroupedByHorse(date: Date): Promise<void> {
      this.executionDateForGroupedByHorse = date;

      return this.getTasksGroupedByHorse();
    },

    updateSearchForGroupedByHorse(search: string | null): Promise<void> {
      this.searchForGroupedByHorse = search;

      return this.getTasksGroupedByHorse();
    },

    updateHorseForGroupedByHorse(horseId: HorseId | null): Promise<void> {
      this.horseForGroupedByHorse = horseId;

      return this.getTasksGroupedByHorse();
    },

    // Recent tasks

    updateUserForRecentTasks(user: UserId | null): Promise<void> {
      this.userForRecentTasks = user;

      return this.getRecentTasks();
    },

    updateHorseForRecentTasks(horseId: HorseId | null): Promise<void> {
      this.horseForRecentTasks = horseId;

      return this.getRecentTasks();
    },

    updateSearchForRecentTasks(search: string | null): Promise<void> {
      this.searchForRecentTasks = search;

      return this.getRecentTasks();
    },

    increaseLimitForRecentTasks(): Promise<void> {
      this.limitForRecentTasks += 50;

      return this.getRecentTasks();
    },

    // Outdated tasks

    updateUserForOutdatedTasks(user: UserId | null): Promise<void> {
      this.userForOutdatedTasks = user;

      return this.getOutdatedTasks();
    },

    updateHorseForOutdatedTasks(horseId: HorseId | null): Promise<void> {
      this.horseForOutdatedTasks = horseId;

      return this.getOutdatedTasks();
    },

    updateSearchForOutdatedTasks(search: string | null): Promise<void> {
      this.searchForOutdatedTasks = search;

      return this.getOutdatedTasks();
    },

    increaseLimitForOutdatedTasks(): Promise<void> {
      this.limitForOutdatedTasks += 50;

      return this.getOutdatedTasks();
    },

    // My tasks

    updateExecutionDateForMyTasks(date: Date): Promise<void> {
      this.executionDateForMyTasks = date;

      return this.getMyTasks();
    },

    // Our tasks

    updateExecutionDateForOurTasks(date: Date): Promise<void> {
      this.executionDateForOurTasks = date;

      return this.getOurTasks();
    },

    // -- Queries

    getFarmManagersForAssignment(): Promise<void> {
      const { getFarmManagersForAssignmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmManagersForAssignmentStatus,
        () => getFarmManagersForAssignment(attachFarmAndUserProperties({}))
          .then(async (farmManagers) => {
            this.farmManagers = farmManagers;
          })
      );
    },

    getHorses(): Promise<void> {
      const { getHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorsesStatus,
        () => getHorses(attachFarmAndUserProperties({}))
          .then(async (horses) => {
            this.horses = horses;
          })
      );
    },

    getTasksGroupedByUser(): Promise<void> {
      const query: GetTasksGroupedByUserAsManagerQuery = {
        executionDate: this.executionDateForGroupedByUser!,
        search: this.searchForGroupedByUser,
      };
      const { getTasksGroupedByUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTasksGroupedByUserStatus,
        () => getTasksGroupedByUser(attachFarmAndUserProperties(query))
          .then(async (tasksGroupedByUser) => {
            this.tasksGroupedByUser = tasksGroupedByUser;
          })
      );
    },

    getTasksGroupedByHorse(): Promise<void> {
      const query: GetTasksGroupedByHorseAsManagerQuery = {
        executionDate: this.executionDateForGroupedByHorse!,
        horseId: this.horseForGroupedByHorse,
        search: this.searchForGroupedByHorse,
      };
      const { getTasksGroupedByHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTasksGroupedByHorseStatus,
        () => getTasksGroupedByHorse(attachFarmAndUserProperties(query))
          .then(async (horsesWithTasks) => {
            this.horsesWithTasks = horsesWithTasks;
          })
      );
    },

    getRecentTasks(): Promise<void> {
      const query: GetRecentTasksAsManagerQuery = {
        idOfAssignedUser: this.userForRecentTasks,
        horseId: this.horseForRecentTasks,
        search: this.searchForRecentTasks,
        limit: this.limitForRecentTasks,
      };
      const { getRecentTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getRecentTasksStatus,
        () => getRecentTasks(attachFarmAndUserProperties(query))
          .then(async (recentTasks) => {
            this.recentTasks = recentTasks;
          })
      );
    },

    getOutdatedTasks(): Promise<void> {
      const query: GetOutdatedTasksAsManagerQuery = {
        idOfAssignedUser: this.userForOutdatedTasks,
        horseId: this.horseForOutdatedTasks,
        search: this.searchForOutdatedTasks,
        limit: this.limitForOutdatedTasks,
      };
      const { getOutdatedTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOutdatedTasksStatus,
        () => getOutdatedTasks(attachFarmAndUserProperties(query))
          .then(async (outdatedTasks) => {
            this.outdatedTasks = outdatedTasks;
          })
      );
    },

    getMyTasks(): Promise<void> {
      const query: GetMyTasksAsManagerQuery = {
        executionDate: this.executionDateForMyTasks!,
      };
      const { getMyTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getMyTasksStatus,
        () => getMyTasks(attachFarmAndUserProperties(query))
          .then(async (myTasks) => {
            this.myTasks = myTasks;
          })
      );
    },

    getOurTasks(): Promise<void> {
      const query: GetOurTasksAsManagerQuery = {
        executionDate: this.executionDateForOurTasks!,
      };
      const { getOurTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOurTasksStatus,
        () => getOurTasks(attachFarmAndUserProperties(query))
          .then(async (ourTasks) => {
            this.ourTasks = ourTasks;
          })
      );
    },

    getOverdueTasks(): Promise<void> {
      const { getOverdueTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOverdueTasksStatus,
        () => getOverdueTasks(attachFarmAndUserProperties({}))
          .then(async (overdueTasks) => {
            this.overdueTasks = overdueTasks;
          })
      );
    },

    // -- Commands

    createTask(command: CreateTaskAsManagerCommand): Promise<void> {
      const { createTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createTaskStatus,
        () => createTask(attachFarmAndUserProperties(command))
      );
    },

    markTaskAsCompleted(command: MarkTaskAsCompletedAsManagerCommand): Promise<void> {
      const { markTaskAsCompletedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTaskAsCompletedStatus,
        () => markTaskAsCompleted(attachFarmAndUserProperties(command))
      );
    },

    updateTitleAndDetailsOfTask(command: UpdateTitleAndDetailsOfTaskAsManagerCommand): Promise<void> {
      const { updateTitleAndDetailsOfTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTitleAndDetailsOfTaskStatus,
        () => updateTitleAndDetailsOfTask(attachFarmAndUserProperties(command))
      );
    },

    updateLastRepetitionOfTask(command: UpdateLastRepetitionOfTaskAsManagerCommand): Promise<void> {
      const { updateLastRepetitionOfTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateLastRepetitionOfTaskStatus,
        () => updateLastRepetitionOfTask(attachFarmAndUserProperties(command))
      );
    },

    deleteTask(command: DeleteTaskAsManagerCommand): Promise<void> {
      const { deleteTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteTaskStatus,
        () => deleteTask(attachFarmAndUserProperties(command))
      );
    },

    assignTaskToUser(command: AssignTaskToUserAsManagerCommand): Promise<void> {
      const { assignTaskToUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        assignTaskToUserStatus,
        () => assignTaskToUser(attachFarmAndUserProperties(command))
      );
    },

    updateExecutionDateOfTask(command: UpdateExecutionDateOfTaskAsManagerCommand): Promise<void> {
      const { updateExecutionDateOfTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateExecutionDateOfTaskStatus,
        () => updateExecutionDateOfTask(attachFarmAndUserProperties(command))
      );
    },

    updateTimeConfigurationOfTask(command: UpdateTimeConfigurationOfTaskAsManagerCommand): Promise<void> {
      const { updateTimeConfigurationOfTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTimeConfigurationOfTaskStatus,
        () => updateTimeConfigurationOfTask(attachFarmAndUserProperties(command))
      );
    },

  },
});
