import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { DeleteFacilityReservationAsManagerCommand, CreateFacilityReservationActivityAsManagerCommand, DisableFacilityReservationActivityAsManagerCommand, EnableFacilityReservationActivityAsManagerCommand, Facility, GetFacilityReservationsForFacilityAsManagerQuery, GetFacilityReservationsForFacilityResponse, UpdateReservationDisplayNameTypeOfFacilityAsManagerCommand, UpdateBookingInAdvanceOfFacilityAsManagerCommand, UpdateBookingNoticeOfFacilityAsManagerCommand, UpdateMaxUsageOfFacilityAsManagerCommand, UpdateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityAsManagerCommand, UpdateTimeOptionIntervalOfFacilityAsManagerCommand, UpdateTimeBasedRestrictionsOfFacilityReservationActivityAsManagerCommand, UpdateColorOfFacilityReservationActivityAsManagerCommand, UpdateNameOfFacilityReservationActivityAsManagerCommand, UpdateRequiredSpacesOfFacilityReservationActivityAsManagerCommand, UpdateTimeOptionIntervalRangeOfFacilityReservationActivityAsManagerCommand, UpdateOrderOfFacilityReservationActivitiesOfFacilityAsManagerCommand, SetUpFacilityReservationsForFacilityAsManagerCommand, AllowParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand, ForbidParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand, UpdateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityAsManagerCommand, EvaluationFilterData, GetEvaluationByUserAsManagerQuery, EvaluationByUser, GetEvaluationByHorseOwnerAsManagerQuery, EvaluationByHorseOwner } from './types';

// -- Queries

export function loadFacilities(query: FarmAndUserProperties): Promise<Facility[]> {
  const url = `${apiUrl}/api/facility-reservations/get-facilities-overview-as-manager-query`;
  return performApiRequest<Facility[]>({ url, method: 'POST', data: query });
}

export function getFacilityReservationsForFacility(
  query: GetFacilityReservationsForFacilityAsManagerQuery & FarmAndUserProperties
): Promise<GetFacilityReservationsForFacilityResponse> {
  const url = `${apiUrl}/api/facility-reservations/get-facility-reservations-for-facility-as-manager-query`;
  return performApiRequest<GetFacilityReservationsForFacilityResponse>({ url, method: 'POST', data: query });
}

export function getEvaluationFilterData(query: FarmAndUserProperties): Promise<EvaluationFilterData> {
  const url = `${apiUrl}/api/facility-reservations/get-evaluation-filter-data-as-manager-query`;
  return performApiRequest<EvaluationFilterData>({ url, method: 'POST', data: query });
}

export function getEvaluationByUser(query: GetEvaluationByUserAsManagerQuery & FarmAndUserProperties): Promise<EvaluationByUser> {
  const url = `${apiUrl}/api/facility-reservations/get-evaluation-by-user-as-manager-query`;
  return performApiRequest<EvaluationByUser>({ url, method: 'POST', data: query });
}

export function getEvaluationByHorseOwner(
  query: GetEvaluationByHorseOwnerAsManagerQuery & FarmAndUserProperties
): Promise<EvaluationByHorseOwner> {
  const url = `${apiUrl}/api/facility-reservations/get-evaluation-by-horse-owner-as-manager-query`;
  return performApiRequest<EvaluationByHorseOwner>({ url, method: 'POST', data: query });
}

// -- Commands

export function setUpFacilityReservationsForFacility(
  command: SetUpFacilityReservationsForFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/set-up-facility-reservations-for-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateBookingInAdvanceOfFacility(
  command: UpdateBookingInAdvanceOfFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-booking-in-advance-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateTimeOptionIntervalOfFacility(
  command: UpdateTimeOptionIntervalOfFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-time-option-interval-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateMaxUsageOfFacility(command: UpdateMaxUsageOfFacilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-max-usage-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacility(
  command: UpdateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  /* eslint-disable-next-line max-len */
  const url = `${apiUrl}/api/facility-reservations/update-min-distance-to-own-bookings-with-different-horses-intervals-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateReservationDisplayNameTypeOfFacility(
  command: UpdateReservationDisplayNameTypeOfFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-reservation-display-name-type-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateBookingNoticeOfFacility(
  command: UpdateBookingNoticeOfFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-booking-notice-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createFacilityReservationActivity(
  command: CreateFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/create-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateNameOfFacilityReservationActivity(
  command: UpdateNameOfFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-name-of-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateTimeOptionIntervalRangeOfFacilityReservationActivity(
  command: UpdateTimeOptionIntervalRangeOfFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-time-option-interval-range-of-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateRequiredSpacesOfFacilityReservationActivity(
  command: UpdateRequiredSpacesOfFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-required-spaces-of-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateColorOfFacilityReservationActivity(
  command: UpdateColorOfFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-color-of-facility-reservation-activity-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateTimeBasedRestrictionsOfFacilityReservationActivity(
  command: UpdateTimeBasedRestrictionsOfFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  /* eslint-disable-next-line max-len */
  const url = `${apiUrl}/api/facility-reservations/update-time-based-restrictions-of-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function enableFacilityReservationActivity(
  command: EnableFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/enable-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function disableFacilityReservationActivity(
  command: DisableFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/disable-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOrderOfFacilityReservationActivitiesOfFacility(
  command: UpdateOrderOfFacilityReservationActivitiesOfFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-order-of-facility-reservation-activities-of-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteFacilityReservation(command: DeleteFacilityReservationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/delete-facility-reservation-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function allowParallelReserverationInOtherFacilitiesForFacility(
  command: AllowParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/allow-parallel-reservations-in-other-facilities-for-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function forbidParallelReserverationInOtherFacilitiesForFacility(
  command: ForbidParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/forbid-parallel-reservations-in-other-facilities-for-facility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivity(
  command: UpdateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  // eslint-disable-next-line max-len
  const url = `${apiUrl}/api/facility-reservations/update-is-rider-able-to-reserve-multiple-parallel-activities-of-facility-reservation-activity-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
