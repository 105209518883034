
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { RegistrationCode } from '@/types';

function registrationCodeRule(): InternalValueRule<string> {
  return (value) => value.trim().length === 0
    || isValidRegistrationCode(value)
    ? true
    : 'Nur Kleinbuchstaben, Strich und Punkt sind erlaubt';
}

function isValidRegistrationCode(value: string): boolean {
  return /^[a-züäö0-9\-.]*$/.test(value);
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class RegistrationCodeFormControl extends Vue implements FormControlComponent<RegistrationCode> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<RegistrationCode>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  readonly internalFieldRules: InternalValueRules = [
    registrationCodeRule(),
  ];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  // We should refactor this as it also includes external rules
  get isValid(): boolean {
    return this.messages.length === 0;
  }

  mounted(): void {
    mountFormControl(this);
  }

  textChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.internalFieldRules, this.internalValue),
    ];

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<RegistrationCode> {
    return this.internalValue.trim().length > 0
      && this.isValid
      ? this.internalValue.trim()
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
