import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { AssignedTaskForToday, MarkTaskAsRejectedAsManagerCommand, MarkTaskAsCompletedAsManagerCommand, GetNewTasksAsManagerQuery, NewTasksResponse, WithdrawnTasksResponse, RejectedTasksResponse, User, GetRejectedTasksAsManagerQuery, GetWithdrawnTasksAsManagerQuery, GetCompletedTasksAsManagerQuery, CompletedTasksResponse, AssignTaskToUserAsManagerCommand, GetAssignedTasksAsManagerQuery, AssignedTasksResponse, CreateAssignedTaskAsManagerCommand, OverdueTask, UpdateExecutionDateOfTaskAsManagerCommand } from './types';

// -- Queries

export function getUsersWithTasks(query: FarmAndUserProperties): Promise<User[]> {
  const url = `${apiUrl}/api/rider-tasks/get-users-with-tasks-as-manager-query`;
  return performApiRequest<User[]>({ url, method: 'POST', data: query });
}

export function getFarmManagersForAssigment(query: FarmAndUserProperties): Promise<User[]> {
  const url = `${apiUrl}/api/rider-tasks/get-farm-managers-for-assignment-as-manager-query`;
  return performApiRequest<User[]>({ url, method: 'POST', data: query });
}

export function getNewTasks(query: GetNewTasksAsManagerQuery & FarmAndUserProperties): Promise<NewTasksResponse> {
  const url = `${apiUrl}/api/rider-tasks/get-new-tasks-as-manager-query`;
  return performApiRequest<NewTasksResponse>({ url, method: 'POST', data: query });
}

export function getAssignedTasks(query: GetAssignedTasksAsManagerQuery & FarmAndUserProperties): Promise<AssignedTasksResponse> {
  const url = `${apiUrl}/api/rider-tasks/get-assigned-tasks-as-manager-query`;
  return performApiRequest<AssignedTasksResponse>({ url, method: 'POST', data: query });
}

export function getCompletedTasks(query: GetCompletedTasksAsManagerQuery & FarmAndUserProperties): Promise<CompletedTasksResponse> {
  const url = `${apiUrl}/api/rider-tasks/get-completed-tasks-as-manager-query`;
  return performApiRequest<CompletedTasksResponse>({ url, method: 'POST', data: query });
}

export function getRejectedTasks(query: GetRejectedTasksAsManagerQuery & FarmAndUserProperties): Promise<RejectedTasksResponse> {
  const url = `${apiUrl}/api/rider-tasks/get-rejected-tasks-as-manager-query`;
  return performApiRequest<RejectedTasksResponse>({ url, method: 'POST', data: query });
}

export function getWithdrawnTasks(query: GetWithdrawnTasksAsManagerQuery & FarmAndUserProperties): Promise<WithdrawnTasksResponse> {
  const url = `${apiUrl}/api/rider-tasks/get-withdrawn-tasks-as-manager-query`;
  return performApiRequest<WithdrawnTasksResponse>({ url, method: 'POST', data: query });
}

export function getAssignedTasksForToday(query: FarmAndUserProperties): Promise<AssignedTaskForToday[]> {
  const url = `${apiUrl}/api/rider-tasks/get-assigned-tasks-for-today-as-manager-query`;
  return performApiRequest<AssignedTaskForToday[]>({ url, method: 'POST', data: query });
}

export function getOverdueTasks(query: FarmAndUserProperties): Promise<OverdueTask[]> {
  const url = `${apiUrl}/api/rider-tasks/get-overdue-tasks-as-manager-query`;
  return performApiRequest<OverdueTask[]>({ url, method: 'POST', data: query });
}

export function getUsersForAssignedTasks(query: FarmAndUserProperties): Promise<User[]> {
  const url = `${apiUrl}/api/rider-tasks/get-users-for-assigned-tasks-as-manager-query`;
  return performApiRequest<User[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function assignTaskToUser(command: AssignTaskToUserAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/assign-task-to-user-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markTaskAsCompleted(command: MarkTaskAsCompletedAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/mark-task-as-completed-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markTaskAsRejected(command: MarkTaskAsRejectedAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/mark-task-as-rejected-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createAssignedTask(command: CreateAssignedTaskAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/create-assigned-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateExecutionDateOfTask(command: UpdateExecutionDateOfTaskAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/update-execution-date-of-task-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
