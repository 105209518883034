
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BlurHashImage, DialogWidth } from '@/helpers/data';

@Component
export default class ShowHorseVeterinarianAppointmentImageDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly image!: BlurHashImage;

  isDialogVisible = false;

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
