import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateVaccinationAsManagerCommand, DataForVaccinationCreation, MedicalRecords, MissingVaccinations, CreateHorseWeightEntryAsManagerCommand, VaccinationStatuses, VaccinationsThatExpireSoon, CreateHorseDrugEntryAsManagerCommand, CreateHorseFarrierAppointmentAsManagerCommand, CreateHorseWormingTreatmentAsManagerCommand, CreateHorseWormingTreatmentForMultipleHorsesAsManagerCommand, Horse } from './types';

// -- Queries

export function getVaccinationStatuses(query: FarmAndUserProperties): Promise<VaccinationStatuses> {
  const url = `${apiUrl}/api/medical-records/get-vaccination-statuses-as-manager-query`;
  return performApiRequest<VaccinationStatuses>({ url, method: 'POST', data: query });
}

export function getHorsesWithMissingVaccinations(query: FarmAndUserProperties): Promise<MissingVaccinations> {
  const url = `${apiUrl}/api/medical-records/get-horses-with-missing-vaccinations-as-manager-query`;
  return performApiRequest<MissingVaccinations>({ url, method: 'POST', data: query });
}

export function getHorsesWithVaccinationsThatExpireSoon(query: FarmAndUserProperties): Promise<VaccinationsThatExpireSoon> {
  const url = `${apiUrl}/api/medical-records/get-horses-with-vaccinations-that-expire-soon-as-manager-query`;
  return performApiRequest<VaccinationsThatExpireSoon>({ url, method: 'POST', data: query });
}

export function getDataForVaccinationCreation(query: FarmAndUserProperties): Promise<DataForVaccinationCreation> {
  const url = `${apiUrl}/api/medical-records/get-data-for-vaccination-creation-as-manager-query`;
  return performApiRequest<DataForVaccinationCreation>({ url, method: 'POST', data: query });
}

export function getMedicalRecords(query: FarmAndUserProperties): Promise<MedicalRecords> {
  const url = `${apiUrl}/api/medical-records/get-medical-records-as-manager-query`;
  return performApiRequest<MedicalRecords>({ url, method: 'POST', data: query });
}

export function getHorses(query: FarmAndUserProperties): Promise<Horse[]> {
  const url = `${apiUrl}/api/medical-records/get-horses-as-manager-query`;
  return performApiRequest<Horse[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createVaccination(command: CreateVaccinationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-vaccination-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createHorseWeightEntry(command: CreateHorseWeightEntryAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-weight-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createHorseDrugEntry(command: CreateHorseDrugEntryAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-drug-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createHorseFarrierAppointment(
  command: CreateHorseFarrierAppointmentAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-farrier-appointment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createHorseWormingTreatment(command: CreateHorseWormingTreatmentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-worming-treatment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function createHorseWormingTreatmentForMultipleHorses(
  command: CreateHorseWormingTreatmentForMultipleHorsesAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-horse-worming-treatment-for-multiple-horses-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
