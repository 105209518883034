
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { VuePdfEmbedMethods } from 'vue-pdf-embed';
import { DialogWidth } from '@/helpers/data';
import { downloadFile } from '@/helpers/file-download-helper';
import { showErrorResponse } from '@/application/snackbar/service';
import { usePdfDialogStore } from './store';

@Component({
  components: {
    VuePdfEmbed,
  },
})
export default class PdfDialog extends Vue {

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, default: null })
  readonly pdfTitle!: string | null;

  @Prop({ type: String, required: true })
  readonly pdfFileUrl!: string;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  readonly dialogMaxWidth = DialogWidth.large;

  @Ref('pdfView')
  readonly pdfView?: VuePdfEmbedMethods;

  readonly store = usePdfDialogStore();

  mounted(): void {
    window.addEventListener('resize', this.rerenderPdf);
  }

  destroyed(): void {
    window.removeEventListener('resize', this.rerenderPdf);
  }

  isDialogVisible = false;

  isLoading = true;

  public show(): void {
    this.isDialogVisible = true;
  }

  pdfLoaded(): void {
    this.isLoading = false;
  }

  rerenderPdf(): void {
    if (this.pdfView) {
      this.pdfView.render();
    }
  }

  downloadPdf(): void {
    const relevantTitle = this.pdfTitle ?? this.title;
    const fileName = `${relevantTitle.replace(/[^a-z0-9]/gi, '-')}.pdf`;

    this.store.getPdfFile(this.pdfFileUrl)
      .then((response) => downloadFile(response.data, response.contentType, fileName))
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
