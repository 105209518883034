import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { createDocument, deleteDocument, getDocuments, updateDocumentDescription, updateDocumentFile } from './service';
import { CreateDocumentAsManagerCommand, DeleteDocumentAsManagerCommand, FarmDocument, UpdateDocumentDescriptionAsManagerCommand, UpdateDocumentFileAsManagerCommand } from './types';

interface FarmDocumentsManagementState {
  farmDocuments: FarmDocument[];

  getDocumentsStatus: ActionStatus;
  createDocumentStatus: ActionStatus;
  updateDocumentFileStatus: ActionStatus;
  updateDocumentDescriptionStatus: ActionStatus;
  deleteDocumentStatus: ActionStatus;
}

function initialState(): FarmDocumentsManagementState {
  return {
    farmDocuments: [],

    getDocumentsStatus: ActionStatus.None,
    createDocumentStatus: ActionStatus.None,
    updateDocumentFileStatus: ActionStatus.None,
    updateDocumentDescriptionStatus: ActionStatus.None,
    deleteDocumentStatus: ActionStatus.None,
  };
}

export const useFarmDocumentsManagementStore = defineStore('manageFarmDocuments', {
  state: (): FarmDocumentsManagementState => initialState(),
  getters: {
    isGetDocumentsProcessing: (state: FarmDocumentsManagementState): boolean =>
      state.getDocumentsStatus === ActionStatus.InProgress,
    isCreateDocumentProcessing: (state: FarmDocumentsManagementState): boolean =>
      state.createDocumentStatus === ActionStatus.InProgress,
    isUpdateDocumentFileProcessing: (state: FarmDocumentsManagementState): boolean =>
      state.updateDocumentFileStatus === ActionStatus.InProgress,
    isUpdateDocumentDescriptionProcessing: (state: FarmDocumentsManagementState): boolean =>
      state.updateDocumentDescriptionStatus === ActionStatus.InProgress,
    isDeleteDocumentProcessing: (state: FarmDocumentsManagementState): boolean =>
      state.deleteDocumentStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getDocuments(): Promise<void> {
      const { getDocumentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentsStatus,
        () => getDocuments(attachFarmAndUserProperties({}))
          .then(async (farmDocuments) => {
            this.farmDocuments = farmDocuments;
          })
      );
    },

    // -- Commands

    createDocument(command: CreateDocumentAsManagerCommand): Promise<void> {
      const { createDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createDocumentStatus,
        () => createDocument(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getDocuments())
      );
    },

    updateDocumentFile(command: UpdateDocumentFileAsManagerCommand): Promise<void> {
      const { updateDocumentFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDocumentFileStatus,
        () => updateDocumentFile(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getDocuments())
      );
    },

    updateDocumentDescription(command: UpdateDocumentDescriptionAsManagerCommand): Promise<void> {
      const { updateDocumentDescriptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDocumentDescriptionStatus,
        () => updateDocumentDescription(attachFarmAndUserProperties(command))
          .then(() => this.getDocuments())
      );
    },

    deleteDocument(command: DeleteDocumentAsManagerCommand): Promise<void> {
      const { deleteDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteDocumentStatus,
        () => deleteDocument(attachFarmAndUserProperties(command))
          .then(() => this.getDocuments())
      );
    },

  },
});
