import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateFeedTypeAsManagerCommand, DisableFeedTypeAsManagerCommand, FeedType, HideFeedTypeOnBoxSignAsManagerCommand, HorseForLatestFeedingUpdate, HorseWithFeeding, ShowFeedTypeOnBoxSignAsManagerCommand, UpdateNameOfFeedTypeAsManagerCommand, UpdateOfferOfFeedTypeAsManagerCommand } from './types';

// -- Queries

export function getFeedTypes(query: FarmAndUserProperties): Promise<FeedType[]> {
  const url = `${apiUrl}/api/feeding/get-feed-types-as-manager-query`;
  return performApiRequest<FeedType[]>({ url, method: 'POST', data: query });
}

export function getFeedingOverview(query: FarmAndUserProperties): Promise<HorseWithFeeding[]> {
  const url = `${apiUrl}/api/feeding/get-feeding-overview-as-manager-query`;
  return performApiRequest<HorseWithFeeding[]>({ url, method: 'POST', data: query });
}

export function getLatestFeedingSettingUpdates(query: FarmAndUserProperties): Promise<HorseForLatestFeedingUpdate[]> {
  const url = `${apiUrl}/api/feeding/get-latest-feeding-setting-updates-as-manager-query`;
  return performApiRequest<HorseForLatestFeedingUpdate[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createFeedType(command: CreateFeedTypeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/create-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function disableFeedType(command: DisableFeedTypeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/disable-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateNameOfFeedType(command: UpdateNameOfFeedTypeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/update-name-of-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateOfferOfFeedType(command: UpdateOfferOfFeedTypeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/update-offer-of-feed-type-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function hideFeedTypeOnBoxSign(command: HideFeedTypeOnBoxSignAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/hide-feed-type-on-box-sign-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function showFeedTypeOnBoxSign(command: ShowFeedTypeOnBoxSignAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feeding/show-feed-type-on-box-sign-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
