import { render, staticRenderFns } from "./our-tasks.vue?vue&type=template&id=7ffc8987&scoped=true&"
import script from "./our-tasks.vue?vue&type=script&setup=true&lang=ts&"
export * from "./our-tasks.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./our-tasks.vue?vue&type=style&index=0&id=7ffc8987&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ffc8987",
  null
  
)

export default component.exports