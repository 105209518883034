
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { EmailAddress } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useMyStableStore } from '../store';
import { InviteUserAsHorseShareCommand } from '../types';

interface Controls extends FormControls {
  emailAddress: FormControl<EmailAddress>;
}

@Component
export default class InviteUserAsHorseShareDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type : String, required: true })
  readonly horseId!: string;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail-Adresse',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: InviteUserAsHorseShareCommand = {
      horseId: this.horseId,
      emailAddress: formValues.emailAddress!,
    };

    this.store.inviteUserAsHorseShare(command)
      .then(() => showSuccessMessage('Der Benutzer wurde als Reitbeteiligung eingeladen.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
