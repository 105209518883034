import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature } from '@/types';
import { isFeatureEnabled } from '@/application/authentication/helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import BookingCalendars from './components/booking-calendars.vue';
import FacilityCalendarLoadingWrapper from './components/facility-calendar-loading-wrapper.vue';
import RidingLessonCalendar from './components/riding-lesson-calendar.vue';

export const bookingCalendarsRoutes: RouteConfig[] = [
  {
    path: '/buchungskalender',
    component: BookingCalendars,
    children: [
      {
        name: 'booking-calendars/root',
        path: '',
        redirect: () => {
          const authenticationStore = useAuthenticationStore();

          if (
            (isFeatureEnabled(Feature.FACILITY_RESERVATIONS)
              || isFeatureEnabled(Feature.RIDING_LESSONS)
            )
            && authenticationStore.facilities.length > 0
          ) {
            const facility = authenticationStore.facilities[0];

            return {
              name: 'booking-calendars/facility-calendar',
              params: { id: facility.facilityId },
            };
          }

          if (authenticationStore.user?.farm?.isRidingLessonPlanVisible) {
            return { name: 'booking-calendars/riding-lesson-plan' };
          }

          return { name: 'root' };
        },
      },
      {
        // Warning - Used in push notifications
        name: 'booking-calendars/facility-calendar',
        path: 'anlage/:id/:date?/:facilityReservationId?/:action?',
        component: FacilityCalendarLoadingWrapper,
        meta: {
          title: 'Buchungskalender',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
        },
      },
      {
        name: 'booking-calendars/facility-calendar-riding-lesson',
        path: 'anlage/:id/:date?/r/:ridingLessonId?/:action?',
        component: FacilityCalendarLoadingWrapper,
        meta: {
          title: 'Buchungskalender',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
        },
      },
      {
        // Warning - Used in push notifications
        name: 'booking-calendars/riding-lesson-plan',
        path: 'reitunterricht/:date?/:ridingLessonId?',
        component: RidingLessonCalendar,
        meta: {
          title: 'Reitunterricht',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.RIDING_LESSONS,
        },
      },
    ],
  },
];
