import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature } from '@/types';
import TaskList from './components/task-list.vue';

export const riderTasksRoutes: RouteConfig[] = [
  {
    name: 'task-list',
    path: '/aufgaben',
    component: TaskList,
    meta: {
      title: 'Aufgaben',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresFeature: Feature.RIDER_TASKS,
    },
  },
];
