
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Settlement, SettlementCategory } from '@/private/management/ledger/ledger-configuration/types';
import { categoriesFromSettlement } from '@/private/management/ledger/ledger-configuration/helper';

@Component
export default class SettlementCategories extends Vue {

  @Prop({ type: Object, required: true })
  readonly settlement!: Settlement;

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Kategorie', value: 'category' },
    { text: 'Betrag', value: 'grossTotalCosts', align: 'end' },
  ];

  get categories(): SettlementCategory[] {
    return categoriesFromSettlement(this.settlement);
  }

}
