import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { AnalyticsData } from './types';

// -- Queries

export function getFarmAnalytics(query: FarmAndUserProperties): Promise<AnalyticsData> {
  const url = `${apiUrl}/api/analytics/get-farm-analytics-as-manager-query`;
  return performApiRequest<AnalyticsData>({ url, method: 'POST', data: query });
}
