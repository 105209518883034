
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { RidingLessonId, RidingLessonIdList, Date } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

export interface RidingLesson {
  ridingLessonId: RidingLessonId;
  date: Date;
  isMaxAmountOfParticipantsReached: boolean;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'ridingLessonId')
      && Object.hasOwn(item, 'date')
      && Object.hasOwn(item, 'isMaxAmountOfParticipantsReached'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class RidingLessonMultiCheckboxFormControl extends Vue implements FormControlComponent<RidingLessonIdList> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<RidingLessonIdList>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: RidingLesson[];

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: RidingLessonIdList = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  get areAllRidingLessonsSelected(): boolean {
    return this.internalValue.length > 0
      && this.items.length === this.internalValue.length;
  }

  get areSomeRidingLessonsSelected(): boolean {
    return this.internalValue.length > 0
      && this.internalValue.length !== this.items.length;
  }

  mounted(): void {
    mountFormControl(this);

    watch(() => this.items, () => this.updateAllowedInternalValues(), { immediate: true });
  }

  updateAllowedInternalValues(): void {
    const countOfInternalValuesBeforeRemoval = this.internalValue.length;
    this.internalValue = this.items
      .filter((item) => !item.isMaxAmountOfParticipantsReached)
      .map((item) => item.ridingLessonId)
      .filter((currentRidingLessonId) => this.internalValue.some((ridingLessonId) => currentRidingLessonId === ridingLessonId));

    if (countOfInternalValuesBeforeRemoval !== this.internalValue.length) {
      internalValuesChanged(this);
    }
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    this.focused();

    internalValuesChanged(this);

    this.blurred();
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  toggleSelectAll(): void {
    this.internalValue = this.areAllRidingLessonsSelected
      ? []
      : this.items.map((item) => item.ridingLessonId);

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value ?? [];
  }

  formValueFromInternalValues(): FormControlValue<RidingLessonIdList> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
