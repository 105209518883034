
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Option, Options } from '@/types';
import CreateOptionDialog from './create-option-dialog.vue';
import UpdateOptionDialog from './update-option-dialog.vue';

@Component({
  components: {
    CreateOptionDialog,
    UpdateOptionDialog,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class OptionsFormControl extends Vue implements FormControlComponent<Options> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Options>;

  @Prop({ type: String, default: null })
  readonly infoText!: string | null;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: Option[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  createItem(item: Option): void {
    const items = this.internalValue;
    items.push(item);

    this.internalValue = items.sort((a: Option, b: Option) => a.label.localeCompare(b.label, 'de'));

    this.blurred();

    internalValuesChanged(this);
  }

  updateItem(index: number, item: Option): void {
    const items = this.internalValue;
    items.splice(index, 1, item);

    this.internalValue = items.sort((a: Option, b: Option) => a.label.localeCompare(b.label, 'de'));

    this.blurred();

    internalValuesChanged(this);
  }

  deleteItem(index: number): void {
    this.internalValue.splice(index, 1);

    this.blurred();

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? []
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Options> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
