import { isNavigationFailure } from 'vue-router';
import { useSnackbarStore } from './store';
import { Snack } from './types';

function showSuccess(snack: Snack): void {
  useSnackbarStore().showSuccess(snack);
}

function showError(snack: Snack): void {
  useSnackbarStore().showError(snack);
}

export function showSuccessMessage(message: string, timeout?: number): void {
  showSuccess({ message, timeout });
}

export function showErrorMessage(message: string, timeout?: number): void {
  showError({ message, timeout });
}

export function showErrorResponse(error: any, timeout?: number): void {
  // Don't show errors on navigation failures.
  if (isNavigationFailure(error)) {
    return;
  }

  const message = errorMessageFromError(error);

  useSnackbarStore().showError({
    message,
    timeout,
  });
}

export function errorMessageFromError(error: any): string {
  const isNetworkError = error?.message === 'Network Error'
    || error?.code === 'ECONNABORTED';

  return isNetworkError
    ? 'Es konnte keine Verbindung zu horse+ aufgebaut werden. Überprüfe bitte deine Internetverbindung.'
    : error?.response?.data?.message || 'Es ist ein interner Fehler aufgetreten';
}
