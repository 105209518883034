
import { Vue, Component } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { requestPermissionForPushNotifications } from '@/helpers/push-notifications-helper';

@Component
export default class IntroducePushNotificationsDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  requestPushNotificationPermissions(): void {
    requestPermissionForPushNotifications();
    this.isDialogVisible = false;
  }

  show(): void {
    this.isDialogVisible = true;
  }

}
