// Vue setup
import Vue from 'vue';
import { SplashScreen } from '@capacitor/splash-screen';
import { App as CapacitorApp, AppInfo } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
// Initialize dayjs with default time zone before everything else that might use dayjs() (through moment)
import '@/plugins/dayjs';
import '@/plugins/polyfills';
// -- Core and plugins
import vuetify from '@/plugins/vuetify';
import { pinia } from '@/plugins/pinia';
import { router } from '@/router';
import '@/plugins/font-awesome';
import '@/plugins/chartjs';
import '@/plugins/sentry';
import '@/plugins/deep-links';
import '@/plugins/design-system';
import '@/plugins/form-controls';
import '@/plugins/deprecated-components';
import '@/plugins/draggable';
import '@/plugins/blur-hash';
import '@/sass/app.sass';
import '@/directives';
import '@/filters';
import { isNativeApplication } from '@/helpers/detection-helpers';
import initAuthenticationInterceptor from '@/application/authentication/axios-authentication-interceptor';
import initAppVersionInterceptor from '@/application/app/axios-app-version-interceptor';
import initMaintenanceModeInterceptor from '@/application/maintenance-overlay/axios-maintenance-mode-interceptor';
import { storeAppInformation, storeDeviceInformation } from '@/application/app-device-info/device-information';
import { InvalidAuthenticationProperties } from '@/helpers/default-parameter-helper';
// -- Component
import AppComponent from '@/application/app/components/app.vue';

// The following error message is a known issue with the current version of Vue.js in combination with the v-calendar of Vuetify.
// The recommended temporary fix is to filter the message. (https://github.com/vuetifyjs/vuetify/issues/9999)
const ignoredMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';

Vue.config.warnHandler = (message, vm, componentTrace) => {
  if (message !== ignoredMessage) {
    // eslint-disable-next-line no-console
    console.warn(message + componentTrace);
  }
};

Vue.config.errorHandler = (error) => {
  if (process.env.ARE_ERRORS_LOGGED_IN_CONSOLE === 'true') {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  if (error instanceof InvalidAuthenticationProperties) {
    router.push({ name: 'logout' });
  }
};

function initApplication() {
  new Vue({
    router,
    vuetify,
    pinia,
    render: (h) => h(AppComponent),
    beforeMount: () => {
      // Axios executes all interceptors in the order of registration, switching between success/error handler as applicable.
      initMaintenanceModeInterceptor();
      initAuthenticationInterceptor();
      initAppVersionInterceptor();
    },
    mounted: () => {
      if (isNativeApplication()) {
        // App and device information is pulled before first request is triggered
        CapacitorApp.getInfo()
          .then((appInfo: AppInfo) => storeAppInformation(appInfo));
        Device.getInfo()
          .then((deviceInfo: DeviceInfo) => storeDeviceInformation(deviceInfo));

        if (SplashScreen) {
          SplashScreen.hide();
        }
      }
    },
  }).$mount('#app');
}

initApplication();
