import { defineStore } from 'pinia';
import { uuid } from '@/helpers';
import { InternalSnack, Snack } from './types';

interface SnackbarState {
  snackQueue: InternalSnack[];
}

function initialState(): SnackbarState {
  return {
    snackQueue: [],
  };
}

export const useSnackbarStore = defineStore('snackbar', {
  state: (): SnackbarState => initialState(),
  getters: {
    firstSnackInQueue: (state: SnackbarState): InternalSnack | null =>
      state.snackQueue[0] || null,
    snackQueueLength: (state: SnackbarState): number =>
      state.snackQueue.length,
  },
  actions: {

    showSuccess(snack: Snack): void {
      const internalSnack: InternalSnack = ({
        id: uuid(),
        type: 'success',
        ...snack,
      });

      this.snackQueue.push(internalSnack);
    },

    showError(snack: Snack): void {
      const internalSnack: InternalSnack = ({
        id: uuid(),
        type: 'error',
        ...snack,
      });

      this.snackQueue.push(internalSnack);
    },

    // This could be triggered multiple times (time out and click on close) for the same snack
    removeSnackFromQueue(internalSnack: InternalSnack): void {
      this.snackQueue = this.snackQueue
        .filter((currentSnack) => currentSnack.id !== internalSnack.id);
    },

  },
});
