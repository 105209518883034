
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { Horse, UpdateHorseImageCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  image: FormControl<Blob>;
}

@Component
export default class UpdateHorseImageDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly horse!: Horse;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        image: {
          label: 'Bild',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateHorseImageCommand = {
      body: {
        horseId: this.store.currentHorseId!
      },
      files: {
        image: formValues.image!,
      },
    };

    this.store.updateHorseImage(command)
      .then(() => showSuccessMessage('Das Bild wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
