import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { EventId } from '@/types';
import { cancelEventRegistrationAsExternalGuest, confirmEventRegistrationAsExternalGuest, confirmEventRegistrationCancellationAsExternalGuest, getEventAsGuest, registerForEventAsExternalGuest } from './service';
import { CancelEventRegistrationAsExternalGuestCommand, ConfirmEventRegistrationAsExternalGuestCommand, ConfirmEventRegistrationCancellationAsExternalGuestCommand, Event, GetEventAsGuestQuery, RegisterForEventAsExternalGuestCommand } from './types';

interface EventAsExternalGuestsState {
  currentEventId: EventId | null;
  currentEvent: Event | null;

  getEventAsGuestStatus: ActionStatus;
  registerForEventAsExternalGuestStatus: ActionStatus;
  confirmEventRegistrationAsExternalGuestStatus: ActionStatus;
  cancelEventRegistrationAsExternalGuestStatus: ActionStatus;
  confirmEventRegistrationCancellationAsExternalGuestStatus: ActionStatus;
}

function initialState(): EventAsExternalGuestsState {
  return {
    currentEventId: null,
    currentEvent: null,

    getEventAsGuestStatus: ActionStatus.None,
    registerForEventAsExternalGuestStatus: ActionStatus.None,
    confirmEventRegistrationAsExternalGuestStatus: ActionStatus.None,
    cancelEventRegistrationAsExternalGuestStatus: ActionStatus.None,
    confirmEventRegistrationCancellationAsExternalGuestStatus: ActionStatus.None,
  };
}

export const useEventAsExternalGuestsStore = defineStore('eventAsExternalGuests', {
  state: (): EventAsExternalGuestsState => initialState(),
  getters: {
    isGetEventAsGuestProcessing: (state: EventAsExternalGuestsState): boolean =>
      state.getEventAsGuestStatus === ActionStatus.InProgress,
    isRegisterForEventAsExternalGuestProcessing: (state: EventAsExternalGuestsState): boolean =>
      state.registerForEventAsExternalGuestStatus === ActionStatus.InProgress,
    isConfirmEventRegistrationAsExternalGuestProcessing: (state: EventAsExternalGuestsState): boolean =>
      state.confirmEventRegistrationAsExternalGuestStatus === ActionStatus.InProgress,
    isCancelEventRegistrationAsExternalGuestProcessing: (state: EventAsExternalGuestsState): boolean =>
      state.cancelEventRegistrationAsExternalGuestStatus === ActionStatus.InProgress,
    isConfirmEventRegistrationCancellationAsExternalGuestProcessing: (state: EventAsExternalGuestsState): boolean =>
      state.confirmEventRegistrationCancellationAsExternalGuestStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentEvent(eventId: EventId): Promise<void> {
      this.currentEventId = eventId;

      return this.getEventAsGuest();
    },

    resetCurrentEvent(): Promise<void> {
      this.currentEventId = null;
      this.currentEvent = null;

      return Promise.resolve();
    },

    // -- Queries

    getEventAsGuest(): Promise<void> {
      const query: GetEventAsGuestQuery = {
        eventId: this.currentEventId!,
      };
      const { getEventAsGuestStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEventAsGuestStatus,
        () => getEventAsGuest(query)
          .then(async (event) => {
            this.currentEvent = event;
          })
      );
    },

    // -- Commands

    registerForEventAsExternalGuest(command: RegisterForEventAsExternalGuestCommand): Promise<void> {
      const { registerForEventAsExternalGuestStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        registerForEventAsExternalGuestStatus,
        () => registerForEventAsExternalGuest(command)
      );
    },

    confirmEventRegistrationAsExternalGuest(command: ConfirmEventRegistrationAsExternalGuestCommand): Promise<void> {
      const { confirmEventRegistrationAsExternalGuestStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmEventRegistrationAsExternalGuestStatus,
        () => confirmEventRegistrationAsExternalGuest(command)
      );
    },

    cancelEventRegistrationAsExternalGuest(command: CancelEventRegistrationAsExternalGuestCommand): Promise<void> {
      const { cancelEventRegistrationAsExternalGuestStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        cancelEventRegistrationAsExternalGuestStatus,
        () => cancelEventRegistrationAsExternalGuest(command)
      );
    },

    confirmEventRegistrationCancellationAsExternalGuest(
      command: ConfirmEventRegistrationCancellationAsExternalGuestCommand
    ): Promise<void> {
      const { confirmEventRegistrationCancellationAsExternalGuestStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmEventRegistrationCancellationAsExternalGuestStatus,
        () => confirmEventRegistrationCancellationAsExternalGuest(command)
      );
    },

  },
});
