import { VuetifySelectItem } from '@/application/types';
import { enumerateTimes } from '@/helpers/date-helpers';
import { Time } from '@/types';

export function generateTimes(startTime: Time, endTime: Time, intervalMinutes: number): Time[] {
  return enumerateTimes(startTime, endTime, intervalMinutes, 'minutes');
}

export function generateTimesSelectItems(startTime: Time, endTime: Time, intervalMinutes: number): VuetifySelectItem<Time>[] {
  return generateTimes(startTime, endTime, intervalMinutes)
    .map((time) => ({ text: time.format('HH:mm'), value: time }));
}

// eslint-disable
export function generateTimeRangeSelectItems(
  startTime: Time,
  endTime: Time,
  intervalMinutes: number,
  includingZero = false
): VuetifySelectItem<number>[] {
  const selectItems = [];
  let previousDiff: number|null;
  let currentDiff: number|null;
  let time = startTime.clone();
  let interval = includingZero ? 0 : 1;

  if (endTime.isBefore(startTime)) {
    do {
      selectItems.push({
        text: `${interval * intervalMinutes} Minuten`,
        value: interval,
      });
      interval++;

      previousDiff = time.diff(endTime);
      time = time.add(intervalMinutes, 'minutes');
      currentDiff = time.diff(endTime);
    } while (currentDiff > 0 && currentDiff > previousDiff);

    selectItems.push({
      text: `${interval * intervalMinutes} Minuten`,
      value: interval,
    });
  } else {
    do {
      selectItems.push({
        text: `${interval * intervalMinutes} Minuten`,
        value: interval,
      });
      interval++;

      previousDiff = time.diff(endTime);
      time = time.add(intervalMinutes, 'minutes');
      currentDiff = time.diff(endTime);
    } while (currentDiff <= 0 && currentDiff > previousDiff);
  }

  return selectItems;
}
