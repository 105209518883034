
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useMyStableStore } from '../store';
import CreateFeedingSettingsDialog from './create-feeding-settings-dialog.vue';
import UpdateFeedingSettingsDialog from './update-feeding-settings-dialog.vue';
import FeedingHistory from './feeding-history.vue';

@Component({
  components: {
    CreateFeedingSettingsDialog,
    UpdateFeedingSettingsDialog,
    FeedingHistory,
  },
})
export default class Feeding extends Vue {

  readonly store = useMyStableStore();
  readonly appStore = useAppStore();

  get isNotConfiguredVisible(): boolean {
    return !this.store.isGetFeedingPlanProcessing
      && !!this.store.feedingPlan
      && this.store.feedingPlan.feedingSettings.length === 0;
  }

  get isCreateFeedingSettingsButtonDisabled(): boolean {
    return this.store.isGetFeedingPlanProcessing;
  }

  get isUpdateFeedingSettingsButtonDisabled(): boolean {
    return this.store.isGetFeedingPlanProcessing;
  }

  get isCreateFeedingVisible(): boolean {
    return this.isNotConfiguredVisible
      && this.store.isCurrentHorseOwn;
  }

  get isUpdateFeedingVisible(): boolean {
    return !this.isNotConfiguredVisible
      && this.store.isCurrentHorseOwn;
  }

  mounted(): void {
    this.store.getFeedingPlan()
      .catch((error) => showErrorResponse(error));

    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Fütterung`)
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    // Reset is necessary for the case when a feed type is removed. Otherwise, it would throw errors due to missing references.
    this.store.resetFeeding();
  }

  toggleDiffToPrevious(): void {
    this.store.toggleDiffToPrevious();
  }

}
