import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { FarmAndUserProperties, FarmAndUserPropertiesForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { EventEntry, CreateEventAsManagerCommand, UpdateEventAsManagerCommand, DeleteEventAsManagerCommand, DeleteEventRegistrationAsManagerCommand, MarkEventAsHiddenAsManagerCommand, MarkEventAsVisibleAsManagerCommand, Configuration, UserGroup, DeleteEventAttachmentAsManagerCommand, DeleteEventImageAsManagerCommand, DuplicateEventAsManagerCommand } from './types';

// -- Queries

export function getEvents(query: FarmAndUserProperties): Promise<EventEntry[]> {
  const url = `${apiUrl}/api/events/get-events-as-manager-query`;
  return performApiRequest<EventEntry[]>({ url, method: 'POST', data: query });
}

export function getConfiguration(query: FarmAndUserProperties): Promise<Configuration> {
  const url = `${apiUrl}/api/events/get-configuration-as-manager-query`;
  return performApiRequest<Configuration>({ url, method: 'POST', data: query });
}

export function getUserGroups(query: FarmAndUserProperties): Promise<UserGroup[]> {
  const url = `${apiUrl}/api/events/get-user-groups-as-manager-query`;
  return performApiRequest<UserGroup[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createEvent(command: CreateEventAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/events/create-event-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateEvent(command: UpdateEventAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/events/update-event-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function deleteEvent(command: DeleteEventAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/events/delete-event-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteEventRegistration(command: DeleteEventRegistrationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/delete-event-registration-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markEventAsHidden(command: MarkEventAsHiddenAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/mark-event-as-hidden-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markEventAsVisible(command: MarkEventAsVisibleAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/mark-event-as-visible-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markEventsAsVisibleForRiders(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/mark-events-as-visible-for-riders-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markEventsAsHiddenForRiders(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/mark-events-as-hidden-for-riders-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function deleteEventAttachment(command: DeleteEventAttachmentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/delete-event-attachment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function deleteEventImage(command: DeleteEventImageAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/delete-event-image-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function duplicateEvent(command: DuplicateEventAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/duplicate-event-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
