import { render, staticRenderFns } from "./allow-impersonation.vue?vue&type=template&id=0fe55852&scoped=true&"
import script from "./allow-impersonation.vue?vue&type=script&setup=true&lang=ts&"
export * from "./allow-impersonation.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./allow-impersonation.vue?vue&type=style&index=0&id=0fe55852&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe55852",
  null
  
)

export default component.exports