
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { isNativeApplication } from '@/helpers/detection-helpers';

type IconButtonType = 'button' | 'icon' | 'menu';

@Component
export default class IconButton extends Vue {

  @Prop({ type: Object })
  readonly to!: RawLocation;

  @Prop({ type: String, default: 'button' })
  readonly type!: IconButtonType;

  @Prop({ type: Array, default: () => ['far', 'exclamation-circle'] })
  readonly icon!: string[];

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  @Prop({ type: String })
  readonly tooltip!: string;

  @Prop({ type: String, default: '' })
  readonly disabledTooltip!: string;

  @Prop({ type: String })
  readonly text!: string;

  @Prop({ type: Boolean, default: false })
  readonly small!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly statusIcon!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  get iconSize(): null|string {
    return this.small ? null : 'lg';
  }

  get isTooltipDisabled(): boolean {
    return isNativeApplication() || (!this.tooltip && !this.$slots.tooltip && !this.disabledTooltip && !this.$slots.disabledTooltip);
  }

  // We use this to push through the Vuetify activation listeners, we are not 100% sure why we need this
  onClick(event: MouseEvent): void {
    this.$emit('click', event);
  }
}
