
import { Component, Vue } from 'vue-property-decorator';
import { showErrorMessage, showErrorResponse } from '@/application/snackbar/service';
import { MovementDirection, movementDirection, movementFromStartAndEnd, TouchMovement, TouchPoint, touchPointFromEvent } from '@/helpers/touch';
import { safeAreaInsetTop, scrollIntoViewWithOffset } from '@/helpers/scrolling';
import { moment } from '@/helpers';
import { useBookingCalendarStore } from '../store';
import { calendarRangeTypeFromCalendarViewPreferenceAndResolution } from '../helper';
import { CalendarRangeType, CalendarType } from '../types';
import RidingLessonEvent from './riding-lesson-event.vue';
import CalendarNavigation from './calendar-navigation.vue';

@Component({
  components: {
    CalendarNavigation,
    RidingLessonEvent,
  },
})
export default class RidingLessonCalendar extends Vue {

  readonly store = useBookingCalendarStore();

  readonly calendarType: CalendarType = CalendarType.RIDING_LESSON_CALENDAR;

  readonly menuBarSize = 60;
  readonly tableHeaderSize = 46;

  readonly weekdays = [1, 2, 3, 4, 5, 6, 0];

  latestTouchStart: TouchPoint | null = null;

  scrollClass: string | null = null;

  refreshInterval: number | null = null;

  get allowBookingFrom(): number {
    return this.store.ridingLessonTimeFrame
      ? this.store.ridingLessonTimeFrame.timeFrom.hour
      : 5;
  }

  get amountIntervalsPerDay(): number {
    let lastHour = this.store.ridingLessonTimeFrame
      && this.store.ridingLessonTimeFrame.timeTo.hour !== 0
      ? this.store.ridingLessonTimeFrame.timeTo.hour
      : 24;

    if (this.store.ridingLessonTimeFrame
      && this.store.ridingLessonTimeFrame.timeTo.minute > 0
    ) {
      lastHour++;
    }

    return lastHour - this.allowBookingFrom;
  }

  get intervalHeight(): number {
    const heightForSingleLine = 26;

    return heightForSingleLine * 4;
  }

  get calendarStart(): string {
    return this.store.currentRangeFrom?.format('YYYY-MM-DD') ?? '';
  }

  get calendarEnd(): string {
    return this.store.currentRangeTo?.format('YYYY-MM-DD') ?? '';
  }

  get calendarClasses(): Record<string, true> {
    const classes: Record<string, true> = {};

    if (this.scrollClass) {
      classes[this.scrollClass] = true;
    }
    if (this.store.currentCalendarRangeType) {
      classes[`range-${this.store.currentCalendarRangeType.toLowerCase()}`] = true;
    }

    return classes;
  }

  mounted(): void {
    const calendarRangeType = calendarRangeTypeFromCalendarViewPreferenceAndResolution();
    if (this.$route.params.date) {
      const date = moment(this.$route.params.date);
      const rangeFrom = calendarRangeType === CalendarRangeType.day
        ? date.startOf('day')
        : date.isoWeekday(1).startOf('day');
      const rangeTo = calendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.store.initializeTimeRangeAndCalendarTypeForRidingLessons(rangeFrom, rangeTo, calendarRangeType)
        .then(() => {
          const ridingLessonId = this.$route.params.ridingLessonId;
          if (ridingLessonId) {
            const calendarEvent = this.store.ridingLessonPlanEventsWithFixedEnd
              .find((calendarEvent) => calendarEvent.id === ridingLessonId);

            if (!calendarEvent) {
              showErrorMessage('Die Unterrichtsstunde konnte nicht gefunden werden. Ggf. wurde sie abgesagt.');
              return;
            }

            this.$nextTick(() => {
              // Result is something like "54px"
              const safeAreaInset = safeAreaInsetTop();
              const marginTop = 16;
              const offset = this.menuBarSize + this.tableHeaderSize + marginTop + safeAreaInset;

              scrollIntoViewWithOffset(`#calendar-event\\:${calendarEvent.id}`, offset);
            });
          }
        })
        .catch((error) => showErrorResponse(error));
    } else if (!this.store.currentRangeFrom
      || !this.store.currentRangeTo
      || !this.store.currentCalendarRangeType
    ) {
      const rangeFrom = calendarRangeType === CalendarRangeType.day
        ? moment().startOf('day')
        : moment().isoWeekday(1).startOf('day');
      const rangeTo = calendarRangeType === CalendarRangeType.day
        ? rangeFrom.add(1, 'day')
        : rangeFrom.add(7, 'days');

      this.store.initializeTimeRangeAndCalendarTypeForRidingLessons(rangeFrom, rangeTo, calendarRangeType)
        .catch((error) => showErrorResponse(error));
    } else {
      this.store.getRidingLessonsAsCalendarEvents()
        .catch((error) => showErrorResponse(error));
    }

    window.addEventListener('resize', this.updateCalendarRangeTypeOnResize);
    window.addEventListener('visibilitychange', this.visibilityChanged);
    window.addEventListener('scroll', this.toggleStickyHeader);

    const fiveMinutes = 5 * 60 * 1000;
    this.refreshInterval = setInterval(() => {
      this.store.getRidingLessonsAsCalendarEvents()
        .catch((error) => showErrorResponse(error));
    }, fiveMinutes);
  }

  destroyed(): void {
    window.removeEventListener('resize', this.updateCalendarRangeTypeOnResize);
    window.removeEventListener('visibilitychange', this.visibilityChanged);
    window.removeEventListener('scroll', this.toggleStickyHeader);

    clearInterval(this.refreshInterval!);
  }

  toggleStickyHeader(): void {
    const calendar = document.getElementsByClassName('v-calendar-daily')[0];
    if (!calendar) {
      return;
    }

    const distanceToTopOfHeader = calendar.getBoundingClientRect().top;

    const menuBarSize = 60;

    this.scrollClass = distanceToTopOfHeader <= menuBarSize + safeAreaInsetTop()
      ? 'sticky-header'
      : null;
  }

  navigateToTodayInCalendar(): void {
    const rangeFrom = this.store.currentCalendarRangeType === CalendarRangeType.day
      ? moment().startOf('day')
      : moment().isoWeekday(1).startOf('day');
    const rangeTo = this.store.currentCalendarRangeType === CalendarRangeType.day
      ? rangeFrom.add(1, 'day')
      : rangeFrom.add(7, 'days');

    this.store.updateCurrentRangeForRidingLesson(rangeFrom, rangeTo)
      .catch((error) => showErrorResponse(error));
  }

  navigateToPreviousCalendarTimeFrame(): void {
    this.store.moveCalendarRangeBackwardsForRidingLessons()
      .catch((error) => showErrorResponse(error));
  }

  navigateToNextCalendarTimeFrame(): void {
    this.store.moveCalendarRangeForwardForRidingLessons()
      .catch((error) => showErrorResponse(error));
  }

  visibilityChanged(): void {
    // Update calendar events when reopening the app
    if (document.visibilityState !== 'hidden') {
      this.store.getRidingLessonsAsCalendarEvents()
        .catch((error) => showErrorResponse(error));
    }
  }

  updateCalendarRangeTypeOnResize(): void {
    const calendarRangeType = calendarRangeTypeFromCalendarViewPreferenceAndResolution();

    if (calendarRangeType !== this.store.currentCalendarRangeType) {
      this.store.updateCalendarTypeForRidingLesson(calendarRangeType)
        .catch((error) => showErrorResponse(error));
    }
  }

  touchStarted(event: any, original: TouchEvent): void {
    this.latestTouchStart = touchPointFromEvent(original);
  }

  touchEnded(event: any, original: TouchEvent): void {
    if (this.latestTouchStart === null) {
      throw new Error('Touch end without start');
    }

    const end: TouchPoint = touchPointFromEvent(original);

    const movement = movementFromStartAndEnd(this.latestTouchStart, end);

    this.handleTouchMovement(movement);
  }

  handleTouchMovement(movement: TouchMovement): void {
    const direction = movementDirection(movement);

    switch (direction) {
      case MovementDirection.LEFT:
        this.onTouchLeft();
        break;
      case MovementDirection.RIGHT:
        this.onTouchRight();
        break;
      default: break;
    }
  }

  onPreviousButtonClicked(): void {
    this.navigateToPreviousCalendarTimeFrame();
  }

  onTodayButtonClicked(): void {
    this.navigateToTodayInCalendar();
  }

  onNextButtonClicked(): void {
    this.navigateToNextCalendarTimeFrame();
  }

  onTouchLeft(): void {
    this.navigateToNextCalendarTimeFrame();
  }

  onTouchRight(): void {
    this.navigateToPreviousCalendarTimeFrame();
  }

  onCalendarRangeTypeToggleClicked(): void {
    const newCalendarRangeType = this.store.currentCalendarRangeType === CalendarRangeType.day
      ? CalendarRangeType.week
      : CalendarRangeType.day;
    this.store.updateCalendarTypeForRidingLesson(newCalendarRangeType)
      .catch((error) => showErrorResponse(error));
  }

  onPreferredCalendarViewWasUpdated(): void {
    const calendarRangeType = calendarRangeTypeFromCalendarViewPreferenceAndResolution();

    if (calendarRangeType !== this.store.currentCalendarRangeType) {
      this.store.updateCalendarTypeForRidingLesson(calendarRangeType)
        .catch((error) => showErrorResponse(error));
    }
  }

  registeredForRidingLesson(): void {
    this.store.getRidingLessonsAsCalendarEvents()
      .catch((error) => showErrorResponse(error));
  }

  ridingLessonRegistrationCanceled(): void {
    this.store.getRidingLessonsAsCalendarEvents()
      .catch((error) => showErrorResponse(error));
  }

}
