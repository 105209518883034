import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FeedingTabs from './components/feeding-tabs.vue';
import FeedTypes from './components/feed-types.vue';
import FeedingOverview from './components/feeding-overview.vue';

export const feedingManagementRoutes: RouteConfig[] = [
  {
    path: 'fuetterung',
    component: FeedingTabs,
    children: [
      {
        path: '',
        redirect: 'futtertypen',
      },
      {
        name: 'manage-feeding-feeding-overview',
        path: 'uebersicht',
        component: FeedingOverview,
        meta: {
          title: 'Fütterung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.FEEDING,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.FEEDING_READ,
        },
      },
      {
        name: 'manage-feeding-feed-types',
        path: 'futtertypen',
        component: FeedTypes,
        meta: {
          title: 'Fütterung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.FEEDING,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.FEEDING_READ,
        },
      },
    ],
  },
];
