import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import MedicalRecordTabs from './components/medical-record-tabs.vue';
import MedicalRecords from './components/medical-records.vue';
import VaccinationStatuses from './components/vaccination-statuses.vue';
import MissingVaccinations from './components/missing-vaccinations.vue';
import SoonExpiringVaccinations from './components/soon-expiring-vaccinations.vue';

export const medicalRecordsManagementRoutes: RouteConfig[] = [
  {
    path: 'gesundheitsakten',
    component: MedicalRecordTabs,
    children: [
      {
        name: 'medical-records-management-medical-records',
        path: '',
        component: MedicalRecords,
        meta: {
          title: 'Gesundheitsakten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.MEDICAL_RECORDS_READ,
        },
      },
      {
        name: 'medical-records-management-vaccination-statuses',
        path: 'impfstatus',
        component: VaccinationStatuses,
        meta: {
          title: 'Gesundheitsakten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.MEDICAL_RECORDS_READ,
        },
      },
      {
        name: 'medical-records-management-missing-vaccinations',
        path: 'kein-impfschutz',
        component: MissingVaccinations,
        meta: {
          title: 'Gesundheitsakten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.MEDICAL_RECORDS_READ,
        },
      },
      {
        name: 'medical-records-management-soon-expiring-vaccinations',
        path: 'ablaufender-impfschutz',
        component: SoonExpiringVaccinations,
        meta: {
          title: 'Gesundheitsakten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.MEDICAL_RECORDS_READ,
        },
      },
    ],
  },
];
