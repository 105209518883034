
import { Component, Vue } from 'vue-property-decorator';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { errorMessageFromError, showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { navigate } from '@/helpers/navigation-helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalPasswordFormControlRule, mandatoryCheckboxRule, passwordRule, phoneNumberRule } from '@/components/form';
import { useAuthenticationStore } from '@/application/authentication/store';
import { LoginCommand } from '@/application/authentication/types';
import { Name } from '@/types';
import { useUserRegistrationStore } from '../store';
import { ConfirmUserRegistrationCommand, GetUserRegistrationRequestQuery } from '../types';

interface Controls extends FormControls {
  name: FormControl<Name>;
  phone: FormControl<string>;
  password: FormControl<string>;
  passwordRepeat: FormControl<string>;
  isDataPolicyAccepted: FormControl<boolean>;
  hasAcceptedNewsletter: FormControl<boolean>;
}

@Component
export default class ConfirmRegistration extends Vue {

  readonly store = useUserRegistrationStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly isNativeApplication = isNativeApplication;

  form: Form<Controls> | null = null;

  registrationRequestErrorMessage: string | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isProcessing(): boolean {
    return this.store.isConfirmUserRegistrationProcessing
      || this.authenticationStore.isLoginProcessing;
  }

  get currentUserRegistrationRequestId(): string {
    return this.$route.params.userRegistrationRequestId;
  }

  mounted(): void {
    const query: GetUserRegistrationRequestQuery = {
      userRegistrationRequestId: this.currentUserRegistrationRequestId,
    };

    this.store.getUserRegistrationRequest(query)
      .then(() => {
        this.form = this.buildForm();
      })
      .catch((error) => {
        this.registrationRequestErrorMessage = errorMessageFromError(error);
      });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
        },
        phone: {
          label: 'Telefonnummer',
          value: null,
          rules: [
            phoneNumberRule(),
          ],
        },
        password: {
          label: 'Passwort',
          value: null,
          isRequired: true,
          rules: [
            passwordRule(),
          ],
          validateFormControlsOnInput: [
            'passwordRepeat',
          ],
        },
        passwordRepeat: {
          label: 'Passwort wiederholen',
          value: null,
          isRequired: true,
          rules: [
            passwordRule(),
          ],
          validateFormControlsOnInput: [
            'password',
          ],
        },
        isDataPolicyAccepted: {
          label: 'Datenschutzbestimmungen',
          value: false,
          isRequired: true,
          rules: [
            mandatoryCheckboxRule(),
          ],
        },
        hasAcceptedNewsletter: {
          label: 'Wir möchten horse+ kontinuierlich weiterentwickeln. Hättest du Lust uns dabei mit deinem Feedback zu unterstützen? Dürfen wir dich diesbezüglich über deine E-Mail-Adresse kontaktieren?',
          value: false,
        },
      },
    });

    form.controls.password.rules!.push(identicalPasswordFormControlRule(form.controls.passwordRepeat));
    form.controls.passwordRepeat.rules!.push(identicalPasswordFormControlRule(form.controls.password));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: ConfirmUserRegistrationCommand = {
      userRegistrationRequestId: this.currentUserRegistrationRequestId,
      farmId: this.store.userRegistrationRequest!.farmId,
      name: formValues.name!,
      password: formValues.password!,
      token: this.store.userRegistrationRequest!.token,
      phone: formValues.phone,
      hasAcceptedNewsletter: formValues.hasAcceptedNewsletter!,
    };

    this.store.confirmUserRegistration(command)
      .then(() => {
        const loginCommand: LoginCommand = {
          emailAddress: this.store.userRegistrationRequest!.emailAddress,
          password: formValues.password!,
        };

        return this.authenticationStore.login(loginCommand);
      })
      .then(() => this.redirectToRoot())
      .then(() => showSuccessMessage('Die Registrierung ist abgeschlossen und du wurdest angemeldet.'))
      .catch((error) => showErrorResponse(error));
  }

  redirectToRoot(): void {
    navigate({ name: 'root' });
  }

}
