import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { Date } from '@/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { ActionStatus } from '@/application/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { createPenStatusEntry, deletePenStatusEntry, disablePenExemptions, enablePenExemptions, getConfiguration, getPenStatusEntries, updatePenStatusVisibility, updatePenStatusEntry, getPenExemptions } from './service';
import { CreatePenStatusEntryAsManagerCommand, DeletePenStatusEntryAsManagerCommand, EnablePenExemptionsAsManagerCommand, Configuration, PenStatusEntry, UpdatePenStatusVisibilityAsManagerCommand, UpdatePenStatusEntryAsManagerCommand, PenExemption, GetPenExemptionsAsManagerQuery } from './types';

interface PenStatusManagementState {
  entries: PenStatusEntry[];
  configuration: Configuration | null;
  dateForPenExemptions: Date | null;
  penExemptions: PenExemption[];

  getPenStatusEntriesStatus: ActionStatus;
  getConfigurationStatus: ActionStatus;
  createPenStatusEntryStatus: ActionStatus;
  updatePenStatusEntryStatus: ActionStatus;
  deletePenStatusEntryStatus: ActionStatus;
  updatePenStatusVisibilityStatus: ActionStatus;
  enablePenExemptionsStatus: ActionStatus;
  disablePenExemptionsStatus: ActionStatus;
  getPenExemptionsStatus: ActionStatus;
}

function initialState(): PenStatusManagementState {
  return {
    entries: [],
    configuration: null,
    dateForPenExemptions: null,
    penExemptions: [],

    getPenStatusEntriesStatus: ActionStatus.None,
    getConfigurationStatus: ActionStatus.None,
    createPenStatusEntryStatus: ActionStatus.None,
    updatePenStatusEntryStatus: ActionStatus.None,
    deletePenStatusEntryStatus: ActionStatus.None,
    updatePenStatusVisibilityStatus: ActionStatus.None,
    enablePenExemptionsStatus: ActionStatus.None,
    disablePenExemptionsStatus: ActionStatus.None,
    getPenExemptionsStatus: ActionStatus.None,
  };
}

export const usePenManagementStore = defineStore('paddockManagement', {
  state: (): PenStatusManagementState => initialState(),
  getters: {
    isGetPenStatusEntriesProcessing: (state: PenStatusManagementState): boolean =>
      state.getPenStatusEntriesStatus === ActionStatus.InProgress,
    isCreatePenStatusEntryProcessing: (state: PenStatusManagementState): boolean =>
      state.createPenStatusEntryStatus === ActionStatus.InProgress,
    isUpdatePenStatusEntryProcessing: (state: PenStatusManagementState): boolean =>
      state.updatePenStatusEntryStatus === ActionStatus.InProgress,
    isDeletePenStatusEntryProcessing: (state: PenStatusManagementState): boolean =>
      state.deletePenStatusEntryStatus === ActionStatus.InProgress,
    isGetConfigurationProcessing: (state: PenStatusManagementState): boolean =>
      state.getConfigurationStatus === ActionStatus.InProgress,
    isUpdatePenStatusVisibilityProcessing: (state: PenStatusManagementState): boolean =>
      state.updatePenStatusVisibilityStatus === ActionStatus.InProgress,
    isEnablePenExemptionsProcessing: (state: PenStatusManagementState): boolean =>
      state.enablePenExemptionsStatus === ActionStatus.InProgress,
    isDisablePenExemptionsProcessing: (state: PenStatusManagementState): boolean =>
      state.disablePenExemptionsStatus === ActionStatus.InProgress,
    isGetPenExemptionsProcessing: (state: PenStatusManagementState): boolean =>
      state.getPenExemptionsStatus === ActionStatus.InProgress,

    todaysPaddockStatus: (state: PenStatusManagementState): PenStatusEntry | null =>
      state.entries.find((entry) => Date.today().isEqualTo(entry.date)) ?? null,
  },
  actions: {

    // -- State management

    updateDateForPenExemptions(date: Date): Promise<void> {
      this.dateForPenExemptions = date;

      return this.getPenExemptions();
    },

    // -- Queries

    getPenStatusEntries(): Promise<void> {
      const { getPenStatusEntriesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPenStatusEntriesStatus,
        () => getPenStatusEntries(attachFarmAndUserProperties({}))
          .then((entries) => {
            this.entries = entries;
          })
      );
    },

    getConfiguration(): Promise<void> {
      const { getConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getConfigurationStatus,
        () => getConfiguration(attachFarmAndUserProperties({}))
          .then((configuration) => {
            this.configuration = configuration;
          })
      );
    },

    getPenExemptions(): Promise<void> {
      const query: GetPenExemptionsAsManagerQuery = {
        date: this.dateForPenExemptions!,
      };
      const { getPenExemptionsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPenExemptionsStatus,
        () => getPenExemptions(attachFarmAndUserProperties(query))
          .then((penExemptions) => {
            this.penExemptions = penExemptions;
          })
      );
    },

    // -- Commands

    createPenStatusEntry(command: CreatePenStatusEntryAsManagerCommand): Promise<void> {
      const { createPenStatusEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPenStatusEntryStatus,
        () => createPenStatusEntry(attachFarmAndUserProperties(command))
          .then(() => this.getPenStatusEntries())
      );
    },

    updatePenStatusEntry(command: UpdatePenStatusEntryAsManagerCommand): Promise<void> {
      const { updatePenStatusEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePenStatusEntryStatus,
        () => updatePenStatusEntry(attachFarmAndUserProperties(command))
          .then(() => this.getPenStatusEntries())
      );
    },

    deletePenStatusEntry(command: DeletePenStatusEntryAsManagerCommand): Promise<void> {
      const { deletePenStatusEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deletePenStatusEntryStatus,
        () => deletePenStatusEntry(attachFarmAndUserProperties(command))
          .then(() => this.getPenStatusEntries())
      );
    },

    updatePenStatusVisibility(command: UpdatePenStatusVisibilityAsManagerCommand): Promise<void> {
      const { updatePenStatusVisibilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePenStatusVisibilityStatus,
        () => updatePenStatusVisibility(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    enablePenExemptions(command: EnablePenExemptionsAsManagerCommand): Promise<void> {
      const { enablePenExemptionsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enablePenExemptionsStatus,
        () => enablePenExemptions(attachFarmAndUserProperties(command))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    disablePenExemptions(): Promise<void> {
      const { disablePenExemptionsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disablePenExemptionsStatus,
        () => disablePenExemptions(attachFarmAndUserProperties({}))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

  },
});
