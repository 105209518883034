
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { RidingLessonDuration } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { ridingLessonDurationTranslation } from '@/helpers/translations';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class RidingLessonDurationFormControl extends Vue implements FormControlComponent<RidingLessonDuration> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<RidingLessonDuration>;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: number | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get items(): VuetifySelectItem<number>[] {
    const items = [];
    const minMinutes = 5;
    const maxMinutes = 60 * 3;
    const intervalInMinutes = 5;
    for (let i = minMinutes; i <= maxMinutes; i += intervalInMinutes) {
      items.push(i);
    }

    return items.map((value) => ({
      text: ridingLessonDurationTranslation(value),
      value,
    }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<RidingLessonDuration> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
