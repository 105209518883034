import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { MoveHorseIntoBoxAsManagerCommand, BoxPlan, UpdateBoxPlanAsManagerWorkflow, PaymentConfiguration, UpdateBoxPlanPaymentMethodAsManagerCommand, RemoveHorseFromBoxAsManagerCommand } from './types';

// -- Queries

export function getBoxPlan(query: FarmAndUserProperties): Promise<BoxPlan> {
  const url = `${apiUrl}/api/ledger/get-box-plan-as-manager-query`;
  return performApiRequest<BoxPlan>({ url, method: 'POST', data: query });
}

export function getBoxPlanPaymentConfiguration(query: FarmAndUserProperties): Promise<PaymentConfiguration> {
  const url = `${apiUrl}/api/ledger/get-box-plan-payment-configuration-as-manager-query`;
  return performApiRequest<PaymentConfiguration>({ url, method: 'POST', data: query });
}

// -- Commands

export function updateBoxPlan(command: UpdateBoxPlanAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-box-plan-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function moveHorseIntoBox(command: MoveHorseIntoBoxAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/move-horse-into-box-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function removeHorseFromBox(command: RemoveHorseFromBoxAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/remove-horse-from-box-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateBoxPlanPaymentMethod(command: UpdateBoxPlanPaymentMethodAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-box-plan-payment-method-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
