
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { VuetifySelectItem } from '@/application/types';
import { UserId } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

export interface User {
  userId: UserId;
  name: string;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'userId')
      && Object.hasOwn(item, 'name'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class UserMultiSelectFormControl extends Vue implements FormControlComponent<UserId[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<UserId[]>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: User[];

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: UserId[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  get selectItems(): VuetifySelectItem<UserId>[] {
    return this.items.map((item) => ({
      text: item.name,
      value: item.userId,
    }));
  }

  get areAllUsersSelected(): boolean {
    return this.internalValue.length > 0
      && this.items.length === this.internalValue.length;
  }

  get areSomeUsersSelected(): boolean {
    return this.internalValue.length > 0
      && this.internalValue.length !== this.items.length;
  }

  mounted(): void {
    mountFormControl(this);

    watch(() => this.items, () => this.updateAllowedInternalValues(), { immediate: true });
  }

  updateAllowedInternalValues(): void {
    const countOfInternalValuesBeforeRemoval = this.internalValue.length;
    this.internalValue = this.items
      .map((item) => item.userId)
      .filter((currentUserId) => this.internalValue.some((userId) => currentUserId === userId));

    if (countOfInternalValuesBeforeRemoval !== this.internalValue.length) {
      internalValuesChanged(this);
    }
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  toggleSelectAll(): void {
    this.internalValue = this.areAllUsersSelected
      ? []
      : this.items.map((item) => item.userId);

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value ?? [];
  }

  formValueFromInternalValues(): FormControlValue<UserId[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
