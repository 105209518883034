import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { clearUserForSentry, setAuthenticatedUserForSentry } from '@/plugins/sentry';
import { TokenStorage } from '@/application/authentication/token-storage';
import { FarmManagerPermission, FarmManagerPermissions, UserRole } from '@/types';
import { removeAuthenticationFromLocalStorage, setAuthenticationInLocalStorage } from '@/helpers/local-storage-helper';
import { FacilityIdentification } from '@/private/rider/booking-calendars/types';
import { useAdminNotificationBannerStore } from '@/private/common/admin-notification-banner/store';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import { AcceptPushNotificationsForDevice, AuthenticatedUser, LoginCommand, NewsEntry, RejectPushNotificationsForDeviceCommand, RequestPasswordResetCommand, ResetPasswordCommand } from './types';
import { acceptPushNotificationsForDevice, allowImpersonation, getAuthentication, login, logout, rejectPushNotificationsForDevice, requestPasswordReset, resetPassword } from './service';

export function hasAccessToPermission(enabledPermissions: FarmManagerPermissions, permission: FarmManagerPermission): boolean {
  return enabledPermissions.areAllPermissionsEnabled
    || enabledPermissions.permissions.includes(permission);
}

interface AuthenticationState {
  wasInitialAuthenticationAttempted: boolean;
  user: AuthenticatedUser | null;

  getAuthenticationStatus: ActionStatus;
  loginStatus: ActionStatus;
  logoutStatus: ActionStatus;
  allowImpersonationStatus: ActionStatus;
  requestPasswordResetStatus: ActionStatus;
  resetPasswordStatus: ActionStatus;
  acceptPushNotificationsForDeviceStatus: ActionStatus;
  rejectPushNotificationsForDeviceStatus: ActionStatus;
}

function initialState(): AuthenticationState {
  return {
    wasInitialAuthenticationAttempted: false,
    user: null,

    getAuthenticationStatus: ActionStatus.None,
    loginStatus: ActionStatus.None,
    logoutStatus: ActionStatus.None,
    allowImpersonationStatus: ActionStatus.None,
    requestPasswordResetStatus: ActionStatus.None,
    resetPasswordStatus: ActionStatus.None,
    acceptPushNotificationsForDeviceStatus: ActionStatus.None,
    rejectPushNotificationsForDeviceStatus: ActionStatus.None,
  };
}

export const useAuthenticationStore = defineStore('authentication', {
  state: (): AuthenticationState => initialState(),
  getters: {

    isAuthenticated: (state: AuthenticationState): boolean =>
      state.user !== null,

    isAdmin: (state: AuthenticationState): boolean =>
      state.user?.role === UserRole.ROLE_ADMIN,

    isFarmManager: (state: AuthenticationState): boolean =>
      state.user?.role === UserRole.ROLE_FARM_MANAGER,

    facilities: (state: AuthenticationState): FacilityIdentification[] =>
      state.user?.farm?.facilities ?? [],

    importantUnreadNews: (state: AuthenticationState): NewsEntry[] =>
      state.user?.importantUnreadNews ?? [],

    getLaborServiceAnnualNumberOfHours: (state: AuthenticationState): number =>
      state.user?.farm?.laborServiceAnnualNumberOfHours ?? 0,

    hasNoPushNotificationConfigurationForDevice: (state: AuthenticationState): (deviceIdentifier: string) => boolean =>
      (deviceIdentifier: string) => !!state.user
        && !state.user.pushNotificationConfigurations
          .some((pushNotificationConfiguration) => pushNotificationConfiguration.deviceIdentifier === deviceIdentifier),

    doesAuthenticatedUserHavePermission: (state: AuthenticationState): (permission: FarmManagerPermission) => boolean =>
      (permission: FarmManagerPermission) => state.user?.enabledPermissions
        ? hasAccessToPermission(state.user.enabledPermissions, permission)
        : false,

    isGetAuthenticationStatusProcessing: (state: AuthenticationState): boolean =>
      state.getAuthenticationStatus === ActionStatus.InProgress,
    isLoginProcessing: (state: AuthenticationState): boolean =>
      state.loginStatus === ActionStatus.InProgress,
    isLogoutProcessing: (state: AuthenticationState): boolean =>
      state.logoutStatus === ActionStatus.InProgress,
    isAllowImpersonationProcessing: (state: AuthenticationState): boolean =>
      state.allowImpersonationStatus === ActionStatus.InProgress,
    isRequestPasswordResetProcessing: (state: AuthenticationState): boolean =>
      state.requestPasswordResetStatus === ActionStatus.InProgress,
    isResetPasswordProcessing: (state: AuthenticationState): boolean =>
      state.resetPasswordStatus === ActionStatus.InProgress,
    isAcceptPushNotificationsForDeviceProcessing: (state: AuthenticationState): boolean =>
      state.acceptPushNotificationsForDeviceStatus === ActionStatus.InProgress,
    isRejectPushNotificationsForDeviceProcessing: (state: AuthenticationState): boolean =>
      state.rejectPushNotificationsForDeviceStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    resetExcludingWasInitialAuthenticationAttempted(): void {
      const state = initialState();
      state.wasInitialAuthenticationAttempted = true;

      Object.assign(this, state);
    },

    startLogoutProcess(): void {
      // Already reset user so that navigation items aren't visible anymore
      this.user = null;
    },

    // -- Queries

    getInitialAuthentication(): Promise<void> {
      const { getAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAuthenticationStatus,
        () => getAuthentication()
          .then(async (authentication) => {
            this.wasInitialAuthenticationAttempted = true;
            this.user = authentication.user;

            setAuthenticatedUserForSentry(authentication.user);
            setAuthenticationInLocalStorage(authentication.user);

            if (!this.isAdmin
              && !TokenStorage.isImpersonatedUser()
            ) {
              await useAdminNotificationBannerStore().getUnseenAdminNotifications();
            }
          })
          .catch((error) => {
            this.wasInitialAuthenticationAttempted = true;
            return Promise.reject(error);
          })
      );
    },

    getAuthentication(): Promise<void> {
      const { getAuthenticationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAuthenticationStatus,
        () => getAuthentication()
          .then(async (authentication) => {
            this.user = authentication.user;

            setAuthenticatedUserForSentry(authentication.user);
            setAuthenticationInLocalStorage(authentication.user);
          })
      );
    },

    // -- Commands

    login(command: LoginCommand): Promise<void> {
      const { loginStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        loginStatus,
        () => login(command)
          .then(async (authentication) => {
            this.user = authentication.user;

            setAuthenticatedUserForSentry(authentication.user);
            setAuthenticationInLocalStorage(authentication.user);

            if (!this.isAdmin) {
              await useMyStableStore().getAvailableHorses();
            }

            if (!this.isAdmin
              && !TokenStorage.isImpersonatedUser()
            ) {
              await useAdminNotificationBannerStore().getUnseenAdminNotifications();
            }
          })
      );
    },

    logout(): Promise<void> {
      const { logoutStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        logoutStatus,
        () => logout()
          .finally(async () => {
            clearUserForSentry();
            removeAuthenticationFromLocalStorage();
            TokenStorage.clear();
          })
      );
    },

    allowImpersonation(token: string): Promise<void> {
      const { allowImpersonationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        allowImpersonationStatus,
        () => allowImpersonation(token)
      );
    },

    requestPasswordReset(command: RequestPasswordResetCommand): Promise<void> {
      const { requestPasswordResetStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestPasswordResetStatus,
        () => requestPasswordReset(command)
      );
    },

    resetPassword(command: ResetPasswordCommand): Promise<void> {
      const { resetPasswordStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        resetPasswordStatus,
        () => resetPassword(command)
      );
    },

    acceptPushNotificationsForDevice(command: AcceptPushNotificationsForDevice): Promise<void> {
      const { acceptPushNotificationsForDeviceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        acceptPushNotificationsForDeviceStatus,
        () => acceptPushNotificationsForDevice(command)
          .then(() => this.getAuthentication())
      );
    },

    rejectPushNotificationsForDevice(command: RejectPushNotificationsForDeviceCommand): Promise<void> {
      const { rejectPushNotificationsForDeviceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        rejectPushNotificationsForDeviceStatus,
        () => rejectPushNotificationsForDevice(command)
          .then(() => this.getAuthentication())
      );
    },

  },
});
