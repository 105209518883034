import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FeedProtocolTabs from './components/feed-protocol-tabs.vue';
import FeedProtocolConfiguration from './components/feed-protocol-configuration.vue';
import FeedProtocolOverview from './components/feed-protocol-overview.vue';
import StationUtilization from './components/station-utilization.vue';

export const feedProtocolManagementRoutes: RouteConfig[] = [
  {
    path: 'fuetterungstechnik',
    component: FeedProtocolTabs,
    children: [
      {
        // WARNING: Name used in push notification routing
        name: 'feed-protocol-overview',
        path: '',
        component: FeedProtocolOverview,
        meta: {
          title: 'Fütterungstechnik',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.FEED_PROTOCOL,
          requiresPermission: FarmManagerPermission.FEED_PROTOCOL_READ,
        },
      },
      {
        name: 'station-utilization',
        path: 'auslastung',
        component: StationUtilization,
        meta: {
          title: 'Fütterungstechnik',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.FEED_PROTOCOL,
          requiresPermission: FarmManagerPermission.FEED_PROTOCOL_READ,
        },
      },
      {
        name: 'feed-protocol-configuration',
        path: 'konfiguration',
        component: FeedProtocolConfiguration,
        meta: {
          title: 'Fütterungstechnik',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.FEED_PROTOCOL,
          requiresPermission: FarmManagerPermission.FEED_PROTOCOL_READ,
        },
      },
    ],
  },
];
