import { Address, Country, CustomFieldValue, Date, FarmAddress, InvoiceAddressForOrganization, InvoiceAddressForPerson, isCustomFieldValueForMultiSelect, isCustomFieldValueForSelect, isCustomFieldValueForText, isCustomFieldValueForYesNo, Moment, Name, Options, Salutation, Time, TimeFrame } from '@/types';
import { countryTranslation } from './translations';
import { switzerlandCantonMap } from './switzerland-canton-mapping';
import { isMoment, moment } from './moment';
import { DATE_FORMAT, TIME_FORMAT } from './data';

export function formatName(name: Name): string {
  return name.title
    ? `${name.title} ${name.firstName} ${name.lastName}`
    : `${name.firstName} ${name.lastName}`;
}

export function formatNameWithOptionalSalutation(salutation: Salutation | null, name: Name): string {
  return salutation
    ? `${salutation} ${formatName(name)}`
    : formatName(name);
}

export function formatAddress(address: Address): string {
  if (address.country === Country.ch) {
    // eslint-disable-next-line max-len
    return `${address.street}<br />${address.postal} ${address.city}<br />${switzerlandCantonMap[Number(address.postal)]}<br />${countryTranslation[address.country]}`;
  }

  return `${address.street}<br />${address.postal} ${address.city}<br />${countryTranslation[address.country]}`;
}

export function formatInvoiceAddressForPerson(invoiceAddress: InvoiceAddressForPerson): string {
  const nameWithSalutation = formatNameWithOptionalSalutation(invoiceAddress.salutation, invoiceAddress.name);

  return invoiceAddress.company
    ? `${nameWithSalutation}<br />${invoiceAddress.company}<br />${formatAddress(invoiceAddress.address)}`
    : `${nameWithSalutation}<br />${formatAddress(invoiceAddress.address)}`;
}

export function formatInvoiceAddressForOrganization(invoiceAddress: InvoiceAddressForOrganization): string {
  return invoiceAddress.nameOfContactPerson
    // eslint-disable-next-line max-len
    ? `${invoiceAddress.company}<br />${formatNameWithOptionalSalutation(invoiceAddress.salutationOfContactPerson, invoiceAddress.nameOfContactPerson)}<br />${formatAddress(invoiceAddress.address)}`
    : `${invoiceAddress.company}<br />${formatAddress(invoiceAddress.address)}`;
}

export function formatFarmAddress(farmAddress: FarmAddress): string {
  return `${farmAddress.company}<br />${formatAddress(farmAddress.address)}`;
}

export function formatCustomFieldValue(customFieldValue: CustomFieldValue, options: Options | null): string {
  if (isCustomFieldValueForText(customFieldValue)) {
    return customFieldValue.value;
  }

  if (isCustomFieldValueForSelect(customFieldValue)) {
    if (options === null) {
      throw new Error('Missing options for select');
    }

    return options
      .find((option) => option.optionId === customFieldValue.value)!
      .label;
  }

  if (isCustomFieldValueForMultiSelect(customFieldValue)) {
    if (options === null) {
      throw new Error('Missing options for multi select');
    }

    return customFieldValue.value
      .map((optionId) => options
        .find((option) => option.optionId === optionId)!
        .label)
      .join('<br />');
  }

  if (isCustomFieldValueForYesNo(customFieldValue)) {
    return formatBoolean(customFieldValue.value);
  }

  throw new Error('Invalid type');
}

export function formatBoolean(bool: boolean): string {
  return bool
    ? 'Ja'
    : 'Nein';
}

export function formatDate(date: Moment | Date | string): string {
  if (date instanceof Date) {
    return moment(date.date).format(DATE_FORMAT);
  }

  if (isMoment(date)) {
    date.format(DATE_FORMAT);
  }

  return moment(date).format(DATE_FORMAT);
}

export function formatTime(time: Time | Moment | string): string {
  const timeToFormat = Time.isTime(time) || isMoment(time) ? time : Time.fromString(time);
  return timeToFormat.format(TIME_FORMAT);
}

export function formatDateTime(date: Moment | string): string {
  const dateToFormat = isMoment(date) ? date : moment(date);
  return `${dateToFormat.format(DATE_FORMAT)} ${dateToFormat.format(TIME_FORMAT)}`;
}

export function formatMinWeekday(date: Moment): string {
  return date.format('dd');
}

export function formatTimeFrame(timeFrame: TimeFrame): string {
  return `${formatTime(timeFrame.timeFrom)} Uhr bis ${formatTime(timeFrame.timeTo)} Uhr`;
}

export function formatHours(value: any): string {
  if (typeof value !== 'number') {
    return value;
  }

  return value.toString().replace('.', ',');
}

export function formatKilogram(gramm: any): string {
  if (typeof gramm !== 'number') {
    return gramm;
  }

  if (gramm === 0) {
    return `–`;
  }

  return `${(gramm / 1000).toString().replace('.', ',')} kg`;
}
