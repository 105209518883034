import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import LedgerTabs from './ledger-configuration/components/ledger-tabs.vue';
import SettlementsForMonth from './ledger-configuration/components/settlements-for-month.vue';
import SpecialBookings from './ledger-configuration/components/special-bookings.vue';
import LedgerConfiguration from './ledger-configuration/components/ledger-configuration.vue';
import PaymentReceipts from './payment-receipt/components/payment-receipts.vue';
import SettlementForecastTable from './settlement-forecast/components/settlement-forecast-table.vue';

export const ledgerManagementRoutes: RouteConfig[] = [
  {
    path: 'abrechnung',
    component: LedgerTabs,
    children: [
      {
        name: 'ledger-management',
        path: '',
        redirect: { name: 'ledger-special-bookings' },
      },
      {
        name: 'ledger-special-bookings',
        path: 'sonderbuchungen',
        component: SpecialBookings,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'ledger-settlement',
        path: 'abrechnung',
        component: SettlementsForMonth,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'ledger-payment-receipt',
        path: 'zahlungseingang',
        component: PaymentReceipts,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'ledger-settlement-forecast',
        path: 'prognose',
        component: SettlementForecastTable,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'ledger-configuration',
        path: 'konfiguration',
        component: LedgerConfiguration,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
    ],
  },
];
