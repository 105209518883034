
import { Component, Vue } from 'vue-property-decorator';
import SettlementDetails from '@/private/management/ledger/ledger-configuration/components/settlement-details.vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, FormControlValue, getFormValues } from '@/components/form';
import { formatDate, formatTime } from '@/helpers';
import { Month } from '@/types';
import { moment } from '@/helpers';
import { useMyStableStore } from '../store';
import { GetSettlementForMonthAsUserQuery } from '../types';
import SettlementCategories from './settlement-categories.vue';

interface Controls extends FormControls {
  selectedMonth: FormControl<Month>;
}

@Component({
  components: {
    SettlementCategories,
    SettlementDetails,
  },
})
export default class SettlementForMonth extends Vue {

  readonly store = useMyStableStore();

  form: Form<Controls> | null = null;

  get isUpdatedAtVisible(): boolean {
    return this.store.settlementLastUpdatedAt !== null
      && !!this.form;
  }

  get isNoSettlementVisible(): boolean {
    return this.store.settlementWasPulled
      && this.store.settlementForMonth === null;
  }

  get updatedAtHTML(): string {
    if (this.store.settlementLastUpdatedAt === null
      || !this.form
    ) {
      return '';
    }

    const formValues = getFormValues(this.form);

    // eslint-disable-next-line max-len
    const updatedAtHTML = `Die Abrechnung für ${moment(formValues.selectedMonth!).format('MMMM')} wurde zuletzt <strong>am ${formatDate(this.store.settlementLastUpdatedAt)} um ${formatTime(this.store.settlementLastUpdatedAt)} Uhr</strong> aktualisiert.`;

    return moment().startOf('month').isSame(formValues.selectedMonth, 'month')
      ? `${updatedAtHTML} Die Daten werden ungefähr alle 15 Minuten aktualisiert.`
      : updatedAtHTML;
  }

  mounted(): void {
    const form = this.buildForm();

    this.form = form;

    const formValues = getFormValues(form);

    const query: GetSettlementForMonthAsUserQuery = {
      month: formValues.selectedMonth!,
    };

    this.store.getSettlementForMonthAsUser(query)
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        selectedMonth: {
          label: 'Von',
          value: moment().format('YYYY-MM') as Month,
          afterTransformationAndValidation: (value: FormControlValue<Month>) => {
            const query: GetSettlementForMonthAsUserQuery = {
              month: value!,
            };

            this.store.getSettlementForMonthAsUser(query)
              .catch((error) => showErrorResponse(error));
          },
        },
      },
    });
  }

  isMonthAllowed(month: Month): boolean {
    const startOfCurrentMonth = moment().startOf('month');

    return moment(month).isSameOrBefore(startOfCurrentMonth);
  }

}
