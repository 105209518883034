import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress } from '@/store';
import { useAuthenticationStore } from '@/application/authentication/store';
import { CSV } from '@/types';
import { moment } from '@/helpers';
import { createSettingsByUserGroup, deleteSettingsByUserGroup, exportSummaryEntriesAsCsv, getAvailableUserGroups, getSettingsByUserGroups, getTimeRecordings, markTimeEntryAsApproved, markTimeEntryAsRejected, updateAnnualNumbersOfHours, updateSettingsByUserGroup } from './service';
import { CreateSettingsByUserGroupAsManagerCommand, DeleteSettingsByUserGroupAsManagerCommand, ExportSummaryEntriesAsCsvAsManagerQuery, GetTimeRecordingsAsManagerQuery, LaborServiceSettingsByUserGroup, MarkTimeEntryAsApprovedAsManagerCommand, MarkTimeEntryAsRejectedAsManagerCommand, SummaryEntry, TimeEntry, UpdateAnnualNumbersOfHoursAsManagerCommand, UpdateSettingsByUserGroupAsManagerCommand, UserGroup } from './types';

interface LaborServicesManagementState {
  timeEntries: TimeEntry[];
  summaryEntries: SummaryEntry[];
  summaryYear: number;
  availableUserGroups: UserGroup[];
  settingsByUserGroup: LaborServiceSettingsByUserGroup[];

  getSettingsByUserGroupsStatus: ActionStatus;
  getAvailableUserGroupsStatus: ActionStatus;
  getTimeRecordingsStatus: ActionStatus;
  exportSummaryEntriesAsCsvStatus: ActionStatus;
  createSettingsByUserGroupStatus: ActionStatus;
  updateSettingsByUserGroupStatus: ActionStatus;
  deleteSettingsByUserGroupStatus: ActionStatus;
  markTimeEntryAsApprovedStatus: ActionStatus;
  markTimeEntryAsRejectedStatus: ActionStatus;
  updateSummaryYearStatus: ActionStatus;
  updateAnnualNumbersOfHoursStatus: ActionStatus;
}

function initialState(): LaborServicesManagementState {
  return {
    timeEntries: [],
    summaryEntries: [],
    summaryYear: moment().year(),
    availableUserGroups: [],
    settingsByUserGroup: [],

    getSettingsByUserGroupsStatus: ActionStatus.None,
    getAvailableUserGroupsStatus: ActionStatus.None,
    getTimeRecordingsStatus: ActionStatus.None,
    exportSummaryEntriesAsCsvStatus: ActionStatus.None,
    createSettingsByUserGroupStatus: ActionStatus.None,
    updateSettingsByUserGroupStatus: ActionStatus.None,
    deleteSettingsByUserGroupStatus: ActionStatus.None,
    markTimeEntryAsApprovedStatus: ActionStatus.None,
    markTimeEntryAsRejectedStatus: ActionStatus.None,
    updateSummaryYearStatus: ActionStatus.None,
    updateAnnualNumbersOfHoursStatus: ActionStatus.None,
  };
}

export const useLaborServicesManagementStore = defineStore('manageLaborService', {
  state: (): LaborServicesManagementState => initialState(),
  getters: {
    isGetSettingsByUserGroupsProcessing: (state: LaborServicesManagementState): boolean =>
      state.getSettingsByUserGroupsStatus === ActionStatus.InProgress,
    isGetAvailableUserGroupsProcessing: (state: LaborServicesManagementState): boolean =>
      state.getAvailableUserGroupsStatus === ActionStatus.InProgress,
    isGetTimeRecordingsProcessing: (state: LaborServicesManagementState): boolean =>
      state.getTimeRecordingsStatus === ActionStatus.InProgress,
    isExportSummaryEntriesAsCsvProcessing: (state: LaborServicesManagementState): boolean =>
      state.exportSummaryEntriesAsCsvStatus === ActionStatus.InProgress,
    isCreateSettingsByUserGroupProcessing: (state: LaborServicesManagementState): boolean =>
      state.createSettingsByUserGroupStatus === ActionStatus.InProgress,
    isUpdateSettingsByUserGroupProcessing: (state: LaborServicesManagementState): boolean =>
      state.updateSettingsByUserGroupStatus === ActionStatus.InProgress,
    isDeleteSettingsByUserGroupProcessing: (state: LaborServicesManagementState): boolean =>
      state.deleteSettingsByUserGroupStatus === ActionStatus.InProgress,
    isMarkTimeEntryAsApprovedProcessing: (state: LaborServicesManagementState): boolean =>
      state.markTimeEntryAsApprovedStatus === ActionStatus.InProgress,
    isMarkTimeEntryAsRejectedProcessing: (state: LaborServicesManagementState): boolean =>
      state.markTimeEntryAsRejectedStatus === ActionStatus.InProgress,
    isUpdateSummaryYearProcessing: (state: LaborServicesManagementState): boolean =>
      state.updateSummaryYearStatus === ActionStatus.InProgress,
    isUpdateAnnualNumbersOfHoursProcessing: (state: LaborServicesManagementState): boolean =>
      state.updateAnnualNumbersOfHoursStatus === ActionStatus.InProgress,

    handledTimeEntries: (state: LaborServicesManagementState): TimeEntry[] =>
      state.timeEntries.filter((timeEntry) => timeEntry.approvedAt || timeEntry.rejectedAt),
    unhandledTimeEntries: (state: LaborServicesManagementState): TimeEntry[] =>
      state.timeEntries.filter((timeEntry) => !timeEntry.approvedAt && !timeEntry.rejectedAt),
  },
  actions: {

    // -- State management

    updateSummaryYear(summaryYear: number): Promise<void> {
      this.summaryYear = summaryYear;

      return this.getTimeRecordings();
    },

    // -- Queries

    getSettingsByUserGroups(): Promise<void> {
      const { getSettingsByUserGroupsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSettingsByUserGroupsStatus,
        () => getSettingsByUserGroups(attachFarmAndUserProperties({}))
          .then((settingsByUserGroup) => {
            this.settingsByUserGroup = settingsByUserGroup;
          })
      );
    },

    getAvailableUserGroups(): Promise<void> {
      const { getAvailableUserGroupsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAvailableUserGroupsStatus,
        () => getAvailableUserGroups(attachFarmAndUserProperties({}))
          .then((availableUserGroups) => {
            this.availableUserGroups = availableUserGroups;
          })
      );
    },

    getTimeRecordings(): Promise<void> {
      const query: GetTimeRecordingsAsManagerQuery = {
        summaryYear: this.summaryYear,
      };
      const { getTimeRecordingsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getTimeRecordingsStatus,
        () => getTimeRecordings(attachFarmAndUserProperties(query))
          .then((timeRecords) => {
            this.timeEntries = timeRecords.timeEntries;
            this.summaryEntries = timeRecords.summaryEntries;
          })
      );
    },

    exportSummaryEntriesAsCsv(): Promise<CSV> {
      const query: ExportSummaryEntriesAsCsvAsManagerQuery = {
        summaryYear: this.summaryYear,
      };
      const { exportSummaryEntriesAsCsvStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        exportSummaryEntriesAsCsvStatus,
        () => exportSummaryEntriesAsCsv(attachFarmAndUserProperties(query))
      );
    },

    // -- Commands

    createSettingsByUserGroup(command: CreateSettingsByUserGroupAsManagerCommand): Promise<void> {
      const { createSettingsByUserGroupStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSettingsByUserGroupStatus,
        () => createSettingsByUserGroup(attachFarmAndUserProperties(command))
          .then(() => this.getSettingsByUserGroups())
      );
    },

    updateSettingsByUserGroup(command: UpdateSettingsByUserGroupAsManagerCommand): Promise<void> {
      const { updateSettingsByUserGroupStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateSettingsByUserGroupStatus,
        () => updateSettingsByUserGroup(attachFarmAndUserProperties(command))
          .then(() => this.getSettingsByUserGroups())
      );
    },

    deleteSettingsByUserGroup(command: DeleteSettingsByUserGroupAsManagerCommand): Promise<void> {
      const { deleteSettingsByUserGroupStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSettingsByUserGroupStatus,
        () => deleteSettingsByUserGroup(attachFarmAndUserProperties(command))
          .then(() => this.getSettingsByUserGroups())
      );
    },

    markTimeEntryAsApproved(command: MarkTimeEntryAsApprovedAsManagerCommand): Promise<void> {
      const { markTimeEntryAsApprovedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTimeEntryAsApprovedStatus,
        () => markTimeEntryAsApproved(attachFarmAndUserProperties(command))
          .then(() => this.getTimeRecordings())
      );
    },

    markTimeEntryAsRejected(command: MarkTimeEntryAsRejectedAsManagerCommand): Promise<void> {
      const { markTimeEntryAsRejectedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTimeEntryAsRejectedStatus,
        () => markTimeEntryAsRejected(attachFarmAndUserProperties(command))
          .then(() => this.getTimeRecordings())
      );
    },

    updateAnnualNumbersOfHours(command: UpdateAnnualNumbersOfHoursAsManagerCommand): Promise<void> {
      const { updateAnnualNumbersOfHoursStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAnnualNumbersOfHoursStatus,
        () => updateAnnualNumbersOfHours(attachFarmAndUserProperties(command))
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

  },
});
