
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InfoPopup extends Vue {

  @Prop({ type: String, required: true })
  readonly infoText!: string;

  @Prop({ type: Boolean, default: false })
  readonly isPositionedInFieldset!: boolean;

  get sizeDependingOnTextLength(): number {
    return this.infoText.length > 100
      ? 500
      : 300;
  }

}
