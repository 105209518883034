import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { UserId } from '@/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { assignTaskToUser, getAssignedTasksForToday, getAssignedTasks, getCompletedTasks, getFarmManagersForAssigment, getNewTasks, getRejectedTasks, getUsersWithTasks, getWithdrawnTasks, markTaskAsCompleted, markTaskAsRejected, createAssignedTask, getOverdueTasks, updateExecutionDateOfTask, getUsersForAssignedTasks } from './service';
import { MarkTaskAsCompletedAsManagerCommand, CompletedTask, GetCompletedTasksAsManagerQuery, GetNewTasksAsManagerQuery, GetRejectedTasksAsManagerQuery, GetWithdrawnTasksAsManagerQuery, NewTask, RejectedTask, MarkTaskAsRejectedAsManagerCommand, AssignedTaskForToday, User, WithdrawnTask, AssignTaskToUserAsManagerCommand, AssignedTask, GetAssignedTasksAsManagerQuery, CreateAssignedTaskAsManagerCommand, OverdueTask, UpdateExecutionDateOfTaskAsManagerCommand } from './types';

interface TaskManagementState {
  usersWithTasks: User[];
  farmManagers: User[];
  usersForAssignedTasks: User[];

  newTasks: NewTask[];
  newTasksLimit: number;
  newTasksTotalCount: number | null;
  newTasksFilteredByUser: UserId | null;

  assignedTasks: AssignedTask[];
  assignedTasksLimit: number;
  assignedTasksTotalCount: number | null;
  assignedTasksFilteredByUser: UserId | null;

  completedTasks: CompletedTask[];
  completedTasksLimit: number;
  completedTasksTotalCount: number | null;
  completedTasksFilteredByUser: UserId | null;

  rejectedTasks: RejectedTask[];
  rejectedTasksLimit: number;
  rejectedTasksTotalCount: number | null;
  rejectedTasksFilteredByUser: UserId | null;

  withdrawnTasks: WithdrawnTask[];
  withdrawnTasksLimit: number;
  withdrawnTasksTotalCount: number | null;
  withdrawnTasksFilteredByUser: UserId | null;

  assignedTasksForToday: AssignedTaskForToday[];

  overdueTasks: OverdueTask[];

  getUsersWithTasksStatus: ActionStatus;
  assignTaskToUserStatus: ActionStatus;
  markTaskAsCompletedStatus: ActionStatus;
  markTaskAsRejectedStatus: ActionStatus;
  getNewTasksStatus: ActionStatus;
  getCompletedTasksStatus: ActionStatus;
  getRejectedTasksStatus: ActionStatus;
  getWithdrawnTasksStatus: ActionStatus;
  getAssignedTasksForTodayStatus: ActionStatus;
  getFarmManagersForAssignmentStatus: ActionStatus;
  getAssignedTasksStatus: ActionStatus;
  createAssignedTaskStatus: ActionStatus;
  getOverdueTasksStatus: ActionStatus;
  updateExecutionDateOfTaskStatus: ActionStatus;
  getUsersForAssignedTasksStatus: ActionStatus;
}

function initialState(): TaskManagementState {
  return {
    usersWithTasks: [],
    farmManagers: [],
    usersForAssignedTasks: [],

    newTasks: [],
    newTasksLimit: 50,
    newTasksTotalCount: null,
    newTasksFilteredByUser: null,

    assignedTasks: [],
    assignedTasksLimit: 50,
    assignedTasksTotalCount: null,
    assignedTasksFilteredByUser: null,

    completedTasks: [],
    completedTasksLimit: 50,
    completedTasksTotalCount: null,
    completedTasksFilteredByUser: null,

    rejectedTasks: [],
    rejectedTasksLimit: 50,
    rejectedTasksTotalCount: null,
    rejectedTasksFilteredByUser: null,

    withdrawnTasks: [],
    withdrawnTasksLimit: 50,
    withdrawnTasksTotalCount: null,
    withdrawnTasksFilteredByUser: null,

    assignedTasksForToday: [],

    overdueTasks: [],

    getUsersWithTasksStatus: ActionStatus.None,
    assignTaskToUserStatus: ActionStatus.None,
    markTaskAsCompletedStatus: ActionStatus.None,
    markTaskAsRejectedStatus: ActionStatus.None,
    getNewTasksStatus: ActionStatus.None,
    getCompletedTasksStatus: ActionStatus.None,
    getRejectedTasksStatus: ActionStatus.None,
    getWithdrawnTasksStatus: ActionStatus.None,
    getAssignedTasksForTodayStatus: ActionStatus.None,
    getFarmManagersForAssignmentStatus: ActionStatus.None,
    getAssignedTasksStatus: ActionStatus.None,
    createAssignedTaskStatus: ActionStatus.None,
    getOverdueTasksStatus: ActionStatus.None,
    updateExecutionDateOfTaskStatus: ActionStatus.None,
    getUsersForAssignedTasksStatus: ActionStatus.None,
  };
}

export const useRiderTaskManagementStore = defineStore('manageRiderTasks', {
  state: (): TaskManagementState => initialState(),
  getters: {
    isGetUsersWithTasksProcessing: (state: TaskManagementState): boolean =>
      state.getUsersWithTasksStatus === ActionStatus.InProgress,
    isAssignTaskToUserProcessing: (state: TaskManagementState): boolean =>
      state.assignTaskToUserStatus === ActionStatus.InProgress,
    isMarkTaskAsCompletedProcessing: (state: TaskManagementState): boolean =>
      state.markTaskAsCompletedStatus === ActionStatus.InProgress,
    isMarkTaskAsRejectedProcessing: (state: TaskManagementState): boolean =>
      state.markTaskAsRejectedStatus === ActionStatus.InProgress,
    isGetNewTasksProcessing: (state: TaskManagementState): boolean =>
      state.getNewTasksStatus === ActionStatus.InProgress,
    isGetAssignedTasksProcessing: (state: TaskManagementState): boolean =>
      state.getAssignedTasksStatus === ActionStatus.InProgress,
    isGetCompletedTasksProcessing: (state: TaskManagementState): boolean =>
      state.getCompletedTasksStatus === ActionStatus.InProgress,
    isGetRejectedTasksProcessing: (state: TaskManagementState): boolean =>
      state.getRejectedTasksStatus === ActionStatus.InProgress,
    isGetWithdrawnTasksProcessing: (state: TaskManagementState): boolean =>
      state.getWithdrawnTasksStatus === ActionStatus.InProgress,
    isGetAssignedTasksForTodayProcessing: (state: TaskManagementState): boolean =>
      state.getAssignedTasksForTodayStatus === ActionStatus.InProgress,
    isGetFarmManagersForAssignmentProcessing: (state: TaskManagementState): boolean =>
      state.getFarmManagersForAssignmentStatus === ActionStatus.InProgress,
    isCreateAssignedTaskProcessing: (state: TaskManagementState): boolean =>
      state.createAssignedTaskStatus === ActionStatus.InProgress,
    isGetOverdueTasksProcessing: (state: TaskManagementState): boolean =>
      state.getOverdueTasksStatus === ActionStatus.InProgress,
    isUpdateExecutionDateOfTaskProcessing: (state: TaskManagementState): boolean =>
      state.updateExecutionDateOfTaskStatus === ActionStatus.InProgress,
    isGetUsersForAssignedTasksProcessing: (state: TaskManagementState): boolean =>
      state.getUsersForAssignedTasksStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    increaseNewTasksLimit(): Promise<void> {
      this.newTasksLimit += 50;

      return this.getNewTasks();
    },

    filterNewTasksByUser(userId: UserId | null): Promise<void> {
      this.newTasksFilteredByUser = userId;

      return this.getNewTasks();
    },

    increaseCompletedTasksLimit(): Promise<void> {
      this.completedTasksLimit += 50;

      return this.getCompletedTasks();
    },

    filterCompletedTasksByUser(userId: UserId | null): Promise<void> {
      this.completedTasksFilteredByUser = userId;

      return this.getCompletedTasks();
    },

    increaseRejectedTasksLimit(): Promise<void> {
      this.rejectedTasksLimit += 50;

      return this.getRejectedTasks();
    },

    filterRejectedTasksByUser(userId: UserId | null): Promise<void> {
      this.rejectedTasksFilteredByUser = userId;

      return this.getRejectedTasks();
    },

    increaseWithdrawnTasksLimit(): Promise<void> {
      this.withdrawnTasksLimit += 50;

      return this.getWithdrawnTasks();
    },

    filterWithdrawnTasksByUser(userId: UserId | null): Promise<void> {
      this.withdrawnTasksFilteredByUser = userId;

      return this.getWithdrawnTasks();
    },

    increaseAssignedTasksLimit(): Promise<void> {
      this.assignedTasksLimit += 50;

      return this.getAssignedTasks();
    },

    filterAssignedTasksByUser(userId: UserId | null): Promise<void> {
      this.assignedTasksFilteredByUser = userId;

      return this.getAssignedTasks();
    },

    // -- Queries

    getUsersWithTasks(): Promise<void> {
      const { getUsersWithTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersWithTasksStatus,
        () => getUsersWithTasks(attachFarmAndUserProperties({}))
          .then(async (users) => {
            this.usersWithTasks = users;
          })
      );
    },

    getFarmManagersForAssignment(): Promise<void> {
      const { getFarmManagersForAssignmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmManagersForAssignmentStatus,
        () => getFarmManagersForAssigment(attachFarmAndUserProperties({}))
          .then(async (farmManagers) => {
            this.farmManagers = farmManagers;
          })
      );
    },

    getNewTasks(): Promise<void> {
      const query: GetNewTasksAsManagerQuery = {
        filteredByUser: this.newTasksFilteredByUser,
        limit: this.newTasksLimit,
      };
      const { getNewTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNewTasksStatus,
        () => getNewTasks(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.newTasks = response.tasks;
            this.newTasksTotalCount = response.totalCount;
          })
      );
    },

    getAssignedTasks(): Promise<void> {
      const query: GetAssignedTasksAsManagerQuery = {
        filteredByUser: this.assignedTasksFilteredByUser,
        limit: this.assignedTasksLimit,
      };
      const { getAssignedTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAssignedTasksStatus,
        () => getAssignedTasks(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.assignedTasks = response.tasks;
            this.assignedTasksTotalCount = response.totalCount;
          })
      );
    },

    getCompletedTasks(): Promise<void> {
      const query: GetCompletedTasksAsManagerQuery = {
        filteredByUser: this.completedTasksFilteredByUser,
        limit: this.completedTasksLimit,
      };
      const { getCompletedTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCompletedTasksStatus,
        () => getCompletedTasks(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.completedTasks = response.tasks;
            this.completedTasksTotalCount = response.totalCount;
          })
      );
    },

    getRejectedTasks(): Promise<void> {
      const query: GetRejectedTasksAsManagerQuery = {
        filteredByUser: this.rejectedTasksFilteredByUser,
        limit: this.rejectedTasksLimit,
      };
      const { getRejectedTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getRejectedTasksStatus,
        () => getRejectedTasks(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.rejectedTasks = response.tasks;
            this.rejectedTasksTotalCount = response.totalCount;
          })
      );
    },

    getWithdrawnTasks(): Promise<void> {
      const query: GetWithdrawnTasksAsManagerQuery = {
        filteredByUser: this.withdrawnTasksFilteredByUser,
        limit: this.withdrawnTasksLimit,
      };
      const { getWithdrawnTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getWithdrawnTasksStatus,
        () => getWithdrawnTasks(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.withdrawnTasks = response.tasks;
            this.withdrawnTasksTotalCount = response.totalCount;
          })
      );
    },

    getAssignedTasksForToday(): Promise<void> {
      const { getAssignedTasksForTodayStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAssignedTasksForTodayStatus,
        () => getAssignedTasksForToday(attachFarmAndUserProperties({}))
          .then(async (tasks) => {
            this.assignedTasksForToday = tasks;
          })
      );
    },

    getOverdueTasks(): Promise<void> {
      const { getOverdueTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOverdueTasksStatus,
        () => getOverdueTasks(attachFarmAndUserProperties({}))
          .then(async (tasks) => {
            this.overdueTasks = tasks;
          })
      );
    },

    getUsersForAssignedTasks(): Promise<void> {
      const { getUsersForAssignedTasksStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersForAssignedTasksStatus,
        () => getUsersForAssignedTasks(attachFarmAndUserProperties({}))
          .then(async (usersForAssignedTasks) => {
            this.usersForAssignedTasks = usersForAssignedTasks;
          })
      );
    },

    // -- Commands

    assignTaskToUser(command: AssignTaskToUserAsManagerCommand): Promise<void> {
      const { assignTaskToUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        assignTaskToUserStatus,
        () => assignTaskToUser(attachFarmAndUserProperties(command))
      );
    },

    markTaskAsCompleted(command: MarkTaskAsCompletedAsManagerCommand): Promise<void> {
      const { markTaskAsCompletedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTaskAsCompletedStatus,
        () => markTaskAsCompleted(attachFarmAndUserProperties(command))
      );
    },

    markTaskAsRejected(command: MarkTaskAsRejectedAsManagerCommand): Promise<void> {
      const { markTaskAsRejectedStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markTaskAsRejectedStatus,
        () => markTaskAsRejected(attachFarmAndUserProperties(command))
          .then(() => this.getNewTasks())
      );
    },

    createAssignedTask(command: CreateAssignedTaskAsManagerCommand): Promise<void> {
      const { createAssignedTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createAssignedTaskStatus,
        () => createAssignedTask(attachFarmAndUserProperties(command))
          .then(() => this.getAssignedTasks())
      );
    },

    updateExecutionDateOfTask(command: UpdateExecutionDateOfTaskAsManagerCommand): Promise<void> {
      const { updateExecutionDateOfTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateExecutionDateOfTaskStatus,
        () => updateExecutionDateOfTask(attachFarmAndUserProperties(command))
          .then(() => this.getAssignedTasks())
      );
    },

  },
});
