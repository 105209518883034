
import { Vue, Component, Prop } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Date } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { moment } from '@/helpers';
import { GerblhofConcentratedFeeding, GerblhofConcentratedFeedingUpdate, GerblhofConcentratedFeedType, UpdateConcentratedFeedingCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  startingAt: FormControl<Date>;
  concentratedFeedings: FormControl<GerblhofConcentratedFeeding[]>;
  ownFeedComment: FormControl<string>;
}

@Component
export default class UpdateGerblhofConcentratedFeedingDialog extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Array, required: true })
  readonly concentratedFeedingUpdates!: GerblhofConcentratedFeedingUpdate[];

  @Prop({ type: Array, required: true })
  readonly concentratedFeedTypes!: GerblhofConcentratedFeedType[];

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get startingAtOptions(): { label: string, date: Date }[] {
    const startingAtOptions: { label: string, date: Date }[] = [];
    const currentDayOfMonth = moment().date();
    let nextOption: Date;

    if (currentDayOfMonth >= 15) {
      nextOption = Date.fromMoment(
          moment()
          .startOf('day')
          .startOf('month')
          .add(1, 'month')
        );
    } else {
      nextOption = Date.fromMoment(
        moment()
          .startOf('day')
          .date(15)
        );
    }

    /* eslint-disable no-loop-func */
    for (let i = 0; i < 24; i++) {
      const isDateBlocked = this.concentratedFeedingUpdates
        .some((feedingUpdate) => feedingUpdate.startingAt.isEqualTo(nextOption));

      if (!isDateBlocked) {
        startingAtOptions.push({
          label: nextOption.format('DD.MM.YYYY'),
          date: nextOption,
        });
      }

      if (nextOption.format('DD') === '15') {
        nextOption = Date.fromMoment(
          moment(nextOption.date)
            .startOf('month')
            .add(1, 'month'),
        );
      } else {
        nextOption = Date.fromMoment(
          moment(nextOption.date)
            .date(15),
        );
      }
    }

    return startingAtOptions;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        startingAt: {
          label: 'Beginn der Anpassung',
          value: this.startingAtOptions[0].date,
          isRequired: true,
        },
        concentratedFeedings: {
          label: 'Kraftfutter-Typen',
          value: this.store.gerblhofFeeding!.activeConcentratedFeedingUpdate
            ? this.store.gerblhofFeeding!.activeConcentratedFeedingUpdate.concentratedFeedings
            : null,
        },
        ownFeedComment: {
          label: 'Eigenes Futter',
          value: this.store.gerblhofFeeding!.activeConcentratedFeedingUpdate
            ? this.store.gerblhofFeeding!.activeConcentratedFeedingUpdate.ownFeedComment
            : null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateConcentratedFeedingCommand = {
      horseId: this.store.currentHorseId!,
      startingAt: formValues.startingAt!,
      concentratedFeedings: formValues.concentratedFeedings ?? [],
      ownFeedComment: formValues.ownFeedComment,
    };

    this.store.updateConcentratedFeeding(command)
      .then(() => showSuccessMessage('Kraftfutter angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
