import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { FarmServiceId, HorseId, Month, UserId, Date } from '@/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { createFarmService, archiveFarmService, disableFarmService, enableFarmService, getFarmServiceOverviewFilterDataAsManager, getFarmServices, getFarmServiceUsagesForDay, getFarmServiceUsagesForMonth, updateFarmServiceBookingStart, updateFarmServicePaymentMethod, updateFarmServicePresentation, updateFarmServicePrice, updateFarmServiceRestrictions, updateHiddenFarmServices } from './service';
import { CreateFarmServiceAsManagerCommand, ArchiveFarmServiceAsManagerCommand, DisableFarmServiceAsManagerCommand, EnableFarmServiceAsManagerCommand, FarmService, FarmServiceOverviewFilterData, FarmServiceUsagesForDay, FarmServiceUsagesForMonth, GetFarmServiceUsagesForDayAsManagerQuery, GetFarmServiceUsagesForMonthAsManagerQuery, UpdateFarmServiceBookingStartAsManagerCommand, UpdateFarmServicePaymentMethodAsManagerCommand, UpdateFarmServicePresentationAsManagerCommand, UpdateFarmServicePriceAsManagerCommand, UpdateFarmServiceRestrictionsAsManagerCommand, UpdateHiddenFarmServicesAsManagerCommand, GetFarmServicesAsManagerQuery } from './types';

interface FarmServiceState {
  farmServiceOverviewFilterData: FarmServiceOverviewFilterData | null;

  selectedDate: Date | null;
  selectedMonth: Month | null;
  selectedUserId: UserId | null;
  selectedHorseId: HorseId | null;
  selectedFarmServiceId: FarmServiceId | null;
  farmServiceOverviewForDay: FarmServiceUsagesForDay | null;
  farmServiceUsagesForMonth: FarmServiceUsagesForMonth | null;
  farmServices: FarmService[] | null;
  searchForFarmServices: string | null;

  currentFarmService: FarmService | null;
  idOfCurrentFarmService: FarmServiceId | null;

  getFarmServiceOverviewFilterDataAsManagerStatus: ActionStatus;
  getFarmServiceUsagesForDayStatus: ActionStatus;
  getFarmServiceUsagesForMonthStatus: ActionStatus;
  updateHiddenFarmServicesStatus: ActionStatus;
  createFarmServiceStatus: ActionStatus;
  updateFarmServicePresentationStatus: ActionStatus;
  updateFarmServicePriceStatus: ActionStatus;
  updateFarmServicePaymentMethodStatus: ActionStatus;
  updateFarmServiceBookingStartStatus: ActionStatus;
  updateFarmServiceRestrictionsStatus: ActionStatus;
  enableFarmServiceStatus: ActionStatus;
  disableFarmServiceStatus: ActionStatus;
  archiveFarmServiceStatus: ActionStatus;
  getFarmServicesStatus: ActionStatus;
}

function initialState(): FarmServiceState {
  return {
    farmServiceOverviewFilterData: null,

    selectedDate: null,
    selectedMonth: null,
    selectedUserId: null,
    selectedHorseId: null,
    selectedFarmServiceId: null,
    farmServiceOverviewForDay: null,
    farmServiceUsagesForMonth: null,
    farmServices: null,
    searchForFarmServices: null,

    currentFarmService: null,
    idOfCurrentFarmService: null,

    getFarmServiceOverviewFilterDataAsManagerStatus: ActionStatus.None,
    getFarmServiceUsagesForDayStatus: ActionStatus.None,
    getFarmServiceUsagesForMonthStatus: ActionStatus.None,
    updateHiddenFarmServicesStatus: ActionStatus.None,
    createFarmServiceStatus: ActionStatus.None,
    updateFarmServicePresentationStatus: ActionStatus.None,
    updateFarmServicePriceStatus: ActionStatus.None,
    updateFarmServicePaymentMethodStatus: ActionStatus.None,
    updateFarmServiceBookingStartStatus: ActionStatus.None,
    updateFarmServiceRestrictionsStatus: ActionStatus.None,
    enableFarmServiceStatus: ActionStatus.None,
    disableFarmServiceStatus: ActionStatus.None,
    archiveFarmServiceStatus: ActionStatus.None,
    getFarmServicesStatus: ActionStatus.None,
  };
}

export const useFarmServiceStore = defineStore('farmService', {
  state: (): FarmServiceState => initialState(),
  getters: {
    isGetHiddenFarmServicesProcessing: (state: FarmServiceState): boolean =>
      state.getFarmServiceOverviewFilterDataAsManagerStatus === ActionStatus.InProgress,
    isGetFarmServiceUsagesForDayProcessing: (state: FarmServiceState): boolean =>
      state.getFarmServiceUsagesForDayStatus === ActionStatus.InProgress,
    isGetFarmServiceUsagesForMonthProcessing: (state: FarmServiceState): boolean =>
      state.getFarmServiceUsagesForMonthStatus === ActionStatus.InProgress,
    isUpdateHiddenFarmServicesProcessing: (state: FarmServiceState): boolean =>
      state.updateHiddenFarmServicesStatus === ActionStatus.InProgress,
    isCreateFarmServiceProcessing: (state: FarmServiceState): boolean =>
      state.createFarmServiceStatus === ActionStatus.InProgress,
    isUpdateFarmServicePresentationProcessing: (state: FarmServiceState): boolean =>
      state.updateFarmServicePresentationStatus === ActionStatus.InProgress,
    isUpdateFarmServicePriceProcessing: (state: FarmServiceState): boolean =>
      state.updateFarmServicePriceStatus === ActionStatus.InProgress,
    isUpdateFarmServicePaymentMethodProcessing: (state: FarmServiceState): boolean =>
      state.updateFarmServicePaymentMethodStatus === ActionStatus.InProgress,
    isUpdateFarmServiceBookingStartProcessing: (state: FarmServiceState): boolean =>
      state.updateFarmServiceBookingStartStatus === ActionStatus.InProgress,
    isUpdateFarmServiceRestrictionsProcessing: (state: FarmServiceState): boolean =>
      state.updateFarmServiceRestrictionsStatus === ActionStatus.InProgress,
    isEnableFarmServiceProcessing: (state: FarmServiceState): boolean =>
      state.enableFarmServiceStatus === ActionStatus.InProgress,
    isDisableFarmServiceProcessing: (state: FarmServiceState): boolean =>
      state.disableFarmServiceStatus === ActionStatus.InProgress,
    isArchiveFarmServiceProcessing: (state: FarmServiceState): boolean =>
      state.archiveFarmServiceStatus === ActionStatus.InProgress,
    isGetFarmServicesProcessing: (state: FarmServiceState): boolean =>
      state.getFarmServicesStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSelectedDate(selectedDate: Date): Promise<void> {
      this.selectedDate = selectedDate;

      return this.getFarmServiceUsagesForDay();
    },

    updateSelectedMonth(selectedMonth: Month): Promise<void> {
      this.selectedMonth = selectedMonth;

      return this.getFarmServiceUsagesForMonth();
    },

    updateSelectedUser(userId: UserId | null): Promise<void> {
      this.selectedUserId = userId;

      return this.getFarmServiceUsagesForMonth();
    },

    updateSelectedHorse(horseId: HorseId | null): Promise<void> {
      this.selectedHorseId = horseId;

      return this.getFarmServiceUsagesForMonth();
    },

    updateSelectedFarmService(farmServiceId: FarmServiceId | null): Promise<void> {
      this.selectedFarmServiceId = farmServiceId;

      return this.getFarmServiceUsagesForMonth();
    },

    async updateCurrentFarmService(farmServiceId: FarmServiceId): Promise<void> {
      this.idOfCurrentFarmService = farmServiceId;
      if (this.farmServices === null) {
        return this.getFarmServices()
          .then(() => {
            this.currentFarmService = this.farmServices!.find((farmService) => farmService.farmServiceId === farmServiceId)!;
          });
      } else {
        this.currentFarmService = this.farmServices!.find((farmService) => farmService.farmServiceId === farmServiceId)!;
        return Promise.resolve();
      }
    },

    resetCurrentFarmService(): Promise<void> {
      this.idOfCurrentFarmService = null;

      return Promise.resolve();
    },

    updateSearchForFarmServices(searchForFarmServices: string | null): Promise<void> {
      this.searchForFarmServices = searchForFarmServices;

      return this.getFarmServices();
    },

    // -- Queries

    getFarmServiceOverviewFilterData(): Promise<void> {
      const { getFarmServiceOverviewFilterDataAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServiceOverviewFilterDataAsManagerStatus,
        () => getFarmServiceOverviewFilterDataAsManager(attachFarmAndUserProperties({}))
          .then((farmServiceOverviewFilterData) => {
            this.farmServiceOverviewFilterData = farmServiceOverviewFilterData;
          })
      );
    },

    getFarmServiceUsagesForDay(): Promise<void> {
      const query: GetFarmServiceUsagesForDayAsManagerQuery = {
        date: this.selectedDate!,
      };
      const { getFarmServiceUsagesForDayStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServiceUsagesForDayStatus,
        () => getFarmServiceUsagesForDay(attachFarmAndUserProperties(query))
          .then((farmServiceOverview) => {
            this.farmServiceOverviewForDay = farmServiceOverview;
          })
      );
    },

    getFarmServiceUsagesForMonth(): Promise<void> {
      const query: GetFarmServiceUsagesForMonthAsManagerQuery = {
        month: this.selectedMonth!,
        filteredByUser: this.selectedUserId,
        filteredByHorse: this.selectedHorseId,
        filteredByFarmService: this.selectedFarmServiceId,
      };
      const { getFarmServiceUsagesForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServiceUsagesForMonthStatus,
        () => getFarmServiceUsagesForMonth(attachFarmAndUserProperties(query))
          .then((farmServiceUsagesForMonth) => {
            this.farmServiceUsagesForMonth = farmServiceUsagesForMonth;
          })
      );
    },

    getFarmServices(): Promise<void> {
      const query: GetFarmServicesAsManagerQuery = {
        search: this.searchForFarmServices,
      };
      const { getFarmServicesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmServicesStatus,
        () => getFarmServices(attachFarmAndUserProperties(query))
          .then((farmServices) => {
            this.farmServices = farmServices;
            if (this.idOfCurrentFarmService !== null) {
              this.currentFarmService = farmServices
                .find((farmService) => farmService.farmServiceId === this.idOfCurrentFarmService)!;
            }
          })
      );
    },

    // -- Commands

    updateHiddenFarmServices(command: UpdateHiddenFarmServicesAsManagerCommand): Promise<void> {
      const { updateHiddenFarmServicesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHiddenFarmServicesStatus,
        () => updateHiddenFarmServices(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServiceOverviewFilterData())
          .then(async () => {
            const actions = [];
            if (this.selectedDate !== null) {
              actions.push(this.getFarmServiceUsagesForDay());
            }
            if (this.selectedMonth !== null) {
              actions.push(this.getFarmServiceUsagesForMonth());
            }

            return Promise.all(actions).then(() => Promise.resolve());
          })
      );
    },

    createFarmService(command: CreateFarmServiceAsManagerCommand): Promise<void> {
      const { createFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFarmServiceStatus,
        () => createFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateFarmServicePresentation(command: UpdateFarmServicePresentationAsManagerCommand): Promise<void> {
      const { updateFarmServicePresentationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePresentationStatus,
        () => updateFarmServicePresentation(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
      );
    },

    updateFarmServicePrice(command: UpdateFarmServicePriceAsManagerCommand): Promise<void> {
      const { updateFarmServicePriceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePriceStatus,
        () => updateFarmServicePrice(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
      );
    },

    updateFarmServicePaymentMethod(command: UpdateFarmServicePaymentMethodAsManagerCommand): Promise<void> {
      const { updateFarmServicePaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServicePaymentMethodStatus,
        () => updateFarmServicePaymentMethod(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
      );
    },

    updateFarmServiceBookingStart(command: UpdateFarmServiceBookingStartAsManagerCommand): Promise<void> {
      const { updateFarmServiceBookingStartStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServiceBookingStartStatus,
        () => updateFarmServiceBookingStart(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
      );
    },

    updateFarmServiceRestrictions(command: UpdateFarmServiceRestrictionsAsManagerCommand): Promise<void> {
      const { updateFarmServiceRestrictionsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmServiceRestrictionsStatus,
        () => updateFarmServiceRestrictions(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
      );
    },

    enableFarmService(command: EnableFarmServiceAsManagerCommand): Promise<void> {
      const { enableFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableFarmServiceStatus,
        () => enableFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
      );
    },

    disableFarmService(command: DisableFarmServiceAsManagerCommand): Promise<void> {
      const { disableFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableFarmServiceStatus,
        () => disableFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
      );
    },

    archiveFarmService(command: ArchiveFarmServiceAsManagerCommand): Promise<void> {
      const { archiveFarmServiceStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        archiveFarmServiceStatus,
        () => archiveFarmService(attachFarmAndUserProperties(command))
          .then(() => this.getFarmServices())
          .then(() => useAuthenticationStore().getAuthentication()) // Farm services might become hidden for riders
      );
    },

  },
});
