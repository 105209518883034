
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { PhoneNumber } from '@/types';
import { Horse, UpdateHorseContactsCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  emergencyContactName: FormControl<string>;
  emergencyContactPhoneNumber: FormControl<PhoneNumber>;
  isEmergencyContactVisibleOnBoxSign: FormControl<boolean>;
  veterinarianName: FormControl<string>;
  veterinarianPhone: FormControl<PhoneNumber>;
  isVeterinarianVisibleOnBoxSign: FormControl<boolean>;
  farrierName: FormControl<string>;
  farrierPhone: FormControl<PhoneNumber>;
  isFarrierVisibleOnBoxSign: FormControl<boolean>;
}

@Component
export default class UpdateHorseContactsDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly horse!: Horse;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emergencyContactName: {
          label: 'Name des Notfallkontakts',
          value: this.horse.emergencyContactName,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'emergencyContactPhoneNumber',
          ],
        },
        emergencyContactPhoneNumber: {
          label: 'Telefonnummer des Notfallkontakts',
          value: this.horse.emergencyContactPhoneNumber,
          rules: [],
          validateFormControlsOnInput: [
            'emergencyContactName',
          ],
        },
        isEmergencyContactVisibleOnBoxSign: {
          label: 'Notfallkontakt auf Boxenschild anzeigen',
          value: this.horse.isEmergencyContactVisibleOnBoxSign,
          validateFormControlsOnInput: [
            'emergencyContactName',
            'emergencyContactPhoneNumber',
          ],
        },
        veterinarianName: {
          label: 'Name des Tierarztes',
          value: this.horse.veterinarianName,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'veterinarianPhone',
          ],
        },
        veterinarianPhone: {
          label: 'Telefonnummer des Tierarztes',
          value: this.horse.veterinarianPhone,
          rules: [],
          validateFormControlsOnInput: [
            'veterinarianName',
          ],
        },
        isVeterinarianVisibleOnBoxSign: {
          label: 'Tierarzt auf Boxenschild anzeigen',
          value: this.horse.isVeterinarianVisibleOnBoxSign,
          validateFormControlsOnInput: [
            'veterinarianName',
            'veterinarianPhone',
          ],
        },
        farrierName: {
          label: 'Name des Hufschmieds',
          value: this.horse.farrierName,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'farrierPhone',
          ],
        },
        farrierPhone: {
          label: 'Telefonnummer des Hufschmieds',
          value: this.horse.farrierPhone,
          rules: [],
          validateFormControlsOnInput: [
            'farrierName',
          ],
        },
        isFarrierVisibleOnBoxSign: {
          label: 'Hufschmied auf Boxenschild anzeigen',
          value: this.horse.isFarrierVisibleOnBoxSign,
          validateFormControlsOnInput: [
            'farrierName',
            'farrierPhone',
          ],
        },
      },
    });

    form.controls.emergencyContactName.rules!.push(() => {
      return !form.controls.isEmergencyContactVisibleOnBoxSign.value
      || form.controls.emergencyContactName.value !== null
      || form.controls.emergencyContactPhoneNumber.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Notfallkontakt auf dem Boxenschild angezeigt wird'
    });
    form.controls.emergencyContactPhoneNumber.rules!.push(() => {
      return !form.controls.isEmergencyContactVisibleOnBoxSign.value
      || form.controls.emergencyContactName.value !== null
      || form.controls.emergencyContactPhoneNumber.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Notfallkontakt auf dem Boxenschild angezeigt wird'
    });

    form.controls.veterinarianName.rules!.push(() => {
      return !form.controls.isVeterinarianVisibleOnBoxSign.value
      || form.controls.veterinarianName.value !== null
      || form.controls.veterinarianPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Tierarzt auf dem Boxenschild angezeigt wird'
    });
    form.controls.veterinarianPhone.rules!.push(() => {
      return !form.controls.isVeterinarianVisibleOnBoxSign.value
      || form.controls.veterinarianName.value !== null
      || form.controls.veterinarianPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Tierarzt auf dem Boxenschild angezeigt wird'
    });

    form.controls.farrierName.rules!.push(() => {
      return !form.controls.isFarrierVisibleOnBoxSign.value
      || form.controls.farrierName.value !== null
      || form.controls.farrierPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Hufschmied auf dem Boxenschild angezeigt wird'
    });
    form.controls.farrierPhone.rules!.push(() => {
      return !form.controls.isFarrierVisibleOnBoxSign.value
      || form.controls.farrierName.value !== null
      || form.controls.farrierPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Hufschmied auf dem Boxenschild angezeigt wird'
    });

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateHorseContactsCommand = {
      horseId: this.store.currentHorseId!,
      emergencyContactName: formValues.emergencyContactName,
      emergencyContactPhoneNumber: formValues.emergencyContactPhoneNumber,
      isEmergencyContactVisibleOnBoxSign: formValues.isEmergencyContactVisibleOnBoxSign!,
      veterinarianName: formValues.veterinarianName,
      veterinarianPhone: formValues.veterinarianPhone,
      isVeterinarianVisibleOnBoxSign: formValues.isVeterinarianVisibleOnBoxSign!,
      farrierName: formValues.farrierName,
      farrierPhone: formValues.farrierPhone,
      isFarrierVisibleOnBoxSign: formValues.isFarrierVisibleOnBoxSign!,
    };

    this.store.updateHorseContacts(command)
      .then(() => showSuccessMessage('Die Kontaktdaten wurden angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
