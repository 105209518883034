import Vue from 'vue';
import { formatDate, formatDateTime, formatMinWeekday, formatTime, formatTimeFrame, formatHours, formatKilogram } from '@/helpers';
import { formatCurrency, formatNumber } from '@/helpers/stateful-format';

Vue.filter('formatDate', formatDate);
Vue.filter('formatTime', formatTime);
Vue.filter('formatTimeFrame', formatTimeFrame);
Vue.filter('formatDateTime', formatDateTime);
Vue.filter('formatMinWeekday', formatMinWeekday);
Vue.filter('formatHours', formatHours);
Vue.filter('formatCurrency', formatCurrency);
Vue.filter('formatKilogram', formatKilogram);
Vue.filter('formatNumber', formatNumber);
