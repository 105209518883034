import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { AppVersionInformation, GetAppVersionInformationQuery } from '@/application/app/types';
import { getAppVersionInformation } from '@/application/app/service';

interface AppState {
  title: string | null;
  appVersionInformation: AppVersionInformation | null;
  isOutdated: boolean;

  getAppVersionInformationStatus: ActionStatus;
}

function initialState(): AppState {
  return {
    title: null,
    appVersionInformation: null,
    isOutdated: false,
    getAppVersionInformationStatus: ActionStatus.None,
  };
}

export const useAppStore = defineStore('app', {
  state: (): AppState => initialState(),
  getters: {
    isGetAppVersionInformationStatusProcessing: (state: AppState): boolean =>
      state.getAppVersionInformationStatus === ActionStatus.InProgress,

    titleWithFallback: (state: AppState): string =>
      state.title || '...',
  },
  actions: {

    // -- State management

    async validateAppVersion(serverVersion: string): Promise<void> {
      if (process.env.SOURCE_VERSION !== serverVersion) {
        this.isOutdated = true;
      }
    },

    async updateTitle(title: string | null): Promise<void> {
      this.title = title;
    },

    // -- Queries

    getAppVersionInformation(query: GetAppVersionInformationQuery): Promise<void> {
      const { getAppVersionInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAppVersionInformationStatus,
        () => getAppVersionInformation(query)
          .then(async (appVersionInformation) => {
            this.appVersionInformation = appVersionInformation;
          })
      );
    },

  },
});
