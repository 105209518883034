
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { FarmServiceId } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

export interface FarmService {
  farmServiceId: FarmServiceId;
  name: string;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'farmServiceId')
      && Object.hasOwn(item, 'name'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class FarmServiceFormControl extends Vue implements FormControlComponent<FarmServiceId> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<FarmServiceId>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: FarmService[];

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: FarmServiceId | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get selectItems(): VuetifySelectItem<FarmServiceId>[] {
    return this.items.map((item) => ({
      text: item.name,
      value: item.farmServiceId,
    }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<FarmServiceId> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
