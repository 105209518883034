import { render, staticRenderFns } from "./assign-horses-to-box-plan.vue?vue&type=template&id=de949a40&scoped=true&"
import script from "./assign-horses-to-box-plan.vue?vue&type=script&setup=true&lang=ts&"
export * from "./assign-horses-to-box-plan.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./assign-horses-to-box-plan.vue?vue&type=style&index=0&id=de949a40&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de949a40",
  null
  
)

export default component.exports