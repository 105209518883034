import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { ConfirmUserRegistrationCommand, GetUserRegistrationRequestQuery, StartUserRegistrationCommand, UserRegistrationRequest } from './types';
import { confirmUserRegistration, getUserRegistrationRequest, startUserRegistration } from './service';

interface UserRegistrationState {
  userRegistrationRequest: UserRegistrationRequest | null;

  getUserRegistrationRequestStatus: ActionStatus;
  startUserRegistrationStatus: ActionStatus;
  confirmUserRegistrationStatus: ActionStatus;
}

function initialState(): UserRegistrationState {
  return {
    userRegistrationRequest: null,

    getUserRegistrationRequestStatus: ActionStatus.None,
    startUserRegistrationStatus: ActionStatus.None,
    confirmUserRegistrationStatus: ActionStatus.None,
  };
}

export const useUserRegistrationStore = defineStore('userRegistration', {
  state: (): UserRegistrationState => initialState(),
  getters: {
    isGetUserRegistrationRequestProcessing: (state: UserRegistrationState): boolean =>
      state.getUserRegistrationRequestStatus === ActionStatus.InProgress,
    isStartUserRegistrationProcessing: (state: UserRegistrationState): boolean =>
      state.startUserRegistrationStatus === ActionStatus.InProgress,
    isConfirmUserRegistrationProcessing: (state: UserRegistrationState): boolean =>
      state.confirmUserRegistrationStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getUserRegistrationRequest(query: GetUserRegistrationRequestQuery): Promise<void> {
      const { getUserRegistrationRequestStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserRegistrationRequestStatus,
        () => getUserRegistrationRequest(query)
          .then(async (userRegistrationRequest) => {
            this.userRegistrationRequest = userRegistrationRequest;
          })
      );
    },

    // -- Commands

    startUserRegistration(command: StartUserRegistrationCommand): Promise<void> {
      const { startUserRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        startUserRegistrationStatus,
        () => startUserRegistration(command)
      );
    },

    confirmUserRegistration(command: ConfirmUserRegistrationCommand): Promise<void> {
      const { confirmUserRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        confirmUserRegistrationStatus,
        () => confirmUserRegistration(command)
      );
    },
  },
});
