export interface Command {}
export interface Query {}

export interface CommandWithFiles {
  body: object;
  files: Partial<Record<string, Blob | null>>;
}

export type Action<T extends Command | Query | void, R = void> = (payload: T) => Promise<R>;

export enum ActionStatus {
  None,
  InProgress,
  Successful,
  Failed,
}

export interface FileResponse {
  data: File;
  contentType: string;
}

// Structure expected by VSelect when not providing callbacks
export interface VuetifySelectItem<T> {
  text: string;
  value: T;
}
