import { UserProperty, UserPropertyForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { CreateFarmAsAdminWorkflow, UpdateFarmAsAdminCommand, UpdateFarmLogoAsAdminCommand, DeleteFarmAsAdminCommand, GetFarmsAsAdminQuery, FarmResponse } from './types';

// -- Queries

export function getFarms(query: GetFarmsAsAdminQuery | UserProperty): Promise<FarmResponse> {
  const url = `${apiUrl}/api/farm-management/get-farms-as-admin-query`;
  return performApiRequest<FarmResponse>({ url, method: 'POST', data: query });
}

// -- Commands

export function createFarm(command: CreateFarmAsAdminWorkflow & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/farm-management/create-farm-as-admin-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateFarm(command: UpdateFarmAsAdminCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/farm-management/update-farm-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteFarm(command: DeleteFarmAsAdminCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/farm-management/delete-farm-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateFarmLogo(command: UpdateFarmLogoAsAdminCommand & UserPropertyForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/farm-management/update-farm-logo-as-admin-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}
