
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { uuid } from '@/helpers';
import { DialogWidth } from '@/helpers/data';
import { navigate } from '@/helpers/navigation-helpers';
import { useProfileStore } from '@/private/rider/profile/store';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { PhoneNumber } from '@/types';
import { CreateHorseWorkflow } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  name: FormControl<string>;
  nickName: FormControl<string>;
  image: FormControl<Blob>;
  emergencyContactName: FormControl<string>;
  emergencyContactPhoneNumber: FormControl<PhoneNumber>;
  isEmergencyContactVisibleOnBoxSign: FormControl<boolean>;
  veterinarianName: FormControl<string>;
  veterinarianPhone: FormControl<PhoneNumber>;
  isVeterinarianVisibleOnBoxSign: FormControl<boolean>;
  farrierName: FormControl<string>;
  farrierPhone: FormControl<PhoneNumber>;
  isFarrierVisibleOnBoxSign: FormControl<boolean>;
}

@Component
export default class CreateHorseDialog extends Vue {

  readonly store = useMyStableStore();
  readonly profileStore = useProfileStore();

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  activePanel = [0, 1, 2];

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.profileStore.getProfile()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        nickName: {
          label: 'Spitzname',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        image: {
          label: 'Bild des Pferds',
          value: null,
        },
        emergencyContactName: {
          label: 'Name des Notfallkontakts',
          value: this.profileStore.profile!.emergencyContactName,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'emergencyContactPhoneNumber',
          ],
        },
        emergencyContactPhoneNumber: {
          label: 'Telefonnummer des Notfallkontakts',
          value: this.profileStore.profile!.emergencyContactPhoneNumber,
          rules: [],
          validateFormControlsOnInput: [
            'emergencyContactName',
          ],
        },
        isEmergencyContactVisibleOnBoxSign: {
          label: 'Notfallkontakt auf Boxenschild anzeigen',
          value: this.profileStore.profile!.isEmergencyContactVisibleOnBoxSign,
          validateFormControlsOnInput: [
            'emergencyContactName',
            'emergencyContactPhoneNumber',
          ],
        },
        veterinarianName: {
          label: 'Name des Tierarztes',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'veterinarianPhone',
          ],
        },
        veterinarianPhone: {
          label: 'Telefonnummer des Tierarztes',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'veterinarianName',
          ],
        },
        isVeterinarianVisibleOnBoxSign: {
          label: 'Tierarzt auf Boxenschild anzeigen',
          value: false,
          validateFormControlsOnInput: [
            'veterinarianName',
            'veterinarianPhone',
          ],
        },
        farrierName: {
          label: 'Name des Hufschmieds',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
          validateFormControlsOnInput: [
            'farrierPhone',
          ],
        },
        farrierPhone: {
          label: 'Telefonnummer des Hufschmieds',
          value: null,
          rules: [],
          validateFormControlsOnInput: [
            'farrierName',
          ],
        },
        isFarrierVisibleOnBoxSign: {
          label: 'Hufschmied auf Boxenschild anzeigen',
          value: false,
          validateFormControlsOnInput: [
            'farrierName',
            'farrierPhone',
          ],
        },
      },
    });

    form.controls.emergencyContactName.rules!.push(() => {
      return !form.controls.isEmergencyContactVisibleOnBoxSign.value
      || form.controls.emergencyContactName.value !== null
      || form.controls.emergencyContactPhoneNumber.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Notfallkontakt auf dem Boxenschild angezeigt wird'
    });
    form.controls.emergencyContactPhoneNumber.rules!.push(() => {
      return !form.controls.isEmergencyContactVisibleOnBoxSign.value
      || form.controls.emergencyContactName.value !== null
      || form.controls.emergencyContactPhoneNumber.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Notfallkontakt auf dem Boxenschild angezeigt wird'
    });

    form.controls.veterinarianName.rules!.push(() => {
      return !form.controls.isVeterinarianVisibleOnBoxSign.value
        || form.controls.veterinarianName.value !== null
        || form.controls.veterinarianPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Tierarzt auf dem Boxenschild angezeigt wird'
    });
    form.controls.veterinarianPhone.rules!.push(() => {
      return !form.controls.isVeterinarianVisibleOnBoxSign.value
        || form.controls.veterinarianName.value !== null
        || form.controls.veterinarianPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Tierarzt auf dem Boxenschild angezeigt wird'
    });

    form.controls.farrierName.rules!.push(() => {
      return !form.controls.isFarrierVisibleOnBoxSign.value
      || form.controls.farrierName.value !== null
      || form.controls.farrierPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Hufschmied auf dem Boxenschild angezeigt wird'
    });
    form.controls.farrierPhone.rules!.push(() => {
      return !form.controls.isFarrierVisibleOnBoxSign.value
      || form.controls.farrierName.value !== null
      || form.controls.farrierPhone.value !== null
        ? true
        : 'Name oder Telefonnummer muss ausgefüllt werden, wenn der Hufschmied auf dem Boxenschild angezeigt wird'
    });

    return form;
  }

  show(): void {
    this.isDialogVisible = true;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const horseId = uuid();

    const command: CreateHorseWorkflow = {
      body: {
        horseId,
        name: formValues.name!,
        nickName: formValues.nickName,
        emergencyContactName: formValues.emergencyContactName,
        emergencyContactPhoneNumber: formValues.emergencyContactPhoneNumber,
        isEmergencyContactVisibleOnBoxSign: formValues.isEmergencyContactVisibleOnBoxSign!,
        veterinarianName: formValues.veterinarianName,
        veterinarianPhone: formValues.veterinarianPhone,
        isVeterinarianVisibleOnBoxSign: formValues.isVeterinarianVisibleOnBoxSign!,
        farrierName: formValues.farrierName,
        farrierPhone: formValues.farrierPhone,
        isFarrierVisibleOnBoxSign: formValues.isFarrierVisibleOnBoxSign!,
      },
      files: {
        image: formValues.image,
      },
    };

    this.store.createHorse(command)
      .then(() => showSuccessMessage('Das Pferd wurde erstellt.'))
      .then(() => navigate({ name: 'horse-details', params: { id: horseId } }))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
