import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { createTask, getTasksForUser, withdrawTask } from './service';
import { CreateTaskCommand, GetTasksForUserQuery, Task, WithdrawTaskCommand } from './types';

interface TasksState {
  currentLimit: number;
  totalCount: number | null;
  tasks: Task[];

  getTasksForUserStatus: ActionStatus;
  createTaskStatus: ActionStatus;
  withdrawTaskStatus: ActionStatus;
}

function initialState(): TasksState {
  return {
    currentLimit: 20,
    totalCount: null,
    tasks: [],

    getTasksForUserStatus: ActionStatus.None,
    createTaskStatus: ActionStatus.None,
    withdrawTaskStatus: ActionStatus.None,
  };
}

export const useRiderTasksStore = defineStore('tasks', {
  state: (): TasksState => initialState(),
  getters: {
    isGetTasksForUserProcessing: (state: TasksState): boolean =>
      state.getTasksForUserStatus === ActionStatus.InProgress,
    isCreateTaskProcessing: (state: TasksState): boolean =>
      state.createTaskStatus === ActionStatus.InProgress,
    isWithdrawTaskProcessing: (state: TasksState): boolean =>
      state.withdrawTaskStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    increaseLimit(): Promise<void> {
      this.currentLimit += 20;

      return this.getTasksForUser();
    },

    // -- Queries

    getTasksForUser(): Promise<void> {
      const { getTasksForUserStatus } = storeToRefs(this);
      const query: GetTasksForUserQuery = {
        limit: this.currentLimit,
      };
      return wrapActionWithProgress(
        getTasksForUserStatus,
        () => getTasksForUser(attachFarmAndUserProperties(query))
          .then(async (taskResponse) => {
            this.totalCount = taskResponse.totalCount;
            this.tasks = taskResponse.tasks;
          })
      );
    },

    // -- Commands

    createTask(command: CreateTaskCommand): Promise<void> {
      const { createTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createTaskStatus,
        () => createTask(attachFarmAndUserProperties(command))
          .then(() => this.getTasksForUser())
      );
    },

    withdrawTask(command: WithdrawTaskCommand): Promise<void> {
      const { withdrawTaskStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        withdrawTaskStatus,
        () => withdrawTask(attachFarmAndUserProperties(command))
          .then(() => this.getTasksForUser())
      );
    },

  },
});
