import { render, staticRenderFns } from "./moment-date-picker-form-control.vue?vue&type=template&id=50b07174&scoped=true&"
import script from "./moment-date-picker-form-control.vue?vue&type=script&lang=ts&"
export * from "./moment-date-picker-form-control.vue?vue&type=script&lang=ts&"
import style0 from "./moment-date-picker-form-control.vue?vue&type=style&index=0&id=50b07174&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b07174",
  null
  
)

export default component.exports