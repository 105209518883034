import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import DataPolicy from './components/data-policy.vue';
import Imprint from './components/imprint.vue';

export const LEGAL_ROUTE_PREFIX = '/rechtliches';

export const legalRoutes: RouteConfig[] = [
  {
    path: LEGAL_ROUTE_PREFIX,
    component: {
      template: `<div class="legal"><router-view /></div>`,
    },
    children: [
      {
        path: '',
        redirect: 'impressum',
      },
      {
        name: 'imprint',
        path: 'impressum',
        component: Imprint,
        meta: {
          title: 'Impressum',
          accessibleFor: RouteAccessibility.ALWAYS,
        },
      },
      {
        name: 'data-policy',
        path: 'datenschutz',
        component: DataPolicy,
        meta: {
          title: 'Datenschutzerklärung',
          accessibleFor: RouteAccessibility.ALWAYS,
        },
      },
    ],
  },
];
