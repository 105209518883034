import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { GetPersonsWithPaymentReceiptsAsManagerQuery, MarkBankTransferForMonthAsNotPayedAsManagerCommand, MarkBankTransferForMonthAsPayedAsManagerCommand, MarkDirectDebitForMonthAsNotPayedAsManagerCommand, MarkDirectDebitForMonthAsPayedAsManagerCommand, UpdatePaymentReceiptNoteForMonthAsManagerCommand, PersonsWithPaymentReceipts } from './types';

// -- Queries

export function getPersonsWithPaymentReceipts(
  query: GetPersonsWithPaymentReceiptsAsManagerQuery & FarmAndUserProperties
): Promise<PersonsWithPaymentReceipts> {
  const url = `${apiUrl}/api/ledger/get-persons-with-payment-receipts-as-manager-query`;
  return performApiRequest<PersonsWithPaymentReceipts>({ url, method: 'POST', data: query });
}

// -- Commands

export function markBankTransferForMonthAsPayed(
  command: MarkBankTransferForMonthAsPayedAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/mark-bank-transfer-for-month-as-payed-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markDirectDebitForMonthAsPayed(
  command: MarkDirectDebitForMonthAsPayedAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/mark-direct-debit-for-month-as-payed-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markBankTransferForMonthAsNotPayed(
  command: MarkBankTransferForMonthAsNotPayedAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/mark-bank-transfer-for-month-as-not-payed-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markDirectDebitForMonthAsNotPayed(
  command: MarkDirectDebitForMonthAsNotPayedAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/mark-direct-debit-for-month-as-not-payed-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePaymentReceiptNoteForMonth(
  command: UpdatePaymentReceiptNoteForMonthAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/ledger/update-payment-receipt-note-for-month-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
