
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { RidingLessonRegistrationId, RidingLessonRegistrationType } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { RidingLessonRegistration } from '@/private/rider/booking-calendars/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class RidingLessonRegistrationRadioFormControl extends Vue implements FormControlComponent<RidingLessonRegistrationId> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<RidingLessonRegistrationId>;

  @Prop({ type: Array, required: true })
  readonly registrations!: RidingLessonRegistration[];

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: RidingLessonRegistrationId | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  name(registration: RidingLessonRegistration): string {
    return registration.type === RidingLessonRegistrationType.USER
      ? registration.user.name
      : registration.nameOfAnotherPerson!;
  }

  radioChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<RidingLessonRegistrationId> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
