import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress } from '@/store';
import { HorseId } from '@/types';
import { createVaccination, getDataForVaccinationCreation, getMedicalRecords, getHorsesWithMissingVaccinations, getHorsesWithVaccinationsThatExpireSoon, getVaccinationStatuses, createHorseWeightEntry, createHorseDrugEntry, createHorseFarrierAppointment, createHorseWormingTreatment, createHorseWormingTreatmentForMultipleHorses, getHorses } from './service';
import { VaccinationStatuses, MissingVaccinations, VaccinationsThatExpireSoon, CreateVaccinationAsManagerCommand, DataForVaccinationCreation, MedicalRecords, CreateHorseWeightEntryAsManagerCommand, CreateHorseDrugEntryAsManagerCommand, CreateHorseFarrierAppointmentAsManagerCommand, CreateHorseWormingTreatmentAsManagerCommand, CreateHorseWormingTreatmentForMultipleHorsesAsManagerCommand, Horse, GetMedicalRecordsAsManagerQuery } from './types';

interface HealthRecordsManagementState {
  horses: Horse[];
  medicalRecords: MedicalRecords | null;
  vaccinationStatuses: VaccinationStatuses | null;
  missingVaccinations: MissingVaccinations | null;
  vaccinationsThatExpireSoon: VaccinationsThatExpireSoon | null;
  dataForVaccinationCreation: DataForVaccinationCreation | null;

  idOfHorseToFilter: HorseId | null;

  getVaccinationStatusesStatus: ActionStatus;
  getHorsesWithMissingVaccinationsStatus: ActionStatus;
  getHorsesWithVaccinationsThatExpireSoonStatus: ActionStatus;
  getDataForVaccinationCreationStatus: ActionStatus;
  createVaccinationStatus: ActionStatus;
  getMedicalRecordsStatus: ActionStatus;
  createHorseWeightEntryStatus: ActionStatus;
  createHorseDrugEntryStatus: ActionStatus;
  createHorseFarrierAppointmentStatus: ActionStatus;
  createHorseWormingTreatmentStatus: ActionStatus;
  createHorseWormingTreatmentForMultipleHorsesStatus: ActionStatus;
  getHorsesStatus: ActionStatus;
}

function initialState(): HealthRecordsManagementState {
  return {
    horses: [],
    medicalRecords: null,
    vaccinationStatuses: null,
    missingVaccinations: null,
    vaccinationsThatExpireSoon: null,
    dataForVaccinationCreation: null,

    idOfHorseToFilter: null,

    getVaccinationStatusesStatus: ActionStatus.None,
    getHorsesWithMissingVaccinationsStatus: ActionStatus.None,
    getHorsesWithVaccinationsThatExpireSoonStatus: ActionStatus.None,
    getDataForVaccinationCreationStatus: ActionStatus.None,
    createVaccinationStatus: ActionStatus.None,
    getMedicalRecordsStatus: ActionStatus.None,
    createHorseWeightEntryStatus: ActionStatus.None,
    createHorseDrugEntryStatus: ActionStatus.None,
    createHorseFarrierAppointmentStatus: ActionStatus.None,
    createHorseWormingTreatmentStatus: ActionStatus.None,
    createHorseWormingTreatmentForMultipleHorsesStatus: ActionStatus.None,
    getHorsesStatus: ActionStatus.None,
  };
}

export const useMedicalRecordsManagementStore = defineStore('manageMedicalRecords', {
  state: (): HealthRecordsManagementState => initialState(),
  getters: {
    isGetVaccinationStatusesProcessing: (state: HealthRecordsManagementState): boolean =>
      state.getVaccinationStatusesStatus === ActionStatus.InProgress,
    isGetHorsesWithMissingVaccinationsProcessing: (state: HealthRecordsManagementState): boolean =>
      state.getHorsesWithMissingVaccinationsStatus === ActionStatus.InProgress,
    isGetHorsesWithVaccinationsThatExpireSoonProcessing: (state: HealthRecordsManagementState): boolean =>
      state.getHorsesWithVaccinationsThatExpireSoonStatus === ActionStatus.InProgress,
    isGetDataForVaccinationCreationProcessing: (state: HealthRecordsManagementState): boolean =>
      state.getDataForVaccinationCreationStatus === ActionStatus.InProgress,
    isCreateVaccinationProcessing: (state: HealthRecordsManagementState): boolean =>
      state.createVaccinationStatus === ActionStatus.InProgress,
    isGetMedicalRecordsProcessing: (state: HealthRecordsManagementState): boolean =>
      state.getMedicalRecordsStatus === ActionStatus.InProgress,
    isCreateHorseWeightEntryProcessing: (state: HealthRecordsManagementState): boolean =>
      state.createHorseWeightEntryStatus === ActionStatus.InProgress,
    isCreateHorseDrugEntryProcessing: (state: HealthRecordsManagementState): boolean =>
      state.createHorseDrugEntryStatus === ActionStatus.InProgress,
    isCreateHorseFarrierAppointmentProcessing: (state: HealthRecordsManagementState): boolean =>
      state.createHorseFarrierAppointmentStatus === ActionStatus.InProgress,
    isCreateHorseWormingTreatmentProcessing: (state: HealthRecordsManagementState): boolean =>
      state.createHorseWormingTreatmentStatus === ActionStatus.InProgress,
    isCreateHorseWormingTreatmentForMultipleHorsesProcessing: (state: HealthRecordsManagementState): boolean =>
      state.createHorseWormingTreatmentForMultipleHorsesStatus === ActionStatus.InProgress,
    isGetHorsesProcessing: (state: HealthRecordsManagementState): boolean =>
      state.getHorsesStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateIdOfHorseToFilter(idOfHorseToFilter: HorseId | null): Promise<void> {
      this.idOfHorseToFilter = idOfHorseToFilter;

      return this.getMedicalRecords();
    },

    // -- Queries

    getVaccinationStatuses(): Promise<void> {
      const { getVaccinationStatusesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getVaccinationStatusesStatus,
        () => getVaccinationStatuses(attachFarmAndUserProperties({}))
          .then((vaccinationStatuses) => {
            this.vaccinationStatuses = vaccinationStatuses;
          })
      );
    },

    getHorsesWithMissingVaccinations(): Promise<void> {
      const { getHorsesWithMissingVaccinationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorsesWithMissingVaccinationsStatus,
        () => getHorsesWithMissingVaccinations(attachFarmAndUserProperties({}))
          .then((missingVaccinations) => {
            this.missingVaccinations = missingVaccinations;
          })
      );
    },

    getHorsesWithVaccinationsThatExpireSoon(): Promise<void> {
      const { getHorsesWithVaccinationsThatExpireSoonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorsesWithVaccinationsThatExpireSoonStatus,
        () => getHorsesWithVaccinationsThatExpireSoon(attachFarmAndUserProperties({}))
          .then((vaccinationsThatExpireSoon) => {
            this.vaccinationsThatExpireSoon = vaccinationsThatExpireSoon;
          })
      );
    },

    getDataForVaccinationCreation(): Promise<void> {
      const { getDataForVaccinationCreationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDataForVaccinationCreationStatus,
        () => getDataForVaccinationCreation(attachFarmAndUserProperties({}))
          .then((dataForVaccinationCreation) => {
            this.dataForVaccinationCreation = dataForVaccinationCreation;
          })
      );
    },

    getMedicalRecords(): Promise<void> {
      const query: GetMedicalRecordsAsManagerQuery = {
        horseId: this.idOfHorseToFilter,
      };
      const { getMedicalRecordsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getMedicalRecordsStatus,
        () => getMedicalRecords(attachFarmAndUserProperties(query))
          .then((medicalRecords) => {
            this.medicalRecords = medicalRecords;
          })
      );
    },

    getHorses(): Promise<void> {
      const { getHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorsesStatus,
        () => getHorses(attachFarmAndUserProperties({}))
          .then((horses) => {
            this.horses = horses;
          })
      );
    },

    // -- Commands

    createVaccination(command: CreateVaccinationAsManagerCommand): Promise<void> {
      const { createVaccinationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createVaccinationStatus,
        () => createVaccination(attachFarmAndUserProperties(command))
          .then(() => this.getVaccinationStatuses())
      );
    },

    createHorseWeightEntry(command: CreateHorseWeightEntryAsManagerCommand): Promise<void> {
      const { createHorseWeightEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWeightEntryStatus,
        () => createHorseWeightEntry(attachFarmAndUserProperties(command))
          .then(() => this.getMedicalRecords())
      );
    },

    createHorseDrugEntry(command: CreateHorseDrugEntryAsManagerCommand): Promise<void> {
      const { createHorseDrugEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseDrugEntryStatus,
        () => createHorseDrugEntry(attachFarmAndUserProperties(command))
          .then(() => this.getMedicalRecords())
      );
    },

    createHorseFarrierAppointment(command: CreateHorseFarrierAppointmentAsManagerCommand): Promise<void> {
      const { createHorseFarrierAppointmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseFarrierAppointmentStatus,
        () => createHorseFarrierAppointment(attachFarmAndUserProperties(command))
          .then(() => this.getMedicalRecords())
      );
    },

    createHorseWormingTreatment(command: CreateHorseWormingTreatmentAsManagerCommand): Promise<void> {
      const { createHorseWormingTreatmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWormingTreatmentStatus,
        () => createHorseWormingTreatment(attachFarmAndUserProperties(command))
          .then(() => this.getMedicalRecords())
      );
    },

    createHorseWormingTreatmentForMultipleHorses(command: CreateHorseWormingTreatmentForMultipleHorsesAsManagerCommand): Promise<void> {
      const { createHorseWormingTreatmentForMultipleHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseWormingTreatmentForMultipleHorsesStatus,
        () => createHorseWormingTreatmentForMultipleHorses(attachFarmAndUserProperties(command))
          .then(() => this.getMedicalRecords())
      );
    },

  },
});
