import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import AnalyticsChartsOverview from './components/analytics-charts-overview.vue';

export const analyticsRoutes: RouteConfig[] = [
  {
    name: 'analytics',
    path: 'analytics',
    component: AnalyticsChartsOverview,
    meta: {
      title: 'Analytics',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresPermission: FarmManagerPermission.ANALYTICS_VIEW,
    },
  },
];
