export const switzerlandCantonMap: Record<number, string> = {
  1000: 'Waadt',
  1003: 'Waadt',
  1004: 'Waadt',
  1005: 'Waadt',
  1006: 'Waadt',
  1007: 'Waadt',
  1008: 'Waadt',
  1009: 'Waadt',
  1010: 'Waadt',
  1011: 'Waadt',
  1012: 'Waadt',
  1015: 'Waadt',
  1018: 'Waadt',
  1020: 'Waadt',
  1022: 'Waadt',
  1023: 'Waadt',
  1024: 'Waadt',
  1025: 'Waadt',
  1026: 'Waadt',
  1027: 'Waadt',
  1028: 'Waadt',
  1029: 'Waadt',
  1030: 'Waadt',
  1031: 'Waadt',
  1032: 'Waadt',
  1033: 'Waadt',
  1034: 'Waadt',
  1035: 'Waadt',
  1036: 'Waadt',
  1037: 'Waadt',
  1038: 'Waadt',
  1040: 'Waadt',
  1041: 'Waadt',
  1042: 'Waadt',
  1043: 'Waadt',
  1044: 'Waadt',
  1045: 'Waadt',
  1046: 'Waadt',
  1047: 'Waadt',
  1052: 'Waadt',
  1053: 'Waadt',
  1054: 'Waadt',
  1055: 'Waadt',
  1058: 'Waadt',
  1059: 'Waadt',
  1061: 'Waadt',
  1062: 'Waadt',
  1063: 'Waadt',
  1066: 'Waadt',
  1068: 'Waadt',
  1070: 'Waadt',
  1071: 'Waadt',
  1072: 'Waadt',
  1073: 'Waadt',
  1076: 'Waadt',
  1077: 'Waadt',
  1078: 'Waadt',
  1080: 'Waadt',
  1081: 'Waadt',
  1082: 'Waadt',
  1083: 'Waadt',
  1084: 'Waadt',
  1085: 'Waadt',
  1088: 'Waadt',
  1090: 'Waadt',
  1091: 'Waadt',
  1092: 'Waadt',
  1093: 'Waadt',
  1094: 'Waadt',
  1095: 'Waadt',
  1096: 'Waadt',
  1097: 'Waadt',
  1098: 'Waadt',
  1110: 'Waadt',
  1112: 'Waadt',
  1113: 'Waadt',
  1114: 'Waadt',
  1115: 'Waadt',
  1116: 'Waadt',
  1117: 'Waadt',
  1121: 'Waadt',
  1122: 'Waadt',
  1123: 'Waadt',
  1124: 'Waadt',
  1125: 'Waadt',
  1126: 'Waadt',
  1127: 'Waadt',
  1128: 'Waadt',
  1131: 'Waadt',
  1132: 'Waadt',
  1134: 'Waadt',
  1135: 'Waadt',
  1136: 'Waadt',
  1141: 'Waadt',
  1142: 'Waadt',
  1143: 'Waadt',
  1144: 'Waadt',
  1145: 'Waadt',
  1146: 'Waadt',
  1147: 'Waadt',
  1148: 'Waadt',
  1149: 'Waadt',
  1162: 'Waadt',
  1163: 'Waadt',
  1164: 'Waadt',
  1165: 'Waadt',
  1166: 'Waadt',
  1167: 'Waadt',
  1168: 'Waadt',
  1169: 'Waadt',
  1170: 'Waadt',
  1172: 'Waadt',
  1173: 'Waadt',
  1174: 'Waadt',
  1175: 'Waadt',
  1176: 'Waadt',
  1180: 'Waadt',
  1182: 'Waadt',
  1183: 'Waadt',
  1184: 'Waadt',
  1185: 'Waadt',
  1186: 'Waadt',
  1187: 'Waadt',
  1188: 'Waadt',
  1189: 'Waadt',
  1195: 'Waadt',
  1196: 'Waadt',
  1197: 'Waadt',
  1201: 'Genf',
  1202: 'Genf',
  1203: 'Genf',
  1204: 'Genf',
  1205: 'Genf',
  1206: 'Genf',
  1207: 'Genf',
  1208: 'Genf',
  1209: 'Genf',
  1212: 'Genf',
  1213: 'Genf',
  1214: 'Genf',
  1215: 'Genf',
  1216: 'Genf',
  1217: 'Genf',
  1218: 'Genf',
  1219: 'Genf',
  1220: 'Genf',
  1222: 'Genf',
  1223: 'Genf',
  1224: 'Genf',
  1225: 'Genf',
  1226: 'Genf',
  1227: 'Genf',
  1228: 'Genf',
  1231: 'Genf',
  1232: 'Genf',
  1233: 'Genf',
  1234: 'Genf',
  1236: 'Genf',
  1237: 'Genf',
  1239: 'Genf',
  1241: 'Genf',
  1242: 'Genf',
  1243: 'Genf',
  1244: 'Genf',
  1245: 'Genf',
  1246: 'Genf',
  1247: 'Genf',
  1248: 'Genf',
  1251: 'Genf',
  1252: 'Genf',
  1253: 'Genf',
  1254: 'Genf',
  1255: 'Genf',
  1256: 'Genf',
  1257: 'Genf',
  1258: 'Genf',
  1260: 'Waadt',
  1261: 'Waadt',
  1262: 'Waadt',
  1263: 'Waadt',
  1264: 'Waadt',
  1265: 'Waadt',
  1266: 'Waadt',
  1267: 'Waadt',
  1268: 'Waadt',
  1269: 'Waadt',
  1270: 'Waadt',
  1271: 'Waadt',
  1272: 'Waadt',
  1273: 'Waadt',
  1274: 'Waadt',
  1275: 'Waadt',
  1276: 'Waadt',
  1277: 'Waadt',
  1278: 'Waadt',
  1279: 'Waadt',
  1281: 'Genf',
  1283: 'Genf',
  1284: 'Genf',
  1285: 'Genf',
  1286: 'Genf',
  1287: 'Genf',
  1288: 'Genf',
  1290: 'Waadt',
  1291: 'Waadt',
  1292: 'Genf',
  1293: 'Genf',
  1294: 'Genf',
  1295: 'Waadt',
  1296: 'Waadt',
  1297: 'Waadt',
  1298: 'Genf',
  1299: 'Waadt',
  1302: 'Waadt',
  1303: 'Waadt',
  1304: 'Waadt',
  1305: 'Waadt',
  1306: 'Waadt',
  1307: 'Waadt',
  1308: 'Waadt',
  1312: 'Waadt',
  1313: 'Waadt',
  1315: 'Waadt',
  1316: 'Waadt',
  1317: 'Waadt',
  1318: 'Waadt',
  1321: 'Waadt',
  1322: 'Waadt',
  1323: 'Waadt',
  1324: 'Waadt',
  1325: 'Waadt',
  1326: 'Waadt',
  1329: 'Waadt',
  1337: 'Waadt',
  1338: 'Waadt',
  1341: 'Waadt',
  1342: 'Waadt',
  1343: 'Waadt',
  1344: 'Waadt',
  1345: 'Waadt',
  1346: 'Waadt',
  1347: 'Waadt',
  1348: 'Waadt',
  1350: 'Waadt',
  1352: 'Waadt',
  1353: 'Waadt',
  1354: 'Waadt',
  1355: 'Waadt',
  1356: 'Waadt',
  1357: 'Waadt',
  1358: 'Waadt',
  1372: 'Waadt',
  1373: 'Waadt',
  1374: 'Waadt',
  1375: 'Waadt',
  1376: 'Waadt',
  1377: 'Waadt',
  1400: 'Waadt',
  1404: 'Waadt',
  1405: 'Waadt',
  1406: 'Waadt',
  1407: 'Waadt',
  1408: 'Waadt',
  1409: 'Waadt',
  1410: 'Waadt',
  1412: 'Waadt',
  1413: 'Waadt',
  1415: 'Waadt',
  1416: 'Waadt',
  1417: 'Waadt',
  1418: 'Waadt',
  1420: 'Waadt',
  1421: 'Waadt',
  1422: 'Waadt',
  1423: 'Waadt',
  1424: 'Waadt',
  1425: 'Waadt',
  1426: 'Waadt',
  1427: 'Waadt',
  1428: 'Waadt',
  1429: 'Waadt',
  1430: 'Waadt',
  1431: 'Waadt',
  1432: 'Waadt',
  1433: 'Waadt',
  1434: 'Waadt',
  1435: 'Waadt',
  1436: 'Waadt',
  1437: 'Waadt',
  1438: 'Waadt',
  1439: 'Waadt',
  1441: 'Waadt',
  1442: 'Waadt',
  1443: 'Waadt',
  1445: 'Waadt',
  1446: 'Waadt',
  1450: 'Waadt',
  1452: 'Waadt',
  1453: 'Waadt',
  1454: 'Waadt',
  1462: 'Waadt',
  1463: 'Waadt',
  1464: 'Waadt',
  1468: 'Freiburg',
  1470: 'Freiburg',
  1473: 'Freiburg',
  1474: 'Freiburg',
  1475: 'Freiburg',
  1482: 'Freiburg',
  1483: 'Freiburg',
  1484: 'Freiburg',
  1485: 'Freiburg',
  1486: 'Freiburg',
  1489: 'Freiburg',
  1509: 'Waadt',
  1510: 'Waadt',
  1512: 'Waadt',
  1513: 'Waadt',
  1514: 'Waadt',
  1515: 'Waadt',
  1521: 'Waadt',
  1522: 'Waadt',
  1523: 'Waadt',
  1524: 'Waadt',
  1525: 'Waadt',
  1526: 'Waadt',
  1527: 'Freiburg',
  1528: 'Freiburg',
  1529: 'Freiburg',
  1530: 'Waadt',
  1532: 'Freiburg',
  1533: 'Freiburg',
  1534: 'Freiburg',
  1535: 'Waadt',
  1536: 'Waadt',
  1537: 'Waadt',
  1538: 'Waadt',
  1541: 'Freiburg',
  1542: 'Freiburg',
  1543: 'Waadt',
  1544: 'Freiburg',
  1545: 'Waadt',
  1551: 'Waadt',
  1552: 'Waadt',
  1553: 'Freiburg',
  1554: 'Waadt',
  1555: 'Waadt',
  1562: 'Waadt',
  1563: 'Freiburg',
  1564: 'Freiburg',
  1565: 'Waadt',
  1566: 'Freiburg',
  1567: 'Freiburg',
  1568: 'Freiburg',
  1580: 'Waadt',
  1583: 'Freiburg',
  1584: 'Waadt',
  1585: 'Waadt',
  1586: 'Waadt',
  1587: 'Waadt',
  1588: 'Waadt',
  1589: 'Waadt',
  1595: 'Bern',
  1607: 'Waadt',
  1608: 'Waadt',
  1609: 'Freiburg',
  1610: 'Waadt',
  1611: 'Freiburg',
  1612: 'Waadt',
  1613: 'Waadt',
  1614: 'Freiburg',
  1615: 'Freiburg',
  1616: 'Freiburg',
  1617: 'Freiburg',
  1618: 'Freiburg',
  1619: 'Freiburg',
  1623: 'Freiburg',
  1624: 'Freiburg',
  1625: 'Freiburg',
  1626: 'Freiburg',
  1627: 'Freiburg',
  1628: 'Freiburg',
  1630: 'Freiburg',
  1632: 'Freiburg',
  1633: 'Freiburg',
  1634: 'Freiburg',
  1635: 'Freiburg',
  1636: 'Freiburg',
  1637: 'Freiburg',
  1638: 'Freiburg',
  1642: 'Freiburg',
  1643: 'Freiburg',
  1644: 'Freiburg',
  1645: 'Freiburg',
  1646: 'Freiburg',
  1647: 'Freiburg',
  1648: 'Freiburg',
  1649: 'Freiburg',
  1651: 'Freiburg',
  1652: 'Freiburg',
  1653: 'Freiburg',
  1654: 'Freiburg',
  1656: 'Freiburg',
  1657: 'Bern',
  1658: 'Waadt',
  1659: 'Waadt',
  1660: 'Waadt',
  1661: 'Freiburg',
  1663: 'Freiburg',
  1665: 'Freiburg',
  1666: 'Freiburg',
  1667: 'Freiburg',
  1669: 'Freiburg',
  1670: 'Freiburg',
  1673: 'Freiburg',
  1674: 'Freiburg',
  1675: 'Freiburg',
  1676: 'Freiburg',
  1677: 'Freiburg',
  1678: 'Freiburg',
  1679: 'Freiburg',
  1680: 'Freiburg',
  1681: 'Freiburg',
  1682: 'Waadt',
  1683: 'Waadt',
  1684: 'Freiburg',
  1685: 'Freiburg',
  1686: 'Freiburg',
  1687: 'Freiburg',
  1688: 'Freiburg',
  1689: 'Freiburg',
  1690: 'Freiburg',
  1691: 'Freiburg',
  1692: 'Freiburg',
  1694: 'Freiburg',
  1695: 'Freiburg',
  1696: 'Freiburg',
  1697: 'Freiburg',
  1699: 'Freiburg',
  1700: 'Freiburg',
  1712: 'Freiburg',
  1713: 'Freiburg',
  1714: 'Freiburg',
  1715: 'Freiburg',
  1716: 'Freiburg',
  1717: 'Freiburg',
  1718: 'Freiburg',
  1719: 'Freiburg',
  1720: 'Freiburg',
  1721: 'Freiburg',
  1722: 'Freiburg',
  1723: 'Freiburg',
  1724: 'Freiburg',
  1725: 'Freiburg',
  1726: 'Freiburg',
  1727: 'Freiburg',
  1728: 'Freiburg',
  1730: 'Freiburg',
  1731: 'Freiburg',
  1732: 'Freiburg',
  1733: 'Freiburg',
  1734: 'Freiburg',
  1735: 'Freiburg',
  1736: 'Freiburg',
  1737: 'Freiburg',
  1738: 'Bern',
  1740: 'Freiburg',
  1741: 'Freiburg',
  1742: 'Freiburg',
  1744: 'Freiburg',
  1745: 'Freiburg',
  1746: 'Freiburg',
  1747: 'Freiburg',
  1748: 'Freiburg',
  1749: 'Freiburg',
  1752: 'Freiburg',
  1753: 'Freiburg',
  1754: 'Freiburg',
  1756: 'Freiburg',
  1757: 'Freiburg',
  1762: 'Freiburg',
  1763: 'Freiburg',
  1772: 'Freiburg',
  1773: 'Freiburg',
  1774: 'Freiburg',
  1775: 'Freiburg',
  1776: 'Freiburg',
  1782: 'Freiburg',
  1783: 'Freiburg',
  1784: 'Freiburg',
  1785: 'Freiburg',
  1786: 'Freiburg',
  1787: 'Freiburg',
  1788: 'Freiburg',
  1789: 'Freiburg',
  1791: 'Freiburg',
  1792: 'Freiburg',
  1793: 'Freiburg',
  1794: 'Freiburg',
  1795: 'Freiburg',
  1796: 'Freiburg',
  1797: 'Bern',
  1800: 'Waadt',
  1801: 'Waadt',
  1802: 'Waadt',
  1803: 'Waadt',
  1804: 'Waadt',
  1805: 'Waadt',
  1806: 'Waadt',
  1807: 'Waadt',
  1808: 'Waadt',
  1809: 'Waadt',
  1814: 'Waadt',
  1815: 'Waadt',
  1816: 'Waadt',
  1817: 'Waadt',
  1820: 'Waadt',
  1822: 'Waadt',
  1823: 'Waadt',
  1824: 'Waadt',
  1832: 'Waadt',
  1833: 'Waadt',
  1844: 'Waadt',
  1845: 'Waadt',
  1846: 'Waadt',
  1847: 'Waadt',
  1852: 'Waadt',
  1853: 'Waadt',
  1854: 'Waadt',
  1856: 'Waadt',
  1860: 'Waadt',
  1862: 'Waadt',
  1863: 'Waadt',
  1864: 'Waadt',
  1865: 'Waadt',
  1866: 'Waadt',
  1867: 'Waadt',
  1868: 'Wallis',
  1869: 'Wallis',
  1870: 'Wallis',
  1871: 'Wallis',
  1872: 'Wallis',
  1873: 'Wallis',
  1874: 'Wallis',
  1875: 'Wallis',
  1880: 'Waadt',
  1882: 'Waadt',
  1884: 'Waadt',
  1885: 'Waadt',
  1890: 'Wallis',
  1891: 'Wallis',
  1892: 'Waadt',
  1893: 'Wallis',
  1895: 'Wallis',
  1896: 'Wallis',
  1897: 'Wallis',
  1898: 'Wallis',
  1899: 'Wallis',
  1902: 'Wallis',
  1903: 'Wallis',
  1904: 'Wallis',
  1905: 'Wallis',
  1906: 'Wallis',
  1907: 'Wallis',
  1908: 'Wallis',
  1911: 'Wallis',
  1912: 'Wallis',
  1913: 'Wallis',
  1914: 'Wallis',
  1918: 'Wallis',
  1920: 'Wallis',
  1921: 'Wallis',
  1922: 'Wallis',
  1923: 'Wallis',
  1925: 'Wallis',
  1926: 'Wallis',
  1927: 'Wallis',
  1928: 'Wallis',
  1929: 'Wallis',
  1932: 'Wallis',
  1933: 'Wallis',
  1934: 'Wallis',
  1936: 'Wallis',
  1937: 'Wallis',
  1938: 'Wallis',
  1941: 'Wallis',
  1942: 'Wallis',
  1943: 'Wallis',
  1944: 'Wallis',
  1945: 'Wallis',
  1946: 'Wallis',
  1947: 'Wallis',
  1948: 'Wallis',
  1950: 'Wallis',
  1955: 'Wallis',
  1957: 'Wallis',
  1958: 'Wallis',
  1961: 'Wallis',
  1962: 'Wallis',
  1963: 'Wallis',
  1964: 'Wallis',
  1965: 'Wallis',
  1966: 'Wallis',
  1967: 'Wallis',
  1968: 'Wallis',
  1969: 'Wallis',
  1971: 'Wallis',
  1972: 'Wallis',
  1973: 'Wallis',
  1974: 'Wallis',
  1975: 'Wallis',
  1976: 'Wallis',
  1977: 'Wallis',
  1978: 'Wallis',
  1981: 'Wallis',
  1982: 'Wallis',
  1983: 'Wallis',
  1984: 'Wallis',
  1985: 'Wallis',
  1986: 'Wallis',
  1987: 'Wallis',
  1988: 'Wallis',
  1991: 'Wallis',
  1992: 'Wallis',
  1993: 'Wallis',
  1994: 'Wallis',
  1996: 'Wallis',
  1997: 'Wallis',
  2000: 'Neuenburg',
  2012: 'Neuenburg',
  2013: 'Neuenburg',
  2014: 'Neuenburg',
  2015: 'Neuenburg',
  2016: 'Neuenburg',
  2017: 'Neuenburg',
  2019: 'Neuenburg',
  2022: 'Neuenburg',
  2023: 'Neuenburg',
  2024: 'Neuenburg',
  2025: 'Neuenburg',
  2027: 'Neuenburg',
  2028: 'Neuenburg',
  2034: 'Neuenburg',
  2035: 'Neuenburg',
  2036: 'Neuenburg',
  2037: 'Neuenburg',
  2042: 'Neuenburg',
  2043: 'Neuenburg',
  2046: 'Neuenburg',
  2052: 'Neuenburg',
  2053: 'Neuenburg',
  2054: 'Neuenburg',
  2056: 'Neuenburg',
  2057: 'Neuenburg',
  2058: 'Neuenburg',
  2063: 'Neuenburg',
  2065: 'Neuenburg',
  2067: 'Neuenburg',
  2068: 'Neuenburg',
  2072: 'Neuenburg',
  2073: 'Neuenburg',
  2074: 'Neuenburg',
  2075: 'Neuenburg',
  2087: 'Neuenburg',
  2088: 'Neuenburg',
  2103: 'Neuenburg',
  2105: 'Neuenburg',
  2108: 'Neuenburg',
  2112: 'Neuenburg',
  2113: 'Neuenburg',
  2114: 'Neuenburg',
  2115: 'Neuenburg',
  2116: 'Neuenburg',
  2117: 'Neuenburg',
  2123: 'Neuenburg',
  2124: 'Neuenburg',
  2126: 'Neuenburg',
  2127: 'Neuenburg',
  2149: 'Neuenburg',
  2206: 'Neuenburg',
  2207: 'Neuenburg',
  2208: 'Neuenburg',
  2300: 'Neuenburg',
  2314: 'Neuenburg',
  2316: 'Neuenburg',
  2318: 'Neuenburg',
  2322: 'Neuenburg',
  2325: 'Neuenburg',
  2333: 'Bern',
  2336: 'Jura',
  2338: 'Jura',
  2340: 'Jura',
  2345: 'Jura',
  2350: 'Jura',
  2353: 'Jura',
  2354: 'Jura',
  2360: 'Jura',
  2362: 'Jura',
  2363: 'Jura',
  2364: 'Jura',
  2400: 'Neuenburg',
  2405: 'Neuenburg',
  2406: 'Neuenburg',
  2414: 'Neuenburg',
  2416: 'Neuenburg',
  2502: 'Bern',
  2503: 'Bern',
  2504: 'Bern',
  2505: 'Bern',
  2512: 'Bern',
  2513: 'Bern',
  2514: 'Bern',
  2515: 'Bern',
  2516: 'Bern',
  2517: 'Bern',
  2518: 'Bern',
  2520: 'Bern',
  2523: 'Neuenburg',
  2525: 'Neuenburg',
  2532: 'Bern',
  2533: 'Bern',
  2534: 'Bern',
  2535: 'Bern',
  2536: 'Bern',
  2537: 'Bern',
  2538: 'Bern',
  2540: 'Solothurn',
  2542: 'Bern',
  2543: 'Bern',
  2544: 'Solothurn',
  2545: 'Solothurn',
  2552: 'Bern',
  2553: 'Bern',
  2554: 'Bern',
  2555: 'Bern',
  2556: 'Bern',
  2557: 'Bern',
  2558: 'Bern',
  2560: 'Bern',
  2562: 'Bern',
  2563: 'Bern',
  2564: 'Bern',
  2565: 'Bern',
  2572: 'Bern',
  2575: 'Bern',
  2576: 'Bern',
  2577: 'Bern',
  2603: 'Bern',
  2604: 'Bern',
  2605: 'Bern',
  2606: 'Bern',
  2607: 'Bern',
  2608: 'Bern',
  2610: 'Bern',
  2612: 'Bern',
  2613: 'Bern',
  2615: 'Bern',
  2616: 'Bern',
  2710: 'Bern',
  2712: 'Bern',
  2713: 'Bern',
  2714: 'Jura',
  2715: 'Bern',
  2716: 'Bern',
  2717: 'Bern',
  2718: 'Jura',
  2720: 'Bern',
  2722: 'Bern',
  2723: 'Bern',
  2732: 'Bern',
  2733: 'Bern',
  2735: 'Bern',
  2736: 'Bern',
  2738: 'Bern',
  2740: 'Bern',
  2742: 'Bern',
  2743: 'Bern',
  2744: 'Bern',
  2745: 'Bern',
  2746: 'Bern',
  2747: 'Bern',
  2748: 'Bern',
  2762: 'Bern',
  2800: 'Jura',
  2802: 'Jura',
  2803: 'Jura',
  2805: 'Jura',
  2806: 'Jura',
  2807: 'Jura',
  2812: 'Jura',
  2813: 'Jura',
  2814: 'Basel - Landschaft',
  2822: 'Jura',
  2823: 'Jura',
  2824: 'Jura',
  2825: 'Jura',
  2826: 'Jura',
  2827: 'Jura',
  2828: 'Jura',
  2829: 'Jura',
  2830: 'Jura',
  2832: 'Jura',
  2842: 'Jura',
  2843: 'Jura',
  2852: 'Jura',
  2853: 'Jura',
  2854: 'Jura',
  2855: 'Jura',
  2856: 'Jura',
  2857: 'Jura',
  2863: 'Jura',
  2864: 'Jura',
  2873: 'Jura',
  2882: 'Jura',
  2883: 'Jura',
  2884: 'Jura',
  2885: 'Jura',
  2886: 'Jura',
  2887: 'Jura',
  2888: 'Jura',
  2889: 'Jura',
  2900: 'Jura',
  2902: 'Jura',
  2903: 'Jura',
  2904: 'Jura',
  2905: 'Jura',
  2906: 'Jura',
  2907: 'Jura',
  2908: 'Jura',
  2912: 'Jura',
  2914: 'Jura',
  2915: 'Jura',
  2916: 'Jura',
  2922: 'Jura',
  2923: 'Jura',
  2924: 'Jura',
  2925: 'Jura',
  2926: 'Jura',
  2932: 'Jura',
  2933: 'Jura',
  2935: 'Jura',
  2942: 'Jura',
  2943: 'Jura',
  2944: 'Jura',
  2946: 'Jura',
  2947: 'Jura',
  2950: 'Jura',
  2952: 'Jura',
  2953: 'Jura',
  2954: 'Jura',
  3004: 'Bern',
  3005: 'Bern',
  3006: 'Bern',
  3007: 'Bern',
  3008: 'Bern',
  3010: 'Bern',
  3011: 'Bern',
  3012: 'Bern',
  3013: 'Bern',
  3014: 'Bern',
  3015: 'Bern',
  3018: 'Bern',
  3019: 'Bern',
  3020: 'Bern',
  3027: 'Bern',
  3032: 'Bern',
  3033: 'Bern',
  3034: 'Bern',
  3035: 'Bern',
  3036: 'Bern',
  3037: 'Bern',
  3038: 'Bern',
  3042: 'Bern',
  3043: 'Bern',
  3044: 'Bern',
  3045: 'Bern',
  3046: 'Bern',
  3047: 'Bern',
  3048: 'Bern',
  3049: 'Bern',
  3052: 'Bern',
  3053: 'Bern',
  3054: 'Bern',
  3063: 'Bern',
  3065: 'Bern',
  3066: 'Bern',
  3067: 'Bern',
  3068: 'Bern',
  3072: 'Bern',
  3073: 'Bern',
  3074: 'Bern',
  3075: 'Bern',
  3076: 'Bern',
  3077: 'Bern',
  3078: 'Bern',
  3082: 'Bern',
  3083: 'Bern',
  3084: 'Bern',
  3086: 'Bern',
  3087: 'Bern',
  3088: 'Bern',
  3089: 'Bern',
  3095: 'Bern',
  3096: 'Bern',
  3097: 'Bern',
  3098: 'Bern',
  3099: 'Bern',
  3110: 'Bern',
  3111: 'Bern',
  3112: 'Bern',
  3113: 'Bern',
  3114: 'Bern',
  3115: 'Bern',
  3116: 'Bern',
  3122: 'Bern',
  3123: 'Bern',
  3124: 'Bern',
  3125: 'Bern',
  3126: 'Bern',
  3127: 'Bern',
  3128: 'Bern',
  3132: 'Bern',
  3144: 'Bern',
  3145: 'Bern',
  3147: 'Bern',
  3148: 'Bern',
  3150: 'Bern',
  3152: 'Bern',
  3153: 'Bern',
  3154: 'Bern',
  3155: 'Bern',
  3156: 'Bern',
  3157: 'Bern',
  3158: 'Bern',
  3159: 'Bern',
  3172: 'Bern',
  3173: 'Bern',
  3174: 'Bern',
  3175: 'Freiburg',
  3176: 'Bern',
  3177: 'Bern',
  3178: 'Freiburg',
  3179: 'Bern',
  3182: 'Freiburg',
  3183: 'Bern',
  3184: 'Freiburg',
  3185: 'Freiburg',
  3186: 'Freiburg',
  3202: 'Bern',
  3203: 'Bern',
  3204: 'Bern',
  3205: 'Bern',
  3206: 'Bern',
  3207: 'Bern',
  3208: 'Bern',
  3210: 'Freiburg',
  3212: 'Freiburg',
  3213: 'Freiburg',
  3214: 'Freiburg',
  3215: 'Freiburg',
  3216: 'Freiburg',
  3225: 'Bern',
  3226: 'Bern',
  3232: 'Bern',
  3233: 'Bern',
  3234: 'Bern',
  3235: 'Bern',
  3236: 'Bern',
  3237: 'Bern',
  3238: 'Bern',
  3250: 'Bern',
  3251: 'Bern',
  3252: 'Bern',
  3253: 'Solothurn',
  3254: 'Solothurn',
  3255: 'Bern',
  3256: 'Bern',
  3257: 'Bern',
  3262: 'Bern',
  3263: 'Bern',
  3264: 'Bern',
  3266: 'Bern',
  3267: 'Bern',
  3268: 'Bern',
  3270: 'Bern',
  3271: 'Bern',
  3272: 'Bern',
  3273: 'Bern',
  3274: 'Bern',
  3280: 'Freiburg',
  3282: 'Bern',
  3283: 'Bern',
  3284: 'Freiburg',
  3285: 'Freiburg',
  3286: 'Freiburg',
  3292: 'Bern',
  3293: 'Bern',
  3294: 'Bern',
  3295: 'Bern',
  3296: 'Bern',
  3297: 'Bern',
  3298: 'Bern',
  3302: 'Bern',
  3303: 'Bern',
  3305: 'Bern',
  3306: 'Bern',
  3307: 'Solothurn',
  3308: 'Bern',
  3309: 'Bern',
  3312: 'Bern',
  3313: 'Bern',
  3314: 'Bern',
  3315: 'Bern',
  3317: 'Bern',
  3322: 'Bern',
  3323: 'Bern',
  3324: 'Bern',
  3325: 'Bern',
  3326: 'Bern',
  3360: 'Bern',
  3362: 'Bern',
  3363: 'Bern',
  3365: 'Bern',
  3366: 'Bern',
  3367: 'Bern',
  3368: 'Bern',
  3372: 'Bern',
  3373: 'Bern',
  3374: 'Bern',
  3375: 'Bern',
  3376: 'Bern',
  3377: 'Bern',
  3380: 'Bern',
  3400: 'Bern',
  3412: 'Bern',
  3413: 'Bern',
  3414: 'Bern',
  3415: 'Bern',
  3416: 'Bern',
  3417: 'Bern',
  3418: 'Bern',
  3419: 'Bern',
  3421: 'Bern',
  3422: 'Bern',
  3423: 'Bern',
  3424: 'Bern',
  3425: 'Bern',
  3426: 'Bern',
  3427: 'Bern',
  3428: 'Bern',
  3429: 'Bern',
  3432: 'Bern',
  3433: 'Bern',
  3434: 'Bern',
  3435: 'Bern',
  3436: 'Bern',
  3437: 'Bern',
  3438: 'Bern',
  3439: 'Bern',
  3452: 'Bern',
  3453: 'Bern',
  3454: 'Bern',
  3455: 'Bern',
  3456: 'Bern',
  3457: 'Bern',
  3462: 'Bern',
  3463: 'Bern',
  3464: 'Bern',
  3465: 'Bern',
  3472: 'Bern',
  3473: 'Bern',
  3474: 'Bern',
  3475: 'Bern',
  3476: 'Bern',
  3503: 'Bern',
  3504: 'Bern',
  3506: 'Bern',
  3507: 'Bern',
  3508: 'Bern',
  3510: 'Bern',
  3512: 'Bern',
  3513: 'Bern',
  3531: 'Bern',
  3532: 'Bern',
  3533: 'Bern',
  3534: 'Bern',
  3535: 'Bern',
  3536: 'Bern',
  3537: 'Bern',
  3538: 'Bern',
  3543: 'Bern',
  3550: 'Bern',
  3551: 'Bern',
  3552: 'Bern',
  3553: 'Bern',
  3555: 'Bern',
  3556: 'Bern',
  3557: 'Bern',
  3600: 'Bern',
  3603: 'Bern',
  3604: 'Bern',
  3608: 'Bern',
  3612: 'Bern',
  3613: 'Bern',
  3614: 'Bern',
  3615: 'Bern',
  3616: 'Bern',
  3617: 'Bern',
  3618: 'Bern',
  3619: 'Bern',
  3622: 'Bern',
  3623: 'Bern',
  3624: 'Bern',
  3625: 'Bern',
  3626: 'Bern',
  3627: 'Bern',
  3628: 'Bern',
  3629: 'Bern',
  3631: 'Bern',
  3632: 'Bern',
  3633: 'Bern',
  3634: 'Bern',
  3635: 'Bern',
  3636: 'Bern',
  3638: 'Bern',
  3645: 'Bern',
  3646: 'Bern',
  3647: 'Bern',
  3652: 'Bern',
  3653: 'Bern',
  3654: 'Bern',
  3655: 'Bern',
  3656: 'Bern',
  3657: 'Bern',
  3658: 'Bern',
  3661: 'Bern',
  3662: 'Bern',
  3663: 'Bern',
  3664: 'Bern',
  3665: 'Bern',
  3671: 'Bern',
  3672: 'Bern',
  3673: 'Bern',
  3674: 'Bern',
  3700: 'Bern',
  3702: 'Bern',
  3703: 'Bern',
  3704: 'Bern',
  3705: 'Bern',
  3706: 'Bern',
  3707: 'Bern',
  3711: 'Bern',
  3713: 'Bern',
  3714: 'Bern',
  3715: 'Bern',
  3716: 'Bern',
  3717: 'Bern',
  3718: 'Bern',
  3722: 'Bern',
  3723: 'Bern',
  3724: 'Bern',
  3725: 'Bern',
  3752: 'Bern',
  3753: 'Bern',
  3754: 'Bern',
  3755: 'Bern',
  3756: 'Bern',
  3757: 'Bern',
  3758: 'Bern',
  3762: 'Bern',
  3763: 'Bern',
  3764: 'Bern',
  3765: 'Bern',
  3766: 'Bern',
  3770: 'Bern',
  3771: 'Bern',
  3772: 'Bern',
  3773: 'Bern',
  3775: 'Bern',
  3776: 'Bern',
  3777: 'Bern',
  3778: 'Bern',
  3780: 'Bern',
  3781: 'Bern',
  3782: 'Bern',
  3783: 'Bern',
  3784: 'Bern',
  3785: 'Bern',
  3792: 'Bern',
  3800: 'Bern',
  3801: 'Wallis',
  3803: 'Bern',
  3804: 'Bern',
  3805: 'Bern',
  3806: 'Bern',
  3807: 'Bern',
  3812: 'Bern',
  3813: 'Bern',
  3814: 'Bern',
  3815: 'Bern',
  3816: 'Bern',
  3818: 'Bern',
  3822: 'Bern',
  3823: 'Bern',
  3824: 'Bern',
  3825: 'Bern',
  3826: 'Bern',
  3852: 'Bern',
  3853: 'Bern',
  3854: 'Bern',
  3855: 'Bern',
  3856: 'Bern',
  3857: 'Bern',
  3858: 'Bern',
  3860: 'Bern',
  3862: 'Bern',
  3863: 'Bern',
  3864: 'Bern',
  3900: 'Wallis',
  3901: 'Wallis',
  3902: 'Wallis',
  3903: 'Wallis',
  3904: 'Wallis',
  3905: 'Wallis',
  3906: 'Wallis',
  3907: 'Wallis',
  3908: 'Wallis',
  3910: 'Wallis',
  3911: 'Wallis',
  3912: 'Wallis',
  3913: 'Wallis',
  3914: 'Wallis',
  3916: 'Wallis',
  3917: 'Wallis',
  3918: 'Wallis',
  3919: 'Wallis',
  3920: 'Wallis',
  3922: 'Wallis',
  3923: 'Wallis',
  3924: 'Wallis',
  3925: 'Wallis',
  3926: 'Wallis',
  3927: 'Wallis',
  3928: 'Wallis',
  3929: 'Wallis',
  3930: 'Wallis',
  3931: 'Wallis',
  3932: 'Wallis',
  3933: 'Wallis',
  3934: 'Wallis',
  3935: 'Wallis',
  3937: 'Wallis',
  3938: 'Wallis',
  3939: 'Wallis',
  3940: 'Wallis',
  3942: 'Wallis',
  3943: 'Wallis',
  3944: 'Wallis',
  3945: 'Wallis',
  3946: 'Wallis',
  3947: 'Wallis',
  3948: 'Wallis',
  3949: 'Wallis',
  3951: 'Wallis',
  3952: 'Wallis',
  3953: 'Wallis',
  3954: 'Wallis',
  3955: 'Wallis',
  3956: 'Wallis',
  3957: 'Wallis',
  3960: 'Wallis',
  3961: 'Wallis',
  3963: 'Wallis',
  3965: 'Wallis',
  3966: 'Wallis',
  3967: 'Wallis',
  3968: 'Wallis',
  3970: 'Wallis',
  3971: 'Wallis',
  3972: 'Wallis',
  3973: 'Wallis',
  3974: 'Wallis',
  3975: 'Wallis',
  3976: 'Wallis',
  3977: 'Wallis',
  3978: 'Wallis',
  3979: 'Wallis',
  3982: 'Wallis',
  3983: 'Wallis',
  3984: 'Wallis',
  3985: 'Wallis',
  3986: 'Wallis',
  3987: 'Wallis',
  3988: 'Wallis',
  3989: 'Wallis',
  3991: 'Wallis',
  3992: 'Wallis',
  3993: 'Wallis',
  3994: 'Wallis',
  3995: 'Wallis',
  3996: 'Wallis',
  3997: 'Wallis',
  3998: 'Wallis',
  3999: 'Wallis',
  4001: 'Basel - Stadt',
  4051: 'Basel - Stadt',
  4052: 'Basel - Stadt',
  4053: 'Basel - Stadt',
  4054: 'Basel - Stadt',
  4055: 'Basel - Stadt',
  4056: 'Basel - Stadt',
  4057: 'Basel - Stadt',
  4058: 'Basel - Stadt',
  4059: 'Basel - Stadt',
  4101: 'Basel - Landschaft',
  4102: 'Basel - Landschaft',
  4103: 'Basel - Landschaft',
  4104: 'Basel - Landschaft',
  4105: 'Basel - Landschaft',
  4106: 'Basel - Landschaft',
  4107: 'Basel - Landschaft',
  4108: 'Solothurn',
  4112: 'Solothurn',
  4114: 'Solothurn',
  4115: 'Solothurn',
  4116: 'Solothurn',
  4117: 'Basel - Landschaft',
  4118: 'Solothurn',
  4123: 'Basel - Landschaft',
  4124: 'Basel - Landschaft',
  4125: 'Basel - Stadt',
  4126: 'Basel - Stadt',
  4127: 'Basel - Landschaft',
  4132: 'Basel - Landschaft',
  4133: 'Basel - Landschaft',
  4142: 'Basel - Landschaft',
  4143: 'Solothurn',
  4144: 'Basel - Landschaft',
  4145: 'Solothurn',
  4146: 'Solothurn',
  4147: 'Basel - Landschaft',
  4148: 'Basel - Landschaft',
  4153: 'Basel - Landschaft',
  4202: 'Basel - Landschaft',
  4203: 'Basel - Landschaft',
  4204: 'Solothurn',
  4206: 'Solothurn',
  4207: 'Basel - Landschaft',
  4208: 'Solothurn',
  4222: 'Basel - Landschaft',
  4223: 'Basel - Landschaft',
  4224: 'Basel - Landschaft',
  4225: 'Basel - Landschaft',
  4226: 'Solothurn',
  4227: 'Solothurn',
  4228: 'Solothurn',
  4229: 'Solothurn',
  4232: 'Solothurn',
  4233: 'Solothurn',
  4234: 'Solothurn',
  4242: 'Basel - Landschaft',
  4243: 'Basel - Landschaft',
  4244: 'Basel - Landschaft',
  4245: 'Solothurn',
  4246: 'Basel - Landschaft',
  4247: 'Solothurn',
  4252: 'Solothurn',
  4253: 'Basel - Landschaft',
  4254: 'Basel - Landschaft',
  4302: 'Basel - Landschaft',
  4303: 'Aargau',
  4304: 'Basel - Landschaft',
  4305: 'Aargau',
  4310: 'Aargau',
  4312: 'Aargau',
  4313: 'Aargau',
  4314: 'Aargau',
  4315: 'Aargau',
  4316: 'Aargau',
  4317: 'Aargau',
  4322: 'Aargau',
  4323: 'Aargau',
  4324: 'Aargau',
  4325: 'Aargau',
  4332: 'Aargau',
  4333: 'Aargau',
  4334: 'Aargau',
  4402: 'Basel - Landschaft',
  4410: 'Basel - Landschaft',
  4411: 'Basel - Landschaft',
  4412: 'Solothurn',
  4413: 'Solothurn',
  4414: 'Basel - Landschaft',
  4415: 'Basel - Landschaft',
  4416: 'Basel - Landschaft',
  4417: 'Basel - Landschaft',
  4418: 'Basel - Landschaft',
  4419: 'Basel - Landschaft',
  4421: 'Solothurn',
  4422: 'Basel - Landschaft',
  4423: 'Basel - Landschaft',
  4424: 'Basel - Landschaft',
  4425: 'Basel - Landschaft',
  4426: 'Basel - Landschaft',
  4431: 'Basel - Landschaft',
  4432: 'Basel - Landschaft',
  4433: 'Basel - Landschaft',
  4434: 'Basel - Landschaft',
  4435: 'Basel - Landschaft',
  4436: 'Basel - Landschaft',
  4437: 'Basel - Landschaft',
  4438: 'Basel - Landschaft',
  4441: 'Basel - Landschaft',
  4442: 'Basel - Landschaft',
  4443: 'Basel - Landschaft',
  4444: 'Basel - Landschaft',
  4445: 'Basel - Landschaft',
  4446: 'Basel - Landschaft',
  4447: 'Basel - Landschaft',
  4448: 'Basel - Landschaft',
  4450: 'Basel - Landschaft',
  4451: 'Basel - Landschaft',
  4452: 'Basel - Landschaft',
  4453: 'Basel - Landschaft',
  4455: 'Basel - Landschaft',
  4456: 'Basel - Landschaft',
  4457: 'Basel - Landschaft',
  4458: 'Basel - Landschaft',
  4460: 'Basel - Landschaft',
  4461: 'Basel - Landschaft',
  4462: 'Basel - Landschaft',
  4463: 'Basel - Landschaft',
  4464: 'Basel - Landschaft',
  4465: 'Basel - Landschaft',
  4466: 'Basel - Landschaft',
  4467: 'Basel - Landschaft',
  4468: 'Solothurn',
  4469: 'Basel - Landschaft',
  4492: 'Basel - Landschaft',
  4493: 'Basel - Landschaft',
  4494: 'Basel - Landschaft',
  4495: 'Basel - Landschaft',
  4496: 'Basel - Landschaft',
  4497: 'Basel - Landschaft',
  4500: 'Solothurn',
  4512: 'Solothurn',
  4513: 'Solothurn',
  4514: 'Solothurn',
  4515: 'Solothurn',
  4522: 'Solothurn',
  4523: 'Solothurn',
  4524: 'Solothurn',
  4525: 'Solothurn',
  4528: 'Solothurn',
  4532: 'Solothurn',
  4533: 'Solothurn',
  4534: 'Solothurn',
  4535: 'Solothurn',
  4536: 'Bern',
  4537: 'Bern',
  4538: 'Bern',
  4539: 'Bern',
  4542: 'Solothurn',
  4543: 'Solothurn',
  4552: 'Solothurn',
  4553: 'Solothurn',
  4554: 'Solothurn',
  4556: 'Solothurn',
  4557: 'Solothurn',
  4558: 'Solothurn',
  4562: 'Solothurn',
  4563: 'Solothurn',
  4564: 'Solothurn',
  4565: 'Solothurn',
  4566: 'Solothurn',
  4571: 'Solothurn',
  4573: 'Solothurn',
  4574: 'Solothurn',
  4576: 'Solothurn',
  4577: 'Solothurn',
  4578: 'Solothurn',
  4579: 'Solothurn',
  4581: 'Solothurn',
  4582: 'Solothurn',
  4583: 'Solothurn',
  4584: 'Solothurn',
  4585: 'Solothurn',
  4586: 'Solothurn',
  4587: 'Solothurn',
  4588: 'Solothurn',
  4600: 'Solothurn',
  4612: 'Solothurn',
  4613: 'Solothurn',
  4614: 'Solothurn',
  4615: 'Solothurn',
  4616: 'Solothurn',
  4617: 'Solothurn',
  4618: 'Solothurn',
  4622: 'Solothurn',
  4623: 'Solothurn',
  4624: 'Solothurn',
  4625: 'Solothurn',
  4626: 'Solothurn',
  4628: 'Solothurn',
  4629: 'Solothurn',
  4632: 'Solothurn',
  4633: 'Solothurn',
  4634: 'Solothurn',
  4652: 'Solothurn',
  4653: 'Solothurn',
  4654: 'Solothurn',
  4655: 'Solothurn',
  4656: 'Solothurn',
  4657: 'Solothurn',
  4658: 'Solothurn',
  4663: 'Aargau',
  4665: 'Aargau',
  4702: 'Solothurn',
  4703: 'Solothurn',
  4704: 'Bern',
  4710: 'Solothurn',
  4712: 'Solothurn',
  4713: 'Solothurn',
  4714: 'Solothurn',
  4715: 'Solothurn',
  4716: 'Solothurn',
  4717: 'Solothurn',
  4718: 'Solothurn',
  4719: 'Solothurn',
  4800: 'Aargau',
  4802: 'Aargau',
  4803: 'Aargau',
  4805: 'Aargau',
  4806: 'Luzern',
  4812: 'Aargau',
  4813: 'Aargau',
  4814: 'Aargau',
  4852: 'Aargau',
  4853: 'Aargau',
  4856: 'Aargau',
  4900: 'Bern',
  4911: 'Bern',
  4912: 'Bern',
  4913: 'Bern',
  4914: 'Bern',
  4915: 'Luzern',
  4916: 'Bern',
  4917: 'Bern',
  4919: 'Bern',
  4922: 'Bern',
  4923: 'Bern',
  4924: 'Bern',
  4932: 'Bern',
  4933: 'Bern',
  4934: 'Bern',
  4935: 'Bern',
  4936: 'Bern',
  4937: 'Bern',
  4938: 'Bern',
  4942: 'Bern',
  4943: 'Bern',
  4944: 'Bern',
  4950: 'Bern',
  4952: 'Bern',
  4953: 'Bern',
  4954: 'Bern',
  4955: 'Bern',
  5000: 'Aargau',
  5004: 'Aargau',
  5012: 'Solothurn',
  5013: 'Solothurn',
  5014: 'Solothurn',
  5015: 'Solothurn',
  5017: 'Aargau',
  5018: 'Aargau',
  5022: 'Aargau',
  5023: 'Aargau',
  5024: 'Aargau',
  5025: 'Aargau',
  5026: 'Aargau',
  5027: 'Aargau',
  5028: 'Aargau',
  5032: 'Aargau',
  5033: 'Aargau',
  5034: 'Aargau',
  5035: 'Aargau',
  5036: 'Aargau',
  5037: 'Aargau',
  5040: 'Aargau',
  5042: 'Aargau',
  5043: 'Aargau',
  5044: 'Aargau',
  5046: 'Aargau',
  5053: 'Aargau',
  5054: 'Aargau',
  5056: 'Aargau',
  5057: 'Aargau',
  5058: 'Aargau',
  5062: 'Aargau',
  5063: 'Aargau',
  5064: 'Aargau',
  5070: 'Aargau',
  5072: 'Aargau',
  5073: 'Aargau',
  5074: 'Aargau',
  5075: 'Aargau',
  5076: 'Aargau',
  5077: 'Aargau',
  5078: 'Aargau',
  5079: 'Aargau',
  5080: 'Aargau',
  5082: 'Aargau',
  5083: 'Aargau',
  5084: 'Aargau',
  5085: 'Aargau',
  5102: 'Aargau',
  5103: 'Aargau',
  5105: 'Aargau',
  5106: 'Aargau',
  5107: 'Aargau',
  5108: 'Aargau',
  5112: 'Aargau',
  5113: 'Aargau',
  5116: 'Aargau',
  5200: 'Aargau',
  5210: 'Aargau',
  5212: 'Aargau',
  5213: 'Aargau',
  5222: 'Aargau',
  5223: 'Aargau',
  5225: 'Aargau',
  5233: 'Aargau',
  5234: 'Aargau',
  5235: 'Aargau',
  5236: 'Aargau',
  5237: 'Aargau',
  5242: 'Aargau',
  5243: 'Aargau',
  5244: 'Aargau',
  5245: 'Aargau',
  5246: 'Aargau',
  5272: 'Aargau',
  5273: 'Aargau',
  5274: 'Aargau',
  5275: 'Aargau',
  5276: 'Aargau',
  5277: 'Aargau',
  5300: 'Aargau',
  5301: 'Aargau',
  5303: 'Aargau',
  5304: 'Aargau',
  5305: 'Aargau',
  5306: 'Aargau',
  5312: 'Aargau',
  5313: 'Aargau',
  5314: 'Aargau',
  5315: 'Aargau',
  5316: 'Aargau',
  5317: 'Aargau',
  5318: 'Aargau',
  5322: 'Aargau',
  5323: 'Aargau',
  5324: 'Aargau',
  5325: 'Aargau',
  5326: 'Aargau',
  5330: 'Aargau',
  5332: 'Aargau',
  5333: 'Aargau',
  5334: 'Aargau',
  5400: 'Aargau',
  5404: 'Aargau',
  5405: 'Aargau',
  5406: 'Aargau',
  5408: 'Aargau',
  5412: 'Aargau',
  5413: 'Aargau',
  5415: 'Aargau',
  5416: 'Aargau',
  5417: 'Aargau',
  5420: 'Aargau',
  5423: 'Aargau',
  5425: 'Aargau',
  5426: 'Aargau',
  5430: 'Aargau',
  5432: 'Aargau',
  5436: 'Aargau',
  5442: 'Aargau',
  5443: 'Aargau',
  5444: 'Aargau',
  5445: 'Aargau',
  5452: 'Aargau',
  5453: 'Aargau',
  5454: 'Aargau',
  5462: 'Aargau',
  5463: 'Aargau',
  5464: 'Aargau',
  5465: 'Aargau',
  5466: 'Aargau',
  5467: 'Aargau',
  5502: 'Aargau',
  5503: 'Aargau',
  5504: 'Aargau',
  5505: 'Aargau',
  5506: 'Aargau',
  5507: 'Aargau',
  5512: 'Aargau',
  5522: 'Aargau',
  5524: 'Aargau',
  5525: 'Aargau',
  5600: 'Aargau',
  5603: 'Aargau',
  5604: 'Aargau',
  5605: 'Aargau',
  5606: 'Aargau',
  5607: 'Aargau',
  5608: 'Aargau',
  5610: 'Aargau',
  5611: 'Aargau',
  5612: 'Aargau',
  5613: 'Aargau',
  5614: 'Aargau',
  5615: 'Aargau',
  5616: 'Aargau',
  5617: 'Aargau',
  5618: 'Aargau',
  5619: 'Aargau',
  5620: 'Aargau',
  5621: 'Aargau',
  5622: 'Aargau',
  5623: 'Aargau',
  5624: 'Aargau',
  5625: 'Aargau',
  5626: 'Aargau',
  5627: 'Aargau',
  5628: 'Aargau',
  5630: 'Aargau',
  5632: 'Aargau',
  5634: 'Aargau',
  5636: 'Aargau',
  5637: 'Aargau',
  5642: 'Aargau',
  5643: 'Aargau',
  5644: 'Aargau',
  5645: 'Aargau',
  5646: 'Aargau',
  5647: 'Aargau',
  5702: 'Aargau',
  5703: 'Aargau',
  5704: 'Aargau',
  5705: 'Aargau',
  5706: 'Aargau',
  5707: 'Aargau',
  5708: 'Aargau',
  5712: 'Aargau',
  5722: 'Aargau',
  5723: 'Aargau',
  5724: 'Aargau',
  5725: 'Aargau',
  5726: 'Aargau',
  5727: 'Aargau',
  5728: 'Aargau',
  5732: 'Aargau',
  5733: 'Aargau',
  5734: 'Aargau',
  5735: 'Luzern',
  5736: 'Aargau',
  5737: 'Aargau',
  5742: 'Aargau',
  5745: 'Aargau',
  5746: 'Solothurn',
  6003: 'Luzern',
  6004: 'Luzern',
  6005: 'Luzern',
  6006: 'Luzern',
  6010: 'Obwalden',
  6012: 'Luzern',
  6013: 'Luzern',
  6014: 'Luzern',
  6015: 'Luzern',
  6016: 'Luzern',
  6017: 'Luzern',
  6018: 'Luzern',
  6019: 'Luzern',
  6020: 'Luzern',
  6022: 'Luzern',
  6023: 'Luzern',
  6024: 'Luzern',
  6025: 'Luzern',
  6026: 'Luzern',
  6027: 'Luzern',
  6028: 'Luzern',
  6030: 'Luzern',
  6032: 'Luzern',
  6033: 'Luzern',
  6034: 'Luzern',
  6035: 'Luzern',
  6036: 'Luzern',
  6037: 'Luzern',
  6038: 'Luzern',
  6039: 'Luzern',
  6042: 'Aargau',
  6043: 'Luzern',
  6044: 'Luzern',
  6045: 'Luzern',
  6047: 'Luzern',
  6048: 'Luzern',
  6052: 'Nidwalden',
  6053: 'Obwalden',
  6055: 'Obwalden',
  6056: 'Obwalden',
  6060: 'Obwalden',
  6062: 'Obwalden',
  6063: 'Obwalden',
  6064: 'Obwalden',
  6066: 'Obwalden',
  6067: 'Obwalden',
  6068: 'Obwalden',
  6072: 'Obwalden',
  6073: 'Obwalden',
  6074: 'Obwalden',
  6078: 'Obwalden',
  6083: 'Bern',
  6084: 'Bern',
  6085: 'Bern',
  6086: 'Bern',
  6102: 'Luzern',
  6103: 'Luzern',
  6105: 'Luzern',
  6106: 'Luzern',
  6110: 'Luzern',
  6112: 'Luzern',
  6113: 'Luzern',
  6114: 'Luzern',
  6122: 'Luzern',
  6123: 'Luzern',
  6125: 'Luzern',
  6126: 'Luzern',
  6130: 'Luzern',
  6132: 'Luzern',
  6133: 'Luzern',
  6142: 'Luzern',
  6143: 'Luzern',
  6144: 'Luzern',
  6145: 'Luzern',
  6146: 'Luzern',
  6147: 'Luzern',
  6152: 'Luzern',
  6153: 'Luzern',
  6154: 'Luzern',
  6156: 'Luzern',
  6162: 'Luzern',
  6163: 'Luzern',
  6166: 'Luzern',
  6167: 'Luzern',
  6170: 'Luzern',
  6173: 'Luzern',
  6174: 'Luzern',
  6182: 'Luzern',
  6192: 'Luzern',
  6196: 'Luzern',
  6197: 'Bern',
  6203: 'Luzern',
  6204: 'Luzern',
  6205: 'Luzern',
  6206: 'Luzern',
  6207: 'Luzern',
  6208: 'Luzern',
  6210: 'Luzern',
  6211: 'Luzern',
  6212: 'Luzern',
  6213: 'Luzern',
  6214: 'Luzern',
  6215: 'Luzern',
  6216: 'Luzern',
  6217: 'Luzern',
  6218: 'Luzern',
  6221: 'Luzern',
  6222: 'Luzern',
  6231: 'Luzern',
  6232: 'Luzern',
  6233: 'Luzern',
  6234: 'Luzern',
  6235: 'Luzern',
  6236: 'Luzern',
  6242: 'Luzern',
  6243: 'Luzern',
  6244: 'Luzern',
  6245: 'Luzern',
  6246: 'Luzern',
  6247: 'Luzern',
  6248: 'Luzern',
  6252: 'Luzern',
  6253: 'Luzern',
  6260: 'Luzern',
  6262: 'Luzern',
  6263: 'Luzern',
  6264: 'Luzern',
  6265: 'Luzern',
  6274: 'Luzern',
  6275: 'Luzern',
  6276: 'Luzern',
  6277: 'Luzern',
  6280: 'Luzern',
  6283: 'Luzern',
  6284: 'Luzern',
  6285: 'Luzern',
  6286: 'Luzern',
  6287: 'Luzern',
  6288: 'Luzern',
  6289: 'Luzern',
  6294: 'Luzern',
  6295: 'Luzern',
  6300: 'Zug',
  6312: 'Zug',
  6313: 'Zug',
  6314: 'Zug',
  6315: 'Zug',
  6317: 'Zug',
  6318: 'Zug',
  6319: 'Zug',
  6330: 'Zug',
  6331: 'Zug',
  6332: 'Zug',
  6333: 'Zug',
  6340: 'Zug',
  6343: 'Zug',
  6344: 'Luzern',
  6345: 'Zug',
  6353: 'Luzern',
  6354: 'Luzern',
  6356: 'Luzern',
  6362: 'Nidwalden',
  6363: 'Nidwalden',
  6365: 'Nidwalden',
  6370: 'Nidwalden',
  6372: 'Nidwalden',
  6373: 'Nidwalden',
  6374: 'Nidwalden',
  6375: 'Nidwalden',
  6376: 'Nidwalden',
  6377: 'Uri',
  6382: 'Nidwalden',
  6383: 'Nidwalden',
  6386: 'Nidwalden',
  6387: 'Nidwalden',
  6388: 'Obwalden',
  6390: 'Obwalden',
  6402: 'Schwyz',
  6403: 'Schwyz',
  6404: 'Luzern',
  6405: 'Schwyz',
  6410: 'Schwyz',
  6414: 'Schwyz',
  6415: 'Schwyz',
  6416: 'Schwyz',
  6417: 'Schwyz',
  6418: 'Schwyz',
  6422: 'Schwyz',
  6423: 'Schwyz',
  6424: 'Schwyz',
  6430: 'Schwyz',
  6432: 'Schwyz',
  6433: 'Schwyz',
  6434: 'Schwyz',
  6436: 'Schwyz',
  6438: 'Schwyz',
  6440: 'Schwyz',
  6441: 'Uri',
  6442: 'Schwyz',
  6443: 'Schwyz',
  6452: 'Schwyz',
  6454: 'Uri',
  6460: 'Uri',
  6461: 'Uri',
  6462: 'Uri',
  6463: 'Uri',
  6464: 'Uri',
  6465: 'Uri',
  6466: 'Uri',
  6467: 'Uri',
  6468: 'Uri',
  6469: 'Uri',
  6472: 'Uri',
  6473: 'Uri',
  6474: 'Uri',
  6475: 'Uri',
  6476: 'Uri',
  6482: 'Uri',
  6484: 'Uri',
  6485: 'Uri',
  6487: 'Uri',
  6490: 'Uri',
  6491: 'Uri',
  6493: 'Uri',
  6500: 'Tessin',
  6503: 'Tessin',
  6512: 'Tessin',
  6513: 'Tessin',
  6514: 'Tessin',
  6515: 'Tessin',
  6516: 'Tessin',
  6517: 'Tessin',
  6518: 'Tessin',
  6523: 'Tessin',
  6524: 'Tessin',
  6525: 'Tessin',
  6526: 'Tessin',
  6527: 'Tessin',
  6528: 'Tessin',
  6532: 'Tessin',
  6533: 'Tessin',
  6534: 'Graubünden',
  6535: 'Graubünden',
  6537: 'Graubünden',
  6538: 'Graubünden',
  6540: 'Graubünden',
  6541: 'Graubünden',
  6542: 'Graubünden',
  6543: 'Graubünden',
  6544: 'Graubünden',
  6545: 'Graubünden',
  6546: 'Graubünden',
  6547: 'Graubünden',
  6548: 'Graubünden',
  6549: 'Graubünden',
  6556: 'Graubünden',
  6557: 'Graubünden',
  6558: 'Graubünden',
  6562: 'Graubünden',
  6563: 'Graubünden',
  6565: 'Graubünden',
  6571: 'Tessin',
  6572: 'Tessin',
  6573: 'Tessin',
  6574: 'Tessin',
  6575: 'Tessin',
  6576: 'Tessin',
  6577: 'Tessin',
  6578: 'Tessin',
  6579: 'Tessin',
  6582: 'Tessin',
  6583: 'Tessin',
  6584: 'Tessin',
  6592: 'Tessin',
  6593: 'Tessin',
  6594: 'Tessin',
  6595: 'Tessin',
  6596: 'Tessin',
  6597: 'Tessin',
  6598: 'Tessin',
  6599: 'Tessin',
  6600: 'Tessin',
  6605: 'Tessin',
  6611: 'Tessin',
  6612: 'Tessin',
  6613: 'Tessin',
  6614: 'Tessin',
  6616: 'Tessin',
  6618: 'Tessin',
  6622: 'Tessin',
  6631: 'Tessin',
  6632: 'Tessin',
  6633: 'Tessin',
  6634: 'Tessin',
  6635: 'Tessin',
  6636: 'Tessin',
  6637: 'Tessin',
  6644: 'Tessin',
  6645: 'Tessin',
  6646: 'Tessin',
  6647: 'Tessin',
  6648: 'Tessin',
  6652: 'Tessin',
  6653: 'Tessin',
  6654: 'Tessin',
  6655: 'Tessin',
  6656: 'Tessin',
  6657: 'Tessin',
  6658: 'Tessin',
  6659: 'Tessin',
  6661: 'Tessin',
  6662: 'Tessin',
  6663: 'Tessin',
  6664: 'Tessin',
  6670: 'Tessin',
  6672: 'Tessin',
  6673: 'Tessin',
  6674: 'Tessin',
  6675: 'Tessin',
  6676: 'Tessin',
  6677: 'Tessin',
  6678: 'Tessin',
  6682: 'Tessin',
  6683: 'Tessin',
  6684: 'Tessin',
  6685: 'Tessin',
  6690: 'Tessin',
  6692: 'Tessin',
  6693: 'Tessin',
  6694: 'Tessin',
  6695: 'Tessin',
  6696: 'Tessin',
  6702: 'Tessin',
  6703: 'Tessin',
  6705: 'Tessin',
  6707: 'Tessin',
  6710: 'Tessin',
  6713: 'Tessin',
  6714: 'Tessin',
  6715: 'Tessin',
  6716: 'Tessin',
  6717: 'Tessin',
  6718: 'Tessin',
  6719: 'Tessin',
  6720: 'Tessin',
  6721: 'Tessin',
  6722: 'Tessin',
  6723: 'Tessin',
  6724: 'Tessin',
  6742: 'Tessin',
  6743: 'Tessin',
  6744: 'Tessin',
  6745: 'Tessin',
  6746: 'Tessin',
  6747: 'Tessin',
  6748: 'Tessin',
  6749: 'Tessin',
  6760: 'Tessin',
  6763: 'Tessin',
  6764: 'Tessin',
  6772: 'Tessin',
  6773: 'Tessin',
  6774: 'Tessin',
  6775: 'Tessin',
  6776: 'Tessin',
  6777: 'Tessin',
  6780: 'Tessin',
  6781: 'Tessin',
  6802: 'Tessin',
  6803: 'Tessin',
  6804: 'Tessin',
  6805: 'Tessin',
  6806: 'Tessin',
  6807: 'Tessin',
  6808: 'Tessin',
  6809: 'Tessin',
  6810: 'Tessin',
  6814: 'Tessin',
  6815: 'Tessin',
  6816: 'Tessin',
  6817: 'Tessin',
  6818: 'Tessin',
  6821: 'Tessin',
  6822: 'Tessin',
  6823: 'Tessin',
  6825: 'Tessin',
  6826: 'Tessin',
  6827: 'Tessin',
  6828: 'Tessin',
  6830: 'Tessin',
  6832: 'Tessin',
  6833: 'Tessin',
  6834: 'Tessin',
  6835: 'Tessin',
  6837: 'Tessin',
  6838: 'Tessin',
  6839: 'Tessin',
  6850: 'Tessin',
  6852: 'Tessin',
  6853: 'Tessin',
  6854: 'Tessin',
  6855: 'Tessin',
  6862: 'Tessin',
  6863: 'Tessin',
  6864: 'Tessin',
  6865: 'Tessin',
  6866: 'Tessin',
  6867: 'Tessin',
  6872: 'Tessin',
  6873: 'Tessin',
  6874: 'Tessin',
  6875: 'Tessin',
  6877: 'Tessin',
  6883: 'Tessin',
  6900: 'Tessin',
  6912: 'Tessin',
  6913: 'Tessin',
  6914: 'Tessin',
  6915: 'Tessin',
  6916: 'Tessin',
  6917: 'Tessin',
  6918: 'Tessin',
  6919: 'Tessin',
  6921: 'Tessin',
  6922: 'Tessin',
  6924: 'Tessin',
  6925: 'Tessin',
  6926: 'Tessin',
  6927: 'Tessin',
  6928: 'Tessin',
  6929: 'Tessin',
  6930: 'Tessin',
  6932: 'Tessin',
  6933: 'Tessin',
  6934: 'Tessin',
  6935: 'Tessin',
  6936: 'Tessin',
  6937: 'Tessin',
  6938: 'Tessin',
  6939: 'Tessin',
  6942: 'Tessin',
  6943: 'Tessin',
  6944: 'Tessin',
  6945: 'Tessin',
  6946: 'Tessin',
  6947: 'Tessin',
  6948: 'Tessin',
  6949: 'Tessin',
  6950: 'Tessin',
  6951: 'Tessin',
  6952: 'Tessin',
  6953: 'Tessin',
  6954: 'Tessin',
  6955: 'Tessin',
  6956: 'Tessin',
  6957: 'Tessin',
  6958: 'Tessin',
  6959: 'Tessin',
  6960: 'Tessin',
  6962: 'Tessin',
  6963: 'Tessin',
  6964: 'Tessin',
  6965: 'Tessin',
  6966: 'Tessin',
  6967: 'Tessin',
  6968: 'Tessin',
  6974: 'Tessin',
  6976: 'Tessin',
  6977: 'Tessin',
  6978: 'Tessin',
  6979: 'Tessin',
  6980: 'Tessin',
  6981: 'Tessin',
  6982: 'Tessin',
  6983: 'Tessin',
  6984: 'Tessin',
  6986: 'Tessin',
  6987: 'Tessin',
  6988: 'Tessin',
  6989: 'Tessin',
  6990: 'Tessin',
  6991: 'Tessin',
  6992: 'Tessin',
  6993: 'Tessin',
  6994: 'Tessin',
  6995: 'Tessin',
  6996: 'Tessin',
  6997: 'Tessin',
  6998: 'Tessin',
  6999: 'Tessin',
  7000: 'Graubünden',
  7012: 'Graubünden',
  7013: 'Graubünden',
  7014: 'Graubünden',
  7015: 'Graubünden',
  7016: 'Graubünden',
  7017: 'Graubünden',
  7018: 'Graubünden',
  7019: 'Graubünden',
  7023: 'Graubünden',
  7026: 'Graubünden',
  7027: 'Graubünden',
  7028: 'Graubünden',
  7029: 'Graubünden',
  7031: 'Graubünden',
  7032: 'Graubünden',
  7050: 'Graubünden',
  7056: 'Graubünden',
  7057: 'Graubünden',
  7058: 'Graubünden',
  7062: 'Graubünden',
  7063: 'Graubünden',
  7064: 'Graubünden',
  7074: 'Graubünden',
  7075: 'Graubünden',
  7076: 'Graubünden',
  7077: 'Graubünden',
  7078: 'Graubünden',
  7082: 'Graubünden',
  7083: 'Graubünden',
  7084: 'Graubünden',
  7104: 'Graubünden',
  7106: 'Graubünden',
  7107: 'Graubünden',
  7109: 'Graubünden',
  7110: 'Graubünden',
  7111: 'Graubünden',
  7112: 'Graubünden',
  7113: 'Graubünden',
  7114: 'Graubünden',
  7115: 'Graubünden',
  7116: 'Graubünden',
  7122: 'Graubünden',
  7126: 'Graubünden',
  7127: 'Graubünden',
  7128: 'Graubünden',
  7130: 'Graubünden',
  7132: 'Graubünden',
  7134: 'Graubünden',
  7137: 'Graubünden',
  7138: 'Graubünden',
  7141: 'Graubünden',
  7142: 'Graubünden',
  7143: 'Graubünden',
  7144: 'Graubünden',
  7145: 'Graubünden',
  7146: 'Graubünden',
  7147: 'Graubünden',
  7148: 'Graubünden',
  7149: 'Graubünden',
  7151: 'Graubünden',
  7152: 'Graubünden',
  7153: 'Graubünden',
  7154: 'Graubünden',
  7155: 'Graubünden',
  7156: 'Graubünden',
  7157: 'Graubünden',
  7158: 'Graubünden',
  7159: 'Graubünden',
  7162: 'Graubünden',
  7163: 'Graubünden',
  7164: 'Graubünden',
  7165: 'Graubünden',
  7166: 'Graubünden',
  7167: 'Graubünden',
  7168: 'Graubünden',
  7172: 'Graubünden',
  7173: 'Graubünden',
  7174: 'Graubünden',
  7175: 'Graubünden',
  7176: 'Graubünden',
  7180: 'Graubünden',
  7182: 'Graubünden',
  7183: 'Graubünden',
  7184: 'Graubünden',
  7185: 'Graubünden',
  7186: 'Graubünden',
  7187: 'Graubünden',
  7188: 'Graubünden',
  7189: 'Graubünden',
  7202: 'Graubünden',
  7203: 'Graubünden',
  7204: 'Graubünden',
  7205: 'Graubünden',
  7206: 'Graubünden',
  7208: 'Graubünden',
  7212: 'Graubünden',
  7213: 'Graubünden',
  7214: 'Graubünden',
  7215: 'Graubünden',
  7220: 'Graubünden',
  7222: 'Graubünden',
  7223: 'Graubünden',
  7224: 'Graubünden',
  7226: 'Graubünden',
  7228: 'Graubünden',
  7231: 'Graubünden',
  7232: 'Graubünden',
  7233: 'Graubünden',
  7235: 'Graubünden',
  7240: 'Graubünden',
  7241: 'Graubünden',
  7242: 'Graubünden',
  7243: 'Graubünden',
  7244: 'Graubünden',
  7245: 'Graubünden',
  7246: 'Graubünden',
  7247: 'Graubünden',
  7249: 'Graubünden',
  7250: 'Graubünden',
  7252: 'Graubünden',
  7260: 'Graubünden',
  7265: 'Graubünden',
  7270: 'Graubünden',
  7272: 'Graubünden',
  7276: 'Graubünden',
  7277: 'Graubünden',
  7278: 'Graubünden',
  7302: 'Graubünden',
  7303: 'Graubünden',
  7304: 'Graubünden',
  7306: 'Graubünden',
  7307: 'Graubünden',
  7310: 'St.Gallen',
  7312: 'St.Gallen',
  7313: 'St.Gallen',
  7314: 'St.Gallen',
  7315: 'St.Gallen',
  7317: 'St.Gallen',
  7320: 'St.Gallen',
  7323: 'St.Gallen',
  7324: 'St.Gallen',
  7325: 'St.Gallen',
  7326: 'St.Gallen',
  7402: 'Graubünden',
  7403: 'Graubünden',
  7404: 'Graubünden',
  7405: 'Graubünden',
  7407: 'Graubünden',
  7408: 'Graubünden',
  7411: 'Graubünden',
  7412: 'Graubünden',
  7413: 'Graubünden',
  7414: 'Graubünden',
  7415: 'Graubünden',
  7416: 'Graubünden',
  7417: 'Graubünden',
  7418: 'Graubünden',
  7419: 'Graubünden',
  7421: 'Graubünden',
  7422: 'Graubünden',
  7423: 'Graubünden',
  7424: 'Graubünden',
  7425: 'Graubünden',
  7426: 'Graubünden',
  7427: 'Graubünden',
  7428: 'Graubünden',
  7430: 'Graubünden',
  7431: 'Graubünden',
  7432: 'Graubünden',
  7433: 'Graubünden',
  7434: 'Graubünden',
  7435: 'Graubünden',
  7436: 'Graubünden',
  7437: 'Graubünden',
  7438: 'Graubünden',
  7440: 'Graubünden',
  7442: 'Graubünden',
  7443: 'Graubünden',
  7444: 'Graubünden',
  7445: 'Graubünden',
  7446: 'Graubünden',
  7447: 'Graubünden',
  7448: 'Graubünden',
  7450: 'Graubünden',
  7451: 'Graubünden',
  7452: 'Graubünden',
  7453: 'Graubünden',
  7454: 'Graubünden',
  7455: 'Graubünden',
  7456: 'Graubünden',
  7457: 'Graubünden',
  7458: 'Graubünden',
  7459: 'Graubünden',
  7460: 'Graubünden',
  7462: 'Graubünden',
  7463: 'Graubünden',
  7464: 'Graubünden',
  7472: 'Graubünden',
  7473: 'Graubünden',
  7477: 'Graubünden',
  7482: 'Graubünden',
  7484: 'Graubünden',
  7492: 'Graubünden',
  7493: 'Graubünden',
  7494: 'Graubünden',
  7500: 'Graubünden',
  7502: 'Graubünden',
  7503: 'Graubünden',
  7504: 'Graubünden',
  7505: 'Graubünden',
  7512: 'Graubünden',
  7513: 'Graubünden',
  7514: 'Graubünden',
  7515: 'Graubünden',
  7516: 'Graubünden',
  7517: 'Graubünden',
  7522: 'Graubünden',
  7523: 'Graubünden',
  7524: 'Graubünden',
  7525: 'Graubünden',
  7526: 'Graubünden',
  7527: 'Graubünden',
  7530: 'Graubünden',
  7532: 'Graubünden',
  7533: 'Graubünden',
  7534: 'Graubünden',
  7535: 'Graubünden',
  7536: 'Graubünden',
  7537: 'Graubünden',
  7542: 'Graubünden',
  7543: 'Graubünden',
  7545: 'Graubünden',
  7546: 'Graubünden',
  7550: 'Graubünden',
  7551: 'Graubünden',
  7552: 'Graubünden',
  7553: 'Graubünden',
  7554: 'Graubünden',
  7556: 'Graubünden',
  7557: 'Graubünden',
  7558: 'Graubünden',
  7559: 'Graubünden',
  7560: 'Graubünden',
  7562: 'Graubünden',
  7563: 'Graubünden',
  7602: 'Graubünden',
  7603: 'Graubünden',
  7604: 'Graubünden',
  7605: 'Graubünden',
  7606: 'Graubünden',
  7608: 'Graubünden',
  7610: 'Graubünden',
  7710: 'Graubünden',
  7741: 'Graubünden',
  7742: 'Graubünden',
  7743: 'Graubünden',
  7744: 'Graubünden',
  7745: 'Graubünden',
  7746: 'Graubünden',
  7747: 'Graubünden',
  7748: 'Graubünden',
  8001: 'Zürich',
  8002: 'Zürich',
  8003: 'Zürich',
  8004: 'Zürich',
  8005: 'Zürich',
  8006: 'Zürich',
  8008: 'Zürich',
  8032: 'Zürich',
  8037: 'Zürich',
  8038: 'Zürich',
  8041: 'Zürich',
  8044: 'Zürich',
  8045: 'Zürich',
  8046: 'Zürich',
  8047: 'Zürich',
  8048: 'Zürich',
  8049: 'Zürich',
  8050: 'Zürich',
  8051: 'Zürich',
  8052: 'Zürich',
  8053: 'Zürich',
  8055: 'Zürich',
  8057: 'Zürich',
  8064: 'Zürich',
  8102: 'Zürich',
  8103: 'Zürich',
  8104: 'Zürich',
  8105: 'Zürich',
  8106: 'Zürich',
  8107: 'Zürich',
  8108: 'Zürich',
  8109: 'Aargau',
  8112: 'Zürich',
  8113: 'Zürich',
  8114: 'Zürich',
  8115: 'Zürich',
  8117: 'Zürich',
  8118: 'Zürich',
  8121: 'Zürich',
  8122: 'Zürich',
  8123: 'Zürich',
  8124: 'Zürich',
  8125: 'Zürich',
  8126: 'Zürich',
  8127: 'Zürich',
  8132: 'Zürich',
  8133: 'Zürich',
  8134: 'Zürich',
  8135: 'Zürich',
  8136: 'Zürich',
  8142: 'Zürich',
  8143: 'Zürich',
  8152: 'Zürich',
  8153: 'Zürich',
  8154: 'Zürich',
  8155: 'Zürich',
  8156: 'Zürich',
  8157: 'Zürich',
  8158: 'Zürich',
  8162: 'Zürich',
  8164: 'Zürich',
  8165: 'Zürich',
  8166: 'Zürich',
  8172: 'Zürich',
  8173: 'Zürich',
  8174: 'Zürich',
  8175: 'Zürich',
  8180: 'Zürich',
  8181: 'Zürich',
  8182: 'Zürich',
  8184: 'Zürich',
  8185: 'Zürich',
  8187: 'Zürich',
  8192: 'Zürich',
  8193: 'Zürich',
  8194: 'Zürich',
  8195: 'Zürich',
  8196: 'Zürich',
  8197: 'Zürich',
  8200: 'Schaffhausen',
  8203: 'Schaffhausen',
  8207: 'Schaffhausen',
  8208: 'Schaffhausen',
  8212: 'Schaffhausen',
  8213: 'Schaffhausen',
  8214: 'Schaffhausen',
  8215: 'Schaffhausen',
  8216: 'Schaffhausen',
  8217: 'Schaffhausen',
  8218: 'Schaffhausen',
  8219: 'Schaffhausen',
  8222: 'Schaffhausen',
  8223: 'Schaffhausen',
  8224: 'Schaffhausen',
  8225: 'Schaffhausen',
  8226: 'Schaffhausen',
  8228: 'Schaffhausen',
  8231: 'Schaffhausen',
  8232: 'Schaffhausen',
  8233: 'Schaffhausen',
  8234: 'Schaffhausen',
  8235: 'Schaffhausen',
  8236: 'Schaffhausen',
  8239: 'Schaffhausen',
  8240: 'Schaffhausen',
  8241: 'Schaffhausen',
  8242: 'Schaffhausen',
  8243: 'Schaffhausen',
  8245: 'Zürich',
  8246: 'Zürich',
  8247: 'Zürich',
  8248: 'Zürich',
  8252: 'Thurgau',
  8253: 'Thurgau',
  8254: 'Thurgau',
  8255: 'Thurgau',
  8259: 'Thurgau',
  8260: 'Schaffhausen',
  8261: 'Schaffhausen',
  8262: 'Schaffhausen',
  8263: 'Schaffhausen',
  8264: 'Thurgau',
  8265: 'Thurgau',
  8266: 'Thurgau',
  8267: 'Thurgau',
  8268: 'Thurgau',
  8269: 'Thurgau',
  8272: 'Thurgau',
  8273: 'Thurgau',
  8274: 'Thurgau',
  8280: 'Thurgau',
  8302: 'Zürich',
  8303: 'Zürich',
  8304: 'Zürich',
  8305: 'Zürich',
  8306: 'Zürich',
  8307: 'Zürich',
  8308: 'Zürich',
  8309: 'Zürich',
  8310: 'Zürich',
  8311: 'Zürich',
  8312: 'Zürich',
  8314: 'Zürich',
  8315: 'Zürich',
  8317: 'Zürich',
  8320: 'Zürich',
  8322: 'Zürich',
  8330: 'Zürich',
  8331: 'Zürich',
  8332: 'Zürich',
  8335: 'Zürich',
  8340: 'Zürich',
  8342: 'Zürich',
  8344: 'Zürich',
  8345: 'Zürich',
  8352: 'Zürich',
  8353: 'Zürich',
  8354: 'Zürich',
  8355: 'Thurgau',
  8356: 'Thurgau',
  8357: 'Thurgau',
  8360: 'Thurgau',
  8362: 'Thurgau',
  8363: 'Thurgau',
  8370: 'Thurgau',
  8371: 'Thurgau',
  8372: 'Thurgau',
  8374: 'Thurgau',
  8376: 'Thurgau',
  8400: 'Zürich',
  8404: 'Zürich',
  8405: 'Zürich',
  8406: 'Zürich',
  8408: 'Zürich',
  8409: 'Zürich',
  8412: 'Zürich',
  8413: 'Zürich',
  8414: 'Zürich',
  8415: 'Zürich',
  8416: 'Zürich',
  8418: 'Zürich',
  8421: 'Zürich',
  8422: 'Zürich',
  8424: 'Zürich',
  8425: 'Zürich',
  8426: 'Zürich',
  8427: 'Zürich',
  8428: 'Zürich',
  8442: 'Zürich',
  8444: 'Zürich',
  8447: 'Zürich',
  8450: 'Zürich',
  8451: 'Zürich',
  8452: 'Zürich',
  8453: 'Zürich',
  8454: 'Schaffhausen',
  8455: 'Schaffhausen',
  8457: 'Zürich',
  8458: 'Zürich',
  8459: 'Zürich',
  8460: 'Zürich',
  8461: 'Zürich',
  8462: 'Zürich',
  8463: 'Zürich',
  8464: 'Zürich',
  8465: 'Zürich',
  8466: 'Zürich',
  8467: 'Zürich',
  8468: 'Zürich',
  8471: 'Zürich',
  8472: 'Zürich',
  8474: 'Zürich',
  8475: 'Zürich',
  8476: 'Zürich',
  8477: 'Zürich',
  8478: 'Zürich',
  8479: 'Zürich',
  8482: 'Zürich',
  8483: 'Zürich',
  8484: 'Zürich',
  8486: 'Zürich',
  8487: 'Zürich',
  8488: 'Zürich',
  8489: 'Zürich',
  8492: 'Zürich',
  8493: 'Zürich',
  8494: 'Zürich',
  8495: 'Zürich',
  8496: 'Zürich',
  8497: 'Zürich',
  8498: 'Zürich',
  8499: 'Zürich',
  8500: 'Thurgau',
  8505: 'Thurgau',
  8506: 'Thurgau',
  8507: 'Thurgau',
  8508: 'Thurgau',
  8512: 'Thurgau',
  8514: 'Thurgau',
  8522: 'Thurgau',
  8523: 'Zürich',
  8524: 'Thurgau',
  8525: 'Thurgau',
  8526: 'Thurgau',
  8532: 'Thurgau',
  8535: 'Thurgau',
  8536: 'Thurgau',
  8537: 'Thurgau',
  8542: 'Zürich',
  8543: 'Zürich',
  8544: 'Zürich',
  8545: 'Zürich',
  8546: 'Thurgau',
  8547: 'Thurgau',
  8548: 'Zürich',
  8552: 'Thurgau',
  8553: 'Thurgau',
  8554: 'Thurgau',
  8555: 'Thurgau',
  8556: 'Thurgau',
  8558: 'Thurgau',
  8560: 'Thurgau',
  8561: 'Thurgau',
  8564: 'Thurgau',
  8565: 'Thurgau',
  8566: 'Thurgau',
  8570: 'Thurgau',
  8572: 'Thurgau',
  8573: 'Thurgau',
  8574: 'Thurgau',
  8575: 'Thurgau',
  8576: 'Thurgau',
  8577: 'Thurgau',
  8580: 'Thurgau',
  8581: 'Thurgau',
  8582: 'Thurgau',
  8583: 'Thurgau',
  8584: 'Thurgau',
  8585: 'Thurgau',
  8586: 'Thurgau',
  8587: 'Thurgau',
  8588: 'Thurgau',
  8589: 'Thurgau',
  8590: 'Thurgau',
  8592: 'Thurgau',
  8593: 'Thurgau',
  8594: 'Thurgau',
  8595: 'Thurgau',
  8596: 'Thurgau',
  8597: 'Thurgau',
  8598: 'Thurgau',
  8599: 'Thurgau',
  8600: 'Zürich',
  8602: 'Zürich',
  8603: 'Zürich',
  8604: 'Zürich',
  8605: 'Zürich',
  8606: 'Zürich',
  8607: 'Zürich',
  8608: 'Zürich',
  8610: 'Zürich',
  8614: 'Zürich',
  8615: 'Zürich',
  8616: 'Zürich',
  8617: 'Zürich',
  8618: 'Zürich',
  8620: 'Zürich',
  8623: 'Zürich',
  8624: 'Zürich',
  8625: 'Zürich',
  8626: 'Zürich',
  8627: 'Zürich',
  8630: 'Zürich',
  8632: 'Zürich',
  8633: 'Zürich',
  8634: 'Zürich',
  8635: 'Zürich',
  8636: 'Zürich',
  8637: 'Zürich',
  8638: 'St.Gallen',
  8640: 'Schwyz',
  8645: 'St.Gallen',
  8646: 'St.Gallen',
  8700: 'Zürich',
  8702: 'Zürich',
  8703: 'Zürich',
  8704: 'Zürich',
  8706: 'Zürich',
  8707: 'Zürich',
  8708: 'Zürich',
  8712: 'Zürich',
  8713: 'Zürich',
  8714: 'Zürich',
  8715: 'St.Gallen',
  8716: 'St.Gallen',
  8717: 'St.Gallen',
  8718: 'St.Gallen',
  8722: 'St.Gallen',
  8723: 'St.Gallen',
  8725: 'St.Gallen',
  8726: 'St.Gallen',
  8727: 'St.Gallen',
  8730: 'St.Gallen',
  8732: 'St.Gallen',
  8733: 'St.Gallen',
  8734: 'St.Gallen',
  8735: 'St.Gallen',
  8737: 'St.Gallen',
  8738: 'St.Gallen',
  8739: 'St.Gallen',
  8750: 'Glarus',
  8751: 'Uri',
  8752: 'Glarus',
  8753: 'Glarus',
  8754: 'Glarus',
  8755: 'Glarus',
  8756: 'Glarus',
  8757: 'Glarus',
  8758: 'Glarus',
  8762: 'Glarus',
  8765: 'Glarus',
  8766: 'Glarus',
  8767: 'Glarus',
  8772: 'Glarus',
  8773: 'Glarus',
  8774: 'Glarus',
  8775: 'Glarus',
  8777: 'Glarus',
  8782: 'Glarus',
  8783: 'Glarus',
  8784: 'Glarus',
  8800: 'Zürich',
  8802: 'Zürich',
  8803: 'Zürich',
  8804: 'Zürich',
  8805: 'Zürich',
  8806: 'Schwyz',
  8807: 'Schwyz',
  8808: 'Schwyz',
  8810: 'Zürich',
  8815: 'Zürich',
  8816: 'Zürich',
  8820: 'Zürich',
  8824: 'Zürich',
  8825: 'Zürich',
  8832: 'Schwyz',
  8833: 'Zürich',
  8834: 'Schwyz',
  8835: 'Schwyz',
  8836: 'Schwyz',
  8840: 'Schwyz',
  8841: 'Schwyz',
  8842: 'Schwyz',
  8843: 'Schwyz',
  8844: 'Schwyz',
  8845: 'Schwyz',
  8846: 'Schwyz',
  8847: 'Schwyz',
  8849: 'Schwyz',
  8852: 'Schwyz',
  8853: 'Schwyz',
  8854: 'Schwyz',
  8855: 'Schwyz',
  8856: 'Schwyz',
  8857: 'Schwyz',
  8858: 'Schwyz',
  8862: 'Schwyz',
  8863: 'Schwyz',
  8864: 'Schwyz',
  8865: 'Glarus',
  8866: 'Glarus',
  8867: 'Glarus',
  8868: 'Glarus',
  8872: 'St.Gallen',
  8873: 'St.Gallen',
  8874: 'Glarus',
  8877: 'St.Gallen',
  8878: 'St.Gallen',
  8880: 'St.Gallen',
  8881: 'St.Gallen',
  8882: 'St.Gallen',
  8883: 'St.Gallen',
  8884: 'St.Gallen',
  8885: 'St.Gallen',
  8886: 'St.Gallen',
  8887: 'St.Gallen',
  8888: 'St.Gallen',
  8889: 'St.Gallen',
  8890: 'St.Gallen',
  8892: 'St.Gallen',
  8893: 'St.Gallen',
  8894: 'St.Gallen',
  8895: 'St.Gallen',
  8896: 'St.Gallen',
  8897: 'St.Gallen',
  8898: 'St.Gallen',
  8902: 'Zürich',
  8903: 'Zürich',
  8904: 'Zürich',
  8905: 'Aargau',
  8906: 'Zürich',
  8907: 'Zürich',
  8908: 'Zürich',
  8909: 'Zürich',
  8910: 'Zürich',
  8911: 'Zürich',
  8912: 'Zürich',
  8913: 'Zürich',
  8914: 'Zürich',
  8915: 'Zürich',
  8916: 'Aargau',
  8917: 'Aargau',
  8918: 'Aargau',
  8919: 'Aargau',
  8925: 'Zürich',
  8926: 'Zürich',
  8932: 'Zürich',
  8933: 'Zürich',
  8934: 'Zürich',
  8942: 'Zürich',
  8951: 'Zürich',
  8952: 'Zürich',
  8953: 'Zürich',
  8954: 'Zürich',
  8955: 'Zürich',
  8956: 'Aargau',
  8957: 'Aargau',
  8962: 'Aargau',
  8964: 'Aargau',
  8965: 'Aargau',
  8966: 'Aargau',
  8967: 'Aargau',
  9000: 'St.Gallen',
  9008: 'St.Gallen',
  9010: 'St.Gallen',
  9011: 'St.Gallen',
  9012: 'St.Gallen',
  9014: 'St.Gallen',
  9015: 'St.Gallen',
  9016: 'St.Gallen',
  9030: 'St.Gallen',
  9032: 'St.Gallen',
  9033: 'St.Gallen',
  9034: 'St.Gallen',
  9035: 'Appenzell Ausserrhoden',
  9036: 'St.Gallen',
  9037: 'Appenzell Ausserrhoden',
  9038: 'Appenzell Ausserrhoden',
  9042: 'Appenzell Ausserrhoden',
  9043: 'Appenzell Ausserrhoden',
  9044: 'Appenzell Ausserrhoden',
  9050: 'Appenzell Innerrhoden',
  9052: 'Appenzell Ausserrhoden',
  9053: 'Appenzell Ausserrhoden',
  9054: 'Appenzell Innerrhoden',
  9055: 'Appenzell Ausserrhoden',
  9056: 'Appenzell Ausserrhoden',
  9057: 'Appenzell Innerrhoden',
  9058: 'Appenzell Innerrhoden',
  9062: 'Appenzell Ausserrhoden',
  9063: 'Appenzell Ausserrhoden',
  9064: 'Appenzell Ausserrhoden',
  9100: 'Appenzell Ausserrhoden',
  9103: 'Appenzell Ausserrhoden',
  9104: 'Appenzell Ausserrhoden',
  9105: 'Appenzell Ausserrhoden',
  9107: 'Appenzell Ausserrhoden',
  9108: 'Appenzell Innerrhoden',
  9112: 'Appenzell Ausserrhoden',
  9113: 'St.Gallen',
  9114: 'St.Gallen',
  9115: 'St.Gallen',
  9116: 'St.Gallen',
  9122: 'St.Gallen',
  9123: 'St.Gallen',
  9125: 'St.Gallen',
  9126: 'St.Gallen',
  9127: 'St.Gallen',
  9200: 'St.Gallen',
  9203: 'St.Gallen',
  9204: 'St.Gallen',
  9205: 'St.Gallen',
  9212: 'St.Gallen',
  9213: 'Thurgau',
  9214: 'Thurgau',
  9215: 'Thurgau',
  9216: 'Thurgau',
  9217: 'Thurgau',
  9220: 'Thurgau',
  9223: 'Thurgau',
  9225: 'Thurgau',
  9230: 'St.Gallen',
  9231: 'St.Gallen',
  9240: 'St.Gallen',
  9242: 'St.Gallen',
  9243: 'St.Gallen',
  9244: 'St.Gallen',
  9245: 'St.Gallen',
  9246: 'St.Gallen',
  9247: 'St.Gallen',
  9248: 'St.Gallen',
  9249: 'St.Gallen',
  9300: 'St.Gallen',
  9304: 'St.Gallen',
  9305: 'St.Gallen',
  9306: 'Thurgau',
  9308: 'St.Gallen',
  9312: 'St.Gallen',
  9313: 'St.Gallen',
  9314: 'Thurgau',
  9315: 'Thurgau',
  9320: 'Thurgau',
  9322: 'Thurgau',
  9323: 'St.Gallen',
  9325: 'Thurgau',
  9326: 'Thurgau',
  9327: 'St.Gallen',
  9400: 'St.Gallen',
  9402: 'St.Gallen',
  9403: 'St.Gallen',
  9404: 'St.Gallen',
  9405: 'Appenzell Ausserrhoden',
  9410: 'Appenzell Ausserrhoden',
  9411: 'St.Gallen',
  9413: 'Appenzell Innerrhoden',
  9422: 'St.Gallen',
  9423: 'St.Gallen',
  9424: 'St.Gallen',
  9425: 'St.Gallen',
  9426: 'Appenzell Ausserrhoden',
  9427: 'Appenzell Ausserrhoden',
  9428: 'Appenzell Ausserrhoden',
  9430: 'St.Gallen',
  9434: 'St.Gallen',
  9435: 'St.Gallen',
  9436: 'St.Gallen',
  9437: 'St.Gallen',
  9442: 'St.Gallen',
  9443: 'St.Gallen',
  9444: 'St.Gallen',
  9445: 'St.Gallen',
  9450: 'St.Gallen',
  9451: 'St.Gallen',
  9452: 'St.Gallen',
  9453: 'St.Gallen',
  9462: 'St.Gallen',
  9463: 'St.Gallen',
  9464: 'St.Gallen',
  9465: 'St.Gallen',
  9466: 'St.Gallen',
  9467: 'St.Gallen',
  9468: 'St.Gallen',
  9469: 'St.Gallen',
  9470: 'St.Gallen',
  9472: 'St.Gallen',
  9473: 'St.Gallen',
  9475: 'St.Gallen',
  9476: 'St.Gallen',
  9477: 'St.Gallen',
  9478: 'St.Gallen',
  9479: 'St.Gallen',
  9485: 'Lichtenstein',
  9486: 'Lichtenstein',
  9487: 'Lichtenstein',
  9488: 'Lichtenstein',
  9490: 'Lichtenstein',
  9491: 'Lichtenstein',
  9492: 'Lichtenstein',
  9493: 'Lichtenstein',
  9494: 'Lichtenstein',
  9495: 'Lichtenstein',
  9496: 'Lichtenstein',
  9497: 'Lichtenstein',
  9498: 'Lichtenstein',
  9500: 'St.Gallen',
  9502: 'Thurgau',
  9503: 'Thurgau',
  9504: 'Thurgau',
  9506: 'Thurgau',
  9507: 'Thurgau',
  9508: 'Thurgau',
  9512: 'St.Gallen',
  9514: 'Thurgau',
  9515: 'Thurgau',
  9517: 'Thurgau',
  9523: 'St.Gallen',
  9524: 'St.Gallen',
  9525: 'St.Gallen',
  9526: 'St.Gallen',
  9527: 'St.Gallen',
  9532: 'St.Gallen',
  9533: 'St.Gallen',
  9534: 'St.Gallen',
  9535: 'Thurgau',
  9536: 'St.Gallen',
  9542: 'Thurgau',
  9543: 'Thurgau',
  9545: 'Thurgau',
  9546: 'Thurgau',
  9547: 'Thurgau',
  9548: 'Thurgau',
  9552: 'St.Gallen',
  9553: 'Thurgau',
  9554: 'Thurgau',
  9555: 'Thurgau',
  9556: 'Thurgau',
  9562: 'Thurgau',
  9565: 'Thurgau',
  9573: 'Thurgau',
  9601: 'St.Gallen',
  9602: 'St.Gallen',
  9604: 'St.Gallen',
  9606: 'St.Gallen',
  9607: 'St.Gallen',
  9608: 'St.Gallen',
  9612: 'St.Gallen',
  9613: 'St.Gallen',
  9614: 'St.Gallen',
  9615: 'St.Gallen',
  9620: 'St.Gallen',
  9621: 'St.Gallen',
  9622: 'St.Gallen',
  9630: 'St.Gallen',
  9631: 'St.Gallen',
  9633: 'St.Gallen',
  9642: 'St.Gallen',
  9643: 'St.Gallen',
  9650: 'St.Gallen',
  9651: 'St.Gallen',
  9652: 'St.Gallen',
  9655: 'St.Gallen',
  9656: 'St.Gallen',
  9657: 'St.Gallen',
  9658: 'St.Gallen',
  9999: 'Bern',
};
