import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { getUnseenAdminNotifications, markAdminNotificationAsSeen } from './service';
import { AdminNotification, MarkAdminNotificationAsSeenCommand } from './types';

interface AdminNotificationBannerState {
  adminNotifications: AdminNotification[];

  getUnseenAdminNotificationsStatus: ActionStatus;
  markAdminNotificationAsSeenStatus: ActionStatus;
}

function initialState(): AdminNotificationBannerState {
  return {
    adminNotifications: [],

    getUnseenAdminNotificationsStatus: ActionStatus.None,
    markAdminNotificationAsSeenStatus: ActionStatus.None,
  };
}

export const useAdminNotificationBannerStore = defineStore('adminNotificationBanner', {
  state: (): AdminNotificationBannerState => initialState(),
  getters: {
    isGetUnseenAdminNotificationsProcessing: (state: AdminNotificationBannerState): boolean =>
      state.getUnseenAdminNotificationsStatus === ActionStatus.InProgress,
    isMarkAdminNotificationAsSeenProcessing: (state: AdminNotificationBannerState): boolean =>
      state.markAdminNotificationAsSeenStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getUnseenAdminNotifications(): Promise<void> {
      const { getUnseenAdminNotificationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUnseenAdminNotificationsStatus,
        () => getUnseenAdminNotifications(attachFarmAndUserProperties({}))
          .then(async (adminNotifications) => {
            this.adminNotifications = adminNotifications;
          })
      );
    },

    // -- Commands

    markAdminNotificationAsSeen(command: MarkAdminNotificationAsSeenCommand): Promise<void> {
      const { markAdminNotificationAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markAdminNotificationAsSeenStatus,
        () => markAdminNotificationAsSeen(attachFarmAndUserProperties(command))
          .then(() => this.getUnseenAdminNotifications())
      );
    },

  },
});
