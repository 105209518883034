
import { Component, Vue } from 'vue-property-decorator';
import { useAppStore } from '@/application/app/store';
import { ActionStatus } from '@/application/types';
import { errorMessageFromError } from '@/application/snackbar/service';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { useEventAsExternalGuestsStore } from '../store';
import { ConfirmEventRegistrationCancellationAsExternalGuestCommand } from '../types';

@Component
export default class EventRegistrationCancellationConfirmation extends Vue {

  readonly store = useEventAsExternalGuestsStore();
  readonly appStore = useAppStore();

  errorMessage = '';

  get isSuccessVisible(): boolean {
    return this.store.confirmEventRegistrationCancellationAsExternalGuestStatus === ActionStatus.Successful;
  }

  get isFailureAlertVisible(): boolean {
    return this.store.confirmEventRegistrationCancellationAsExternalGuestStatus === ActionStatus.Failed;
  }

  get isRetryButtonVisible(): boolean {
    return this.store.confirmEventRegistrationCancellationAsExternalGuestStatus === ActionStatus.Failed
      && isNativeApplication();
  }

  mounted(): void {
    this.confirmEventRegistrationCancellationAsExternalGuest();
  }

  confirmEventRegistrationCancellationAsExternalGuest(): void {
    const command: ConfirmEventRegistrationCancellationAsExternalGuestCommand = {
      token: this.$route.params.token,
    };

    this.store.confirmEventRegistrationCancellationAsExternalGuest(command)
      .then(() => this.appStore.updateTitle('Stornierung bestätigt'))
      .catch((error) => {
        this.appStore.updateTitle('Stornierung fehlgeschlagen')
          .catch(() => {});
        this.errorMessage = errorMessageFromError(error);
      });
  }

}
