
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BlurHashImage, DialogWidth } from '@/helpers/data';

@Component
export default class BlurHashImageDialog extends Vue {

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: Object, default: null })
  readonly image!: BlurHashImage | null;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  public show(): void {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
