
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { navigate } from '@/helpers/navigation-helpers';
import { ResetPasswordCommand } from '@/application/authentication/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, identicalStringFormControlRule } from '@/components/form';

interface Controls extends FormControls {
  password: FormControl<string>;
  passwordRepeat: FormControl<string>;
}

@Component
export default class ResetPassword extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        password: {
          label: 'Passwort',
          value: null,
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'passwordRepeat',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'passwordRepeat',
          ],
        },
        passwordRepeat: {
          label: 'Passwort wiederholen',
          value: null,
          isRequired: true,
          rules: [],
          validateFormControlsOnInput: [
            'password',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'password',
          ],
        },
      },
    });

    form.controls.password.rules!.push(identicalStringFormControlRule(form.controls.passwordRepeat, 'Passwörter müssen übereinstimmen'));
    form.controls.passwordRepeat.rules!.push(identicalStringFormControlRule(form.controls.password, 'Passwörter müssen übereinstimmen'));

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: ResetPasswordCommand = {
      token: this.$route.params.token,
      password: formValues.password!,
    };

    this.authenticationStore.resetPassword(command)
      .then(() => navigate({ name: 'root' }))
      .catch((error) => showErrorResponse(error));
  }

}
