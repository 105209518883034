
import { Component, Vue, Prop } from 'vue-property-decorator';
import Dropzone, { DropzoneFile } from 'dropzone';
import { showErrorMessage } from '@/application/snackbar/service';

Dropzone.autoDiscover = false;

@Component
export default class DropzoneUpload extends Vue {

  @Prop({ default: 'dropzone' })
  readonly id!: string;

  @Prop({ default: 'Datei auswählen' })
  readonly emptyText!: string;

  @Prop({ default: 'Enthält bereits eine Datei' })
  readonly oldFileText!: string;

  @Prop({ default: 'Datei ausgewählt' })
  readonly selectedText!: string;

  @Prop({ default: 'Datei hochgeladen' })
  readonly uploadedText!: string;

  @Prop({ default: 'Bild' })
  readonly allowedFormatText!: string;

  @Prop({ default: false })
  readonly hasOldFile!: boolean;

  @Prop({ default: false })
  readonly selected!: boolean;

  @Prop({ default: false })
  readonly uploaded!: boolean;

  @Prop({ default() {
    return [
      'image/jpeg',
      'image/jpg',
      'image/gif',
      'image/png',
    ];
  },
  })
  readonly acceptedMimeTypes!: string[];

  @Prop({ type: Number, default: 10 })
  readonly maxFileSize!: number;

  isDraggingOver = false;

  get statusClasses(): any[] {
    const classes = ['dropzone-element'];

    if (!this.uploaded && !this.selected && this.hasOldFile) {
      classes.push('old-file');
    } else if (this.uploaded && !this.selected) {
      classes.push('file-uploaded');
    } else if (this.selected) {
      classes.push('file-selected');
    }

    if (this.isDraggingOver) {
      classes.push('dragging-over');
    }

    return classes;
  }

  mounted(): void {
    // @ts-ignore
    const dropzone = new Dropzone(this.$refs[this.id], {
      url: '-',
      acceptedFiles: this.acceptedMimeTypes.join(','),
    });

    dropzone.on('addedfile', (file: DropzoneFile) => {
      this.addFile(file);
      dropzone.removeFile(file);
    });
  }

  removeFile(): void {
    this.$emit('remove-file');
  }

  addFile(file: DropzoneFile): void {
    if (file.size / 1024 / 1024 > this.maxFileSize) {
      showErrorMessage(`Die Datei darf maximal ${this.maxFileSize}MB groß sein`);
      return;
    }

    if (!this.acceptedMimeTypes.includes(file.type)) {
      showErrorMessage('Die Datei ist nicht im richtigen Format');
      return;
    }

    this.$emit('upload-file', file);
  }
}
