
import { Component, Ref, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { useMyStableStore } from '../store';
import { AcceptHorseShareInviteCommand, RejectHorseShareInviteCommand, HorseShareInviteAsInvitedUser } from '../types';
import HorseCard from './horse-card.vue';
import UpdatePhoneNumberDialog from './update-phone-number-dialog.vue';
import CreateHorseDialog from './create-horse-dialog.vue';

@Component({
  components: {
    HorseCard,
    UpdatePhoneNumberDialog,
    CreateHorseDialog,
  },
})
export default class Horses extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();

  @Ref('createHorseDialog')
  readonly createHorseDialog!: CreateHorseDialog;

  get isNoHorseShareInvitesMessageVisible(): boolean {
    return this.store.horseShareInvitesAsInvitedUser.length === 0
      && !this.store.isGetHorseShareInvitesAsInvitedUserProcessing;
  }

  get isUpdatePhoneNumberDialogVisible(): boolean {
    return !this.authenticationStore.user!.phone;
  }

  get isCreateHorseAsPrimaryVisible(): boolean {
    return !!this.authenticationStore.user!.phone
      && this.store.ownAndSharedHorses.length === 0;
  }

  get isCreateHorseAsSecondaryVisible(): boolean {
    return !!this.authenticationStore.user!.phone
      && this.store.ownAndSharedHorses.length > 0;
  }

  mounted(): void {
    this.store.getHorseShareInvitesAsInvitedUser()
      .catch((error) => showErrorResponse(error));
  }

  async acceptHorseShareInviteAction(horseShareInvite: HorseShareInviteAsInvitedUser): Promise<void> {
    const command: AcceptHorseShareInviteCommand = {
      horseShareInviteId: horseShareInvite.horseShareInviteId,
    };

    return this.store.acceptHorseShareInvite(command)
      .then(() => showSuccessMessage('Die Einladung wurde angenommen.'))
      .catch((error) => showErrorResponse(error));
  }

  async rejectHorseShareInviteAction(horseShareInvite: HorseShareInviteAsInvitedUser): Promise<void> {
    const command: RejectHorseShareInviteCommand = {
      horseShareInviteId: horseShareInvite.horseShareInviteId,
    };

    return this.store.rejectHorseShareInvite(command)
      .then(() => showSuccessMessage('Die Einladung wurde abgelehnt.'))
      .catch((error) => showErrorResponse(error));
  }

  phoneNumberUpdated(): void {
    this.createHorseDialog.show();
  }

}
