import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import CurrentEvent from './components/current-event.vue';
import EventRegistrationConfirmation from './components/event-registration-confirmation.vue';
import EventRegistrationCancellationConfirmation from './components/event-registration-cancellation-confirmation.vue';
import EventRegistrationCancellationStart from './components/event-registration-cancellation-start.vue';

export const eventAsExternalGuestsRoutes: RouteConfig[] = [
  // Warning: Path is used in emails (see ClientURLGenerator)
  {
    name: 'announcements-event-for-external-guests',
    path: '/veranstaltung-als-gast/:eventId',
    component: CurrentEvent,
    meta: {
      title: 'Veranstaltung',
      accessibleFor: RouteAccessibility.ALWAYS,
    },
  },
  // Warning: Path is used in emails (see ClientURLGenerator)
  {
    name: 'announcements-event-registration-confirmation',
    path: '/veranstaltung-als-gast/anmeldung-bestaetigen/:eventId/:token',
    component: EventRegistrationConfirmation,
    meta: {
      title: 'Anmeldung bestätigen',
      accessibleFor: RouteAccessibility.ALWAYS,
    },
  },
  // Warning: Path is used in emails (see ClientURLGenerator)
  {
    name: 'announcements-event-registration-cancellation-start',
    path: '/veranstaltung-als-gast/stornierung-starten/:eventId/:eventRegistrationId',
    component: EventRegistrationCancellationStart,
    meta: {
      title: 'Stornieren',
      accessibleFor: RouteAccessibility.ALWAYS,
    },
  },
  // Warning: Path is used in emails (see ClientURLGenerator)
  {
    name: 'announcements-event-registration-cancellation-confirmation',
    path: '/veranstaltung-als-gast/stornierung-bestaetigen/:eventId/:token',
    component: EventRegistrationCancellationConfirmation,
    meta: {
      title: 'Stornierung bestätigen',
      accessibleFor: RouteAccessibility.ALWAYS,
    },
  },
];
