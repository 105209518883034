
import { Component, Vue } from 'vue-property-decorator';
import { TokenStorage } from '@/application/authentication/token-storage';
import { useAuthenticationStore } from '@/application/authentication/store';
import { formatName } from '@/helpers';

@Component
export default class ImpersonationBanner extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  get isImpersonationAlertVisible(): boolean {
    return this.authenticationStore.wasInitialAuthenticationAttempted
      && this.authenticationStore.isAuthenticated
      && TokenStorage.isImpersonatedUser();
  }

  get impersonationMessageHTML(): string {
    // eslint-disable-next-line max-len
    return `Du hast derzeit das Profil des Benutzers <strong>${formatName(this.authenticationStore.user!.name)}</strong> übernommen.`;
  }

}
