import { Repetition, Date } from '@/types';
import { timeConfigurationTranslation, weekdayTranslations } from '@/helpers/translations';
import { formatDate, formatTime } from '@/helpers';
import { RecentOrOutdatedTask, TaskForGroupedByHorse, TaskForGroupedByUser } from './types';

/**
 * The notes should look as close as possible but are slightly different by design, so they are all managed here to not overlook one variant
 * when changing one of the notes.
 */

export function taskNoteForRecentOrOutDatedTask(task: RecentOrOutdatedTask): string {
  let note = '';

  if (task.repetition) {
    const repetitionTranslation = task.repetition.repetition === Repetition.DAILY
      ? 'täglich'
      : 'wöchentlich';
    const weekdayTranslation = task.repetition.weekdays
      ? ''.concat(
        ' (',
        task.repetition.weekdays
          .map((weekday) => weekdayTranslations[weekday])
          .join(', '),
        ')'
      )
      : '';

    note += timeConfigurationTranslation(task.timeConfiguration, true)
      ? `Wiederholt sich ${repetitionTranslation}${weekdayTranslation} ${timeConfigurationTranslation(task.timeConfiguration, true)}`
      : `Wiederholt sich ${repetitionTranslation}${weekdayTranslation}`;

    if (task.repetition.lastRepetitionAt) {
      note += `, letzte Wiederholung am ${formatDate(task.repetition.lastRepetitionAt)}`;
    }
  } else {
    note += timeConfigurationTranslation(task.timeConfiguration, true)
      ? `Einmalig am ${formatDate(task.executionDate)} ${timeConfigurationTranslation(task.timeConfiguration, true)}`
      : `Einmalig am ${formatDate(task.executionDate)}`;
  }

  if (task.nameOfAssignedUser) {
    note += `, zugewiesen an ${task.nameOfAssignedUser}`;
  }

  return note;
}

export function taskNoteForTaskGroupedByUser(task: TaskForGroupedByUser): string {
  let note = '';

  if (task.repetition) {
    const repetitionTranslation = task.repetition.repetition === Repetition.DAILY
      ? 'täglich'
      : 'wöchentlich';
    const weekdayTranslation = task.repetition.weekdays
      ? ''.concat(
        ' (',
        task.repetition.weekdays
          .map((weekday) => weekdayTranslations[weekday])
          .join(', '),
        ')'
      )
      : '';

    note += timeConfigurationTranslation(task.timeConfiguration, true)
      ? `Wiederholt sich ${repetitionTranslation}${weekdayTranslation} ${timeConfigurationTranslation(task.timeConfiguration, true)}`
      : `Wiederholt sich ${repetitionTranslation}${weekdayTranslation}`;

    if (task.repetition.lastRepetitionAt) {
      note += `, letzte Wiederholung am ${formatDate(task.repetition.lastRepetitionAt)}`;
    }
  } else {
    note += timeConfigurationTranslation(task.timeConfiguration, true)
      ? `Einmalig am ${formatDate(task.executionDate)} ${timeConfigurationTranslation(task.timeConfiguration, true)}`
      : `Einmalig am ${formatDate(task.executionDate)}`;
  }

  if (task.completedAt) {
    /**
     * It's possible that a task that was meant to be completed on a specific date, is only completed afterward. In those cases we need to
     * show the date it was completed as well.
     */
    note += !task.repetition
      && task.executionDate.isNotEqualTo(Date.fromMoment(task.completedAt))
      ? `, erledigt am ${formatDate(task.completedAt)} um ${formatTime(task.completedAt)} Uhr von ${task.completedByName}`
      : `, erledigt um ${formatTime(task.completedAt)} Uhr von ${task.completedByName}`;
  }

  return note;
}

export function taskNoteForTaskGroupedByHorse(task: TaskForGroupedByHorse): string {
  let note = '';

  if (task.repetition) {
    const repetitionTranslation = task.repetition.repetition === Repetition.DAILY
      ? 'täglich'
      : 'wöchentlich';
    const weekdayTranslation = task.repetition.weekdays
      ? ''.concat(
        ' (',
        task.repetition.weekdays
          .map((weekday) => weekdayTranslations[weekday])
          .join(', '),
        ')'
      )
      : '';

    note += timeConfigurationTranslation(task.timeConfiguration, true)
      ? `Wiederholt sich ${repetitionTranslation}${weekdayTranslation} ${timeConfigurationTranslation(task.timeConfiguration, true)}`
      : `Wiederholt sich ${repetitionTranslation}${weekdayTranslation}`;

    if (task.repetition.lastRepetitionAt) {
      note += `, letzte Wiederholung am ${formatDate(task.repetition.lastRepetitionAt)}`;
    }
  } else {
    note += timeConfigurationTranslation(task.timeConfiguration, true)
      ? `Einmalig am ${formatDate(task.executionDate)} ${timeConfigurationTranslation(task.timeConfiguration, true)}`
      : `Einmalig am ${formatDate(task.executionDate)}`;
  }

  if (task.nameOfAssignedUser) {
    note += `, zugewiesen an ${task.nameOfAssignedUser}`;
  }

  if (task.completedAt) {
    /**
     * It's possible that a task that was meant to be completed on a specific date, is only completed afterward. In those cases we need to
     * show the date it was completed as well.
     */
    note += !task.repetition
      && task.executionDate.isNotEqualTo(Date.fromMoment(task.completedAt))
      ? `, erledigt am ${formatDate(task.completedAt)} um ${formatTime(task.completedAt)} Uhr von ${task.completedByName}`
      : `, erledigt um ${formatTime(task.completedAt)} Uhr von ${task.completedByName}`;
  }

  return note;
}
