
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FormControlMessages extends Vue {

  @Prop({ type: Array, required: true })
  readonly messages!: string[];

  @Prop({ type: Boolean, required: true })
  readonly isFocussed!: boolean;

  @Prop({ type: Boolean, required: true })
  readonly isTouched!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isMarkedAsMessagesForcedVisible!: boolean;

  get areMessagesShown(): boolean {
    return this.isTouched
      || this.isFocussed
      || this.isMarkedAsMessagesForcedVisible;
  }

  get hasAtLeastOneMessage(): boolean {
    return this.messages.length > 0;
  }

  get firstMessage(): string | null {
    const [firstErrorMessage] = this.messages;

    return firstErrorMessage ?? null;
  }

}
