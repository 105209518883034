
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Time, NumberRange, TimeFrame } from '@/types';
import { VuetifySelectItem } from '@/application/types';
import { generateTimeRangeSelectItems } from '@/helpers/form-helpers';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class NumberRangeIntervalFormControl extends Vue implements FormControlComponent<NumberRange> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<NumberRange>;

  @Prop({ type: Number, default: 15 })
  readonly intervalMinutes!: number;

  @Prop({ type: Object, default: null })
  readonly timeFrame!: TimeFrame | null;

  readonly formControlId = createFormControlId();

  readonly internalTimeFromRules = [
    this.fromBeforeToRule(),
  ];
  readonly internalTimeToRules = [
    this.toAfterFromRule(),
  ];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalNumberFrom: number | null = null;
  internalNumberTo: number | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get timesSelectItems(): VuetifySelectItem<number>[] {
    const timeFrame: TimeFrame = this.timeFrame ?? {
        timeFrom: new Time(5, 0, 0),
        timeTo: new Time(0, 0, 0),
      };

    return generateTimeRangeSelectItems(timeFrame.timeFrom, timeFrame.timeTo, this.intervalMinutes);
  }

  mounted(): void {
    mountFormControl(this);
  }

  timeFromChanged(): void {
    internalValuesChanged(this);
  }

  timeToChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  fromBeforeToRule(): InternalValueRule<number> {
    return (value: number | null) => value === null
      || this.internalNumberTo === null
      || value <= this.internalNumberTo
      || 'Die minimale Zeit darf nicht über der maximalen Zeit liegen';
  }

  toAfterFromRule(): InternalValueRule<number> {
    return (value: number | null) => value === null
      || this.internalNumberFrom === null
      || value >= this.internalNumberFrom
      || 'Die maximale Zeit darf nicht unter der minimalen Zeit liegen';
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.internalTimeFromRules, this.internalNumberFrom),
      ...errorMessagesForInternalRules(this.internalTimeToRules, this.internalNumberTo),
    ];

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    this.internalNumberFrom = this.formControl.value?.from ?? null;
    this.internalNumberTo = this.formControl.value?.to ?? null;
  }

  formValueFromInternalValues(): FormControlValue<NumberRange> {
    return this.internalNumberFrom !== null
      && this.internalNumberTo !== null
      ? {
        from: this.internalNumberFrom!,
        to: this.internalNumberTo!,
      }
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
