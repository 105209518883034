import { Moment } from '@/types';
import { moment } from '@/helpers';

export function midnightSevenDaysAgo(): Moment {
  return moment()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .subtract(7, 'days');
}
