import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { AddHorseToTimeRangeAsManagerCommand, CreatePaddockAsManagerCommand, CreateTimeRangeAsManagerCommand, DeletePaddockAsManagerCommand, DeleteTimeRangeAsManagerCommand, PaddockPlan, RemoveHorseFromTimeRangeAsManagerCommand, UpdatePaddockAsManagerCommand, UpdateTimeRangeAsManagerCommand } from './types';

// -- Queries

export function getPaddockPlan(query: FarmAndUserProperties): Promise<PaddockPlan> {
  const url = `${apiUrl}/api/paddock-plan/get-paddock-plan-as-manager-query`;
  return performApiRequest<PaddockPlan>({ url, method: 'POST', data: query });
}

// -- Commands

export function createPaddock(command: CreatePaddockAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/create-paddock-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePaddock(command: UpdatePaddockAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/update-paddock-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deletePaddock(command: DeletePaddockAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/delete-paddock-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createTimeRange(command: CreateTimeRangeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/create-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateTimeRange(command: UpdateTimeRangeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/update-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteTimeRange(command: DeleteTimeRangeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/delete-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function addHorseToTimeRange(command: AddHorseToTimeRangeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/assign-horse-to-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function removeHorseFromTimeRange(command: RemoveHorseFromTimeRangeAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/paddock-plan/remove-horse-from-time-range-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
