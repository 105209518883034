import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { getPdfFile } from './service';

interface PdfDialogState {
  getPdfFileStatus: ActionStatus;
}

function initialState(): PdfDialogState {
  return {
    getPdfFileStatus: ActionStatus.None,
  };
}

export const usePdfDialogStore = defineStore('pdfDialog', {
  state: (): PdfDialogState => initialState(),
  getters: {
    isGetFileProcessing: (state: PdfDialogState): boolean =>
      state.getPdfFileStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getPdfFile(url: string): Promise<FileResponse> {
      const { getPdfFileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPdfFileStatus,
        () => getPdfFile(url)
      );
    },

  },
});
