import { getLocalStorageItem, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { useAppStore } from '@/application/app/store';
import { moment } from '@/helpers';

function setAppVersionReloadTimestamp() {
  const currentTime = moment().format();
  setLocalStorageItem('last_app_version_reload', currentTime);
}

function isAppReloadNeeded(): boolean {
  const appStore = useAppStore();
  const lastAppVersionReloadTime = getLocalStorageItem('last_app_version_reload');
  const wasLastReloadAMinuteAgo = !lastAppVersionReloadTime
    || moment().subtract(60, 'seconds').isAfter(lastAppVersionReloadTime);

  return appStore.isOutdated && wasLastReloadAMinuteAgo;
}

export function appVersionGuard() {
  if (isAppReloadNeeded()) {
    setAppVersionReloadTimestamp();

    // Requires timeout to ensure that the reload happens on the new route
    setTimeout(() => window.location.reload(), 100);
  }
}
