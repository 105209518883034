
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Time, TimeFrame } from '@/types';
import { VuetifySelectItem } from '@/application/types';
import { generateTimesSelectItems } from '@/helpers/form-helpers';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class TimeFrameFormControl extends Vue implements FormControlComponent<TimeFrame> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<TimeFrame>;

  @Prop({ type: Number, default: 15 })
  readonly intervalMinutes!: number;

  @Prop({ type: Object, default: null })
  readonly timeFrame!: TimeFrame | null;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  readonly formControlId = createFormControlId();

  readonly internalTimeFromRules = [
    this.timeFromMustBeSetWhenTimeToIsSetRule(),
    this.fromBeforeToRule(),
  ];
  readonly internalTimeToRules = [
    this.timeTOMustBeSetWhenTimeFromIsSetRule(),
    this.toAfterFromRule(),
  ];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalTimeFrom: Time | null = null;
  internalTimeTo: Time | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get timesSelectItems(): VuetifySelectItem<Time>[] {
    const relevantTimeFrame = this.timeFrame ?? {
        timeFrom: new Time(5, 0, 0),
        timeTo: new Time(0, 0, 0),
      };

    return generateTimesSelectItems(relevantTimeFrame.timeFrom, relevantTimeFrame.timeTo, this.intervalMinutes);
  }

  get isFieldInternallyValid(): boolean {
    return this.internalTimeFrom !== null
      && this.internalTimeTo !== null
      && this.internalTimeFromRules.every((rule) => rule(this.internalTimeFrom!) === true)
      && this.internalTimeToRules.every((rule) => rule(this.internalTimeTo!) === true);
  }

  mounted(): void {
    mountFormControl(this);
  }

  timeFromChanged(): void {
    internalValuesChanged(this);
  }

  timeToChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  cleared(): void {
    this.isFocused = false;
    this.isTouched = true;

    this.internalTimeFrom = null;
    this.internalTimeTo = null;

    internalValuesChanged(this);
  }

  timeFromMustBeSetWhenTimeToIsSetRule(): InternalValueRule<Time> {
    return (value: Time | null) => this.internalTimeTo === null
      || value !== null
      || 'Startzeit muss ausgewählt werden, wenn die Endzeit ausgewählt ist';
  }

  timeTOMustBeSetWhenTimeFromIsSetRule(): InternalValueRule<Time> {
    return (value: Time | null) => this.internalTimeFrom === null
      || value !== null
      || 'Endzeit muss ausgewählt werden, wenn die Startzeit ausgewählt ist';
  }

  fromBeforeToRule(): InternalValueRule<Time> {
    return (value: Time | null) => value === null
      || this.internalTimeTo === null
      || this.internalTimeTo.isMidnight
      || value.isBefore(this.internalTimeTo)
      || 'Startzeit muss vor der Endzeit liegen';
  }

  toAfterFromRule(): InternalValueRule<Time> {
    return (value: Time | null) => value === null
      || this.internalTimeFrom === null
      || value.isMidnight
      || value.isAfter(this.internalTimeFrom)
      || 'Endzeit muss nach der Startzeit liegen';
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.internalTimeFromRules, this.internalTimeFrom),
      ...errorMessagesForInternalRules(this.internalTimeToRules, this.internalTimeTo),
    ];

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    this.internalTimeFrom = this.formControl.value?.timeFrom ?? null;
    this.internalTimeTo = this.formControl.value?.timeTo ?? null;
  }

  formValueFromInternalValues(): FormControlValue<TimeFrame> {
    return this.isFieldInternallyValid
      ? {
        timeFrom: this.internalTimeFrom!,
        timeTo: this.internalTimeTo!,
      }
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
