import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { isFeatureVisibleForRiders } from '@/application/authentication/helper';
import { Feature, FeatureVisibleForRiders } from '@/types';
import Pinboard from './components/pinboard.vue';
import NewsList from './components/news-list.vue';
import EventList from './components/event-list.vue';
import SurveyList from './components/survey-list.vue';
import NewsDetailsLoadingWrapper from './components/news-details-loading-wrapper.vue';
import EventDetailsLoadingWrapper from './components/event-details-loading-wrapper.vue';

export const pinboardRoutes: RouteConfig[] = [
  {
    path: '/pinnwand',
    component: Pinboard,
    children: [
      {
        name: 'pinboard',
        path: '',
        redirect: () => {
          if (isFeatureVisibleForRiders(FeatureVisibleForRiders.NEWS)) {
            return { name: 'news-list' };
          }
          if (isFeatureVisibleForRiders(FeatureVisibleForRiders.EVENTS)) {
            return { name: 'event-list' };
          }

          return { name: 'profile' };
        },
      },
      {
        name: 'news-list',
        path: 'neuigkeiten',
        component: NewsList,
        meta: {
          title: 'Neuigkeiten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
        },
      },
      {
        name: 'event-list',
        path: 'veranstaltungen',
        component: EventList,
        meta: {
          title: 'Veranstaltungen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.EVENTS,
        },
      },
      {
        name: 'survey-list',
        path: 'umfragen/:surveyId?',
        component: SurveyList,
        meta: {
          title: 'Umfragen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.SURVEYS,
        },
      },
      {
        // WARNING: Name used in push notification routing
        name: 'news-details',
        path: 'neuigkeit/:newsEntryId',
        component: NewsDetailsLoadingWrapper,
        meta: {
          title: 'Neuigkeit',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          routeNameForBackButton: 'news-list',
        },
      },
      {
        // WARNING: Name used in push notification routing
        name: 'event-details',
        path: 'veranstaltung/:eventId',
        component: EventDetailsLoadingWrapper,
        meta: {
          title: 'Veranstaltung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.EVENTS,
          routeNameForBackButton: 'event-list',
        },
      },
    ],
  },
];
