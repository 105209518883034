import { UserProperty } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { Vaccine, CreateVaccineAsAdminCommand, Illness } from './types';

// -- Queries

export function getVaccines(query: UserProperty): Promise<Vaccine[]> {
  const url = `${apiUrl}/api/medical-records/get-vaccines-as-admin-query`;
  return performApiRequest<Vaccine[]>({ url, method: 'POST', data: query });
}

export function getIllnesses(query: UserProperty): Promise<Illness[]> {
  const url = `${apiUrl}/api/medical-records/get-illnesses-as-admin-query`;
  return performApiRequest<Illness[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createVaccine(command: CreateVaccineAsAdminCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/medical-records/create-vaccine-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
