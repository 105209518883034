
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Date, FarmTaskRepetition, Repetition, Weekday, weekdayFromDate } from '@/types';
import { VuetifySelectItem } from '@/application/types';
import { repetitionTranslation, weekdayTranslations } from '@/helpers/translations';
import { formattedDatePickerValueAsDate, moment } from '@/helpers';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class FarmTaskRepetitionFormControl extends Vue implements FormControlComponent<FarmTaskRepetition> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<FarmTaskRepetition>;

  @Prop({ type: Object, default: null })
  readonly taskStartedAt!: Date | null;

  readonly formControlId = createFormControlId();

  repetition: Repetition = Repetition.DAILY;
  weekdays: Weekday[] = [];
  lastRepetitionAt: string = '';

  readonly internalWeekdayRules: InternalValueRules = [
    this.requiredWhenWeeklyRepetitionRule(),
    this.lastRepetitionAtOnWeekdayRule(),
  ];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  isLastRepetitionAtMenuVisible = false;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get repetitionItems(): VuetifySelectItem<Repetition>[] {
    return Object.values(Repetition).map((reptition) => ({
      text: repetitionTranslation[reptition],
      value: reptition,
    }));
  }

  get weekdayItems(): VuetifySelectItem<Weekday>[] {
    return Object.values(Weekday).map((weekday) => ({
      text: weekdayTranslations[weekday],
      value: weekday,
    }));
  }

  get formattedLastRepetitionAtTextFieldValue(): string {
    return formattedDatePickerValueAsDate(this.lastRepetitionAt);
  }

  get areWeekdaysVisible(): boolean {
    return this.repetition === Repetition.WEEKLY;
  }

  mounted(): void {
    mountFormControl(this);
  }

  requiredWhenWeeklyRepetitionRule(): InternalValueRule<Weekday[]> {
    return (value) => this.repetition === Repetition.DAILY
      || value.length > 0
      ? true
      : 'Mindestens ein Wochentag muss ausgewählt werden';
  }

  lastRepetitionAtOnWeekdayRule(): InternalValueRule<Weekday[]> {
    return (value) => this.repetition === Repetition.DAILY
      || !this.lastRepetitionAt
      || !value
      || value.includes(weekdayFromDate(new Date(this.lastRepetitionAt)))
      ? true
      : 'Der Wochentag des Datums der letzten Wiederholung muss enthalten sein';
  }

  repetitionChanged(): void {
    this.focused();
    if (this.repetition === Repetition.DAILY) {
      this.weekdays = [];
    }
    internalValuesChanged(this);
    this.blurred();
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  lastRepetitionAtChanged(): void {
    if (this.lastRepetitionAt === null) {
      this.lastRepetitionAt = '';
    }

    internalValuesChanged(this);
    this.focused();
    this.blurred();

    this.isLastRepetitionAtMenuVisible = false;
  }

  weekdaySelectionChanged(): void {
    this.focused();
    internalValuesChanged(this);
    this.blurred();
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  isValid(): boolean {
    if (this.repetition === Repetition.WEEKLY) {
      return this.weekdays.length > 0;
    } else {
      return this.weekdays.length === 0;
    }
  }

  allowedDates(date: string): boolean {
    return this.taskStartedAt !== null
      ? moment(date).isAfter(this.taskStartedAt.date, 'day')
      : true;
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(this.internalWeekdayRules, this.weekdays),
    ];

    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    if (this.formControl.value === null) {
      this.repetition = Repetition.DAILY;
      this.weekdays = [];
      this.lastRepetitionAt = '';
    } else {
      this.repetition = this.formControl.value.repetition;
      this.weekdays = this.formControl.value.weekdays || [];
      this.lastRepetitionAt = this.formControl.value.lastRepetitionAt
        ? this.formControl.value.lastRepetitionAt.date
        : '';
    }
  }

  formValueFromInternalValues(): FormControlValue<FarmTaskRepetition> {
    if (!this.isValid()) {
      return null;
    }

    if (this.repetition === Repetition.DAILY) {
      return {
        repetition: Repetition.DAILY,
        weekdays: null,
        lastRepetitionAt: this.lastRepetitionAt.length > 0
          ? new Date(this.lastRepetitionAt)
          : null,
      };
    } else {
      return {
        repetition: Repetition.WEEKLY,
        weekdays: this.weekdays,
        lastRepetitionAt: this.lastRepetitionAt.length > 0
          ? new Date(this.lastRepetitionAt)
          : null,
      };
    }
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
