
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CalendarType, RidingLessonCalendarEvent } from '../types';
import RegisterForRidingLessonDialog from './register-for-riding-lesson-dialog.vue';
import { useBookingCalendarStore } from '../store';

@Component({
  components: {
    RegisterForRidingLessonDialog,
  },
})
export default class RidingLessonEvent extends Vue {

  readonly store = useBookingCalendarStore();

  @Prop({ type: Object, required: true })
  readonly calendarEvent!: RidingLessonCalendarEvent;

  @Prop({ type: String, required: true })
  readonly calendarType!: CalendarType;

  get participantsDescription(): string {
    return this.calendarEvent.maxAmountOfParticipants === null
      ? `${this.calendarEvent.registrations.length} Teilnehmer angemeldet`
      : `${this.calendarEvent.registrations.length} / ${this.calendarEvent.maxAmountOfParticipants} Teilnehmer angemeldet`;
  }

  get isBlockerSpacesDescriptionVisible(): boolean {
    return this.calendarType === CalendarType.FACILITY_CALENDAR;
  }

  get isFacilityNameVisible(): boolean {
    return this.calendarType === CalendarType.RIDING_LESSON_CALENDAR;
  }

  get blockerSpacesDescription(): string {
    if (this.store.currentFacility === null) {
      return '';
    }

    if (!this.calendarEvent.ridingLessonType.requiredSpaces
      || this.store.currentFacility.spaces === this.calendarEvent.ridingLessonType.requiredSpaces
    ) {
      return 'Blockiert alle Plätze';
    }

    return `Blockiert ${this.calendarEvent.ridingLessonType.requiredSpaces} von ${this.store.currentFacility.spaces} Plätzen`;
  }

}
