
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth, dialogWidthTranslation } from '@/helpers/data';

@Component
export default class InfoDialog extends Vue {

  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, default: 'small' })
  readonly size!: string;

  isDialogVisible = false;

  get dialogMaxWidth(): DialogWidth {
    const dialogWidth = dialogWidthTranslation[this.size];
    if (!dialogWidth) {
      throw new Error('Invalid dialog size');
    }

    return dialogWidth;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
