import FileSaver from 'file-saver';
import * as Sentry from '@sentry/vue';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { PreviewAnyFile } from '@ionic-native/preview-any-file';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { showErrorMessage } from '@/application/snackbar/service';
import { CSV } from '@/types';

export async function downloadFile(data: Blob, contentType: string, fileName: string): Promise<void> {
  if (isNativeApplication()) {
    await saveFileForMobile(data, contentType, fileName);
  } else {
    FileSaver.saveAs(data, fileName);
  }
}

export async function downloadCSVFile(csv: CSV, fileName: string): Promise<void> {
  const blob = new Blob([csv.content], { type: 'text/csv;charset=utf-8' });
  return downloadFile(blob, 'text/csv', fileName);
}

/**
 * A file is stored into the documents directory on iOS and the downloads directory in Android.
 * We then open the files in a preview.
 */
async function saveFileForMobile(data: Blob, contentType: string, fileName: string): Promise<void> {
  let wasPermissionGranted = false;
  try {
    const permissionStatus = await Filesystem.checkPermissions();

    if (permissionStatus.publicStorage === 'denied') {
      showErrorMessage('Die App hat keine Berechtigung Dateien zu speichern. Bitte überprüfe die Einstellungen.');
    } else {
      wasPermissionGranted = true;
    }
  } catch (error) {
    showErrorMessage(
      // eslint-disable-next-line max-len
      'Es kann nicht festgestellt werden, ob die App die Berechtigung zum Speichern einer Datei hat. Bitte überprüfe die Einstellungen.'
    );
  }

  if (!wasPermissionGranted) {
    return Promise.reject();
  }

  const isPlatformIOS = Capacitor.getPlatform() === 'ios';

  const base64Data = await convertBlobToBase64(data);

  const directory = isPlatformIOS
    ? Directory.Documents
    : Directory.External;

  const filePath = isPlatformIOS
    ? fileName
    : `Download/${fileName}`;

  return Filesystem.writeFile({
    path: filePath,
    data: base64Data,
    directory,
    recursive: true,
  }).then(
    (result) => {
      PreviewAnyFile.preview(result.uri);
    },
    (error?: any) => {
      if (error !== undefined) {
        Sentry.captureEvent({
          level: 'error',
          message: 'Filesystem write file failed',
          extra: {
            error,
          },
        });
      }
      showErrorMessage(
        'Die Datei konnte nicht gespeichert werden. Überprüfe bitte deine Berechtigungen der App in deinen Einstellungen.'
      );
    }
  );
}

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onabort = reject;
    reader.onload = () => {
      const result = reader.result as string;
      const base64Data = result.split(',')[1];

      resolve(base64Data);
    };
    reader.readAsDataURL(blob);
  });
}
