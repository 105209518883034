import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress } from '@/store';
import { getBoxPlan, getBoxPlanPaymentConfiguration, moveHorseIntoBox, removeHorseFromBox, updateBoxPlan, updateBoxPlanPaymentMethod } from './service';
import { Stable, CreateBoxDTO, CreateBoxRowDTO, CreateStableDTO, DeleteBoxDTO, DeleteBoxRowDTO, DeleteStableDTO, HorseIncludingOwner, MoveHorseIntoBoxAsManagerCommand, PaymentConfiguration, RemoveHorseFromBoxAsManagerCommand, UpdateBoxNumberDTO, UpdateBoxPlanAsManagerWorkflow, UpdateBoxPlanPaymentMethodAsManagerCommand, UpdateBoxRowDetailsDTO, UpdateStableDetailsDTO } from './types';

interface BoxPlanManagementState {
  stables: Stable[];
  unboxedHorses: HorseIncludingOwner[];
  hasPendingConfigurationChanges: boolean;
  boxPlanPaymentConfiguration: PaymentConfiguration | null;

  getBoxPlanStatus: ActionStatus;
  updateBoxPlanStatus: ActionStatus;
  moveHorseIntoBoxStatus: ActionStatus;
  removeHorseFromBoxStatus: ActionStatus;
  getBoxPlanPaymentConfigurationStatus: ActionStatus;
  updateBoxPlanPaymentMethodStatus: ActionStatus;
  createBoxStatus: ActionStatus;
  updateBoxNumberStatus: ActionStatus;
}

function initialState(): BoxPlanManagementState {
  return {
    stables: [],
    unboxedHorses: [],
    hasPendingConfigurationChanges: false,
    boxPlanPaymentConfiguration: null,

    getBoxPlanStatus: ActionStatus.None,
    updateBoxPlanStatus: ActionStatus.None,
    moveHorseIntoBoxStatus: ActionStatus.None,
    removeHorseFromBoxStatus: ActionStatus.None,
    getBoxPlanPaymentConfigurationStatus: ActionStatus.None,
    updateBoxPlanPaymentMethodStatus: ActionStatus.None,
    createBoxStatus: ActionStatus.None,
    updateBoxNumberStatus: ActionStatus.None,
  };
}

export const useBoxPlanManagementStore = defineStore('boxPlanManagement', {
  state: (): BoxPlanManagementState => initialState(),
  getters: {
    isGetBoxPlanProcessing: (state: BoxPlanManagementState): boolean =>
      state.getBoxPlanStatus === ActionStatus.InProgress,
    isUpdateBoxPlanProcessing: (state: BoxPlanManagementState): boolean =>
      state.updateBoxPlanStatus === ActionStatus.InProgress,
    isMoveHorseIntoBoxProcessing: (state: BoxPlanManagementState): boolean =>
      state.moveHorseIntoBoxStatus === ActionStatus.InProgress,
    isRemoveHorseFromBoxProcessing: (state: BoxPlanManagementState): boolean =>
      state.removeHorseFromBoxStatus === ActionStatus.InProgress,
    isGetBoxPlanPaymentConfigurationProcessing: (state: BoxPlanManagementState): boolean =>
      state.getBoxPlanPaymentConfigurationStatus === ActionStatus.InProgress,
    isUpdateBoxPlanPaymentMethodProcessing: (state: BoxPlanManagementState): boolean =>
      state.updateBoxPlanPaymentMethodStatus === ActionStatus.InProgress,
    isCreateBoxProcessing: (state: BoxPlanManagementState): boolean =>
      state.createBoxStatus === ActionStatus.InProgress,
    isUpdateBoxNumberProcessing: (state: BoxPlanManagementState): boolean =>
      state.updateBoxNumberStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getBoxPlan(): Promise<void> {
      const { getBoxPlanStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxPlanStatus,
        () => getBoxPlan(attachFarmAndUserProperties({}))
          .then((boxPlan) => {
            this.stables = boxPlan.stables;
            this.unboxedHorses = boxPlan.unboxedHorses;
            this.hasPendingConfigurationChanges = false;
          })
      );
    },

    getBoxPlanPaymentConfiguration(): Promise<void> {
      const { getBoxPlanPaymentConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxPlanPaymentConfigurationStatus,
        () => getBoxPlanPaymentConfiguration(attachFarmAndUserProperties({}))
          .then((boxPlanPaymentConfiguration) => {
            this.boxPlanPaymentConfiguration = boxPlanPaymentConfiguration;
          })
      );
    },

    // -- Commands

    updateBoxPlan(command: UpdateBoxPlanAsManagerWorkflow): Promise<void> {
      const { updateBoxPlanStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateBoxPlanStatus,
        () => updateBoxPlan(attachFarmAndUserProperties(command))
          .then(() => this.getBoxPlan())
      );
    },

    moveHorseIntoBox(command: MoveHorseIntoBoxAsManagerCommand): Promise<void> {
      const { moveHorseIntoBoxStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveHorseIntoBoxStatus,
        () => moveHorseIntoBox(attachFarmAndUserProperties(command))
          .then(() => this.getBoxPlan())
      );
    },

    removeHorseFromBox(command: RemoveHorseFromBoxAsManagerCommand): Promise<void> {
      const { removeHorseFromBoxStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeHorseFromBoxStatus,
        () => removeHorseFromBox(attachFarmAndUserProperties(command))
          .then(() => this.getBoxPlan())
      );
    },

    updateBoxPlanPaymentMethod(command: UpdateBoxPlanPaymentMethodAsManagerCommand): Promise<void> {
      const { updateBoxPlanPaymentMethodStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateBoxPlanPaymentMethodStatus,
        () => updateBoxPlanPaymentMethod(attachFarmAndUserProperties(command))
          .then(() => this.getBoxPlanPaymentConfiguration())
      );
    },

    createStable(dto: CreateStableDTO): Promise<void> {
      this.stables.push(dto.stable);
      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    deleteStable(dto: DeleteStableDTO): Promise<void> {
      this.stables.splice(dto.stableIndex, 1);
      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    updateStableDetails(dto: UpdateStableDetailsDTO): Promise<void> {
      this.stables = this.stables.map((stable) => {
        if (stable.stableId !== dto.stableId) {
          return stable;
        }
        return { ...stable, name: dto.name };
      });
      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    createBoxRow(dto: CreateBoxRowDTO): Promise<void> {
      for (const stable of this.stables) {
        if (stable.stableId === dto.stableId) {
          stable.boxRows.push(dto.boxRow);
        }
      }

      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    updateBoxRowDetails(dto: UpdateBoxRowDetailsDTO): Promise<void> {
      for (const stable of this.stables) {
        if (stable.stableId === dto.stableId) {
          stable.boxRows = stable.boxRows.map((boxRow) => {
            if (boxRow.boxRowId !== dto.boxRowId) {
              return boxRow;
            }
            return {
              ...boxRow,
              name: dto.name,
              grossSellingPrice: dto.grossSellingPrice,
              netSellingPrice: dto.netSellingPrice,
              taxRate: dto.taxRate,
              taxAmount: dto.taxAmount,
            };
          });
          break;
        }
      }

      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    deleteBoxRow(dto: DeleteBoxRowDTO): Promise<void> {
      this.stables[dto.stableIndex].boxRows.splice(dto.boxRowIndex, 1);
      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    createBox(dto: CreateBoxDTO): Promise<void> {
      this.stables[dto.stableIndex].boxRows[dto.boxRowIndex].boxes.push(dto.box);
      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    deleteBox(dto: DeleteBoxDTO): Promise<void> {
      this.stables[dto.stableIndex].boxRows[dto.boxRowIndex].boxes.splice(dto.boxIndex, 1);
      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

    updateBoxNumber(dto: UpdateBoxNumberDTO): Promise<void> {
      for (const stable of this.stables) {
        for (const boxRow of stable.boxRows) {
          for (const box of boxRow.boxes) {
            if (box.boxId !== dto.boxId) {
              continue;
            }
            box.number = dto.number;
          }
        }
      }
      this.hasPendingConfigurationChanges = true;

      return Promise.resolve();
    },

  },
});
