import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import RequestPasswordReset from './components/request-password-reset.vue';
import ResetPassword from './components/reset-password.vue';
import Logout from './components/logout.vue';

export const applicationRoutes: RouteConfig[] = [
  {
    name: 'logout',
    path: '/abmeldung',
    component: Logout,
    meta: {
      title: 'Abmeldung',
      accessibleFor: RouteAccessibility.ALWAYS,
      hasNarrowLayout: true,
    },
  },
  {
    name: 'request-password-reset',
    path: '/passwort-vergessen',
    component: RequestPasswordReset,
    meta: {
      title: 'Passwort vergessen',
      accessibleFor: RouteAccessibility.UNAUTHENTICATED,
      hasNarrowLayout: true,
    },
  },
  {
    name: 'reset-password',
    path: '/passwort-zuruecksetzen/:token',
    component: ResetPassword,
    meta: {
      title: 'Passwort zurücksetzen',
      accessibleFor: RouteAccessibility.UNAUTHENTICATED,
      hasNarrowLayout: true,
    },
  },
];
