
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { useAuthenticationStore } from '@/application/authentication/store';
import { clearAuthenticationStates, clearDomainStates } from '@/helpers/store-reset-helper';
import { moment } from '@/helpers';

@Component
export default class Logout extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  mounted(): void {
    this.authenticationStore.startLogoutProcess();

    const logoutStartedAt = moment();
    this.authenticationStore.logout()
      .catch(() => {})
      .finally(() => {
        const logoutFinishedAt = moment().add(500, 'ms');
        const timeDifference = logoutFinishedAt.diff(logoutStartedAt);

        // Timeout that the page is visible on fast connections, and it doesn't just flickr
        setTimeout(async () => {
          clearAuthenticationStates();
          clearDomainStates();
          navigate({ name: 'root' });
        }, timeDifference);
      });
  }

}
