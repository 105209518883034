
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import ExtensionDocument from '@tiptap/extension-document';
import ExtensionParagraph from '@tiptap/extension-paragraph';
import ExtensionText from '@tiptap/extension-text';
import ExtensionListItem from '@tiptap/extension-list-item';
import ExtensionHardBreak from '@tiptap/extension-hard-break';
import ExtensionBold from '@tiptap/extension-bold';
import ExtensionLink from '@tiptap/extension-link';
import ExtensionBulletList from '@tiptap/extension-bullet-list';
import ExtensionOrderedList from '@tiptap/extension-ordered-list';
import { Editor, EditorContent } from '@tiptap/vue-2';
import isURL, { IsURLOptions } from 'validator/es/lib/isURL';
import { watch } from 'vue';
import { showErrorMessage } from '@/application/snackbar/service';

export type ExtensionOption =
  | 'bold'
  | 'italic'
  | 'link'
  | 'bullet-list'
  | 'ordered-list';

function allExtensionOptions(): ExtensionOption[] {
  return [
    'bold',
    'italic',
    'link',
    'bullet-list',
    'ordered-list',
  ];
}

@Component({
  components: {
    EditorContent,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class HtmlFormControl extends Vue implements FormControlComponent<string> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<string>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: String, default: null })
  readonly dataCy!: string | null;

  @Prop({ type: Number, default: 3 })
  readonly rows!: number;

  @Prop({ type: Array, default: () => allExtensionOptions() })
  readonly extensions!: ExtensionOption[];

  readonly formControlId = createFormControlId();

  editor: Editor | null = null;

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  get isBoldActive(): boolean {
    return this.editor!.isActive('bold');
  }

  get isBulletListActive(): boolean {
    return this.editor!.isActive('bulletList');
  }

  get isOrderedListActive(): boolean {
    return this.editor!.isActive('orderedList');
  }

  get isLinkActive(): boolean {
    return this.editor!.isActive('link');
  }

  get isUnlinkDisabled(): boolean {
    return !this.editor!.isActive('link');
  }

  mounted(): void {
    const enabledExtensions: any[] = [
      ExtensionDocument,
      ExtensionParagraph,
      ExtensionText,
      ExtensionListItem,
      ExtensionHardBreak,
    ];

    if (this.extensions.includes('bold')) {
      enabledExtensions.push(ExtensionBold);
    }

    if (this.extensions.includes('link')) {
      enabledExtensions.push(ExtensionLink.configure({
        openOnClick: false,
      }));
    }

    if (this.extensions.includes('bullet-list')) {
      enabledExtensions.push(ExtensionBulletList);
    }

    if (this.extensions.includes('ordered-list')) {
      enabledExtensions.push(ExtensionOrderedList);
    }

    this.editor = new Editor({
      content: this.internalValue,
      extensions: enabledExtensions,
      enablePasteRules: false,
      onBlur: () => this.blurred(),
      onFocus: () => this.focused(),
      onUpdate: () => {
        this.internalValue = this.editor!.getHTML();
        internalValuesChanged(this);
      },
    });

    watch(() => this.internalValue, (internalValue: string) => {
      if (!this.editor || this.editor.getHTML() === internalValue) {
        return;
      }

      this.editor.commands.setContent(internalValue, false);
    });

    mountFormControl(this);

    if (this.isAutofocused
      && this.$vuetify.breakpoint.mdAndUp
    ) {
      this.editor.commands.focus();
    }
  }

  beforeDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  toggleBold(): void {
    this.editor!.chain().focus().toggleBold().run();
  }

  toggleBulletList(): void {
    this.editor!.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList(): void {
    this.editor!.chain().focus().toggleOrderedList().run();
  }

  setLink() {
    const previousUrl = this.editor!.getAttributes('link').href;
    // eslint-disable-next-line no-alert
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // Empty link
    if (url === '') {
      this.editor!
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();

      return;
    }

    if (!this.isValidHttpUrl(url)) {
      showErrorMessage('Der Link ist leider nicht valide.');
      return;
    }

    // Update link
    this.editor!
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
  }

  unsetLink(): void {
    this.editor!.chain().focus().unsetLink().run();
  }

  addHardBreak(): void {
    this.editor!
      .commands
      .setHardBreak();
  }

  isValidHttpUrl(url: string): boolean {
    const options: IsURLOptions = {
      protocols: ['http', 'https'],
      require_protocol: true,
    };

    return isURL(url, options);
  }

  isExtensionEnabled(extension: ExtensionOption): boolean {
    return this.extensions.includes(extension);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<string> {
    return this.internalValue.replace(/<\/?[^>]+(>|$)/g, '').trim().length > 0
      ? this.internalValue.trim()
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
