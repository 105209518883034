import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import Login from '@/public/authentication/components/login.vue';
import NotFound from '@/application/app/components/not-found.vue';
import { bookingCalendarsRoutes } from '@/private/rider/booking-calendars/routes';
import { facilityReservationManagementRoutes } from '@/private/management/facility-reservations/routes';
import { feedingPlanRoutes } from '@/private/management/ledger/feeding-plan/routes';
import { farmServiceRoutes } from '@/private/management/farm-services/routes';
import { eventManagementRoutes } from '@/private/management/events/routes';
import { analyticsRoutes } from '@/private/management/analytics/routes';
import { newsManagementRoutes } from '@/private/management/news/routes';
import { riderTasksRoutes } from '@/private/rider/rider-tasks/routes';
import { riderTaskManagementRoutes } from '@/private/management/rider-tasks/routes';
import { paddockPlanManagementRoutes } from '@/private/management/paddock-plan/routes';
import { userManagementRoutes } from '@/private/management/users/routes';
import { farmDocumentsManagementRoutes } from '@/private/management/farm-documents/routes';
import { farmDocumentsRoutes } from '@/private/rider/farm-documents/routes';
import { farmManagementRoutes } from '@/private/admin/farms/routes';
import { globalUserManagementRoutes } from '@/private/admin/global-users/routes';
import { penManagementRoutes } from '@/private/management/pen/routes';
import { myStableRoutes } from '@/private/rider/my-stable/routes';
import { registrationRoutes } from '@/public/user-registration/routes';
import { ledgerManagementRoutes } from '@/private/management/ledger/routes';
import { horseManagementRoutes } from '@/private/management/horses/routes';
import { profileRoutes } from '@/private/rider/profile/routes';
import { surveyManagementRoutes } from '@/private/management/surveys/routes';
import { vaccineManagementRoutes } from '@/private/admin/vaccines/routes';
import { legalRoutes } from '@/application/legal/routes';
import { applicationRoutes } from '@/public/authentication/routes';
import { pinboardRoutes } from '@/private/rider/pinboard/routes';
import { boxPlanManagementRoutes } from '@/private/management/box-plan/routes';
import { controlPanelRoutes } from '@/private/management/control-panel/routes';
import { laborServiceRoutes } from '@/private/rider/labor-service/routes';
import { laborServicesManagementRoutes } from '@/private/management/labor-services/routes';
import { medicalRecordsManagementRoutes } from '@/private/management/medical-records/routes';
import { feedingManagementRoutes } from '@/private/management/feeding/routes';
import { feedProtocolManagementRoutes } from '@/private/management/feed-protocol/routes';
import { adminNotificationManagementRoutes } from '@/private/admin/admin-notifications/routes';
import { facilityManagementRoutes } from '@/private/management/facilities/routes';
import { ridingLessonManagementRoutes } from '@/private/management/riding-lessons/routes';
import { dashboardRoutes } from '@/private/rider/dashboard/routes';
import { eventAsExternalGuestsRoutes } from '@/public/event-as-external-guests/routes';
import { farmTaskManagementRoutes } from '@/private/management/farm-tasks/routes';
import { customerMasterDataManagementRoutes } from '@/private/management/customer-master-data/routes';
import { invoicingRoutes } from '@/private/management/invoicing/routes';
import { redirectRouteDependingOnAuthentication } from '@/application/authentication/helper';

export const MANAGEMENT_ROUTE_PREFIX = '/verwaltung';
export const ADMIN_ROUTE_PREFIX = '/admin';

export const routes: RouteConfig[] = [
  {
    name: 'root',
    path: '/',
    redirect: () => redirectRouteDependingOnAuthentication(),
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      title: 'Anmeldung',
      accessibleFor: RouteAccessibility.UNAUTHENTICATED,
      hasNarrowLayout: true,
    },
  },
  ...dashboardRoutes,
  ...pinboardRoutes,
  ...applicationRoutes,
  ...bookingCalendarsRoutes,
  ...controlPanelRoutes,
  ...farmDocumentsRoutes,
  ...legalRoutes,
  ...laborServiceRoutes,
  ...myStableRoutes,
  ...registrationRoutes,
  ...riderTasksRoutes,
  ...profileRoutes,
  ...eventAsExternalGuestsRoutes,
  {
    path: MANAGEMENT_ROUTE_PREFIX,
    component: {
      template: `<div class="management"><router-view /></div>`,
    },
    children: [
      {
        path: '',
        redirect: 'benutzer',
      },
      ...analyticsRoutes,
      ...eventManagementRoutes,
      ...newsManagementRoutes,
      ...boxPlanManagementRoutes,
      ...farmServiceRoutes,
      ...feedingPlanRoutes,
      ...feedProtocolManagementRoutes,
      ...ledgerManagementRoutes,
      ...facilityReservationManagementRoutes,
      ...farmDocumentsManagementRoutes,
      ...feedingManagementRoutes,
      ...horseManagementRoutes,
      ...laborServicesManagementRoutes,
      ...paddockPlanManagementRoutes,
      ...penManagementRoutes,
      ...userManagementRoutes,
      ...medicalRecordsManagementRoutes,
      ...surveyManagementRoutes,
      ...riderTaskManagementRoutes,
      ...facilityManagementRoutes,
      ...ridingLessonManagementRoutes,
      ...farmTaskManagementRoutes,
      ...customerMasterDataManagementRoutes,
      ...invoicingRoutes,
    ],
  },
  {
    path: ADMIN_ROUTE_PREFIX,
    component: {
      template: `<div class="admin"><router-view /></div>`,
    },
    children: [
      ...globalUserManagementRoutes,
      ...adminNotificationManagementRoutes,
      ...farmManagementRoutes,
      ...vaccineManagementRoutes,
    ],
  },
  {
    // Redirect can be removed after 06.24
    path: '/verwaltung/aufgaben/*',
    redirect: '/verwaltung/reiteraufgaben/*',
  },
  {
    name: 'not-found',
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: {
      title: 'Seite nicht gefunden',
      accessibleFor: RouteAccessibility.ALWAYS,
    },
  },
];
