import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { AcceptHorseShareInviteCommand, HorseShareInvite, MarkNotificationAsSeenCommand, PenStatusResponse, RejectHorseShareInviteCommand, UnseenNotification, NotificationStatus, Appointment, CancelRidingLessonRegistrationCommand } from './types';

// -- Queries

export function getNextAppointments(query: FarmAndUserProperties): Promise<Appointment[]> {
  const url = `${apiUrl}/api/dashboard/get-next-appointments-query`;
  return performApiRequest<Appointment[]>({ url, method: 'POST', data: query });
}

export function getCurrentPenStatusEntry(query: FarmAndUserProperties): Promise<PenStatusResponse> {
  const url = `${apiUrl}/api/pen/get-current-pen-status-entry-query`;
  return performApiRequest<PenStatusResponse>({ url, method: 'POST', data: query });
}

export function getUnseenNotifications(query: FarmAndUserProperties): Promise<UnseenNotification[]> {
  const url = `${apiUrl}/api/dashboard/get-unseen-notifications-query`;
  return performApiRequest<UnseenNotification[]>({ url, method: 'POST', data: query });
}

export function getHorseShareInvites(query: FarmAndUserProperties): Promise<HorseShareInvite[]> {
  const url = `${apiUrl}/api/dashboard/get-horse-share-invites-query`;
  return performApiRequest<HorseShareInvite[]>({ url, method: 'POST', data: query });
}

export function getNotificationStatus(query: FarmAndUserProperties): Promise<NotificationStatus> {
  const url = `${apiUrl}/api/dashboard/get-notification-status-query`;
  return performApiRequest<NotificationStatus>({ url, method: 'POST', data: query });
}

// -- Commands

export function acceptHorseShareInvite(command: AcceptHorseShareInviteCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/accept-horse-share-invite-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function rejectHorseShareInvite(command: RejectHorseShareInviteCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/reject-horse-share-invite-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markNotificationAsSeen(command: MarkNotificationAsSeenCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/dashboard/mark-notification-as-seen-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markAllNotificationAsSeen(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/dashboard/mark-all-notification-as-seen-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function cancelRidingLessonRegistration(command: CancelRidingLessonRegistrationCommand): Promise<void> {
  const url = `${apiUrl}/api/riding-lessons/cancel-riding-lesson-registration-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
