
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { UserGroupId } from '@/types';

export interface UserGroupIdentification {
  userGroupId: UserGroupId;
  name: string;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
    && typeof item === 'object'
    && Object.hasOwn(item, 'userGroupId')
    && Object.hasOwn(item, 'name'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class UserGroupMultiFormControl extends Vue implements FormControlComponent<UserGroupId[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<UserGroupId[]>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: UserGroupIdentification[];

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: UserGroupId[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    this.focused();
    internalValuesChanged(this);
    this.blurred();
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value !== null
      ? this.formControl.value
      : [];
  }

  formValueFromInternalValues(): FormControlValue<UserGroupId[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
