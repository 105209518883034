
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TertiaryButton extends Vue {

  @Prop({ type: Boolean, default: false })
  readonly isAlwaysShownInBlockMode!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isSmall!: boolean;

  get isBlock(): boolean {
    return this.isAlwaysShownInBlockMode
      || this.$vuetify.breakpoint.smAndDown;
  }

}
