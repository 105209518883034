import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import FacilityList from './components/facility-list.vue';
import CurrentFacilityTabs from './components/current-facility-tabs.vue';
import FacilityConfiguration from './components/facility-configuration.vue';
import FacilityBlockers from './components/facility-blockers.vue';

export const facilityManagementRoutes: RouteConfig[] = [
  {
    name: 'facility-management',
    path: 'anlagen',
    component: FacilityList,
    meta: {
      title: 'Anlagen',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresPermission: FarmManagerPermission.FACILITIES_READ,
    },
  },
  {
    path: 'anlagen/:id',
    component: CurrentFacilityTabs,
    children: [
      {
        path: '',
        redirect: { name: 'facility-management-current-facility-blockers' },
      },
      {
        name: 'facility-management-current-facility-blockers',
        path: 'sperrzeiten',
        component: FacilityBlockers,
        meta: {
          title: 'Sperrzeiten',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.FACILITIES_READ,
          routeNameForBackButton: 'facility-management',
        },
      },
      {
        name: 'facility-management-current-facility-configuration',
        path: 'konfiguration',
        component: FacilityConfiguration,
        meta: {
          title: 'Konfiguration',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.FACILITIES_READ,
          routeNameForBackButton: 'facility-management',
        },
      },
    ],
  },
];
