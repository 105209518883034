import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { getFarmDocuments } from './service';
import { FarmDocument } from './types';

interface FarmDocumentsState {
  farmDocuments: FarmDocument[];

  getFarmDocumentsStatus: ActionStatus;
}

function initialState(): FarmDocumentsState {
  return {
    farmDocuments: [],

    getFarmDocumentsStatus: ActionStatus.None,
  };
}

export const useFarmDocumentsStore = defineStore('farmDocuments', {
  state: (): FarmDocumentsState => initialState(),
  getters: {
    isGetFarmDocumentsProcessing: (state: FarmDocumentsState): boolean =>
      state.getFarmDocumentsStatus === ActionStatus.InProgress,
    isGetFarmDocumentsSuccessful: (state: FarmDocumentsState): boolean =>
      state.getFarmDocumentsStatus === ActionStatus.Successful,
  },
  actions: {

    // -- Queries

    getFarmDocuments(): Promise<void> {
      const { getFarmDocumentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmDocumentsStatus,
        () => getFarmDocuments(attachFarmAndUserProperties({}))
          .then(async (farmDocuments) => {
            this.farmDocuments = farmDocuments;
          })
      );
    },

  },
});
