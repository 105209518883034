
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Link } from '@/types';

function isValidUrl(trimmedValue: string): boolean {
  try {
    const urlToValidate = trimmedValue.startsWith('https://')
      ? trimmedValue
      : `https://${trimmedValue}`;

    new URL(urlToValidate);
    return !trimmedValue.includes(' ');
  } catch (error) {
    return false;
  }
}

@Component({
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class LinkFormControl extends Vue implements FormControlComponent<Link> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Link>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: String, default: null })
  readonly dataCy!: string | null;

  @Prop({ type: String, default: null })
  readonly infoText!: string | null;

  readonly formControlId = createFormControlId();

  readonly internalRules: InternalValueRules = [
    this.validUrlRule(),
  ];

  areInternalValuesValid = false;

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  textChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;

    if (this.internalValue.startsWith('http://')) {
      this.internalValue = this.internalValue.replace('http://', 'https://');
      internalValuesChanged(this);
    }

    if (!this.internalValue.startsWith('https://')) {
      this.internalValue = `https://${this.internalValue}`;
      internalValuesChanged(this);
    }
  }

  validUrlRule(): InternalValueRule<any> {
    return () => this.internalValue.trim().length === 0
      || isValidUrl(this.internalValue.trim())
      || 'Es muss ein valider Link sein';
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.internalRules, {}),
    ];

    this.areInternalValuesValid = this.messages.length === 0;

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.trim();
  }

  formValueFromInternalValues(): FormControlValue<Link> {
    let trimmedInternalValue = this.internalValue.trim();

    if (trimmedInternalValue.length === 0) {
      return null;
    }

    if (trimmedInternalValue.startsWith('http://')) {
      trimmedInternalValue = trimmedInternalValue.replace('http://', 'https://');
    }

    if (!trimmedInternalValue.startsWith('https://')) {
      trimmedInternalValue = `https://${trimmedInternalValue}`;
    }

    return trimmedInternalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
