
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { useAuthenticationStore } from '@/application/authentication/store';
import { constructForm, FormControl, Form, getFormValues, maxLengthRule, FormControls } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { HorseId, Moment, RidingLessonRegistrationType } from '@/types';
import { moment } from '@/helpers';
import { formatDate, formatTime } from '@/helpers';
import { useMyStableStore } from '@/private/rider/my-stable/store';
import CancelRidingLessonRegistrationDialog from './cancel-riding-lesson-registration-dialog.vue';
import { RegisterForRidingLessonCommand, RidingLessonCalendarEvent, RidingLessonRegistration } from '../types';
import { useBookingCalendarStore } from '../store';

interface Controls extends FormControls {
  ridingLessonRegistrationType: FormControl<RidingLessonRegistrationType>;
  horse: FormControl<HorseId>;
  nameOfAnotherPerson: FormControl<string>;
  comment: FormControl<string>;
}

@Component({
  components: {
    CancelRidingLessonRegistrationDialog,
  },
})
export default class RegisterForRidingLessonDialog extends Vue {

  readonly store = useBookingCalendarStore();
  readonly authenticationStore = useAuthenticationStore();
  readonly myStableStore = useMyStableStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly calendarEvent!: RidingLessonCalendarEvent;

  readonly listFormatter = new Intl.ListFormat('de', { style: 'short', type: 'conjunction' });

  readonly ridingLessonRegistrationTypeTranslations: Record<RidingLessonRegistrationType, string> = {
    USER: 'mich selbst anmelden',
    IN_NAME_OF_ANOTHER_PERSON: 'eine andere Person anmelden',
  };

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get areHorsesVisible(): boolean {
    return this.calendarEvent.horses.length > 0;
  }

  get assignedHorsesDescription(): string {
    const nameList = this.calendarEvent.horses
      .map((horse) => horse.name);

    const names = this.listFormatter.format(nameList);

    return this.calendarEvent.horses.length > 1
      ? `Mit den Pferden ${names}`
      : `Mit dem Pferd ${names}`;
  }

  get isRegistrationPossibleForUser(): boolean {
    return (
      (
        this.calendarEvent.isRegistrationForOneselfAllowed
        && !this.isAuthenticatedUserAlreadyRegisteredForSelf
      )
        || this.calendarEvent.isRegistrationOnBehalfOfAnotherPersonAllowed
    ) && !this.isLastRegistrationAtInThePast
      && !this.isMaxAmountOfParticipantsReached
      && this.isEarliestMomentForRegistrationReached;
  }

  get isRegistrationOnWaitingListPossibleForUser(): boolean {
    return this.calendarEvent.isWaitingListAvailable
      && (
        (
          this.calendarEvent.isRegistrationForOneselfAllowed
          && !this.isAuthenticatedUserAlreadyRegisteredForSelf
        )
          || this.calendarEvent.isRegistrationOnBehalfOfAnotherPersonAllowed
      ) && !this.isLastRegistrationAtInThePast
      && this.isMaxAmountOfParticipantsReached
      && this.isEarliestMomentForRegistrationReached;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isNoRegistrationsVisible(): boolean {
    return this.calendarEvent.registrations.length === 0;
  }

  get registeredUsersTitle(): string {
    if (this.calendarEvent.maxAmountOfParticipants === null) {
      return this.calendarEvent.registrations.length === 0
        ? `Anmeldungen`
        : this.calendarEvent.registrations.length === 1
          ? `1 Person hat sich angemeldet`
          : `${this.calendarEvent.registrations.length} Personen sind angemeldet`;
    }

    const amountOnWaitingList = this.calendarEvent.registrations.length - this.calendarEvent.maxAmountOfParticipants;

    return this.calendarEvent.registrations.length > this.calendarEvent.maxAmountOfParticipants
      ? `Alle Plätze belegt und ${amountOnWaitingList} ${amountOnWaitingList > 1 ? 'Personen' : 'Person'} auf der Warteliste`
      : `${this.calendarEvent.registrations.length} von ${this.calendarEvent.maxAmountOfParticipants} Personen sind angemeldet`;
  }

  get isCloseButtonVisible(): boolean {
    return !this.isRegistrationPossibleForUser
      && !this.isCancelRegistrationVisible;
  }

  get isCancelButtonVisible(): boolean {
    return this.isRegisterForRidingLessonVisible
      || this.isCancelRegistrationVisible;
  }

  get areFormFieldsVisible(): boolean {
    return this.isRegistrationPossibleForUser
      || this.isRegistrationOnWaitingListPossibleForUser;
  }

  get isRegisterForRidingLessonVisible(): boolean {
    return this.isRegistrationPossibleForUser;
  }

  get isRegisterForRidingLessonOnWaitingListVisible(): boolean {
    return this.isRegistrationOnWaitingListPossibleForUser;
  }

  get isCancelRegistrationVisible(): boolean {
    return this.isAuthenticatedUserRegistered
      && !this.isLastRegistrationAtInThePast
      && this.isEarliestMomentForRegistrationReached;
  }

  get isAuthenticatedUserAlreadyRegisteredForSelf(): boolean {
    return this.isRegisteredSelf !== null;
  }

  get isRegisteredSelf(): RidingLessonRegistration | null {
    return this.calendarEvent.registrations
      .find((registration) => registration.user.userId === this.authenticationStore.user!.id
        && registration.type === RidingLessonRegistrationType.USER)
      || null;
  }

  get isAuthenticatedUserRegistered(): boolean {
    return this.calendarEvent.registrations
      .some((registration) => registration.user.userId === this.authenticationStore.user!.id);
  }

  get earliestMomentForRegistration(): Moment {
    return this.calendarEvent.from
      .hour(0)
      .minute(0)
      .second(0)
      .subtract(this.calendarEvent.ridingLessonType.registrationInAdvanceInDays, 'days');
  }

  get isEarliestMomentForRegistrationReached(): boolean {
    const now = moment();

    return now.isSameOrAfter(this.earliestMomentForRegistration);
  }

  get isLastRegistrationAtInThePast(): boolean {
    const now = moment();

    return this.calendarEvent.lastRegistrationAt.isBefore(now);
  }

  get isMaxAmountOfParticipantsReached(): boolean {
    return !!this.calendarEvent.maxAmountOfParticipants
      && this.calendarEvent.registrations.length >= this.calendarEvent.maxAmountOfParticipants;
  }

  get registrationTimeFrameDescription(): string {
    if (this.isLastRegistrationAtInThePast) {
      // eslint-disable-next-line max-len
      return `Die Anmeldung war vom ${formatDate(this.earliestMomentForRegistration)} bis zum ${formatDate(this.calendarEvent.lastRegistrationAt)} um ${formatTime(this.calendarEvent.lastRegistrationAt)} Uhr möglich.`;
    }
    return this.isEarliestMomentForRegistrationReached
      // eslint-disable-next-line max-len
      ? `Die Anmeldung ist bis zum ${formatDate(this.calendarEvent.lastRegistrationAt)} um ${formatTime(this.calendarEvent.lastRegistrationAt)} Uhr möglich.`
      // eslint-disable-next-line max-len
      : `Die Anmeldung ist vom ${formatDate(this.earliestMomentForRegistration)} bis zum ${formatDate(this.calendarEvent.lastRegistrationAt)} um ${formatTime(this.calendarEvent.lastRegistrationAt)} Uhr möglich.`;
  }

  get isRidingLessonTypeSelectionVisible(): boolean {
    return this.calendarEvent.isRegistrationForOneselfAllowed
      && this.calendarEvent.isRegistrationOnBehalfOfAnotherPersonAllowed
      && !this.isAuthenticatedUserAlreadyRegisteredForSelf;
  }

  get isRegistrationTypeForSelfVisible(): boolean {
    return this.form!.controls.ridingLessonRegistrationType.value === RidingLessonRegistrationType.USER;
  }

  get isRegistrationInNameOfAnotherPersonVisible(): boolean {
    return this.form!.controls.ridingLessonRegistrationType.value === RidingLessonRegistrationType.IN_NAME_OF_ANOTHER_PERSON;
  }

  get isWaitingListNoticeVisible(): boolean {
    return this.isRegistrationOnWaitingListPossibleForUser;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  isRegistrationForSelfAndAuthenticatedUser(registration: RidingLessonRegistration): boolean {
    return registration.user.userId === this.authenticationStore.user!.id
      && registration.type === RidingLessonRegistrationType.USER;
  }

  isRegistrationOnWaitingList(index: number): boolean {
    return this.calendarEvent.isWaitingListAvailable
      && this.calendarEvent.maxAmountOfParticipants !== null
      && index + 1 > this.calendarEvent.maxAmountOfParticipants;
  }

  name(registration: RidingLessonRegistration): string {
    return registration.type === RidingLessonRegistrationType.USER
      ? registration.user.name
      : registration.nameOfAnotherPerson!;
  }

  buildForm(): Form<Controls> {
    const initialRidingLessonRegistrationType = this.calendarEvent.isRegistrationForOneselfAllowed
      && !this.isAuthenticatedUserAlreadyRegisteredForSelf
      ? RidingLessonRegistrationType.USER
      : RidingLessonRegistrationType.IN_NAME_OF_ANOTHER_PERSON;

    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        ridingLessonRegistrationType: {
          label: 'Ich möchte',
          value: initialRidingLessonRegistrationType,
          isRequired: true,
          validateFormControlsOnInput: [
            'nameOfAnotherPerson',
          ],
        },
        horse: {
          label: 'Pferd',
          value: null,
          rules: [],
        },
        nameOfAnotherPerson: {
          label: 'Name der Person',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
        comment: {
          label: 'Kommentar',
          value: null,
          rules: [
            maxLengthRule(255),
          ],
        },
      },
    });

    // Conditional required
    form.controls.nameOfAnotherPerson.rules!.push(
      () => form.controls.ridingLessonRegistrationType.value === RidingLessonRegistrationType.USER
        || form.controls.nameOfAnotherPerson.value !== null
        || 'Bitte gebe den Namen der Person an, für den du dich anmelden möchtest.'
    );

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: RegisterForRidingLessonCommand = {
      ridingLessonId: this.calendarEvent.id,
      type: formValues.ridingLessonRegistrationType!,
      horseId: formValues.ridingLessonRegistrationType === RidingLessonRegistrationType.USER
        ? formValues.horse
        : null,
      nameOfAnotherPerson: formValues.ridingLessonRegistrationType === RidingLessonRegistrationType.IN_NAME_OF_ANOTHER_PERSON
        ? formValues.nameOfAnotherPerson
        : null,
      comment: formValues.comment,
    };
    this.store.registerForRidingLesson(command)
      .then(() => this.$emit('registered-for-riding-lesson'))
      .then(() => showSuccessMessage('Du bist für die Unterrichtsstunde angemeldet.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error, 6000));
  }

  ridingLessonRegistrationCanceled(): void {
    this.$emit('riding-lesson-registration-canceled');
    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
