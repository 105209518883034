import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import ManagePenTabs from './components/manage-pen-tabs.vue';
import PaddockStatusHistoryTable from './components/paddock-status-history-table.vue';
import ConfigurationLoadingWrapper from './components/configuration-loading-wrapper.vue';

export const penManagementRoutes: RouteConfig[] = [
  {
    path: 'koppel',
    component: ManagePenTabs,
    children: [
      {
        name: 'manage-pen-paddock-status-history',
        path: '',
        component: PaddockStatusHistoryTable,
        meta: {
          title: 'Koppel',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.PEN_READ,
        },
      },
      {
        name: 'manage-pen-configuration',
        path: 'konfiguration',
        component: ConfigurationLoadingWrapper,
        meta: {
          title: 'Koppel',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.PEN_READ,
        },
      },
    ],
  },
];
