import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import FarmDocumentList from './components/farm-document-list.vue';

export const farmDocumentsRoutes: RouteConfig[] = [
  {
    name: 'show-farm-documents',
    path: '/dokumente',
    component: FarmDocumentList,
    meta: {
      title: 'Dokumente',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
    },
  },
];
