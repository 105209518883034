import { CommandWithFiles } from '@/application/types';
import { getLocalStorageItem, LOCAL_STORAGE_FARM_ID, LOCAL_STORAGE_USER_ID } from '@/helpers/local-storage-helper';

export interface FarmAndUserProperties {
  userId: string;
  farmId: string;
}

export interface UserProperty {
  userId: string;
}

export interface UserPropertyForCommandWithFiles {
  body: {
    userId: string;
  }
}

export interface FarmAndUserPropertiesForCommandWithFiles {
  body: {
    userId: string;
    farmId: string;
  }
}

export class InvalidAuthenticationProperties extends Error {
  constructor(msg: string) {
    super(msg);

    Object.setPrototypeOf(this, InvalidAuthenticationProperties.prototype);
  }
}

export function attachFarmAndUserProperties<T>(body: T): T & FarmAndUserProperties {
  const userId = getLocalStorageItem(LOCAL_STORAGE_USER_ID);
  const farmId = getLocalStorageItem(LOCAL_STORAGE_FARM_ID);
  if (!userId || !farmId) {
    throw new InvalidAuthenticationProperties('User id or farm id is not available');
  }

  return {
    ...body,
    userId,
    farmId,
  };
}

export function attachUserProperty<T>(body: T): T & UserProperty {
  const userId = getLocalStorageItem(LOCAL_STORAGE_USER_ID);
  if (!userId) {
    throw new InvalidAuthenticationProperties('User id is not available');
  }

  return {
    ...body,
    userId,
  };
}

export function attachFarmAndUserPropertiesToCommandWithFiles<T extends CommandWithFiles>(
  commandWithFiles: T
): T & FarmAndUserPropertiesForCommandWithFiles {
  const userId = getLocalStorageItem(LOCAL_STORAGE_USER_ID);
  const farmId = getLocalStorageItem(LOCAL_STORAGE_FARM_ID);
  if (!userId || !farmId) {
    throw new InvalidAuthenticationProperties('User id or farm id is not available');
  }

  return {
    ...commandWithFiles,
    body: {
      ...commandWithFiles.body,
      userId,
      farmId,
    },
  };
}

export function attachUserPropertyToCommandWithFiles<T extends CommandWithFiles>(
  commandWithFiles: T
): T & UserPropertyForCommandWithFiles {
  const userId = getLocalStorageItem(LOCAL_STORAGE_USER_ID);
  if (!userId) {
    throw new InvalidAuthenticationProperties('User id is not available');
  }

  return {
    ...commandWithFiles,
    body: {
      ...commandWithFiles.body,
      userId,
    },
  };
}
