import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FarmServiceTabs from './components/farm-service-tabs.vue';
import FarmServiceUsagesForDay from './components/farm-service-usages-for-day.vue';
import FarmServiceUsagesForMonth from './components/farm-service-usages-for-month.vue';
import FarmServicesConfiguration from './components/farm-services-configuration.vue';
import FarmServiceDetailsLoadingWrapper from './components/farm-service-details-loading-wrapper.vue';

const defaultMeta = {
  title: 'Hofdienste und Hofartikel',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresFeature: Feature.FARM_SERVICES,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.FARM_SERVICES_READ,
};

export const farmServiceRoutes: RouteConfig[] = [
  {
    path: 'hofdienste',
    component: FarmServiceTabs,
    children: [
      {
        name: 'farm-services',
        path: '',
        redirect: { name: 'farm-services-farm-service-usages-for-day' },
        meta: defaultMeta,
      },
      {
        name: 'farm-services-farm-service-usages-for-day',
        path: 'tagesansicht',
        component: FarmServiceUsagesForDay,
        meta: defaultMeta,
      },
      {
        name: 'farm-services-farm-service-usages-for-month',
        path: 'monatsansicht',
        component: FarmServiceUsagesForMonth,
        meta: defaultMeta,
      },
      {
        path: 'konfiguration',
        component: {
          template: '<router-view />',
        },
        meta: defaultMeta,
        children: [
          {
            name: 'farm-services-farm-service-configuration',
            path: '',
            component: FarmServicesConfiguration,
            meta: defaultMeta,
          },
          {
            name: 'farm-services-farm-service-details',
            path: 'hofdienst/:farmServiceId',
            component: FarmServiceDetailsLoadingWrapper,
            meta: {
              ...defaultMeta,
              routeNameForBackButton: 'farm-services-farm-service-configuration',
            },
          },
        ],
      },
    ],
    meta: defaultMeta,
  },
];
