import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import UserRegistration from './components/user-registration.vue';
import ConfirmRegistration from './components/confirm-registration.vue';

export const registrationRoutes: RouteConfig[] = [
  {
    name: 'register',
    path: '/registrierung',
    component: UserRegistration,
    meta: {
      title: 'Registrierung',
      accessibleFor: RouteAccessibility.UNAUTHENTICATED,
    },
  },
  {
    name: 'register-through-request',
    path: '/registrierung-abschliessen/:userRegistrationRequestId',
    component: ConfirmRegistration,
    meta: {
      title: 'Registrierung abschließen',
      accessibleFor: RouteAccessibility.UNAUTHENTICATED,
    },
  },
];
