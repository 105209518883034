import { RouteConfig, RouteMeta } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import CustomerMasterDataTabs from './components/customer-master-data-tabs.vue';
import PersonList from './components/person-list.vue';
import OrganizationList from './components/organization-list.vue';
import CustomerMasterDataConfiguration from './components/customer-master-data-configuration.vue';
import PersonDetailsLoadingWrapper from './components/person-details-loading-wrapper.vue';
import OrganizationDetailsLoadingWrapper from './components/organization-details-loading-wrapper.vue';

const defaultMeta: RouteMeta = {
  title: 'Kundenstammdaten',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresFeature: Feature.CUSTOMER_MASTER_DATA,
  requiresPermission: FarmManagerPermission.CUSTOMER_MASTER_DATA_READ,
};

export const customerMasterDataManagementRoutes: RouteConfig[] = [
  {
    path: 'kundenstammdaten',
    component: CustomerMasterDataTabs,
    meta: defaultMeta,
    children: [
      {
        name: 'customer-master-data',
        path: '',
        redirect: { name: 'customer-master-data-person-list' },
      },
      {
        path: 'personen',
        component: {
          template: '<router-view />',
        },
        meta: defaultMeta,
        children: [
          {
            name: 'customer-master-data-person-list',
            path: '',
            component: PersonList,
            meta: defaultMeta,
          },
          {
            name: 'customer-master-data-person-details',
            path: 'person/:personId',
            component: PersonDetailsLoadingWrapper,
            meta: {
              ...defaultMeta,
              routeNameForBackButton: 'customer-master-data-person-list',
            },
          },
        ],
      },
      {
        path: 'organisationen',
        component: {
          template: '<router-view />',
        },
        meta: defaultMeta,
        children: [
          {
            name: 'customer-master-data-organization-list',
            path: '',
            component: OrganizationList,
            meta: defaultMeta,
          },
          {
            name: 'customer-master-data-organization-details',
            path: 'organisation/:organizationId',
            component: OrganizationDetailsLoadingWrapper,
            meta: {
              ...defaultMeta,
              routeNameForBackButton: 'customer-master-data-organization-list',
            },
          },
        ],
      },
      {
        name: 'customer-master-data-configuration',
        path: 'konfiguration',
        component: CustomerMasterDataConfiguration,
        meta: defaultMeta,
      },
    ],
  },
];
