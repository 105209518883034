import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { ConfirmUserRegistrationCommand, GetUserRegistrationRequestQuery, StartUserRegistrationCommand, UserRegistrationRequest } from './types';

// -- Queries

export function getUserRegistrationRequest(query: GetUserRegistrationRequestQuery): Promise<UserRegistrationRequest> {
  const url = `${apiUrl}/api/unauthorized/user-management/get-user-registration-request-query`;
  return performApiRequest<UserRegistrationRequest>({ url, method: 'POST', data: query });
}

// -- Commands

export function startUserRegistration(command: StartUserRegistrationCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/user-management/start-user-registration-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function confirmUserRegistration(command: ConfirmUserRegistrationCommand): Promise<void> {
  const url = `${apiUrl}/api/unauthorized/user-management/confirm-user-registration-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
