import { AppInfo } from '@capacitor/app';
import { DeviceInfo } from '@capacitor/device';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@/helpers/local-storage-helper';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { Platform } from '@/application/app/types';

const LOCAL_STORAGE_MOBILE_APP_VERSION = 'mobile_app_version';
const LOCAL_STORAGE_MOBILE_PLATFORM = 'mobile_platform';

export function storeAppInformation(appInfo: AppInfo): void {
  setLocalStorageItem(LOCAL_STORAGE_MOBILE_APP_VERSION, appInfo.version);
}

export function storeDeviceInformation(deviceInfo: DeviceInfo): void {
  setLocalStorageItem(LOCAL_STORAGE_MOBILE_PLATFORM, deviceInfo.platform);
}

export function clearDeviceInformation(): void {
  removeLocalStorageItem(LOCAL_STORAGE_MOBILE_APP_VERSION);
}

export function getMobileAppVersion(): string | null {
  if (!isNativeApplication()) {
    return null;
  }

  return getLocalStorageItem(LOCAL_STORAGE_MOBILE_APP_VERSION);
}

export function getPlatform(): Platform | undefined {
  if (!isNativeApplication()) {
    return undefined;
  }

  const platform = getLocalStorageItem(LOCAL_STORAGE_MOBILE_PLATFORM);

  return platform as Platform || undefined;
}
