import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachUserProperty } from '@/helpers/default-parameter-helper';
import { createVaccine, getIllnesses, getVaccines } from './service';
import { CreateVaccineAsAdminCommand, Illness, Vaccine } from './types';

interface ManageVaccinesState {
  vaccines: Vaccine[];
  illnesses: Illness[];

  getVaccinesStatus: ActionStatus;
  getIllnessesStatus: ActionStatus;
  createVaccineStatus: ActionStatus;
}

function initialState(): ManageVaccinesState {
  return {
    vaccines: [],
    illnesses: [],

    getVaccinesStatus: ActionStatus.None,
    getIllnessesStatus: ActionStatus.None,
    createVaccineStatus: ActionStatus.None,
  };
}

export const useVaccineManagementStore = defineStore('manageVaccines', {
  state: (): ManageVaccinesState => initialState(),
  getters: {
    isGetVaccinesProcessing: (state: ManageVaccinesState): boolean =>
      state.getVaccinesStatus === ActionStatus.InProgress,
    isGetIllnessesProcessing: (state: ManageVaccinesState): boolean =>
      state.getIllnessesStatus === ActionStatus.InProgress,
    isCreateVaccineProcessing: (state: ManageVaccinesState): boolean =>
      state.createVaccineStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getVaccines(): Promise<void> {
      const { getVaccinesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getVaccinesStatus,
        () => getVaccines(attachUserProperty({}))
          .then(async (vaccines) => {
            this.vaccines = vaccines;
          })
      );
    },

    getIllnesses(): Promise<void> {
      const { getIllnessesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getIllnessesStatus,
        () => getIllnesses(attachUserProperty({}))
          .then(async (illnesses) => {
            this.illnesses = illnesses;
          })
      );
    },

    // -- Commands

    createVaccine(command: CreateVaccineAsAdminCommand): Promise<void> {
      const { createVaccineStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createVaccineStatus,
        () => createVaccine(attachUserProperty(command))
          .then(() => this.getVaccines())
      );
    },

  },
});
