import Vue from 'vue';
import { isNavigationFailure } from 'vue-router';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/vue';
import { User } from '@sentry/vue';
import { AuthenticatedUser } from '@/application/authentication/types';
import { InvalidAuthenticationProperties } from '@/helpers/default-parameter-helper';

Sentry.init({
  Vue,
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn: process.env.SENTRY_DSN,
  release: process.env.SOURCE_VERSION,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  enableTracing: true,
  tracesSampleRate: process.env.SENTRY_TRACING_RATE_CLIENT,
  logErrors: process.env.ARE_ERRORS_LOGGED_IN_CONSOLE === 'true',
  attachStacktrace: true,
  ignoreErrors: ['Network Error'],
  beforeSend(event, hint) {
    const exceptions = event?.exception?.values || [];
    for (const exception of exceptions) {
      // Delete local cache and reload page on chunk error
      if (exception.type === 'ChunkLoadError') {
        // Fallback for old browser versions
        if (!caches) {
          // eslint-disable-next-line no-restricted-globals
          window.location.reload();
          return null;
        }

        caches.keys()
          .then(async (names) => Promise.all(names.map((name) => caches.delete(name))))
          .then(() => window.location.reload());

        return null;
      }
    }

    // Filter out connection errors with ether have a code of "ECONNABORTED" or the name "Network Error"
    if (hint) {
      const error = hint.originalException;
      if (error instanceof AxiosError
        && error.code === 'ECONNABORTED'
      ) {
        return null;
      }

      if (error instanceof InvalidAuthenticationProperties) {
        return null;
      }

      if (isNavigationFailure(error)) {
        return null;
      }
    }

    return event;
  },
});

export function setAuthenticatedUserForSentry(authenticatedUser: AuthenticatedUser) {
  const user: User = {
    id: authenticatedUser.id,
  };
  if (authenticatedUser.farm) {
    user.farmId = authenticatedUser.farm.id;
  }

  Sentry.setUser(user);
}

export function clearUserForSentry() {
  Sentry.setUser(null);
}
