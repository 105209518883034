import { InternalValueRule } from './types';

export function maxLengthRule(maxLength: number, message: string): InternalValueRule<string> {
  return (value) => value.trim().length <= maxLength
    ? true
    : message;
}

export function csvCompliantRule(message: string): InternalValueRule<string> {
  return (value) => value === ''
    || !value.match('[,;"\']')
    ? true
    : message;
}
