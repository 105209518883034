import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { useAuthenticationStore } from '@/application/authentication/store';
import { createNewsEntry, deleteNewsEntry, deleteNewsEntryAttachment, deleteNewsEntryImage, getConfiguration, getNewsEntries, getUserGroups, hideNewsEntry, markNewsAsHiddenForRiders, markNewsAsVisibleForRiders, releaseNewsEntry, updateNewsEntry, updateNewsEntryVisibility } from './service';
import { CreateNewsEntryAsManagerCommand, DeleteNewsEntryAttachmentAsManagerCommand, DeleteNewsEntryAsMangerCommand, DeleteNewsEntryImageAsManagerCommand, HideNewsEntryAsManagerCommand, NewsEntry, ReleaseNewsEntryAsManagerCommand, UpdateNewsEntryAsManagerCommand, UpdateNewsEntryVisibilityAsManagerCommand, Configuration, UserGroup } from './types';

interface NewsManagementState {
  entries: NewsEntry[];
  userGroups: UserGroup[];
  configuration: Configuration | null,

  getNewsEntriesStatus: ActionStatus;
  getConfigurationStatus: ActionStatus;
  createEntryStatus: ActionStatus;
  updateEntryStatus: ActionStatus;
  deleteNewsEntryStatus: ActionStatus;
  hideNewsEntryStatus: ActionStatus;
  releaseNewsEntryStatus: ActionStatus;
  updateNewsEntryVisibilityStatus: ActionStatus;
  deleteNewsEntryAttachmentStatus: ActionStatus;
  deleteNewsEntryImageStatus: ActionStatus;
  markNewsAsVisibleForRidersStatus: ActionStatus;
  markNewsAsHiddenForRidersStatus: ActionStatus;
  getUserGroupsStatus: ActionStatus;
}

function initialState(): NewsManagementState {
  return {
    entries: [],
    userGroups: [],
    configuration: null,

    getNewsEntriesStatus: ActionStatus.None,
    getConfigurationStatus: ActionStatus.None,
    createEntryStatus: ActionStatus.None,
    updateEntryStatus: ActionStatus.None,
    deleteNewsEntryStatus: ActionStatus.None,
    hideNewsEntryStatus: ActionStatus.None,
    releaseNewsEntryStatus: ActionStatus.None,
    updateNewsEntryVisibilityStatus: ActionStatus.None,
    deleteNewsEntryAttachmentStatus: ActionStatus.None,
    deleteNewsEntryImageStatus: ActionStatus.None,
    markNewsAsVisibleForRidersStatus: ActionStatus.None,
    markNewsAsHiddenForRidersStatus: ActionStatus.None,
    getUserGroupsStatus: ActionStatus.None,
  };
}

export const useNewsManagementStore = defineStore('newsManagement', {
  state: (): NewsManagementState => initialState(),
  getters: {
    isGetNewsEntriesProcessing: (state: NewsManagementState): boolean =>
      state.getNewsEntriesStatus === ActionStatus.InProgress,
    isGetConfigurationProcessing: (state: NewsManagementState): boolean =>
      state.getConfigurationStatus === ActionStatus.InProgress,
    isCreateEntryProcessing: (state: NewsManagementState): boolean =>
      state.createEntryStatus === ActionStatus.InProgress,
    isUpdateEntryProcessing: (state: NewsManagementState): boolean =>
      state.updateEntryStatus === ActionStatus.InProgress,
    isDeleteNewsEntryProcessing: (state: NewsManagementState): boolean =>
      state.deleteNewsEntryStatus === ActionStatus.InProgress,
    isHideNewsEntryProcessing: (state: NewsManagementState): boolean =>
      state.hideNewsEntryStatus === ActionStatus.InProgress,
    isReleaseNewsEntryProcessing: (state: NewsManagementState): boolean =>
      state.releaseNewsEntryStatus === ActionStatus.InProgress,
    isUpdateNewsEntryVisibilityProcessing: (state: NewsManagementState): boolean =>
      state.updateNewsEntryVisibilityStatus === ActionStatus.InProgress,
    isDeleteNewsEntryAttachmentProcessing: (state: NewsManagementState): boolean =>
      state.deleteNewsEntryAttachmentStatus === ActionStatus.InProgress,
    isDeleteNewsEntryImageProcessing: (state: NewsManagementState): boolean =>
      state.deleteNewsEntryImageStatus === ActionStatus.InProgress,
    isMarkNewsAsVisibleForRidersProcessing: (state: NewsManagementState): boolean =>
      state.markNewsAsVisibleForRidersStatus === ActionStatus.InProgress,
    isMarkNewsAsHiddenForRidersProcessing: (state: NewsManagementState): boolean =>
      state.markNewsAsHiddenForRidersStatus === ActionStatus.InProgress,
    isGetUserGroupsProcessing: (state: NewsManagementState): boolean =>
      state.getUserGroupsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getNewsEntries(): Promise<void> {
      const { getNewsEntriesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNewsEntriesStatus,
        () => getNewsEntries(attachFarmAndUserProperties({}))
          .then((entries) => {
            this.entries = entries;
          })
      );
    },

    getConfiguration(): Promise<void> {
      const { getConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getConfigurationStatus,
        () => getConfiguration(attachFarmAndUserProperties({}))
          .then((configuration) => {
            this.configuration = configuration;
          })
      );
    },

    getUserGroups(): Promise<void> {
      const { getUserGroupsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserGroupsStatus,
        () => getUserGroups(attachFarmAndUserProperties({}))
          .then((userGroups) => {
            this.userGroups = userGroups;
          })
      );
    },

    // -- Commands

    createEntry(command: CreateNewsEntryAsManagerCommand): Promise<void> {
      const { createEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createEntryStatus,
        () => createNewsEntry(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getNewsEntries())
      );
    },

    updateEntry(command: UpdateNewsEntryAsManagerCommand): Promise<void> {
      const { updateEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEntryStatus,
        () => updateNewsEntry(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getNewsEntries())
      );
    },

    deleteNewsEntry(command: DeleteNewsEntryAsMangerCommand): Promise<void> {
      const { deleteNewsEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteNewsEntryStatus,
        () => deleteNewsEntry(attachFarmAndUserProperties(command))
          .then(() => this.getNewsEntries())
      );
    },

    hideNewsEntry(command: HideNewsEntryAsManagerCommand): Promise<void> {
      const { hideNewsEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        hideNewsEntryStatus,
        () => hideNewsEntry(attachFarmAndUserProperties(command))
          .then(() => this.getNewsEntries())
      );
    },

    releaseNewsEntry(command: ReleaseNewsEntryAsManagerCommand): Promise<void> {
      const { releaseNewsEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        releaseNewsEntryStatus,
        () => releaseNewsEntry(attachFarmAndUserProperties(command))
          .then(() => this.getNewsEntries())
      );
    },

    updateNewsEntryVisibility(command: UpdateNewsEntryVisibilityAsManagerCommand): Promise<void> {
      const { updateNewsEntryVisibilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNewsEntryVisibilityStatus,
        () => updateNewsEntryVisibility(attachFarmAndUserProperties(command))
          .then(() => this.getNewsEntries())
      );
    },

    deleteNewsEntryAttachment(command: DeleteNewsEntryAttachmentAsManagerCommand): Promise<void> {
      const { deleteNewsEntryAttachmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteNewsEntryAttachmentStatus,
        () => deleteNewsEntryAttachment(attachFarmAndUserProperties(command))
          .then(() => this.getNewsEntries())
      );
    },

    deleteNewsEntryImage(command: DeleteNewsEntryImageAsManagerCommand): Promise<void> {
      const { deleteNewsEntryImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteNewsEntryImageStatus,
        () => deleteNewsEntryImage(attachFarmAndUserProperties(command))
          .then(() => this.getNewsEntries())
      );
    },

    markNewsAsVisibleForRiders(): Promise<void> {
      const { markNewsAsVisibleForRidersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markNewsAsVisibleForRidersStatus,
        () => markNewsAsVisibleForRiders(attachFarmAndUserProperties({}))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    markNewsAsHiddenForRiders(): Promise<void> {
      const { markNewsAsHiddenForRidersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markNewsAsHiddenForRidersStatus,
        () => markNewsAsHiddenForRiders(attachFarmAndUserProperties({}))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

  },
});
