
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { ConcentratedFeedId } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { GerblhofConcentratedFeeding, GerblhofConcentratedFeedType } from '../types';

interface Controls extends FormControls {
  type: FormControl<ConcentratedFeedId>;
  amountMorning: FormControl<number>;
  amountNoon: FormControl<number>;
  amountEvening: FormControl<number>;
}

@Component
export default class CreateGerblhofConcentratedFeedDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.largeNested;

  @Prop({ type: Array, required: true })
  readonly concentratedFeedings!: GerblhofConcentratedFeeding[];

  @Prop({ type: Array, required: true })
  readonly notConfiguredConcentratedFeedTypes!: GerblhofConcentratedFeedType[];

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        type: {
          label: 'Kraftfutter',
          value: this.notConfiguredConcentratedFeedTypes[0].concentratedFeedId,
          isRequired: true,
        },
        amountMorning: {
          label: 'Morgens',
          value: 0,
          isRequired: true,
        },
        amountNoon: {
          label: 'Mittags',
          value: 0,
          isRequired: true,
        },
        amountEvening: {
          label: 'Abends',
          value: 0,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const option: GerblhofConcentratedFeeding = {
      type: formValues.type!,
      amountMorning: formValues.amountMorning! * 1_000,
      amountNoon: formValues.amountNoon! * 1_000,
      amountEvening: formValues.amountEvening! * 1_000,
    };

    this.$emit('submitted', option);

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
    this.$emit('closed');
  }

}
