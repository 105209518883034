import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { Date } from '@/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { getFarmChanges } from './service';
import { FarmChange, GetFarmChangesAsManagerQuery } from './types';

interface ShowFarmChangesForDayState {
  dateForFarmChanges: Date | null;
  entries: FarmChange[];

  getFarmChangesStatus: ActionStatus;
}

function initialState(): ShowFarmChangesForDayState {
  return {
    dateForFarmChanges: null,
    entries: [],

    getFarmChangesStatus: ActionStatus.None,
  };
}

export const useShowFarmChangesForDayStore = defineStore('showFarmChangesForDay', {
  state: (): ShowFarmChangesForDayState => initialState(),
  getters: {
    isGetFarmChangesProcessing: (state: ShowFarmChangesForDayState): boolean =>
      state.getFarmChangesStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateDateForFarmChanges(date: Date): Promise<void> {
      this.dateForFarmChanges = date;

      return this.getFarmChanges();
    },

    // -- Queries

    getFarmChanges(): Promise<void> {
      const query: GetFarmChangesAsManagerQuery = {
        date: this.dateForFarmChanges!,
      };
      const { getFarmChangesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmChangesStatus,
        () => getFarmChanges(attachFarmAndUserProperties(query))
          .then((entries) => {
            this.entries = entries;
          })
      );
    },

  },
});
