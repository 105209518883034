import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { createTimeEntry, getSummaryForUser } from './service';
import { CreateTimeEntryCommand, GetSummaryForUserQuery, Summary } from './types';

interface LaborServiceState {
  currentSummaryYear: number | null;
  summary: Summary | null;

  getSummaryForUserStatus: ActionStatus;
  createTimeEntryStatus: ActionStatus;
}

function initialState(): LaborServiceState {
  return {
    currentSummaryYear: null,
    summary: null,

    getSummaryForUserStatus: ActionStatus.None,
    createTimeEntryStatus: ActionStatus.None,
  };
}

export const useLaborServiceStore = defineStore('laborService', {
  state: (): LaborServiceState => initialState(),
  getters: {
    isGetSummaryForUserProcessing: (state: LaborServiceState): boolean =>
      state.getSummaryForUserStatus === ActionStatus.InProgress,
    isCreateTimeEntryProcessing: (state: LaborServiceState): boolean =>
      state.createTimeEntryStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSummaryYear(summaryYear: number): Promise<void> {
      this.currentSummaryYear = summaryYear;

      return this.getSummaryForUser();
    },

    // -- Queries

    getSummaryForUser(): Promise<void> {
      const query: GetSummaryForUserQuery = {
        summaryYear: this.currentSummaryYear!,
      };
      const { getSummaryForUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSummaryForUserStatus,
        () => getSummaryForUser(attachFarmAndUserProperties(query))
          .then(async (summary) => {
            this.summary = summary;
          })
      );
    },

    // -- Commands

    createTimeEntry(command: CreateTimeEntryCommand): Promise<void> {
      const { createTimeEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createTimeEntryStatus,
        () => createTimeEntry(attachFarmAndUserProperties(command))
          .then(() => this.getSummaryForUser())
      );
    },

  },
});
