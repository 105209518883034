
import { Component, Vue } from 'vue-property-decorator';
import { LoginCommand } from '@/application/authentication/types';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { showErrorResponse } from '@/application/snackbar/service';
import { navigate } from '@/helpers/navigation-helpers';
import { useAuthenticationStore } from '@/application/authentication/store';
import { EmailAddress } from '@/types';

interface Controls extends FormControls {
  email: FormControl<EmailAddress>;
  password: FormControl<string>;
}

@Component
export default class Login extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  readonly isNativeApplication = isNativeApplication;

  form: Form<Controls> | null = null;

  get isProcessing(): boolean {
    return this.authenticationStore.isLoginProcessing;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        email: {
          label: 'E-Mail-Adresse',
          value: null,
          isRequired: true,
        },
        password: {
          label: 'Passwort',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: LoginCommand = {
      emailAddress: formValues.email!,
      password: formValues.password!,
    };

    this.authenticationStore.login(command)
      .then(() => navigate({ name: 'root' }))
      .catch((error) => showErrorResponse(error));
  }

}
