import { defineStore, storeToRefs } from 'pinia';
import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { ArchivedStatus, CSV, NameOrderType, OrganizationId, PersonId } from '@/types';
import { archiveOrganization, archivePerson, assignOptionToPersons, createCustomField, createOrganization, createPerson, createPersonDocument, defineInvoiceAddressOfOrganization, defineInvoiceAddressOfPerson, deleteCustomField, deletePersonDocument, getCustomFields, getDocumentPDF, getDocumentsForPerson, getInvoicePDF, getInvoicesForPerson, getOrganization, getOrganizations, getOrganizationsAsCSV, getOrganizationsForSelection, getPerson, getPersons, getPersonsAsCSV, getPersonsForSelection, getServicesForPerson, removeInvoiceAddressOfOrganization, removeInvoiceAddressOfPerson, updateAdditionalMasterDataOfOrganization, updateAdditionalMasterDataOfPerson, updateAddressOfOrganization, updateAddressOfPerson, updateAssignedPersonsOfOrganization, updateCustomFieldShownWithAssignedPersons, updateEmailAddressOfPerson, updateInvoiceAddressOfOrganization, updateInvoiceAddressOfPerson, updateLabelOfCustomField, updateNameOfOrganization, updateNameOfPerson, updateOptionsOfCustomField, updateSalutationOfPerson } from './service';
import { ArchiveOrganizationAsManagerWorkflow, ArchivePersonAsManagerWorkflow, AssignOptionToPersonsAsManagerCommand, CreateCustomFieldAsManagerCommand, CreateOrganizationAsManagerWorkflow, CreatePersonAsManagerWorkflow, CreatePersonDocumentAsManagerCommand, CustomFields, DefineInvoiceAddressOfOrganizationAsManagerWorkflow, DefineInvoiceAddressOfPersonAsManagerWorkflow, DeleteCustomFieldAsManagerCommand, DeletePersonDocumentAsManagerCommand, GetDocumentPDFAsManagerQuery, GetDocumentsForPersonAsManagerQuery, GetInvoicePDFAsManagerQuery, GetInvoicesForPersonAsManagerQuery, GetOrganizationAsManagerQuery, GetOrganizationsAsCSVAsManagerQuery, GetOrganizationsAsManagerQuery, GetPersonAsManagerQuery, GetPersonsAsCSVAsManagerQuery, GetPersonsAsManagerQuery, GetServicesForPersonAsManagerQuery, Invoice, Organization, OrganizationForList, OrganizationReference, OrganizationsFilter, Person, PersonDocument, PersonForList, PersonReference, PersonsFilter, RemoveInvoiceAddressOfOrganizationAsManagerWorkflow, RemoveInvoiceAddressOfPersonAsManagerWorkflow, Service, UpdateAdditionalMasterDataOfOrganizationAsManagerCommand, UpdateAdditionalMasterDataOfPersonAsManagerCommand, UpdateAddressOfOrganizationAsManagerCommand, UpdateAddressOfPersonAsManagerCommand, UpdateAssignedPersonsOfOrganizationAsManagerWorkflow, UpdateCustomFieldShownWithAssignedPersonsAsManagerCommand, UpdateEmailAddressOfPersonAsManagerCommand, UpdateInvoiceAddressOfOrganizationAsManagerWorkflow, UpdateInvoiceAddressOfPersonAsManagerWorkflow, UpdateLabelOfCustomFieldAsManagerCommand, UpdateNameOfOrganizationAsManagerWorkflow, UpdateNameOfPersonAsManagerWorkflow, UpdateOptionsOfCustomFieldAsManagerCommand, UpdateSalutationOfPersonAsManagerCommand } from './types';

interface CustomerMasterDataManagementState {
  searchForPersons: string | null;
  nameOrderTypeForPersons: NameOrderType;
  filterForPersons: PersonsFilter;
  limitForPersons: number;
  totalCountOfPersons: number | null;
  persons: PersonForList[];
  currentPerson: Person | null;
  idOfCurrentPerson: PersonId | null;
  servicesForCurrentPerson: Service[] | null;
  invoicesForCurrentPerson: Invoice[] | null;
  documentsForCurrentPerson: PersonDocument[] | null;

  searchForOrganizations: string | null;
  filterForOrganizations: OrganizationsFilter;
  limitForOrganizations: number;
  totalCountOfOrganizations: number | null;
  organizations: OrganizationForList[];
  currentOrganization: Organization | null;
  idOfCurrentOrganization: OrganizationId | null;

  personsForSelection: PersonReference[] | null;
  organizationsForSelection: OrganizationReference[] | null;

  customFields: CustomFields | null;

  getPersonsStatus: ActionStatus;
  getOrganizationsStatus: ActionStatus;
  createPersonStatus: ActionStatus;
  updateNameOfPersonStatus: ActionStatus;
  updateAddressOfPersonStatus: ActionStatus;
  defineInvoiceAddressOfPersonStatus: ActionStatus;
  updateInvoiceAddressOfPersonStatus: ActionStatus;
  removeInvoiceAddressOfPersonStatus: ActionStatus;
  updateAdditionalMasterDataOfPersonStatus: ActionStatus;
  createOrganizationStatus: ActionStatus;
  updateNameOfOrganizationStatus: ActionStatus;
  updateAddressOfOrganizationStatus: ActionStatus;
  defineInvoiceAddressOfOrganizationStatus: ActionStatus;
  updateInvoiceAddressOfOrganizationStatus: ActionStatus;
  removeInvoiceAddressOfOrganizationStatus: ActionStatus;
  updateAdditionalMasterDataOfOrganizationStatus: ActionStatus;
  getPersonsForSelectionStatus: ActionStatus;
  getOrganizationsForSelectionStatus: ActionStatus;
  updateAssignedPersonsOfOrganizationStatus: ActionStatus;
  createCustomFieldStatus: ActionStatus;
  getCustomFieldsStatus: ActionStatus;
  updateLabelOfCustomFieldStatus: ActionStatus;
  updateOptionsOfCustomFieldStatus: ActionStatus;
  getServicesForPersonStatus: ActionStatus;
  getInvoicesForPersonStatus: ActionStatus;
  getInvoicePDFStatus: ActionStatus;
  updateEmailAddressOfPersonStatus: ActionStatus;
  updateSalutationOfPersonStatus: ActionStatus;
  getPersonStatus: ActionStatus;
  getOrganizationStatus: ActionStatus;
  getPersonsAsCSVStatus: ActionStatus;
  getOrganizationsAsCSVStatus: ActionStatus;
  archivePersonStatus: ActionStatus;
  archiveOrganizationStatus: ActionStatus;
  getDocumentsForPersonStatus: ActionStatus;
  createPersonDocumentStatus: ActionStatus;
  getDocumentPDFStatus: ActionStatus;
  deletePersonDocumentStatus: ActionStatus;
  updateCustomFieldShownWithAssignedPersonsStatus: ActionStatus;
  deleteCustomFieldStatus: ActionStatus;
  assignOptionToPersonsStatus: ActionStatus;
}

function initialState(): CustomerMasterDataManagementState {
  return {
    searchForPersons: null,
    nameOrderTypeForPersons: NameOrderType.FIRST_NAME_FIRST,
    filterForPersons: {
      addressCountry: null,
      invoiceAddressCountry: null,
      language: null,
      customFields: null,
      archivedStatus: ArchivedStatus.NOT_ARCHIVED,
      organizationId: null,
    },
    limitForPersons: 50,
    totalCountOfPersons: null,
    persons: [],
    currentPerson: null,
    idOfCurrentPerson: null,
    servicesForCurrentPerson: null,
    invoicesForCurrentPerson: null,
    documentsForCurrentPerson: null,

    searchForOrganizations: null,
    filterForOrganizations: {
      addressCountry: null,
      invoiceAddressCountry: null,
      language: null,
      customFields: null,
      archivedStatus: ArchivedStatus.NOT_ARCHIVED,
    },
    limitForOrganizations: 50,
    totalCountOfOrganizations: null,
    organizations: [],
    currentOrganization: null,
    idOfCurrentOrganization: null,

    personsForSelection: null,
    organizationsForSelection: null,

    customFields: null,

    getPersonsStatus: ActionStatus.None,
    getOrganizationsStatus: ActionStatus.None,
    createPersonStatus: ActionStatus.None,
    updateNameOfPersonStatus: ActionStatus.None,
    updateAddressOfPersonStatus: ActionStatus.None,
    defineInvoiceAddressOfPersonStatus: ActionStatus.None,
    updateInvoiceAddressOfPersonStatus: ActionStatus.None,
    removeInvoiceAddressOfPersonStatus: ActionStatus.None,
    updateAdditionalMasterDataOfPersonStatus: ActionStatus.None,
    createOrganizationStatus: ActionStatus.None,
    updateNameOfOrganizationStatus: ActionStatus.None,
    updateAddressOfOrganizationStatus: ActionStatus.None,
    defineInvoiceAddressOfOrganizationStatus: ActionStatus.None,
    updateInvoiceAddressOfOrganizationStatus: ActionStatus.None,
    removeInvoiceAddressOfOrganizationStatus: ActionStatus.None,
    updateAdditionalMasterDataOfOrganizationStatus: ActionStatus.None,
    getPersonsForSelectionStatus: ActionStatus.None,
    getOrganizationsForSelectionStatus: ActionStatus.None,
    updateAssignedPersonsOfOrganizationStatus: ActionStatus.None,
    createCustomFieldStatus: ActionStatus.None,
    getCustomFieldsStatus: ActionStatus.None,
    updateLabelOfCustomFieldStatus: ActionStatus.None,
    updateOptionsOfCustomFieldStatus: ActionStatus.None,
    getServicesForPersonStatus: ActionStatus.None,
    getInvoicesForPersonStatus: ActionStatus.None,
    getInvoicePDFStatus: ActionStatus.None,
    updateEmailAddressOfPersonStatus: ActionStatus.None,
    updateSalutationOfPersonStatus: ActionStatus.None,
    getPersonStatus: ActionStatus.None,
    getOrganizationStatus: ActionStatus.None,
    getPersonsAsCSVStatus: ActionStatus.None,
    getOrganizationsAsCSVStatus: ActionStatus.None,
    archivePersonStatus: ActionStatus.None,
    archiveOrganizationStatus: ActionStatus.None,
    getDocumentsForPersonStatus: ActionStatus.None,
    createPersonDocumentStatus: ActionStatus.None,
    getDocumentPDFStatus: ActionStatus.None,
    deletePersonDocumentStatus: ActionStatus.None,
    updateCustomFieldShownWithAssignedPersonsStatus: ActionStatus.None,
    deleteCustomFieldStatus: ActionStatus.None,
    assignOptionToPersonsStatus: ActionStatus.None,
  };
}

export const useCustomerMasterDataManagementStore = defineStore('customerMasterDataManagement', {
  state: (): CustomerMasterDataManagementState => initialState(),
  getters: {
    isGetPersonsProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getPersonsStatus === ActionStatus.InProgress,
    isGetPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getPersonStatus === ActionStatus.InProgress,
    isGetOrganizationsProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getOrganizationsStatus === ActionStatus.InProgress,
    isGetOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getOrganizationStatus === ActionStatus.InProgress,
    isCreatePersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.createPersonStatus === ActionStatus.InProgress,
    isUpdateNameOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateNameOfPersonStatus === ActionStatus.InProgress,
    isUpdateAddressOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateAddressOfPersonStatus === ActionStatus.InProgress,
    isDefineInvoiceAddressOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.defineInvoiceAddressOfPersonStatus === ActionStatus.InProgress,
    isUpdateInvoiceAddressOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateInvoiceAddressOfPersonStatus === ActionStatus.InProgress,
    isRemoveInvoiceAddressOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.removeInvoiceAddressOfPersonStatus === ActionStatus.InProgress,
    isUpdateAdditionalMasterDataOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateAdditionalMasterDataOfPersonStatus === ActionStatus.InProgress,
    isCreateOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.createOrganizationStatus === ActionStatus.InProgress,
    isUpdateNameOfOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateNameOfOrganizationStatus === ActionStatus.InProgress,
    isUpdateAddressOfOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateAddressOfOrganizationStatus === ActionStatus.InProgress,
    isDefineInvoiceAddressOfOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.defineInvoiceAddressOfOrganizationStatus === ActionStatus.InProgress,
    isUpdateInvoiceAddressOfOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateInvoiceAddressOfOrganizationStatus === ActionStatus.InProgress,
    isRemoveInvoiceAddressOfOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.removeInvoiceAddressOfOrganizationStatus === ActionStatus.InProgress,
    isUpdateAdditionalMasterDataOfOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateAdditionalMasterDataOfOrganizationStatus === ActionStatus.InProgress,
    isGetPersonsForSelectionProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getPersonsForSelectionStatus === ActionStatus.InProgress,
    isGetOrganizationsForSelectionProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getOrganizationsForSelectionStatus === ActionStatus.InProgress,
    isUpdateAssignedPersonsOfOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateAssignedPersonsOfOrganizationStatus === ActionStatus.InProgress,
    isCreateCustomFieldProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.createCustomFieldStatus === ActionStatus.InProgress,
    isGetCustomFieldsProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getCustomFieldsStatus === ActionStatus.InProgress,
    isUpdateLabelOfCustomFieldProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateLabelOfCustomFieldStatus === ActionStatus.InProgress,
    isUpdateOptionsOfCustomFieldProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateOptionsOfCustomFieldStatus === ActionStatus.InProgress,
    isGetServicesForPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getServicesForPersonStatus === ActionStatus.InProgress,
    isGetInvoicesForPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getInvoicesForPersonStatus === ActionStatus.InProgress,
    isGetInvoicePDFProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getInvoicePDFStatus === ActionStatus.InProgress,
    isUpdateEmailAddressOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateEmailAddressOfPersonStatus === ActionStatus.InProgress,
    isUpdateSalutationOfPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateSalutationOfPersonStatus === ActionStatus.InProgress,
    isGetPersonsAsCSVProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getPersonsAsCSVStatus === ActionStatus.InProgress,
    isGetOrganizationsAsCSVProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getOrganizationsAsCSVStatus === ActionStatus.InProgress,
    isArchivePersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.archivePersonStatus === ActionStatus.InProgress,
    isArchiveOrganizationProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.archiveOrganizationStatus === ActionStatus.InProgress,
    isGetDocumentsForPersonProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getDocumentsForPersonStatus === ActionStatus.InProgress,
    isCreatePersonDocumentProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.createPersonDocumentStatus === ActionStatus.InProgress,
    isGetDocumentPDFProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.getDocumentPDFStatus === ActionStatus.InProgress,
    isDeletePersonDocumentProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.deletePersonDocumentStatus === ActionStatus.InProgress,
    isUpdateCustomFieldShownWithAssignedPersonsProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.updateCustomFieldShownWithAssignedPersonsStatus === ActionStatus.InProgress,
    isDeleteCustomFieldProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.deleteCustomFieldStatus === ActionStatus.InProgress,
    isAssignOptionToPersonsProcessing: (state: CustomerMasterDataManagementState): boolean =>
      state.assignOptionToPersonsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSearchForPersons(search: string | null): Promise<void> {
      this.searchForPersons = search;

      return this.getPersons();
    },

    updateNameOrderTypeForPersons(nameOrderType: NameOrderType): Promise<void> {
      this.nameOrderTypeForPersons = nameOrderType;

      return this.getPersons();
    },

    updateFiltersForPersons(personsFilter: PersonsFilter): Promise<void> {
      this.filterForPersons = personsFilter;

      return this.getPersons();
    },

    resetFiltersForPersons(): Promise<void> {
      this.filterForPersons = {
        addressCountry: null,
        invoiceAddressCountry: null,
        language: null,
        customFields: null,
        archivedStatus: ArchivedStatus.NOT_ARCHIVED,
        organizationId: null,
      };

      return this.getPersons();
    },

    updateFiltersForOrganizations(organizationsFilter: OrganizationsFilter): Promise<void> {
      this.filterForOrganizations = organizationsFilter;

      return this.getOrganizations();
    },

    resetFiltersForOrganizations(): Promise<void> {
      this.filterForOrganizations = {
        addressCountry: null,
        invoiceAddressCountry: null,
        language: null,
        customFields: null,
        archivedStatus: ArchivedStatus.NOT_ARCHIVED,
      };

      return this.getOrganizations();
    },

    increaseLimitForPersons(): Promise<void> {
      this.limitForPersons += 50;

      return this.getPersons();
    },

    increaseLimitForOrganizations(): Promise<void> {
      this.limitForOrganizations += 50;

      return this.getOrganizations();
    },

    updateSearchForOrganizations(search: string | null): Promise<void> {
      this.searchForOrganizations = search;

      return this.getOrganizations();
    },

    updateCurrentPerson(personId: PersonId): Promise<void> {
      this.idOfCurrentPerson = personId;

      return this.getPerson();
    },

    resetCurrentPerson(): Promise<void> {
      this.idOfCurrentPerson = null;

      return Promise.resolve();
    },

    resetLimitForPersons(): Promise<void> {
      this.limitForPersons = 50;

      return Promise.resolve();
    },

    resetLimitForOrganizations(): Promise<void> {
      this.limitForOrganizations = 50;

      return Promise.resolve();
    },

    updateCurrentOrganization(organizationId: OrganizationId): Promise<void> {
      this.idOfCurrentOrganization = organizationId;

      return this.getOrganization();
    },

    resetCurrentOrganization(): Promise<void> {
      this.idOfCurrentOrganization = null;

      return Promise.resolve();
    },

    resetServicesForCurrentPerson(): Promise<void> {
      this.servicesForCurrentPerson = null;

      return Promise.resolve();
    },

    resetInvoicesForCurrentPerson(): Promise<void> {
      this.invoicesForCurrentPerson = null;

      return Promise.resolve();
    },

    resetDocumentsForCurrentPerson(): Promise<void> {
      this.documentsForCurrentPerson = null;

      return Promise.resolve();
    },

    // -- Queries

    getPersons(): Promise<void> {
      const query: GetPersonsAsManagerQuery = {
        search: this.searchForPersons,
        nameOrderType: this.nameOrderTypeForPersons,
        addressCountry: this.filterForPersons.addressCountry,
        invoiceAddressCountry: this.filterForPersons.invoiceAddressCountry,
        language: this.filterForPersons.language,
        customFields: this.filterForPersons.customFields,
        archivedStatus: this.filterForPersons.archivedStatus,
        organizationId: this.filterForPersons.organizationId,
        limit: this.limitForPersons,
      };
      const { getPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonsStatus,
        () => getPersons(attachFarmAndUserProperties(query))
          .then((response) => {
            this.totalCountOfPersons = response.totalCount;
            this.persons = response.persons;
          })
      );
    },

    getPersonsAsCSV(): Promise<CSV> {
      const query: GetPersonsAsCSVAsManagerQuery = {
        search: this.searchForPersons,
        addressCountry: this.filterForPersons.addressCountry,
        invoiceAddressCountry: this.filterForPersons.invoiceAddressCountry,
        language: this.filterForPersons.language,
        customFields: this.filterForPersons.customFields,
        archivedStatus: this.filterForPersons.archivedStatus,
        organizationId: this.filterForPersons.organizationId,
      };
      const { getPersonsAsCSVStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonsAsCSVStatus,
        () => getPersonsAsCSV(attachFarmAndUserProperties(query))
      );
    },

    getPerson(): Promise<void> {
      const query: GetPersonAsManagerQuery = {
        personId: this.idOfCurrentPerson!,
      };
      const { getPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonStatus,
        () => getPerson(attachFarmAndUserProperties(query))
          .then((person) => {
            this.currentPerson = person;
          })
      );
    },

    getOrganizations(): Promise<void> {
      const query: GetOrganizationsAsManagerQuery = {
        search: this.searchForOrganizations,
        addressCountry: this.filterForOrganizations.addressCountry,
        invoiceAddressCountry: this.filterForOrganizations.invoiceAddressCountry,
        language: this.filterForOrganizations.language,
        customFields: this.filterForOrganizations.customFields,
        archivedStatus: this.filterForOrganizations.archivedStatus,
        limit: this.limitForOrganizations,
      };
      const { getOrganizationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOrganizationsStatus,
        () => getOrganizations(attachFarmAndUserProperties(query))
          .then((response) => {
            this.totalCountOfOrganizations = response.totalCount;
            this.organizations = response.organizations;
          })
      );
    },

    getOrganizationsAsCSV(): Promise<CSV> {
      const query: GetOrganizationsAsCSVAsManagerQuery = {
        search: this.searchForPersons,
        addressCountry: this.filterForOrganizations?.addressCountry ?? null,
        invoiceAddressCountry: this.filterForOrganizations?.invoiceAddressCountry ?? null,
        language: this.filterForOrganizations?.language ?? null,
        customFields: this.filterForOrganizations?.customFields ?? null,
      };
      const { getOrganizationsAsCSVStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOrganizationsAsCSVStatus,
        () => getOrganizationsAsCSV(attachFarmAndUserProperties(query))
      );
    },

    getOrganization(): Promise<void> {
      const query: GetOrganizationAsManagerQuery = {
        organizationId: this.idOfCurrentOrganization!,
      };
      const { getOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOrganizationStatus,
        () => getOrganization(attachFarmAndUserProperties(query))
          .then((organization) => {
            this.currentOrganization = organization;
          })
      );
    },

    getPersonsForSelection(): Promise<void> {
      const { getPersonsForSelectionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPersonsForSelectionStatus,
        () => getPersonsForSelection(attachFarmAndUserProperties({}))
          .then((personsForSelection) => {
            this.personsForSelection = personsForSelection;
          })
      );
    },

    getOrganizationsForSelection(): Promise<void> {
      const { getOrganizationsForSelectionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getOrganizationsForSelectionStatus,
        () => getOrganizationsForSelection(attachFarmAndUserProperties({}))
          .then((organizationsForSelection) => {
            this.organizationsForSelection = organizationsForSelection;
          })
      );
    },

    getCustomFields(): Promise<void> {
      const { getCustomFieldsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCustomFieldsStatus,
        () => getCustomFields(attachFarmAndUserProperties({}))
          .then((customFields) => {
            this.customFields = customFields;
          })
      );
    },

    getServicesForPerson(): Promise<void> {
      const query: GetServicesForPersonAsManagerQuery = {
        personId: this.idOfCurrentPerson!,
      };
      const { getServicesForPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getServicesForPersonStatus,
        () => getServicesForPerson(attachFarmAndUserProperties(query))
          .then((servicesForCurrentPerson) => {
            this.servicesForCurrentPerson = servicesForCurrentPerson;
          })
      );
    },

    getInvoicesForPerson(): Promise<void> {
      const query: GetInvoicesForPersonAsManagerQuery = {
        personId: this.idOfCurrentPerson!,
      };
      const { getInvoicesForPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicesForPersonStatus,
        () => getInvoicesForPerson(attachFarmAndUserProperties(query))
          .then((invoicesForCurrentPerson) => {
            this.invoicesForCurrentPerson = invoicesForCurrentPerson;
          })
      );
    },

    getInvoicePDFAsFarmManager(query: GetInvoicePDFAsManagerQuery): Promise<FileResponse> {
      const { getInvoicePDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicePDFStatus,
        () => getInvoicePDF(attachFarmAndUserProperties(query))
      );
    },

    getDocumentsForPerson(): Promise<void> {
      const query: GetDocumentsForPersonAsManagerQuery = {
        personId: this.idOfCurrentPerson!,
      };
      const { getDocumentsForPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentsForPersonStatus,
        () => getDocumentsForPerson(attachFarmAndUserProperties(query))
          .then((documentsForCurrentPerson) => {
            this.documentsForCurrentPerson = documentsForCurrentPerson;
          })
      );
    },

    getDocumentPDF(query: GetDocumentPDFAsManagerQuery): Promise<FileResponse> {
      const { getDocumentPDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentPDFStatus,
        () => getDocumentPDF(attachFarmAndUserProperties(query))
      );
    },

    // -- Commands

    createPerson(command: CreatePersonAsManagerWorkflow): Promise<void> {
      const { createPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPersonStatus,
        () => createPerson(attachFarmAndUserProperties(command))
          .then(() => this.getPersons())
      );
    },

    updateSalutationOfPerson(command: UpdateSalutationOfPersonAsManagerCommand): Promise<void> {
      const { updateSalutationOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateSalutationOfPersonStatus,
        () => updateSalutationOfPerson(attachFarmAndUserProperties(command))
      );
    },

    updateNameOfPerson(command: UpdateNameOfPersonAsManagerWorkflow): Promise<void> {
      const { updateNameOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameOfPersonStatus,
        () => updateNameOfPerson(attachFarmAndUserProperties(command))
      );
    },

    updateAddressOfPerson(command: UpdateAddressOfPersonAsManagerCommand): Promise<void> {
      const { updateAddressOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAddressOfPersonStatus,
        () => updateAddressOfPerson(attachFarmAndUserProperties(command))
      );
    },

    defineInvoiceAddressOfPerson(command: DefineInvoiceAddressOfPersonAsManagerWorkflow): Promise<void> {
      const { defineInvoiceAddressOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineInvoiceAddressOfPersonStatus,
        () => defineInvoiceAddressOfPerson(attachFarmAndUserProperties(command))
      );
    },

    updateInvoiceAddressOfPerson(command: UpdateInvoiceAddressOfPersonAsManagerWorkflow): Promise<void> {
      const { updateInvoiceAddressOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvoiceAddressOfPersonStatus,
        () => updateInvoiceAddressOfPerson(attachFarmAndUserProperties(command))
      );
    },

    removeInvoiceAddressOfPerson(command: RemoveInvoiceAddressOfPersonAsManagerWorkflow): Promise<void> {
      const { removeInvoiceAddressOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeInvoiceAddressOfPersonStatus,
        () => removeInvoiceAddressOfPerson(attachFarmAndUserProperties(command))
      );
    },

    updateAdditionalMasterDataOfPerson(command: UpdateAdditionalMasterDataOfPersonAsManagerCommand): Promise<void> {
      const { updateAdditionalMasterDataOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAdditionalMasterDataOfPersonStatus,
        () => updateAdditionalMasterDataOfPerson(attachFarmAndUserProperties(command))
      );
    },

    createOrganization(command: CreateOrganizationAsManagerWorkflow): Promise<void> {
      const { createOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createOrganizationStatus,
        () => createOrganization(attachFarmAndUserProperties(command))
          .then(() => this.getOrganizations())
      );
    },

    updateNameOfOrganization(command: UpdateNameOfOrganizationAsManagerWorkflow): Promise<void> {
      const { updateNameOfOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameOfOrganizationStatus,
        () => updateNameOfOrganization(attachFarmAndUserProperties(command))
      );
    },

    updateAddressOfOrganization(command: UpdateAddressOfOrganizationAsManagerCommand): Promise<void> {
      const { updateAddressOfOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAddressOfOrganizationStatus,
        () => updateAddressOfOrganization(attachFarmAndUserProperties(command))
      );
    },

    defineInvoiceAddressOfOrganization(command: DefineInvoiceAddressOfOrganizationAsManagerWorkflow): Promise<void> {
      const { defineInvoiceAddressOfOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineInvoiceAddressOfOrganizationStatus,
        () => defineInvoiceAddressOfOrganization(attachFarmAndUserProperties(command))
      );
    },

    updateInvoiceAddressOfOrganization(command: UpdateInvoiceAddressOfOrganizationAsManagerWorkflow): Promise<void> {
      const { updateInvoiceAddressOfOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateInvoiceAddressOfOrganizationStatus,
        () => updateInvoiceAddressOfOrganization(attachFarmAndUserProperties(command))
      );
    },

    removeInvoiceAddressOfOrganization(command: RemoveInvoiceAddressOfOrganizationAsManagerWorkflow): Promise<void> {
      const { removeInvoiceAddressOfOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeInvoiceAddressOfOrganizationStatus,
        () => removeInvoiceAddressOfOrganization(attachFarmAndUserProperties(command))
      );
    },

    updateAdditionalMasterDataOfOrganization(command: UpdateAdditionalMasterDataOfOrganizationAsManagerCommand): Promise<void> {
      const { updateAdditionalMasterDataOfOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAdditionalMasterDataOfOrganizationStatus,
        () => updateAdditionalMasterDataOfOrganization(attachFarmAndUserProperties(command))
      );
    },

    updateAssignedPersonsOfOrganization(command: UpdateAssignedPersonsOfOrganizationAsManagerWorkflow): Promise<void> {
      const { updateAssignedPersonsOfOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAssignedPersonsOfOrganizationStatus,
        () => updateAssignedPersonsOfOrganization(attachFarmAndUserProperties(command))
      );
    },

    createCustomField(command: CreateCustomFieldAsManagerCommand): Promise<void> {
      const { createCustomFieldStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createCustomFieldStatus,
        () => createCustomField(attachFarmAndUserProperties(command))
          .then(() => this.getCustomFields())
      );
    },

    updateLabelOfCustomField(command: UpdateLabelOfCustomFieldAsManagerCommand): Promise<void> {
      const { updateLabelOfCustomFieldStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateLabelOfCustomFieldStatus,
        () => updateLabelOfCustomField(attachFarmAndUserProperties(command))
          .then(() => this.getCustomFields())
      );
    },

    updateOptionsOfCustomField(command: UpdateOptionsOfCustomFieldAsManagerCommand): Promise<void> {
      const { updateOptionsOfCustomFieldStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOptionsOfCustomFieldStatus,
        () => updateOptionsOfCustomField(attachFarmAndUserProperties(command))
          .then(() => this.getCustomFields())
      );
    },

    updateEmailAddressOfPerson(command: UpdateEmailAddressOfPersonAsManagerCommand): Promise<void> {
      const { updateEmailAddressOfPersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEmailAddressOfPersonStatus,
        () => updateEmailAddressOfPerson(attachFarmAndUserProperties(command))
          .then(() => this.getPersons())
      );
    },

    archivePerson(command: ArchivePersonAsManagerWorkflow): Promise<void> {
      const { archivePersonStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        archivePersonStatus,
        () => archivePerson(attachFarmAndUserProperties(command))
          .then(() => this.getPersons())
      );
    },

    archiveOrganization(command: ArchiveOrganizationAsManagerWorkflow): Promise<void> {
      const { archiveOrganizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        archiveOrganizationStatus,
        () => archiveOrganization(attachFarmAndUserProperties(command))
          .then(() => this.getOrganizations())
      );
    },

    createPersonDocument(command: CreatePersonDocumentAsManagerCommand): Promise<void> {
      const { createPersonDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPersonDocumentStatus,
        () => createPersonDocument(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getDocumentsForPerson())
          .then(() => this.getPerson())
      );
    },

    deletePersonDocument(command: DeletePersonDocumentAsManagerCommand): Promise<void> {
      const { deletePersonDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deletePersonDocumentStatus,
        () => deletePersonDocument(attachFarmAndUserProperties(command))
          .then(() => this.getDocumentsForPerson())
          .then(() => this.getPerson())
      );
    },

    updateCustomFieldShownWithAssignedPersons(command: UpdateCustomFieldShownWithAssignedPersonsAsManagerCommand): Promise<void> {
      const { updateCustomFieldShownWithAssignedPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateCustomFieldShownWithAssignedPersonsStatus,
        () => updateCustomFieldShownWithAssignedPersons(attachFarmAndUserProperties(command))
          .then(() => this.getCustomFields())
      );
    },

    deleteCustomField(command: DeleteCustomFieldAsManagerCommand): Promise<void> {
      const { deleteCustomFieldStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteCustomFieldStatus,
        () => deleteCustomField(attachFarmAndUserProperties(command))
          .then(() => this.getCustomFields())
      );
    },

    assignOptionToPersons(command: AssignOptionToPersonsAsManagerCommand): Promise<void> {
      const { assignOptionToPersonsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        assignOptionToPersonsStatus,
        () => assignOptionToPersons(attachFarmAndUserProperties(command))
          .then(() => this.getCustomFields())
      );
    },

  },
});
