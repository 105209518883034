
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { FeedTypeId } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { canBeParsedAsNumber, convertStringToNumber, countDecimalsOnString } from '@/helpers';
import { feedUnitTranslations } from '@/helpers/translations';
import { FeedingSettingPayload, FeedType } from '../types';
import { formatNumber } from '@/helpers/stateful-format';

export interface InternalFeedingSetting {
  feedTypeId: FeedTypeId;
  name: string;
  isOfferedInTheMorning: boolean;
  isOfferedAtNoon: boolean;
  isOfferedInTheEvening: boolean;
  isUserAbleToDefineAmount: boolean;
  isSelectedInTheMorning: boolean;
  isSelectedAtNoon: boolean;
  isSelectedInTheEvening: boolean;
  amountMorning: string;
  amountNoon: string;
  amountEvening: string;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'feedTypeId')
      && Object.hasOwn(item, 'name'));
}

function allAmountsAreValidNumbers(): InternalValueRule<InternalFeedingSetting[] | null> {
  return (value) => (
    value === null
    || value.every((feedingSettingPayload) => (
      !feedingSettingPayload.isSelectedInTheMorning
      || feedingSettingPayload.amountMorning.length === 0
      || canBeParsedAsNumber(feedingSettingPayload.amountMorning)
    ) && (
      !feedingSettingPayload.isSelectedAtNoon
      || feedingSettingPayload.amountNoon.length === 0
      || canBeParsedAsNumber(feedingSettingPayload.amountNoon)
    ) && (
      !feedingSettingPayload.isSelectedInTheEvening
      || feedingSettingPayload.amountEvening.length === 0
      || canBeParsedAsNumber(feedingSettingPayload.amountEvening)
    )
  ) ? true
    : 'Nicht alle Mengen sind gültige Zahlen');
}

function allAmountsAreSetWhenUserIsAbleToDefineAmountRule(): InternalValueRule<InternalFeedingSetting[] | null> {
  return (value) => (
    value === null
    || value.every((feedingSettingPayload) => (
        !feedingSettingPayload.isUserAbleToDefineAmount
        || !feedingSettingPayload.isSelectedInTheMorning
        || feedingSettingPayload.amountMorning.length > 0
      ) && (
        !feedingSettingPayload.isUserAbleToDefineAmount
        || !feedingSettingPayload.isSelectedAtNoon
        || feedingSettingPayload.amountNoon.length > 0
      ) && (
        !feedingSettingPayload.isUserAbleToDefineAmount
        || !feedingSettingPayload.isSelectedInTheEvening
        || feedingSettingPayload.amountEvening.length > 0
      )
    ) ? true
      : 'Wenn eine Fütterung ausgewählt ist und eine Menge definiert werden kann, muss eine Menge angegeben werden');
}

function noAmountHasMoreThen2DecimalsRule(): InternalValueRule<InternalFeedingSetting[] | null> {
  return (value) => (
    value === null
    || value.every((feedingSettingPayload) => (
        !feedingSettingPayload.isSelectedInTheMorning
        || feedingSettingPayload.amountMorning.length === 0
        || countDecimalsOnString(feedingSettingPayload.amountMorning) <= 2
      ) && (
        !feedingSettingPayload.isSelectedAtNoon
        || feedingSettingPayload.amountNoon.length === 0
        || countDecimalsOnString(feedingSettingPayload.amountNoon) <= 2
      ) && (
        !feedingSettingPayload.isSelectedInTheEvening
        || feedingSettingPayload.amountEvening.length === 0
        || countDecimalsOnString(feedingSettingPayload.amountEvening) <= 2
      )
    ) ? true
      : 'Eine Mengen kann maximal 2 Nachkommastellen haben');
}

function noAmountHasIsGreaterThenZeroRule(): InternalValueRule<InternalFeedingSetting[] | null> {
  return (value) => (
    value === null
    || value.every((feedingSettingPayload) => (
        !feedingSettingPayload.isSelectedInTheMorning
        || feedingSettingPayload.amountMorning.length === 0
        || convertStringToNumber(feedingSettingPayload.amountMorning) > 0
      ) && (
        !feedingSettingPayload.isSelectedAtNoon
        || feedingSettingPayload.amountNoon.length === 0
        || convertStringToNumber(feedingSettingPayload.amountNoon) > 0
      ) && (
        !feedingSettingPayload.isSelectedInTheEvening
        || feedingSettingPayload.amountEvening.length === 0
        || convertStringToNumber(feedingSettingPayload.amountEvening) > 0
      )
    ) ? true
      : 'Eine Menge darf nicht 0 oder kleiner sein');
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class FeedingSettingsFormControl extends Vue implements FormControlComponent<FeedingSettingPayload[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<FeedingSettingPayload[]>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly feedTypes!: FeedType[];

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: InternalFeedingSetting[] | null = null;

  readonly internalFieldRules: InternalValueRules = [
    allAmountsAreValidNumbers(),
    allAmountsAreSetWhenUserIsAbleToDefineAmountRule(),
    noAmountHasMoreThen2DecimalsRule(),
    noAmountHasIsGreaterThenZeroRule(),
  ];

  formFieldValueWatcher = emptyFormFieldWatcher();

  areInternalRulesValid = false;

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  feedType(feedTypeId: FeedTypeId): FeedType | null {
    return this.feedTypes
      .find((feedType) => feedType.feedTypeId === feedTypeId) ?? null;
  }

  isSelectedInTheMorningChanged(feedingSettingPayload: InternalFeedingSetting): void {
    if (!feedingSettingPayload.isSelectedInTheMorning) {
      feedingSettingPayload.amountMorning = '';
    }
    this.focused();
    internalValuesChanged(this);
    this.blurred();
  }

  isSelectedAtNoonChanged(feedingSettingPayload: InternalFeedingSetting): void {
    if (!feedingSettingPayload.isSelectedAtNoon) {
      feedingSettingPayload.amountNoon = '';
    }
    this.focused();
    internalValuesChanged(this);
    this.blurred();
  }

  isSelectedInTheEveningChanged(feedingSettingPayload: InternalFeedingSetting): void {
    if (!feedingSettingPayload.isSelectedInTheEvening) {
      feedingSettingPayload.amountEvening = '';
    }
    this.focused();
    internalValuesChanged(this);
    this.blurred();
  }

  amountLabel(feedTypeId: FeedTypeId): string {
    const feedUnit = this.feedTypes
      .find((feedType) => feedType.feedTypeId === feedTypeId)!
      .feedUnit;

    return `Menge in ${feedUnitTranslations[feedUnit]}`;
  }

  isUserAbleToDefineAmount(feedTypeId: FeedTypeId): boolean {
    return this.feedTypes
      .find((feedType) => feedType.feedTypeId === feedTypeId)!
      .isUserAbleToDefineAmount;
  }

  amountUpdated(): void {
    internalValuesChanged(this);
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        this.internalFieldRules,
        this.internalValue,
      ),
    ];

    this.areInternalRulesValid = messages.length === 0;
    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value
      ? this.feedTypes.map((feedType) => {
        const feedingSettingPayload = (this.formControl.value!
          .find((feedingSettingPayload) => feedingSettingPayload.feedTypeId === feedType.feedTypeId)) ?? null;

        return {
          feedTypeId: feedType.feedTypeId,
          name: feedType.name,
          isOfferedInTheMorning: feedType.isOfferedInTheMorning,
          isOfferedAtNoon: feedType.isOfferedAtNoon,
          isOfferedInTheEvening: feedType.isOfferedInTheEvening,
          isUserAbleToDefineAmount: feedType.isUserAbleToDefineAmount,
          isSelectedInTheMorning: feedingSettingPayload?.isSelectedInTheMorning ?? false,
          isSelectedAtNoon: feedingSettingPayload?.isSelectedAtNoon ?? false,
          isSelectedInTheEvening: feedingSettingPayload?.isSelectedInTheEvening ?? false,
          amountMorning: feedingSettingPayload && feedingSettingPayload.amountMorning !== null
            ? formatNumber(feedingSettingPayload.amountMorning)
            : '',
          amountNoon: feedingSettingPayload && feedingSettingPayload.amountNoon !== null
            ? formatNumber(feedingSettingPayload.amountNoon)
            : '',
          amountEvening: feedingSettingPayload && feedingSettingPayload.amountEvening !== null
            ? formatNumber(feedingSettingPayload.amountEvening)
            : '',
        };
      })
      : this.feedTypes.map((feedType) => ({
        feedTypeId: feedType.feedTypeId,
        name: feedType.name,
        isOfferedInTheMorning: feedType.isOfferedInTheMorning,
        isOfferedAtNoon: feedType.isOfferedAtNoon,
        isOfferedInTheEvening: feedType.isOfferedInTheEvening,
        isUserAbleToDefineAmount: feedType.isUserAbleToDefineAmount,
        isSelectedInTheMorning: false,
        isSelectedAtNoon: false,
        isSelectedInTheEvening: false,
        amountMorning: '',
        amountNoon: '',
        amountEvening: '',
      }));
  }

  formValueFromInternalValues(): FormControlValue<FeedingSettingPayload[]> {
    return this.internalValue !== null
      && this.areInternalRulesValid
      ? this.internalValue.map((internalFeedingSetting) => ({
        feedTypeId: internalFeedingSetting.feedTypeId,
        isSelectedInTheMorning: internalFeedingSetting.isSelectedInTheMorning,
        isSelectedAtNoon: internalFeedingSetting.isSelectedAtNoon,
        isSelectedInTheEvening: internalFeedingSetting.isSelectedInTheEvening,
        amountMorning: internalFeedingSetting.isSelectedInTheMorning
          && internalFeedingSetting.isUserAbleToDefineAmount
          && internalFeedingSetting.amountMorning.length > 0
          ? convertStringToNumber(internalFeedingSetting.amountMorning)
          : null,
        amountNoon: internalFeedingSetting.isSelectedAtNoon
          && internalFeedingSetting.isUserAbleToDefineAmount
          && internalFeedingSetting.amountNoon.length > 0
          ? convertStringToNumber(internalFeedingSetting.amountNoon)
          : null,
        amountEvening: internalFeedingSetting.isSelectedInTheEvening
          && internalFeedingSetting.isUserAbleToDefineAmount
          && internalFeedingSetting.amountEvening.length > 0
          ? convertStringToNumber(internalFeedingSetting.amountEvening)
          : null,
      }))
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
