
import { Component, Vue } from 'vue-property-decorator';
import { useMaintenanceModeStore } from '../store';

@Component
export default class MaintenanceOverlay extends Vue {

  readonly store = useMaintenanceModeStore();

}
