
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Name } from '@/types';
import { csvCompliantRule, maxLengthRule } from '@/components/form/internal-rules';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class NameFormControl extends Vue implements FormControlComponent<Name> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Name>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  title: string = '';
  firstName: string = '';
  lastName: string = '';

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  isValid = false;

  mounted(): void {
    mountFormControl(this);
  }

  requiredWhenAnyFieldIsFilledRule(): InternalValueRule<any> {
    return () => (
        this.title.trim().length === 0
          && this.firstName.trim().length === 0
          && this.lastName.trim().length === 0
      ) || (
        this.firstName.trim().length > 0
        && this.lastName.trim().length > 0
    )
      ? true
      : 'Wenn ein Feld gefüllt ist, müssen Vor- und Nachname gefüllt werden';
  }

  titleChanged(): void {
    internalValuesChanged(this);
  }

  firstNameChanged(): void {
    internalValuesChanged(this);
  }

  lastNameChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    const messages = [
      ...errorMessagesForInternalRules(
        [
          this.requiredWhenAnyFieldIsFilledRule(),
        ],
        null
      ),
      ...errorMessagesForInternalRules(
        [
          maxLengthRule(30, 'Der Titel darf nicht länger als 30 Zeichen sein'),
          csvCompliantRule('Der Titel darf keines der folgenden Zeichen enthalten: \' " , ;'),
        ],
        this.title,
      ),
      ...errorMessagesForInternalRules(
        [
          maxLengthRule(50, 'Der Vorname darf nicht länger als 50 Zeichen sein'),
          csvCompliantRule('Der Vorname darf keines der folgenden Zeichen enthalten: \' " , ;'),
        ],
        this.firstName,
      ),
      ...errorMessagesForInternalRules(
        [
          maxLengthRule(50, 'Der Nachname darf nicht länger als 50 Zeichen sein'),
          csvCompliantRule('Der Nachname darf keines der folgenden Zeichen enthalten: \' " , ;'),
        ],
        this.lastName,
      ),
    ];

    this.isValid = messages.length === 0;

    this.messages = messages;

    return wasValidationSuccessful(messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    if (this.formControl.value === null) {
      this.title = '';
      this.firstName = '';
      this.lastName = '';
    } else {
      this.title = this.formControl.value.title ?? '';
      this.firstName = this.formControl.value.firstName;
      this.lastName = this.formControl.value.lastName;
    }
  }

  formValueFromInternalValues(): FormControlValue<Name> {
    if (!this.isValid
      || (
        this.title.trim().length === 0
        && this.firstName.trim().length === 0
        && this.lastName.trim().length === 0
      )
    ) {
      return null;
    }

    return {
      title: this.title.trim().length > 0
        ? this.title.trim()
        : null,
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
    };
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
