import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { FarmAndUserProperties, FarmAndUserPropertiesForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { CreateNewsEntryAsManagerCommand, DeleteNewsEntryAttachmentAsManagerCommand, DeleteNewsEntryAsMangerCommand, DeleteNewsEntryImageAsManagerCommand, HideNewsEntryAsManagerCommand, NewsEntry, ReleaseNewsEntryAsManagerCommand, UpdateNewsEntryAsManagerCommand, UpdateNewsEntryVisibilityAsManagerCommand, Configuration, UserGroup } from './types';

// -- Queries

export function getNewsEntries(query: FarmAndUserProperties): Promise<NewsEntry[]> {
  const url = `${apiUrl}/api/news/get-news-entries-as-manager-query`;
  return performApiRequest<NewsEntry[]>({ url, method: 'POST', data: query });
}

export function getConfiguration(query: FarmAndUserProperties): Promise<Configuration> {
  const url = `${apiUrl}/api/news/get-configuration-as-manager-query`;
  return performApiRequest<Configuration>({ url, method: 'POST', data: query });
}

export function getUserGroups(query: FarmAndUserProperties): Promise<UserGroup[]> {
  const url = `${apiUrl}/api/news/get-user-groups-as-manager-query`;
  return performApiRequest<UserGroup[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createNewsEntry(command: CreateNewsEntryAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/news/create-news-entry-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateNewsEntry(command: UpdateNewsEntryAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/news/update-news-entry-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function deleteNewsEntry(command: DeleteNewsEntryAsMangerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/delete-news-entry-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function hideNewsEntry(command: HideNewsEntryAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/hide-news-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function releaseNewsEntry(command: ReleaseNewsEntryAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/release-news-entry-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateNewsEntryVisibility(command: UpdateNewsEntryVisibilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/update-news-entry-visibility-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function deleteNewsEntryAttachment(command: DeleteNewsEntryAttachmentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/delete-news-entry-attachment-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function deleteNewsEntryImage(command: DeleteNewsEntryImageAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/delete-news-entry-image-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markNewsAsVisibleForRiders(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/mark-news-as-visible-for-riders-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markNewsAsHiddenForRiders(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/mark-news-as-hidden-for-riders-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
