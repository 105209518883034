import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { GetSettlementForecastAsManagerQuery, SettlementForecast } from './types';

// -- Queries

export function getSettlementForecast(query: GetSettlementForecastAsManagerQuery & FarmAndUserProperties): Promise<SettlementForecast> {
  const url = `${apiUrl}/api/ledger/get-settlement-forecast-as-manager-query`;
  return performApiRequest<SettlementForecast>({ url, method: 'POST', data: query });
}
