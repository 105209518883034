import { EventId, FacilityId, HorseId, LaborServiceTimeEntryId, NewsEntryId, NotificationConfiguration, NotificationPayload, NotificationType, RiderTaskId, SurveyId, UserId } from './value-objects';
import { Date } from './date';

export interface NewsEntryPublishedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.NEWS_ENTRY_PUBLISHED;
  payload: NewsEntryPublishedNotificationPayload;
}
export interface NewsEntryPublishedNotificationPayload extends NotificationPayload {
  newsEntryId: NewsEntryId;
}
export function isNewsEntryPublishedNotification(
  configuration: NotificationConfiguration
): configuration is NewsEntryPublishedNotificationConfiguration {
  return configuration.type === NotificationType.NEWS_ENTRY_PUBLISHED;
}

export interface EventPublishedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.EVENT_PUBLISHED;
  payload: EventPublishedNotificationPayload;
}
export interface EventPublishedNotificationPayload extends NotificationPayload {
  eventId: EventId;
}
export function isEventPublishedNotification(
  configuration: NotificationConfiguration
): configuration is EventPublishedNotificationConfiguration {
  return configuration.type === NotificationType.EVENT_PUBLISHED;
}

export interface EventCanceledNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.EVENT_CANCELED;
  payload: EventCanceledNotificationPayload;
}
export interface EventCanceledNotificationPayload extends NotificationPayload {
  eventId: EventId;
}
export function isEventCanceledNotification(
  configuration: NotificationConfiguration
): configuration is EventCanceledNotificationConfiguration {
  return configuration.type === NotificationType.EVENT_CANCELED;
}

export interface RiderTaskCreatedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_CREATED;
  payload: RiderTaskCreatedNotificationPayload;
}
export interface RiderTaskCreatedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskCreatedNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskCreatedNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_CREATED;
}

export interface RiderTaskAssignedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_ASSIGNED;
  payload: RiderTaskAssignedNotificationPayload;
}
export interface RiderTaskAssignedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskAssignedNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskAssignedNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_ASSIGNED;
}

export interface RiderTaskAssignedWithExecutionDateUpdateNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE;
  payload: RiderTaskAssignedWithExecutionDateUpdateNotificationPayload;
}
export interface RiderTaskAssignedWithExecutionDateUpdateNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskAssignedWithExecutionDateUpdateNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskAssignedWithExecutionDateUpdateNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE;
}

export interface RiderTaskAssignedToYouNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_ASSIGNED_TO_YOU;
  payload: RiderTaskAssignedToYouNotificationPayload;
}
export interface RiderTaskAssignedToYouNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskAssignedToYouNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskAssignedToYouNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_ASSIGNED_TO_YOU;
}

export interface RiderTaskRejectedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_REJECTED;
  payload: RiderTaskRejectedNotificationPayload;
}
export interface RiderTaskRejectedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskRejectedNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskRejectedNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_REJECTED;
}

export interface RiderTaskRejectedWithReasonNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_REJECTED_WITH_REASON;
  payload: RiderTaskRejectedWithReasonNotificationPayload;
}
export interface RiderTaskRejectedWithReasonNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskRejectedWithReasonNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskRejectedWithReasonNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_REJECTED_WITH_REASON;
}

export interface RiderTaskCompletedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_COMPLETED;
  payload: RiderTaskCompletedNotificationPayload;
}
export interface RiderTaskCompletedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskCompletedNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskCompletedNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_COMPLETED;
}

export interface RiderTaskCompletedWithCommentNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_COMPLETED_WITH_COMMENT;
  payload: RiderTaskCompletedWithCommentNotificationPayload;
}
export interface RiderTaskCompletedWithCommentNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskCompletedWithCommentNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskCompletedWithCommentNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_COMPLETED_WITH_COMMENT;
}

export interface RiderTaskWithdrawnNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_WITHDRAWN;
  payload: RiderTaskWithdrawnNotificationPayload;
}
export interface RiderTaskWithdrawnNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskWithdrawnNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskWithdrawnNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_WITHDRAWN;
}

export interface RiderTaskCreatedAssignedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDER_TASK_CREATED_ASSIGNED;
  payload: RiderTaskCreatedAssignedNotificationPayload;
}
export interface RiderTaskCreatedAssignedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isRiderTaskCreatedAssignedNotification(
  configuration: NotificationConfiguration
): configuration is RiderTaskCreatedAssignedNotificationConfiguration {
  return configuration.type === NotificationType.RIDER_TASK_CREATED_ASSIGNED;
}

export interface FarmTaskCreatedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FARM_TASK_CREATED;
  payload: FarmTaskCreatedNotificationPayload;
}
export interface FarmTaskCreatedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isFarmTaskCreatedNotification(
  configuration: NotificationConfiguration
): configuration is FarmTaskCreatedNotificationConfiguration {
  return configuration.type === NotificationType.FARM_TASK_CREATED;
}

export interface FarmTaskAssignedToYouNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FARM_TASK_ASSIGNED_TO_YOU;
  payload: FarmTaskAssignedToYouNotificationPayload;
}
export interface FarmTaskAssignedToYouNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isFarmTaskAssignedToYouNotification(
  configuration: NotificationConfiguration
): configuration is FarmTaskAssignedToYouNotificationConfiguration {
  return configuration.type === NotificationType.FARM_TASK_ASSIGNED_TO_YOU;
}

export interface FarmTaskYouHaveBeenRemovedFromTaskNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FARM_TASK_YOU_HAVE_BEEN_REMOVED_FROM_TASK;
  payload: FarmTaskYouHaveBeenRemovedFromTaskNotificationPayload;
}
export interface FarmTaskYouHaveBeenRemovedFromTaskNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isFarmTaskYouHaveBeenRemovedFromTaskNotification(
  configuration: NotificationConfiguration
): configuration is FarmTaskYouHaveBeenRemovedFromTaskNotificationConfiguration {
  return configuration.type === NotificationType.FARM_TASK_YOU_HAVE_BEEN_REMOVED_FROM_TASK;
}

export interface FarmTaskExecutionDateUpdatedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FARM_TASK_EXECUTION_DATE_UPDATED;
  payload: FarmTaskExecutionDateUpdatedNotificationPayload;
}
export interface FarmTaskExecutionDateUpdatedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
  date: Date;
}
export function isFarmTaskExecutionDateUpdatedNotification(
  configuration: NotificationConfiguration
): configuration is FarmTaskExecutionDateUpdatedNotificationConfiguration {
  return configuration.type === NotificationType.FARM_TASK_EXECUTION_DATE_UPDATED;
}

export interface FarmTaskDeletedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FARM_TASK_DELETED;
  payload: FarmTaskDeletedNotificationPayload;
}
export interface FarmTaskDeletedNotificationPayload extends NotificationPayload {
  riderTaskId: RiderTaskId;
}
export function isFarmTaskDeletedNotification(
  configuration: NotificationConfiguration
): configuration is FarmTaskDeletedNotificationConfiguration {
  return configuration.type === NotificationType.FARM_TASK_DELETED;
}

export interface FeedProtocolMissingSignalNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FEED_PROTOCOL_MISSING_SIGNAL;
  payload: FeedProtocolMissingSignalNotificationPayload;
}
export interface FeedProtocolMissingSignalNotificationPayload extends NotificationPayload {
}
export function isFeedProtocolMissingSignalNotification(
  configuration: NotificationConfiguration
): configuration is FeedProtocolMissingSignalNotificationConfiguration {
  return configuration.type === NotificationType.FEED_PROTOCOL_MISSING_SIGNAL;
}

export interface ImpersonationRequestedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.IMPERSONATION_REQUESTED;
  payload: ImpersonationRequestedNotificationPayload;
}
export interface ImpersonationRequestedNotificationPayload extends NotificationPayload {
  token: string;
}
export function isImpersonationRequestedNotification(
  configuration: NotificationConfiguration
): configuration is ImpersonationRequestedNotificationConfiguration {
  return configuration.type === NotificationType.IMPERSONATION_REQUESTED;
}

export interface ImpersonationConfirmedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.IMPERSONATION_CONFIRMED;
  payload: ImpersonationConfirmedNotificationPayload;
}
export interface ImpersonationConfirmedNotificationPayload extends NotificationPayload {
  userId: UserId;
}
export function isImpersonationConfirmedNotification(
  configuration: NotificationConfiguration
): configuration is ImpersonationConfirmedNotificationConfiguration {
  return configuration.type === NotificationType.IMPERSONATION_CONFIRMED;
}

export interface VaccinationExpiresSoonNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.VACCINATION_EXPIRES_SOON;
  payload: VaccinationExpiresSoonNotificationPayload;
}
export interface VaccinationExpiresSoonNotificationPayload extends NotificationPayload {
  horseId: HorseId;
}
export function isVaccinationExpiresSoonNotification(
  configuration: NotificationConfiguration
): configuration is VaccinationExpiresSoonNotificationConfiguration {
  return configuration.type === NotificationType.VACCINATION_EXPIRES_SOON;
}

export interface FacilityReservationCanceledDueToFacilityBlockerNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER;
  payload: FacilityReservationCanceledDueToFacilityBlockerNotificationPayload;
}
export interface FacilityReservationCanceledDueToFacilityBlockerNotificationPayload extends NotificationPayload {
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToFacilityBlockerNotification(
  configuration: NotificationConfiguration
): configuration is FacilityReservationCanceledDueToFacilityBlockerNotificationConfiguration {
  return configuration.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER;
}

export interface FacilityReservationCanceledDueToReducedOpeningHoursNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS;
  payload: FacilityReservationCanceledDueToReducedOpeningHoursNotificationPayload;
}
export interface FacilityReservationCanceledDueToReducedOpeningHoursNotificationPayload extends NotificationPayload {
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToReducedOpeningHoursNotification(
  configuration: NotificationConfiguration
): configuration is FacilityReservationCanceledDueToReducedOpeningHoursNotificationConfiguration {
  return configuration.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS;
}

export interface FacilityReservationCanceledDueToDisabledActivityNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY;
  payload: FacilityReservationCanceledDueToDisabledActivityNotificationPayload;
}
export interface FacilityReservationCanceledDueToDisabledActivityNotificationPayload extends NotificationPayload {
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToDisabledActivityNotification(
  configuration: NotificationConfiguration
): configuration is FacilityReservationCanceledDueToDisabledActivityNotificationConfiguration {
  return configuration.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY;
}

export interface FacilityReservationCanceledDueToRidingLessonNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON;
  payload: FacilityReservationCanceledDueToRidingLessonNotificationPayload;
}
export interface FacilityReservationCanceledDueToRidingLessonNotificationPayload extends NotificationPayload {
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToRidingLessonNotification(
  configuration: NotificationConfiguration
): configuration is FacilityReservationCanceledDueToRidingLessonNotificationConfiguration {
  return configuration.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON;
}

export interface RidingLessonRescheduledNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDING_LESSON_RESCHEDULED;
  payload: RidingLessonRescheduledNotificationPayload;
}
export interface RidingLessonRescheduledNotificationPayload extends NotificationPayload {
  facilityId: FacilityId;
}
export function isRidingLessonRescheduledNotification(
  configuration: NotificationConfiguration
): configuration is RidingLessonRescheduledNotificationConfiguration {
  return configuration.type === NotificationType.RIDING_LESSON_RESCHEDULED;
}

export interface RidingLessonTypeUpdatedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.RIDING_LESSON_TYPE_UPDATED;
  payload: RidingLessonTypeUpdatedNotificationPayload;
}
export interface RidingLessonTypeUpdatedNotificationPayload extends NotificationPayload {
  facilityId: FacilityId;
}
export function isRidingLessonTypeUpdatedNotification(
  configuration: NotificationConfiguration
): configuration is RidingLessonTypeUpdatedNotificationConfiguration {
  return configuration.type === NotificationType.RIDING_LESSON_TYPE_UPDATED;
}

export interface InvitedAsRideShareNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.INVITED_AS_RIDE_SHARE;
  payload: InvitedAsRideShareNotificationPayload;
}
export interface InvitedAsRideShareNotificationPayload extends NotificationPayload {
  horseId: HorseId;
}
export function isInvitedAsRideShareNotification(
  configuration: NotificationConfiguration
): configuration is InvitedAsRideShareNotificationConfiguration {
  return configuration.type === NotificationType.INVITED_AS_RIDE_SHARE;
}

export interface LaborServiceEntryCreatedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.LABOR_SERVICE_ENTRY_CREATED;
  payload: LaborServiceEntryCreatedNotificationPayload;
}
export interface LaborServiceEntryCreatedNotificationPayload extends NotificationPayload {
  laborServiceTimeEntryId: LaborServiceTimeEntryId;
}
export function isLaborServiceEntryCreatedNotification(
  configuration: NotificationConfiguration
): configuration is LaborServiceEntryCreatedNotificationConfiguration {
  return configuration.type === NotificationType.LABOR_SERVICE_ENTRY_CREATED;
}

export interface LaborServiceEntryApprovedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.LABOR_SERVICE_ENTRY_APPROVED;
  payload: LaborServiceEntryApprovedNotificationPayload;
}
export interface LaborServiceEntryApprovedNotificationPayload extends NotificationPayload {
  laborServiceTimeEntryId: LaborServiceTimeEntryId;
}
export function isLaborServiceEntryApprovedNotification(
  configuration: NotificationConfiguration
): configuration is LaborServiceEntryApprovedNotificationConfiguration {
  return configuration.type === NotificationType.LABOR_SERVICE_ENTRY_APPROVED;
}

export interface LaborServiceEntryRejectedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.LABOR_SERVICE_ENTRY_REJECTED;
  payload: LaborServiceEntryRejectedNotificationPayload;
}
export interface LaborServiceEntryRejectedNotificationPayload extends NotificationPayload {
  laborServiceTimeEntryId: LaborServiceTimeEntryId;
}
export function isLaborServiceEntryRejectedNotification(
  configuration: NotificationConfiguration
): configuration is LaborServiceEntryRejectedNotificationConfiguration {
  return configuration.type === NotificationType.LABOR_SERVICE_ENTRY_REJECTED;
}

export interface UserDeletedOwnAccountNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.USER_DELETED_OWN_ACCOUNT;
  payload: UserDeletedOwnAccountNotificationPayload;
}
export interface UserDeletedOwnAccountNotificationPayload extends NotificationPayload {
  userId: UserId;
}
export function isUserDeletedOwnAccountNotification(
  configuration: NotificationConfiguration
): configuration is UserDeletedOwnAccountNotificationConfiguration {
  return configuration.type === NotificationType.USER_DELETED_OWN_ACCOUNT;
}

export interface SurveyCreatedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.SURVEY_CREATED;
  payload: SurveyCreatedNotificationPayload;
}
export interface SurveyCreatedNotificationPayload extends NotificationPayload {
  surveyId: SurveyId;
}
export function isSurveyCreatedNotification(
  configuration: NotificationConfiguration
): configuration is SurveyCreatedNotificationConfiguration {
  return configuration.type === NotificationType.SURVEY_CREATED;
}

export interface SurveyAnswerDeletedNotificationConfiguration extends NotificationConfiguration {
  type: NotificationType.SURVEY_ANSWER_DELETED;
  payload: SurveyAnswerDeletedNotificationPayload;
}
export interface SurveyAnswerDeletedNotificationPayload extends NotificationPayload {
  surveyId: SurveyId;
}
export function isSurveyAnswerDeletedNotification(
  configuration: NotificationConfiguration
): configuration is SurveyAnswerDeletedNotificationConfiguration {
  return configuration.type === NotificationType.SURVEY_ANSWER_DELETED;
}
