export function scrollIntoViewWithOffset(selector: string, offset: number) {
  window.scrollTo({
    behavior: 'smooth',
    top: document.querySelector(selector)!.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
  });
}

export function safeAreaInsetTop(): number {
  const safeAreaInsetTopProperty = getComputedStyle(document.body).getPropertyValue('--safe-area-inset-top');
  return safeAreaInsetTopProperty
    ? parseInt(safeAreaInsetTopProperty.substring(0, safeAreaInsetTopProperty.length - 2), 10)
    : 0;
}
