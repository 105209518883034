import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { Month } from '@/types';
import { defineFarmBankInformation, configureInvoicing, getInvoiceConfiguration, getInvoicePDF, getInvoicesAsZIPForMonth, getInvoicesForMonth, removeFarmBankInformation, updateFarmAddress, updateFarmBankInformation, updateFarmLegalInformation, defineFarmTermsOfPayment, updateFarmTermsOfPayment, removeFarmTermsOfPayment, updateAccountingEmailAddress } from './service';
import { DefineFarmBankInformationAsManagerCommand, DefineFarmTermsOfPaymentAsManagerCommand, ConfigureInvoicingAsManagerCommand, GetInvoicePDFAsManagerQuery, GetInvoicesAsZIPForMonthAsManagerQuery, GetInvoicesForMonthAsManagerQuery, Invoice, InvoiceConfiguration, UpdateFarmAddressAsManagerCommand, UpdateFarmBankInformationAsManagerCommand, UpdateFarmLegalInformationAsManagerCommand, UpdateFarmTermsOfPaymentAsManagerCommand, UpdateAccountingEmailAddressAsManagerCommand } from './types';

interface InvoicingState {
  invoices: Invoice[];
  selectedMonthForInvoices: Month | null;
  invoiceConfiguration: InvoiceConfiguration | null;

  configureInvoicingStatus: ActionStatus;
  updateFarmAddressStatus: ActionStatus;
  updateFarmLegalInformationStatus: ActionStatus;
  defineFarmBankInformationStatus: ActionStatus;
  updateFarmBankInformationStatus: ActionStatus;
  removeFarmBankInformationStatus: ActionStatus;
  defineFarmTermsOfPaymentStatus: ActionStatus;
  updateFarmTermsOfPaymentStatus: ActionStatus;
  removeFarmTermsOfPaymentStatus: ActionStatus;
  getInvoiceConfigurationStatus: ActionStatus;
  getInvoicesForMonthStatus: ActionStatus;
  getInvoicePDFStatus: ActionStatus;
  getInvoicesAsZIPForMonthStatus: ActionStatus;
  updateAccountingEmailAddressStatus: ActionStatus;
}

function initialState(): InvoicingState {
  return {
    invoices: [],
    selectedMonthForInvoices: null,
    invoiceConfiguration: null,

    configureInvoicingStatus: ActionStatus.None,
    updateFarmAddressStatus: ActionStatus.None,
    updateFarmLegalInformationStatus: ActionStatus.None,
    defineFarmBankInformationStatus: ActionStatus.None,
    updateFarmBankInformationStatus: ActionStatus.None,
    removeFarmBankInformationStatus: ActionStatus.None,
    defineFarmTermsOfPaymentStatus: ActionStatus.None,
    updateFarmTermsOfPaymentStatus: ActionStatus.None,
    removeFarmTermsOfPaymentStatus: ActionStatus.None,
    getInvoiceConfigurationStatus: ActionStatus.None,
    getInvoicesForMonthStatus: ActionStatus.None,
    getInvoicePDFStatus: ActionStatus.None,
    getInvoicesAsZIPForMonthStatus: ActionStatus.None,
    updateAccountingEmailAddressStatus: ActionStatus.None,
  };
}

export const useInvoicingStore = defineStore('invoicing', {
  state: (): InvoicingState => initialState(),
  getters: {
    isConfigureInvoicingProcessing: (state: InvoicingState): boolean =>
      state.configureInvoicingStatus === ActionStatus.InProgress,
    isUpdateFarmAddressProcessing: (state: InvoicingState): boolean =>
      state.updateFarmAddressStatus === ActionStatus.InProgress,
    isUpdateFarmLegalInformationProcessing: (state: InvoicingState): boolean =>
      state.updateFarmLegalInformationStatus === ActionStatus.InProgress,
    isDefineFarmBankInformationProcessing: (state: InvoicingState): boolean =>
      state.defineFarmBankInformationStatus === ActionStatus.InProgress,
    isUpdateFarmBankInformationProcessing: (state: InvoicingState): boolean =>
      state.updateFarmBankInformationStatus === ActionStatus.InProgress,
    isRemoveFarmBankInformationProcessing: (state: InvoicingState): boolean =>
      state.removeFarmBankInformationStatus === ActionStatus.InProgress,
    isDefineFarmTermsOfPaymentProcessing: (state: InvoicingState): boolean =>
      state.defineFarmTermsOfPaymentStatus === ActionStatus.InProgress,
    isUpdateFarmTermsOfPaymentProcessing: (state: InvoicingState): boolean =>
      state.updateFarmTermsOfPaymentStatus === ActionStatus.InProgress,
    isRemoveFarmTermsOfPaymentProcessing: (state: InvoicingState): boolean =>
      state.removeFarmTermsOfPaymentStatus === ActionStatus.InProgress,
    isGetInvoiceConfigurationProcessing: (state: InvoicingState): boolean =>
      state.getInvoiceConfigurationStatus === ActionStatus.InProgress,
    isGetInvoicesForMonthProcessing: (state: InvoicingState): boolean =>
      state.getInvoicesForMonthStatus === ActionStatus.InProgress,
    isGetInvoicePDFProcessing: (state: InvoicingState): boolean =>
      state.getInvoicePDFStatus === ActionStatus.InProgress,
    isGetInvoicesAsZIPForMonthProcessing: (state: InvoicingState): boolean =>
      state.getInvoicesAsZIPForMonthStatus === ActionStatus.InProgress,
    isUpdateAccountingEmailAddressProcessing: (state: InvoicingState): boolean =>
      state.updateAccountingEmailAddressStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSelectedMonthForInvoices(selectedMonth: Month): Promise<void> {
      this.selectedMonthForInvoices = selectedMonth;

      return this.getInvoicesForMonth();
    },

    // -- Queries

    getInvoiceConfiguration(): Promise<void> {
      const { getInvoiceConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoiceConfigurationStatus,
        () => getInvoiceConfiguration(attachFarmAndUserProperties({}))
          .then((invoiceConfiguration) => {
            this.invoiceConfiguration = invoiceConfiguration;
          })
      );
    },

    getInvoicesForMonth(): Promise<void> {
      const query: GetInvoicesForMonthAsManagerQuery = {
        month: this.selectedMonthForInvoices!,
      };
      const { getInvoicesForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicesForMonthStatus,
        () => getInvoicesForMonth(attachFarmAndUserProperties(query))
          .then((invoices) => {
            this.invoices = invoices;
          })
      );
    },

    getInvoicePDFAsFarmManager(query: GetInvoicePDFAsManagerQuery): Promise<FileResponse> {
      const { getInvoicePDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicePDFStatus,
        () => getInvoicePDF(attachFarmAndUserProperties(query))
      );
    },

    getInvoicesAsZIPForMonth(): Promise<FileResponse> {
      const query: GetInvoicesAsZIPForMonthAsManagerQuery = {
        month: this.selectedMonthForInvoices!,
      };
      const { getInvoicesAsZIPForMonthStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getInvoicesAsZIPForMonthStatus,
        () => getInvoicesAsZIPForMonth(attachFarmAndUserProperties(query))
      );
    },

    // -- Commands

    configureInvoicing(command: ConfigureInvoicingAsManagerCommand): Promise<void> {
      const { configureInvoicingStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        configureInvoicingStatus,
        () => configureInvoicing(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    updateFarmAddress(command: UpdateFarmAddressAsManagerCommand): Promise<void> {
      const { updateFarmAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmAddressStatus,
        () => updateFarmAddress(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    updateFarmLegalInformation(command: UpdateFarmLegalInformationAsManagerCommand): Promise<void> {
      const { updateFarmLegalInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmLegalInformationStatus,
        () => updateFarmLegalInformation(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    defineFarmBankInformation(command: DefineFarmBankInformationAsManagerCommand): Promise<void> {
      const { defineFarmBankInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineFarmBankInformationStatus,
        () => defineFarmBankInformation(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    updateFarmBankInformation(command: UpdateFarmBankInformationAsManagerCommand): Promise<void> {
      const { updateFarmBankInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmBankInformationStatus,
        () => updateFarmBankInformation(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    removeFarmBankInformation(): Promise<void> {
      const { removeFarmBankInformationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeFarmBankInformationStatus,
        () => removeFarmBankInformation(attachFarmAndUserProperties({}))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    defineFarmTermsOfPayment(command: DefineFarmTermsOfPaymentAsManagerCommand): Promise<void> {
      const { defineFarmTermsOfPaymentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        defineFarmTermsOfPaymentStatus,
        () => defineFarmTermsOfPayment(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    updateFarmTermsOfPayment(command: UpdateFarmTermsOfPaymentAsManagerCommand): Promise<void> {
      const { updateFarmTermsOfPaymentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmTermsOfPaymentStatus,
        () => updateFarmTermsOfPayment(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    removeFarmTermsOfPayment(): Promise<void> {
      const { removeFarmTermsOfPaymentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeFarmTermsOfPaymentStatus,
        () => removeFarmTermsOfPayment(attachFarmAndUserProperties({}))
          .then(() => this.getInvoiceConfiguration())
      );
    },

    updateAccountingEmailAddress(command: UpdateAccountingEmailAddressAsManagerCommand): Promise<void> {
      const { updateAccountingEmailAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAccountingEmailAddressStatus,
        () => updateAccountingEmailAddress(attachFarmAndUserProperties(command))
          .then(() => this.getInvoiceConfiguration())
      );
    },

  },
});
