import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { ReserveFacilityCommand, GetCalendarEventsForFacilityQuery, Facility, CancelFacilityReservationCommand, CalendarEvent, DismissBookingNoticeCommand, RegisterForRidingLessonCommand, CancelRidingLessonRegistrationCommand, GetRidingLessonsAsCalendarEventsQuery, GetRidingLessonsAsCalendarEventsResponse, UpdatePreferredCalendarViewCommand, UpdateFacilityReservationCommand, PreferredIntervalsForFacilityReservationActivity } from './types';

// -- Queries

export function getFacilitiesOverview(query: FarmAndUserProperties): Promise<Facility[]> {
  const url = `${apiUrl}/api/facilities/get-facilities-overview-query`;
  return performApiRequest<Facility[]>({ url, method: 'POST', data: query });
}

export function getCalendarEventsForFacility(query: GetCalendarEventsForFacilityQuery): Promise<CalendarEvent[]> {
  const url = `${apiUrl}/api/facilities/get-calendar-events-for-facility-query`;
  return performApiRequest<CalendarEvent[]>({ url, method: 'POST', data: query });
}

export function getRidingLessonsAsCalendarEvents(
  query: GetRidingLessonsAsCalendarEventsQuery
): Promise<GetRidingLessonsAsCalendarEventsResponse> {
  const url = `${apiUrl}/api/riding-lessons/get-riding-lessons-as-calendar-events-query`;
  return performApiRequest<GetRidingLessonsAsCalendarEventsResponse>({ url, method: 'POST', data: query });
}

export function getPreferredIntervals(query: FarmAndUserProperties): Promise<PreferredIntervalsForFacilityReservationActivity[]> {
  const url = `${apiUrl}/api/facility-reservations/get-preferred-intervals-query`;
  return performApiRequest<PreferredIntervalsForFacilityReservationActivity[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function reserveFacility(command: ReserveFacilityCommand, isTrialRun: boolean): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/reserve-facility-command`;
  return performApiRequest({ url, method: 'POST', data: command }, isTrialRun);
}

export function updateFacilityReservation(command: UpdateFacilityReservationCommand, isTrialRun: boolean): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/update-facility-reservation-command`;
  return performApiRequest({ url, method: 'POST', data: command }, isTrialRun);
}

export function cancelFacilityReservation(command: CancelFacilityReservationCommand): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/cancel-facility-reservation-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function dismissBookingNotice(command: DismissBookingNoticeCommand): Promise<void> {
  const url = `${apiUrl}/api/facility-reservations/dismiss-booking-notice-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function registerForRidingLesson(command: RegisterForRidingLessonCommand): Promise<void> {
  const url = `${apiUrl}/api/riding-lessons/register-for-riding-lesson-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function cancelRidingLessonRegistration(command: CancelRidingLessonRegistrationCommand): Promise<void> {
  const url = `${apiUrl}/api/riding-lessons/cancel-riding-lesson-registration-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePreferredCalendarView(command: UpdatePreferredCalendarViewCommand): Promise<void> {
  const url = `${apiUrl}/api/facilities/update-preferred-calendar-view-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
