import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import ManageUserTabs from './components/manage-users-tabs.vue';
import UserTable from './components/user-table.vue';
import GroupTable from './components/group-table.vue';
import RegistrationConfiguration from './components/registration-configuration.vue';

export const userManagementRoutes: RouteConfig[] = [
  {
    path: 'benutzer',
    component: ManageUserTabs,
    children: [
      {
        // Warning - Used in push notifications
        name: 'user-management/manage-users/user-table',
        path: '',
        component: UserTable,
        meta: {
          title: 'Benutzer',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.USERS_READ,
        },
      },
      {
        name: 'user-management/manage-users/group-table',
        path: 'gruppen',
        component: GroupTable,
        meta: {
          title: 'Benutzer',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.USERS_READ,
        },
      },
      {
        name: 'user-management/manage-users/registration-configuration',
        path: 'registrierungseinstellungen',
        component: RegistrationConfiguration,
        meta: {
          title: 'Benutzer',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresPermission: FarmManagerPermission.USERS_READ,
        },
      },
    ],
  },
];
