
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { FarmServiceBookingType, FarmServiceId, Date } from '@/types';
import { constructForm, Form, FormControl, FormControls, FormControlValue, getFormValues } from '@/components/form';
import { uuid } from '@/helpers';
import { formatCurrency } from '@/helpers/stateful-format';
import { subscriptionScheduleRuntimeTranslations } from '@/helpers/translations';
import { CreateFarmServiceBookingCommand, FarmService, FarmServiceBooking } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  startingAtForOneOff: FormControl<Date>;
  startingAtForSubscription: FormControl<Date>;
  comment: FormControl<string>;
}

@Component
export default class CreateFarmServiceBookingDialog extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get costAlertMessage(): string {
    return this.farmService.subscriptionConfiguration !== null
      ? `Die Buchung kostet ${formatCurrency(this.farmService.grossSellingPrice)} ${subscriptionScheduleRuntimeTranslations[this.farmService.subscriptionConfiguration.subscriptionSchedule!]}. Ohne Kündigung verlängert sie sich automatisch um die selbe Laufzeit.`
      : `Die Buchung kostet ${formatCurrency(this.farmService.grossSellingPrice)}.`;
  }

  get farmServiceBookingsToEnableFarmService(): FarmServiceBooking[] {
    if (this.farmService.enableWithFarmServiceBookings.length === 0) {
      return [];
    }

    return (this.store.farmServicesForHorse ?? [])
      .filter((farmService) => this.farmService.enableWithFarmServiceBookings.includes(farmService.farmServiceId))
      .flatMap((farmService) => farmService.bookings);
  }

  get farmServiceBookingsToDisableFarmService(): FarmServiceBooking[] {
    if (this.farmService.disableWithFarmServiceBookings.length === 0) {
      return [];
    }

    return (this.store.farmServicesForHorse ?? [])
      .filter((farmService) => this.farmService.disableWithFarmServiceBookings.includes(farmService.farmServiceId))
      .flatMap((farmService) => farmService.bookings);
  }

  get farmServiceRestrictions(): string {
    let restrictionHtml = '';
    // Variant with both restriction types
    if (this.farmService.enableWithFarmServiceBookings.length > 0
      && this.farmService.disableWithFarmServiceBookings.length > 0
    ) {
      restrictionHtml = 'Um diesen Hofdienst buchen zu können, ...<br />';

      // Enable restrictions
      const enableNames = this.getFarmServiceNamesById(this.farmService.enableWithFarmServiceBookings);
      if (enableNames.length === 1) {
        restrictionHtml += `... muss der Hofdienst <strong>${enableNames[0]}</strong> gebucht sein.<br />`;
      } else {
        /* eslint-disable-next-line max-len */
        restrictionHtml += `... muss einer der Hofdienste <strong>${enableNames.join('</strong> oder <strong>')}</strong> gebucht sein.<br />`;
      }

      // Disable restrictions
      const disableNames = this.getFarmServiceNamesById(this.farmService.disableWithFarmServiceBookings);
      if (disableNames.length === 1) {
        restrictionHtml += `... darf der Hofdienst <strong>${disableNames[0]}</strong> nicht gebucht sein.<br />`;
      } else {
        /* eslint-disable-next-line max-len */
        restrictionHtml += `... darf keiner der Hofdienste <strong>${disableNames.join('</strong> oder <strong>')}</strong> gebucht sein.<br />`;
      }

      return restrictionHtml;
    }

    // Variant with only one restriction type
    restrictionHtml = 'Um diesen Hofdienst buchen zu können, ';

    // Enable restrictions
    if (this.farmService.enableWithFarmServiceBookings.length > 0) {
      const enableNames = this.getFarmServiceNamesById(this.farmService.enableWithFarmServiceBookings);
      if (enableNames.length === 1) {
        restrictionHtml += `muss der Hofdienst <strong>${enableNames[0]}</strong> gebucht sein.`;
      } else {
        /* eslint-disable-next-line max-len */
        restrictionHtml += `muss einer der Hofdienste <strong>${enableNames.join('</strong> oder <strong>')}</strong> gebucht sein.`;
      }
    }

    // Disable restrictions
    if (this.farmService.disableWithFarmServiceBookings.length > 0) {
      const disableNames = this.getFarmServiceNamesById(this.farmService.disableWithFarmServiceBookings);
      if (disableNames.length === 1) {
        restrictionHtml += `darf der Hofdienst <strong>${disableNames[0]}</strong> nicht gebucht sein.`;
      } else {
        /* eslint-disable-next-line max-len */
        restrictionHtml += `darf keiner der Hofdienste <strong>${disableNames.join('</strong> oder <strong>')}</strong> gebucht sein.`;
      }
    }

    return restrictionHtml;
  }

  get isStartingAtForOneOffVisible(): boolean {
    return this.farmService.bookingType === FarmServiceBookingType['ONE_OFF'];
  }

  get isStartingAtForSubscriptionVisible(): boolean {
    return this.farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION
      && this.subscriptionDates.length > 0;
  }

  get isCommentVisible(): boolean {
    return this.farmService.bookingType === FarmServiceBookingType['ONE_OFF'];
  }

  get subscriptionDates(): { label: string, date: Date }[] {
    if (!this.farmService.subscriptionConfiguration) {
      return [];
    }

    const firstPossibleDate = Date.today()
      .startOfMonth();
    const lastPossibleDate = firstPossibleDate.addMonths(6);

    const dates = [];
    // Validate available time range
    let currentDate = firstPossibleDate;
    while (!currentDate.isAfter(lastPossibleDate)) {
      if (this.isDateAllowedForSubscriptionFarmService(currentDate)) {
        dates.push({
          label: currentDate.format('DD.MM.YYYY'),
          date: currentDate,
        });
      }
      currentDate = currentDate.addMonths(1);
    }

    return dates;
  }

  get isThereAnActiveSubscriptionBooking(): boolean {
    return this.farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION
      && this.farmService.bookings.some((farmServiceBooking) => farmServiceBooking.endingAt === null);
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const initialStartingAtForSubscription = this.subscriptionDates[0]?.date ?? null;

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        startingAtForOneOff: {
          label: 'Datum',
          value: null,
          rules: [
            (value: FormControlValue<Date>) => this.farmService.bookingType !== FarmServiceBookingType.ONE_OFF
              || value !== null
              ? true
              : 'Ein Datum muss ausgewählt werden',
          ],
        },
        startingAtForSubscription: {
          label: 'Startdatum',
          value: initialStartingAtForSubscription,
          rules: [
            (value: FormControlValue<Date>) => this.farmService.bookingType !== FarmServiceBookingType.SUBSCRIPTION
              || value !== null
              ? true
              : 'Ein Datum muss ausgewählt werden',
            () => this.farmService.bookingType !== FarmServiceBookingType.SUBSCRIPTION
              || this.subscriptionDates.length > 0
              ? true
              : 'Kann nicht gebucht werden',
          ],
        },
        comment: {
          label: 'Kommentar',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateFarmServiceBookingCommand = {
      horseId: this.store.currentHorseId!,
      farmServiceId: this.farmService.farmServiceId,
      farmServiceBookingId: uuid(),
      startingAt: this.farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION
        ? formValues.startingAtForSubscription!
        : formValues.startingAtForOneOff!,
      comment: formValues.comment,
    };

    this.store.createFarmServiceBooking(command)
      .then(() => showSuccessMessage('Hofdienst gebucht.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  getFarmServiceNamesById(farmServiceIds: FarmServiceId[]): string[] {
    return (this.store.farmServicesForHorse ?? [])
      .filter((farmService) => farmServiceIds.includes(farmService.farmServiceId))
      .map((farmService) => farmService.name);
  }

  isDateAllowedForSubscriptionFarmService(date: Date): boolean {
    const today = Date.today();

    const firstPossibleDate = today.startOfMonth();
    const lastPossibleDate = firstPossibleDate.addMonths(6);
    const firstPossibleDateWhenBookingIsNotAllowedInCurrentMonth = firstPossibleDate.addMonths(1);

    if (date.isBefore(firstPossibleDate)) {
      return false;
    }
    if (date.isAfter(lastPossibleDate)) {
      return false;
    }
    if (!this.farmService.subscriptionConfiguration!.isBookingInCurrentMonthAllowed
      && date.isBefore(firstPossibleDateWhenBookingIsNotAllowedInCurrentMonth)
    ) {
      return false;
    }
    if (this.farmService.bookings.some(
      (farmServiceBooking) => farmServiceBooking.endingAt === null
        || farmServiceBooking.endingAt.isAfterOrEqualTo(date)
    )) {
      return false;
    }
    if (this.farmService.subscriptionConfiguration!.bookingInAdvance !== null
      && date.isBefore(today.add(this.farmService.subscriptionConfiguration!.bookingInAdvance!))
    ) {
      return false;
    }
    if (this.farmService.enableWithFarmServiceBookings.length > 0
      && !this.isFarmServiceBookingOverlappingWithDate(this.farmServiceBookingsToEnableFarmService, date)
    ) {
      return false;
    }
    if (this.farmService.disableWithFarmServiceBookings.length > 0
      && this.isFarmServiceBookingOverlappingWithDate(this.farmServiceBookingsToDisableFarmService, date)
    ) {
      return false;
    }

    return true;
  }

  isDateAllowedForOneOffFarmService(date: Date): boolean {
    const today = Date.today();
    const earliestDateToDueBookingInAdvance = today.add(this.farmService.oneOffConfiguration!.bookingInAdvance)
    if (date.isBefore(earliestDateToDueBookingInAdvance)) {
      return false;
    }

    const latestBookingDate = today.addMonths(6);
    if (date.isAfter(latestBookingDate)) {
      return false;
    }

    if (this.farmService.enableWithFarmServiceBookings.length > 0
      && !this.isFarmServiceBookingOverlappingWithDate(this.farmServiceBookingsToEnableFarmService, date)
    ) {
      return false;
    }

    if (this.farmService.disableWithFarmServiceBookings.length > 0
      && this.isFarmServiceBookingOverlappingWithDate(this.farmServiceBookingsToDisableFarmService, date)
    ) {
      return false;
    }

    return true;
  }

  isFarmServiceBookingOverlappingWithDate(
    farmServiceBookings: FarmServiceBooking[],
    date: Date,
  ): boolean {
    return farmServiceBookings
      .some((farmServiceBooking) => date.isAfterOrEqualTo(farmServiceBooking.startingAt)
        && (!farmServiceBooking.endingAt || date.isBeforeOrEqualTo(farmServiceBooking.endingAt)));
  }

  show(): void {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
