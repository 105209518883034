
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FeedingSettingsHistoryEntry } from '../types';
import FeedingHistoryEntry from './feeding-history-entry.vue';

@Component({
  components: {
    FeedingHistoryEntry,
  },
})
export default class FeedingHistory extends Vue {

  @Prop({ type: Array, required: true })
  readonly feedingHistory!: FeedingSettingsHistoryEntry[];

  @Prop({ type: Boolean, required: true })
  readonly isDiffToPreviousShown!: boolean;

}
