import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress } from '@/store';
import { HorseId, PaddockPlanTimeRangeId } from '@/types';
import { addHorseToTimeRange, createPaddock, createTimeRange, deletePaddock, deleteTimeRange, getPaddockPlan, removeHorseFromTimeRange, updatePaddock, updateTimeRange } from './service';
import { AddHorseToTimeRangeAsManagerCommand, CreatePaddockAsManagerCommand, CreateTimeRangeAsManagerCommand, DeletePaddockAsManagerCommand, DeleteTimeRangeAsManagerCommand, PaddockPlan, RemoveHorseFromTimeRangeAsManagerCommand, UpdatePaddockAsManagerCommand, UpdateTimeRangeAsManagerCommand } from './types';

interface PaddockPlanManagementState {
  paddockPlan: PaddockPlan | null;
  idOfSelectedHorse: HorseId | null;
  idOfPaddockPlanTimeRangeInProgress: PaddockPlanTimeRangeId | null;

  getPaddockPlanStatus: ActionStatus;
  createPaddockStatus: ActionStatus;
  updatePaddockStatus: ActionStatus;
  deletePaddockStatus: ActionStatus;
  createTimeRangeStatus: ActionStatus;
  updateTimeRangeStatus: ActionStatus;
  deleteTimeRangeStatus: ActionStatus;
  addHorseToTimeRangeStatus: ActionStatus;
  removeHorseFromTimeRangeStatus: ActionStatus;
}

function initialState(): PaddockPlanManagementState {
  return {
    paddockPlan: null,
    idOfSelectedHorse: null,
    idOfPaddockPlanTimeRangeInProgress: null,

    getPaddockPlanStatus: ActionStatus.None,
    createPaddockStatus: ActionStatus.None,
    updatePaddockStatus: ActionStatus.None,
    deletePaddockStatus: ActionStatus.None,
    createTimeRangeStatus: ActionStatus.None,
    updateTimeRangeStatus: ActionStatus.None,
    deleteTimeRangeStatus: ActionStatus.None,
    addHorseToTimeRangeStatus: ActionStatus.None,
    removeHorseFromTimeRangeStatus: ActionStatus.None,
  };
}

export const usePaddockPlanManagementStore = defineStore('paddockPlanManagement', {
  state: (): PaddockPlanManagementState => initialState(),
  getters: {
    isPaddockPlanTimeRangeProcessing: (state: PaddockPlanManagementState): (paddockPlanTimeRangeId: PaddockPlanTimeRangeId) => boolean =>
      (paddockPlanTimeRangeId) => state.idOfPaddockPlanTimeRangeInProgress === paddockPlanTimeRangeId,

    isGetPaddockPlanProcessing: (state: PaddockPlanManagementState): boolean =>
      state.getPaddockPlanStatus === ActionStatus.InProgress,
    isCreatePaddockProcessing: (state: PaddockPlanManagementState): boolean =>
      state.createPaddockStatus === ActionStatus.InProgress,
    isUpdatePaddockProcessing: (state: PaddockPlanManagementState): boolean =>
      state.updatePaddockStatus === ActionStatus.InProgress,
    isDeletePaddockProcessing: (state: PaddockPlanManagementState): boolean =>
      state.deletePaddockStatus === ActionStatus.InProgress,
    isCreateTimeRangeProcessing: (state: PaddockPlanManagementState): boolean =>
      state.createTimeRangeStatus === ActionStatus.InProgress,
    isUpdateTimeRangeProcessing: (state: PaddockPlanManagementState): boolean =>
      state.updateTimeRangeStatus === ActionStatus.InProgress,
    isDeleteTimeRangeProcessing: (state: PaddockPlanManagementState): boolean =>
      state.deleteTimeRangeStatus === ActionStatus.InProgress,
    isAddHorseToTimeRangeProcessing: (state: PaddockPlanManagementState): boolean =>
      state.addHorseToTimeRangeStatus === ActionStatus.InProgress,
    isRemoveHorseFromTimeRangeProcessing: (state: PaddockPlanManagementState): boolean =>
      state.removeHorseFromTimeRangeStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    selectHorse(horseId: HorseId): Promise<void> {
      this.idOfSelectedHorse = horseId;

      return Promise.resolve();
    },

    revertHorseSelection(): Promise<void> {
      this.idOfSelectedHorse = null;

      return Promise.resolve();
    },

    // -- Queries

    getPaddockPlan(): Promise<void> {
      const { getPaddockPlanStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getPaddockPlanStatus,
        () => getPaddockPlan(attachFarmAndUserProperties({}))
          .then(async (paddockPlan) => {
            this.paddockPlan = paddockPlan;
          })
      );
    },

    // -- Commands

    createPaddock(command: CreatePaddockAsManagerCommand): Promise<void> {
      const { createPaddockStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createPaddockStatus,
        () => createPaddock(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
      );
    },

    updatePaddock(command: UpdatePaddockAsManagerCommand): Promise<void> {
      const { updatePaddockStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePaddockStatus,
        () => updatePaddock(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
      );
    },

    deletePaddock(command: DeletePaddockAsManagerCommand): Promise<void> {
      const { deletePaddockStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deletePaddockStatus,
        () => deletePaddock(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
      );
    },

    createTimeRange(command: CreateTimeRangeAsManagerCommand): Promise<void> {
      const { createTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createTimeRangeStatus,
        () => createTimeRange(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
      );
    },

    updateTimeRange(command: UpdateTimeRangeAsManagerCommand): Promise<void> {
      const { updateTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTimeRangeStatus,
        () => updateTimeRange(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
      );
    },

    deleteTimeRange(command: DeleteTimeRangeAsManagerCommand): Promise<void> {
      const { deleteTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteTimeRangeStatus,
        () => deleteTimeRange(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
      );
    },

    addHorseToTimeRange(command: AddHorseToTimeRangeAsManagerCommand): Promise<void> {
      this.idOfPaddockPlanTimeRangeInProgress = command.paddockPlanTimeRangeId;
      const { addHorseToTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        addHorseToTimeRangeStatus,
        () => addHorseToTimeRange(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
          .finally(() => {
            this.idOfSelectedHorse = null;
            this.idOfPaddockPlanTimeRangeInProgress = null;
          })
      );
    },

    removeHorseFromTimeRange(command: RemoveHorseFromTimeRangeAsManagerCommand): Promise<void> {
      const { removeHorseFromTimeRangeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeHorseFromTimeRangeStatus,
        () => removeHorseFromTimeRange(attachFarmAndUserProperties(command))
          .then(() => this.getPaddockPlan())
      );
    },

  },
});
