import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress } from '@/store';
import { acceptNewsletter, deleteOwnAccount, getProfile, rejectNewsletter, updateDefaultNameOrderType, updateEmergencyContactOfUser, updatePasswordOfUser, updatePhoneNumber, updateUserEmailAddress, updateUserName } from './service';
import { DeleteOwnAccountWorkflow, Profile, UpdateUserNameCommand, UpdatePhoneNumberCommand, UpdateUserEmailAddressCommand, UpdateEmergencyContactOfUserCommand, UpdatePasswordOfUserCommand, UpdateDefaultNameOrderTypeAsManagerCommand } from './types';

interface ProfileState {
  profile: Profile | null;

  getProfileStatus: ActionStatus;
  updateUserNameStatus: ActionStatus;
  updateUserEmailAddressStatus: ActionStatus;
  updatePhoneNumberStatus: ActionStatus;
  updateEmergencyContactOfUserStatus: ActionStatus;
  updatePasswordOfUserStatus: ActionStatus;
  acceptNewsletterStatus: ActionStatus;
  rejectNewsletterStatus: ActionStatus;
  deleteOwnAccountStatus: ActionStatus;
  updateDefaultNameOrderTypeStatus: ActionStatus;
}

function initialState(): ProfileState {
  return {
    profile: null,

    getProfileStatus: ActionStatus.None,
    updateUserNameStatus: ActionStatus.None,
    updateUserEmailAddressStatus: ActionStatus.None,
    updatePhoneNumberStatus: ActionStatus.None,
    updateEmergencyContactOfUserStatus: ActionStatus.None,
    updatePasswordOfUserStatus: ActionStatus.None,
    acceptNewsletterStatus: ActionStatus.None,
    rejectNewsletterStatus: ActionStatus.None,
    deleteOwnAccountStatus: ActionStatus.None,
    updateDefaultNameOrderTypeStatus: ActionStatus.None,
  };
}

export const useProfileStore = defineStore('profile', {
  state: (): ProfileState => initialState(),
  getters: {
    isGetProfileProcessing: (state: ProfileState): boolean =>
      state.getProfileStatus === ActionStatus.InProgress,
    isUpdateUserNameProcessing: (state: ProfileState): boolean =>
      state.updateUserNameStatus === ActionStatus.InProgress,
    isUpdateUserEmailAddressProcessing: (state: ProfileState): boolean =>
      state.updateUserEmailAddressStatus === ActionStatus.InProgress,
    isUpdatePhoneNumberProcessing: (state: ProfileState): boolean =>
      state.updatePhoneNumberStatus === ActionStatus.InProgress,
    isUpdateEmergencyContactOfUserProcessing: (state: ProfileState): boolean =>
      state.updateEmergencyContactOfUserStatus === ActionStatus.InProgress,
    isUpdatePasswordOfUserProcessing: (state: ProfileState): boolean =>
      state.updatePasswordOfUserStatus === ActionStatus.InProgress,
    isAcceptNewsletterProcessing: (state: ProfileState): boolean =>
      state.acceptNewsletterStatus === ActionStatus.InProgress,
    isRejectNewsletterProcessing: (state: ProfileState): boolean =>
      state.rejectNewsletterStatus === ActionStatus.InProgress,
    isDeleteOwnAccountProcessing: (state: ProfileState): boolean =>
      state.deleteOwnAccountStatus === ActionStatus.InProgress,
    isUpdateDefaultNameOrderTypeProcessing: (state: ProfileState): boolean =>
      state.updateDefaultNameOrderTypeStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getProfile(): Promise<void> {
      const { getProfileStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getProfileStatus,
        () => getProfile(attachFarmAndUserProperties({}))
          .then((profile) => {
            this.profile = profile;
          })
      );
    },

    // -- Commands

    updateUserName(command: UpdateUserNameCommand): Promise<void> {
      const { updateUserNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserNameStatus,
        () => updateUserName(attachFarmAndUserProperties(command))
          .then(() => this.getProfile())
      );
    },

    updateUserEmailAddress(command: UpdateUserEmailAddressCommand): Promise<void> {
      const { updateUserEmailAddressStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserEmailAddressStatus,
        () => updateUserEmailAddress(attachFarmAndUserProperties(command))
          .then(() => this.getProfile())
      );
    },

    updatePhoneNumber(command: UpdatePhoneNumberCommand): Promise<void> {
      const { updatePhoneNumberStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePhoneNumberStatus,
        () => updatePhoneNumber(attachFarmAndUserProperties(command))
          .then(() => useAuthenticationStore().getAuthentication())
          .then(() => this.getProfile())
      );
    },

    updateEmergencyContactOfUser(command: UpdateEmergencyContactOfUserCommand): Promise<void> {
      const { updateEmergencyContactOfUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEmergencyContactOfUserStatus,
        () => updateEmergencyContactOfUser(attachFarmAndUserProperties(command))
          .then(() => this.getProfile())
      );
    },

    updatePasswordOfUser(command: UpdatePasswordOfUserCommand): Promise<void> {
      const { updatePasswordOfUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updatePasswordOfUserStatus,
        () => updatePasswordOfUser(attachFarmAndUserProperties(command))
          .then(() => this.getProfile())
      );
    },

    acceptNewsletter(): Promise<void> {
      const { acceptNewsletterStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        acceptNewsletterStatus,
        () => acceptNewsletter(attachFarmAndUserProperties({}))
          .then(() => this.getProfile())
      );
    },

    rejectNewsletter(): Promise<void> {
      const { rejectNewsletterStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        rejectNewsletterStatus,
        () => rejectNewsletter(attachFarmAndUserProperties({}))
          .then(() => this.getProfile())
      );
    },

    deleteOwnAccount(command: DeleteOwnAccountWorkflow): Promise<void> {
      const { deleteOwnAccountStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteOwnAccountStatus,
        () => deleteOwnAccount(attachFarmAndUserProperties(command))
      );
    },

    updateDefaultNameOrderType(command: UpdateDefaultNameOrderTypeAsManagerCommand): Promise<void> {
      const { updateDefaultNameOrderTypeStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDefaultNameOrderTypeStatus,
        () => updateDefaultNameOrderType(attachFarmAndUserProperties(command))
          .then(() => this.getProfile())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

  },
});
