
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { Link, Strain } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { useAuthenticationStore } from '@/application/authentication/store';
import { CreateHorseTrainingSessionForFacilityReservationCommand, TrainingDiaryEntry } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  rider: FormControl<string>;
  facility: FormControl<string>;
  activity: FormControl<string>;
  strainOnHorse: FormControl<Strain>;
  externalLink: FormControl<Link>;
  notes: FormControl<string>;
}

@Component
export default class CreateHorseTrainingSessionForFacilityReservationDialog extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly trainingDiaryEntry!: TrainingDiaryEntry;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        rider: {
          label: 'Reiter',
          value: this.trainingDiaryEntry.rider,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        facility: {
          label: 'Anlage',
          value: this.trainingDiaryEntry.facility,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        activity: {
          label: 'Aktivität',
          value: this.trainingDiaryEntry.activity,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        strainOnHorse: {
          label: 'Belastung für das Pferd',
          value: Strain.MINIMAL_STRAIN,
          isRequired: true,
        },
        externalLink: {
          label: 'Link zu weiterführenden Informationen',
          value: null,
        },
        notes: {
          label: 'Notizen',
          value: null,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateHorseTrainingSessionForFacilityReservationCommand = {
      facilityReservationId: this.trainingDiaryEntry.facilityReservationId!,
      facility: formValues.facility!,
      activity: formValues.activity!,
      rider: formValues.rider!,
      strainOnHorse: formValues.strainOnHorse!,
      externalLink: formValues.externalLink,
      notes: formValues.notes,
    };

    this.store.createHorseTrainingSessionForFacilityReservation(command)
      .then(() => showSuccessMessage('Die Anlagenreservierung wurde ergänzt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
