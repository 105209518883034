
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useBookingCalendarStore } from '../store';
import FacilityCalendar from './facility-calendar.vue';

@Component({
  components: {
    FacilityCalendar,
  },
})
export default class FacilityCalendarLoadingWrapper extends Vue {

  readonly store = useBookingCalendarStore();
  readonly appStore = useAppStore();

  mounted(): void {
    this.store.getFacilitiesOverview()
      .catch((error) => showErrorResponse(error));

    watch(() => this.store.currentFacility, () => {
      this.appStore.updateTitle(this.store.currentFacility?.name ?? null)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

}
