import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachUserProperty } from '@/helpers/default-parameter-helper';
import { AdminNotification, CreateAdminNotificationAsAdminCommand, DeleteAdminNotificationAsAdminCommand, UpdateAdminNotificationAsAdminCommand } from './types';
import { createAdminNotification, deleteAdminNotification, getAllAdminNotifications, updateAdminNotification } from './service';

interface ManageAdminNotificationsState {
  adminNotifications: AdminNotification[];

  getAllAdminNotificationsStatus: ActionStatus;
  createAdminNotificationStatus: ActionStatus;
  updateAdminNotificationStatus: ActionStatus;
  deleteAdminNotificationStatus: ActionStatus;
}

function initialState(): ManageAdminNotificationsState {
  return {
    adminNotifications: [],

    getAllAdminNotificationsStatus: ActionStatus.None,
    createAdminNotificationStatus: ActionStatus.None,
    updateAdminNotificationStatus: ActionStatus.None,
    deleteAdminNotificationStatus: ActionStatus.None,
  };
}

export const useAdminNotificationManagementStore = defineStore('manageAdminNotifications', {
  state: (): ManageAdminNotificationsState => initialState(),
  getters: {
    isGetAllAdminNotificationsProcessing: (state: ManageAdminNotificationsState): boolean =>
      state.getAllAdminNotificationsStatus === ActionStatus.InProgress,
    isCreateAdminNotificationProcessing: (state: ManageAdminNotificationsState): boolean =>
      state.createAdminNotificationStatus === ActionStatus.InProgress,
    isUpdateAdminNotificationProcessing: (state: ManageAdminNotificationsState): boolean =>
      state.updateAdminNotificationStatus === ActionStatus.InProgress,
    isDeleteAdminNotificationProcessing: (state: ManageAdminNotificationsState): boolean =>
      state.deleteAdminNotificationStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getAllAdminNotifications(): Promise<void> {
      const { getAllAdminNotificationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getAllAdminNotificationsStatus,
        () => getAllAdminNotifications(attachUserProperty({}))
          .then(async (adminNotifications) => {
            this.adminNotifications = adminNotifications;
          })
      );
    },

    // -- Commands

    createAdminNotification(command: CreateAdminNotificationAsAdminCommand): Promise<void> {
      const { createAdminNotificationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createAdminNotificationStatus,
        () => createAdminNotification(attachUserProperty(command))
          .then(() => this.getAllAdminNotifications())
      );
    },

    updateAdminNotification(command: UpdateAdminNotificationAsAdminCommand): Promise<void> {
      const { updateAdminNotificationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateAdminNotificationStatus,
        () => updateAdminNotification(attachUserProperty(command))
          .then(() => this.getAllAdminNotifications())
      );
    },

    deleteAdminNotification(command: DeleteAdminNotificationAsAdminCommand): Promise<void> {
      const { deleteAdminNotificationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteAdminNotificationStatus,
        () => deleteAdminNotification(attachUserProperty(command))
          .then(() => this.getAllAdminNotifications())
      );
    },

  },
});
