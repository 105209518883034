import { CalendarViewPreference, Time, TimeFrame } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { CalendarRangeType } from './types';

export function calendarRangeTypeFromCalendarViewPreferenceAndResolution(): CalendarRangeType {
  const calendarViewPreference = useAuthenticationStore().user!.calendarViewPreference;

  switch (calendarViewPreference) {
    case CalendarViewPreference.AUTOMATIC:
      return calendarRangeTypeFromResolution();
    case CalendarViewPreference.DAY_FIRST:
      return CalendarRangeType.day;
    case CalendarViewPreference.WEEK_FIRST:
      return calendarRangeTypeFromResolution();
    default:
      throw new Error(`Unknown calendar view preference: ${calendarViewPreference}`);
  }
}

export function calendarRangeTypeFromResolution(): CalendarRangeType {
  // 667 is the width of an iPhone SE 2 in landscape mode. This is the smallest size that must use the week view.
  return window.innerWidth >= 667
    ? CalendarRangeType.week
    : CalendarRangeType.day;
}

export function calculateTimeThroughScrollPosition(
  calendar: Element,
  menuBarSize: number,
  tableHeaderSize: number,
  timeOptionInterval: number,
  openingHours: TimeFrame
): Time {
  const calendarRect = calendar.getBoundingClientRect();
  const calendarContentHeight = calendarRect.height - tableHeaderSize;
  const minutesInOpeningHours = openingHours.timeTo.diff(openingHours.timeFrom, 'minutes');
  const intervals = minutesInOpeningHours / timeOptionInterval;
  const intervalHeight = calendarContentHeight / intervals;

  // * - 1 as it's measuring the position within the component
  const calendarPositionWithoutMenu = (calendarRect.y - menuBarSize) * -1;

  // Using ceil as it's more relevant to get to the next interval instead of the previous one.
  const amountOfIntervalsFromTop = calendarPositionWithoutMenu > 0
    ? Math.ceil(calendarPositionWithoutMenu / intervalHeight)
    : 0;

  return openingHours.timeFrom.add(amountOfIntervalsFromTop * timeOptionInterval, 'minutes');
}
