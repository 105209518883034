
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { ActionStatus } from '@/application/types';
import { formatDate, formatTime } from '@/helpers';
import { Moment, Month } from '@/types';
import { moment } from '@/helpers';
import SettlementDetails from '@/private/management/ledger/ledger-configuration/components/settlement-details.vue';
import { useMyStableStore } from '../store';
import { GetSettlementForecastQuery } from '../types';
import SettlementCategories from './settlement-categories.vue';

@Component({
  components: { SettlementDetails, SettlementCategories }
})
export default class SettlementForecast extends Vue {

  readonly store = useMyStableStore();

  get translatedMonth(): string {
    return this.monthForForecast.format('MMMM');
  }

  get isNoSettlementForecastVisible(): boolean {
    return this.store.getSettlementForecastStatus === ActionStatus.Failed
      && this.store.settlementForecast === null;
  }

  get updatedAtHTML(): string {
    if (this.store.settlementForecast === null) {
      return '';
    }

    // eslint-disable-next-line max-len
    return `Die Prognose für ${this.translatedMonth} wurde zuletzt <strong>am ${formatDate(this.store.settlementForecast!.lastUpdatedAt)} um ${formatTime(this.store.settlementForecast!.lastUpdatedAt)} Uhr</strong> aktualisiert. Die Daten werden jede Nacht aktualisiert.`;
  }

  get monthForForecast(): Moment {
    return moment().startOf('month').add(1, 'month');
  }

  mounted(): void {
    const nextMonth = moment()
      .startOf('month')
      .add(1, 'month');

    const query: GetSettlementForecastQuery = {
      month: nextMonth.format('YYYY-MM') as Month,
    };

    this.store.getSettlementForecast(query)
      .catch((error) => showErrorResponse(error));
  }

}
