import Vue, { VNode } from 'vue';
// @ts-ignore
import Sortable from 'sortablejs/modular/sortable.core.esm';
import { SortableEvent } from 'sortablejs';
import { DirectiveBinding } from 'vue/types/options';

Vue.directive('sortableDataTable', {
  // When the bound element is inserted into the DOM...
  bind: (el: HTMLElement, binding: DirectiveBinding, vNode: VNode) => {
    Sortable.create(
      el.getElementsByTagName('tbody')[0],
      {
        animation: 150,
        onUpdate: (event: SortableEvent): void => {
          vNode.componentInstance!.$emit('sorted', event);
        },
        handle: '.sort-handle',
      }
    );
  },
});

export function sortArray<T>(items: T[], event: SortableEvent): void {
  const movedItem = items.splice(event.oldIndex!, 1)[0];
  items.splice(event.newIndex!, 0, movedItem);
}
