
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { MONTH_FORMAT } from '@/helpers/data';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, mountFormControl, wasValidationSuccessful, isFieldShownAsContainingAnError, labelWithRequiredIndicator } from '@/components/form';
import { formattedDatePickerValueAsDate, moment } from '@/helpers';
import { Moment } from '@/types';

export type FormatType = 'day' | 'month' | 'calendar-week';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class MomentDatePickerFormControl extends Vue implements FormControlComponent<Moment> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Moment>;

  @Prop({ type: Function, default: null })
  readonly isDateAllowed!: ((date: Moment) => boolean) | null;

  @Prop({ type: String, default: 'day' })
  readonly formatType!: FormatType;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  isMenuVisible = false;

  internalValue = '';

  formFieldValueWatcher = emptyFormFieldWatcher();

  get formattedTextFieldValue(): string {
    if (this.internalValue === '') {
      return '';
    }

    if (this.formatType === 'day') {
      return formattedDatePickerValueAsDate(this.internalValue);
    }

    if (this.formatType === 'month') {
      return moment(this.internalValue).format(MONTH_FORMAT);
    }

    if (this.formatType === 'calendar-week') {
      return `KW ${moment(this.internalValue).format('w')}`;
    }

    return '';
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  dateChanged(): void {
    if (this.internalValue === null) {
      this.internalValue = '';
    }

    internalValuesChanged(this);
    this.focused();
    this.blurred();

    this.isMenuVisible = false;
  }

  textFieldListeners(on: any): any {
    return {
      ...on,
      'click:clear': this.clearButtonClicked,
    };
  }

  allowedDates(date: string): boolean {
    return this.isDateAllowed !== null
      ? this.isDateAllowed(moment(date))
      : true;
  }

  clearButtonClicked(): void {
    this.internalValue = '';

    internalValuesChanged(this);
    this.blurred();
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? ''
      : this.formControl.value.format('YYYY-MM-DD');
  }

  formValueFromInternalValues(): FormControlValue<Moment> {
    return this.internalValue.length > 0
      ? moment(this.internalValue).startOf('day')
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
