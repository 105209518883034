import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateTaskCommand, GetTasksForUserQuery, TaskResponse, WithdrawTaskCommand } from './types';

// -- Queries

export function getTasksForUser(query: GetTasksForUserQuery & FarmAndUserProperties): Promise<TaskResponse> {
  const url = `${apiUrl}/api/rider-tasks/get-tasks-for-user-query`;
  return performApiRequest<TaskResponse>({ url, method: 'POST', data: query });
}

// -- Commands

export function createTask(command: CreateTaskCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/create-task-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function withdrawTask(command: WithdrawTaskCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/rider-tasks/withdraw-task-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
