import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash-es/merge';
import validator from 'validator';
import { getMobileAppVersion } from '@/application/app-device-info/device-information';
import { CommandWithFiles, FileResponse } from '@/application/types';
import { Time, Date, Moment } from '@/types';
import { isMoment, momentWithoutTimezone } from '@/helpers';

// Regular expression to pre-check if strings contain an ISO-8601 date.
// Based on http://dotat.at/tmp/ISO_8601-2004_E.pdf, implementation from https://stackoverflow.com/a/3143231
// eslint-disable-next-line max-len
const dateTimeRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;
// Regular expression to pre-check if strings contain an DIN-5008 date.
// const dayRegex = /^\d{4}-\d{2}-\d{2}$/;

function isDateTimeString(value: unknown): boolean {
  // Check if string contains ISO-8601 or DIN-5008 date before parsing to avoid warnings.
  return typeof value === 'string'
    && dateTimeRegex.test(value);
}

function isDateString(value: unknown): boolean {
  return typeof value === 'string'
    && momentWithoutTimezone(value, 'YYYY-MM-DD', true).isValid();
}

function momentAndDateAndTimeReviver(_: string, value: unknown): Moment | unknown {
  if (isDateTimeString(value)) {
    return momentWithoutTimezone
      .utc(value as string)
      .tz('Europe/Berlin');
  }

  if (isDateString(value)) {
    return new Date(value as string);
  }

  if (Time.isValidTimeString(value)) {
    return Time.fromString(value as string);
  }

  return value;
}

function momentAndDateAndTimeReplacer(this: any, key: string, value: unknown): string | unknown {
  if (isMoment(this[key])) {
    return (this[key] as Moment).toISOString();
  }

  if (Date.isDate(this[key])) {
    return (this[key] as Date).date;
  }

  if (Time.isTime(this[key])) {
    return (this[key] as Time).format();
  }

  return value;
}

function transformRequest(data: object): unknown {
  // Catch calls of transform function with unexpected payload (string|undefined) to avoid double-encoding.
  if (typeof data !== 'object') {
    return data;
  }
  return JSON.stringify(data, momentAndDateAndTimeReplacer);
}

function transformRequestWithFiles(command: CommandWithFiles): unknown {
  // Catch calls of transform function with unexpected payload (string|undefined) to avoid double-encoding.
  if (typeof command !== 'object') {
    return command;
  }
  const data = new FormData();
  data.append('body', JSON.stringify(command.body, momentAndDateAndTimeReplacer));
  Object.keys(command.files).forEach((key) => {
    const file = command.files[key];
    // We need to filter files with name `body` as they otherwise would overwrite the other JSON serialized content we want to send
    if (key !== 'body' && file) {
      data.append(key, file);
    }
  });

  return data;
}

function transformResponse(data: any): string {
  return typeof data === 'string'
    && data.length > 0
    && validator.isJSON(data)
    ? JSON.parse(data, momentAndDateAndTimeReviver)
    : data;
}

function defaultHeaders(): Record<string, string> {
  const mobileAppVersion = getMobileAppVersion();
  const headers = {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
  if (!mobileAppVersion) {
    return headers;
  }

  return {
    ...headers,
    'X-MOBILE-APP-VERSION': mobileAppVersion,
  };
}

function formDataHeaders(): Record<string, string> {
  const mobileAppVersion = getMobileAppVersion();
  const headers = {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'multipart/form-data',
    'X-APP-VERSION': process.env.SOURCE_VERSION,
  };
  if (!mobileAppVersion) {
    return headers;
  }

  return {
    ...headers,
    'X-MOBILE-APP-VERSION': mobileAppVersion,
  };
}

function generateTrialRunHeader(isTrialRun: boolean): AxiosRequestConfig {
  return (isTrialRun)
    ? { headers: { 'X-TRIAL-RUN': true } }
    : {};
}

function buildRequest(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: defaultHeaders(),
    transformRequest: [transformRequest],
    transformResponse: [transformResponse],
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

function buildRequestWithFiles(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: formDataHeaders(),
    transformRequest: [transformRequestWithFiles],
    transformResponse: [transformResponse],
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

function buildRequestForBinary(config: AxiosRequestConfig, isTrialRun: boolean): AxiosRequestConfig {
  const defaults: AxiosRequestConfig = {
    headers: {
      ...defaultHeaders(),
      ...config.headers,
    },
    transformRequest,
    withCredentials: true,
    responseType: 'blob',
  };
  const trialRunHeader = generateTrialRunHeader(isTrialRun);

  return merge(defaults, config, trialRunHeader);
}

export async function performApiRequest<T = void>(config: AxiosRequestConfig, isTrialRun = false): Promise<T> {
  return axios.request(buildRequest(config, isTrialRun))
    .then((response) => response.data);
}

export async function performApiRequestWithFiles<T = void>(config: AxiosRequestConfig, isTrialRun = false): Promise<T> {
  return axios.request(buildRequestWithFiles(config, isTrialRun))
    .then((response) => response.data);
}

export async function performUnauthorizedApiRequest<T>(request: AxiosRequestConfig): Promise<T> {
  return axios.request(request)
    .then((response) => response.data);
}

export async function performApiRequestForFile(config: AxiosRequestConfig, isTrialRun = false): Promise<FileResponse> {
  return axios.request(buildRequestForBinary(config, isTrialRun))
    .then((response) => ({
      data: response.data,
      contentType: response.headers['content-type'],
    }));
}
