
import { watch } from 'vue';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, errorMessagesForInternalRules, FormControl, FormControlComponent, FormControlValue, FormFunctions, InternalValueRule, InternalValueRules, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Time, TimeFrame } from '@/types';
import { VuetifySelectItem } from '@/application/types';
import { generateTimes } from '@/helpers/form-helpers';
import { translateMinutes } from '@/helpers/translations';

@Component({
  methods: { translateMinutes, isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class TimeFrameIntervalFormControl extends Vue implements FormControlComponent<TimeFrame> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<TimeFrame>;

  @Prop({ type: Number, required: true })
  readonly timeOptionInterval!: number;

  @Prop({ type: Number, required: true })
  readonly minIntervals!: number;

  @Prop({ type: Number, required: true })
  readonly maxIntervals!: number;

  @Prop({ type: Number, default: 1 })
  readonly preferredIntervals!: number;

  @Prop({ type: Object, default: null })
  readonly timeFrame!: TimeFrame | null;

  readonly formControlId = createFormControlId();

  readonly defaultTimeFrame: TimeFrame = {
    timeFrom: new Time(5, 0, 0),
    timeTo: new Time(0, 0, 0),
  };

  readonly internalRules: InternalValueRules = [
    this.fromBeforeToRule(),
    this.notBelowMinIntervalsRule(),
    this.notOverMaxIntervalsRule(),
    // This must be the last rules as every previous issue leads to an empty field
    this.allFieldsSelected(),
  ];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalTimeFrom: Time | null = null;
  internalTimeTo: Time | null = null;
  internalIntervals: number | null = !!this.preferredIntervals
    && this.availableIntervals.includes(this.preferredIntervals)
    ? this.preferredIntervals
    : this.minIntervals;
  areInternalValuesValid = false;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get timeFromSelectItems(): VuetifySelectItem<Time>[] {
    const relevantTimeFrame = this.timeFrame ?? this.defaultTimeFrame;

    const minDistance = this.timeOptionInterval * this.minIntervals;

    const startTime = relevantTimeFrame.timeFrom;
    const endTime = relevantTimeFrame.timeTo
      .substract(minDistance, 'minutes');

    return generateTimes(startTime, endTime, this.timeOptionInterval)
      .map((time) => ({
        text: time.format('HH:mm'),
        value: time,
      }));
  }

  get timeToSelectItems(): VuetifySelectItem<Time>[] {
    const relevantTimeFrame = this.timeFrame ?? this.defaultTimeFrame;

    const minDistance = this.timeOptionInterval * this.minIntervals;

    const startTime = relevantTimeFrame.timeFrom
      .add(minDistance, 'minutes');
    const endTime = relevantTimeFrame.timeTo;

    return generateTimes(startTime, endTime, this.timeOptionInterval)
      .map((time) => ({
        text: time.format('HH:mm'),
        value: time,
      }));
  }

  get availableIntervals(): number[] {
    const intervals: number[] = [];
    for (let i = this.minIntervals; i <= this.maxIntervals; i++) {
      intervals.push(i);
    }
    return intervals;
  }

  get durationSelectItems(): VuetifySelectItem<number>[] {
    return this.availableIntervals
      .map((interval) => ({
        text: translateMinutes(interval * this.timeOptionInterval),
        value: interval,
      }));
  }

  mounted(): void {
    mountFormControl(this);

    watch(() => this.timeOptionInterval, () => {
      internalValuesChanged(this);
    });
    watch(() => this.minIntervals, () => {
      internalValuesChanged(this);
    });
    watch(() => this.maxIntervals, () => {
      internalValuesChanged(this);
    });
    watch(() => this.preferredIntervals, (preferredIntervals) => {
      this.internalIntervals = !!preferredIntervals
      && this.availableIntervals.includes(preferredIntervals)
        ? preferredIntervals
        : this.minIntervals;
      internalValuesChanged(this);
    });

    // Initial as the preferred values aren't send first
    internalValuesChanged(this);
  }

  timeFromChanged(): void {
    if (this.internalIntervals === null) {
      const calculatedIntervals = this.internalTimeTo!.diff(this.internalTimeFrom!, 'minutes') / this.timeOptionInterval;
      if (calculatedIntervals <= this.maxIntervals) {
        this.internalIntervals = calculatedIntervals;
      }
    } else {
      const durationInMinutes = this.internalIntervals * this.timeOptionInterval;
      const newTimeTo = this.internalTimeFrom!.add(durationInMinutes, 'minutes');
      const timeFrame = this.timeFrame ?? this.defaultTimeFrame;
      /**
       * Usually the time to is simply moved back depending on the duration. But when the time to would be outside the opening time, we
       * reduce the duration instead.
       */
      if (newTimeTo.isWithinTimeFrame(timeFrame)) {
        this.internalTimeTo = newTimeTo;
      } else {
        const maxIntervalsLeftInTimeFrame = this.maxIntervalsLeftInTimeFrame(
          {
            timeFrom: this.internalTimeFrom!,
            timeTo: timeFrame.timeTo,
          },
          this.timeOptionInterval
        );

        if (maxIntervalsLeftInTimeFrame !== null) {
          this.internalIntervals = maxIntervalsLeftInTimeFrame;
          this.internalTimeTo = this.internalTimeFrom!.add(maxIntervalsLeftInTimeFrame * this.timeOptionInterval, 'minutes');
        } else {
          // I'm not sure how we would trigger this, but it's in here to make sure that we don't enable confirming the form.
          this.internalIntervals = null;
        }
      }
    }

    internalValuesChanged(this);
  }

  maxIntervalsLeftInTimeFrame(
    timeFrame: TimeFrame,
    timeOptionInterval: number
  ): number | null {
    let intervals = 0;
    while (timeFrame.timeFrom.add((intervals + 1) * timeOptionInterval, 'minutes').isWithinTimeFrame(timeFrame)) {
      intervals++;
    }

    return intervals > 0
      ? intervals
      : null;
  }

  timeToChanged(): void {
    if (this.internalTimeFrom
      && this.internalTimeTo
      && this.internalTimeFrom.isBefore(this.internalTimeTo)
    ) {
      const calculatedIntervals = this.internalTimeTo!.diff(this.internalTimeFrom!, 'minutes') / this.timeOptionInterval;
      if (calculatedIntervals <= this.maxIntervals) {
        this.internalIntervals = calculatedIntervals;
      } else {
        this.internalIntervals = null;
      }
    } else {
      this.internalIntervals = null;
    }

    internalValuesChanged(this);
  }

  durationChanged(): void {
    if (this.internalTimeFrom) {
      const durationInMinutes = this.internalIntervals! * this.timeOptionInterval;
      const newTimeTo = this.internalTimeFrom.add(durationInMinutes, 'minutes');
      if (newTimeTo.isWithinTimeFrame(this.timeFrame ?? this.defaultTimeFrame)) {
        this.internalTimeTo = newTimeTo;
      } else {
        this.internalTimeFrom = (this.timeFrame ?? this.defaultTimeFrame)
          .timeTo
          .substract(durationInMinutes, 'minutes');
      }
    }

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  fromBeforeToRule(): InternalValueRule<any> {
    return () => this.internalTimeFrom !== null
      && this.internalTimeTo !== null
      && this.internalTimeFrom.isBefore(this.internalTimeTo)
      || '"Von" muss vor "Bis" sein';
  }

  notBelowMinIntervalsRule(): InternalValueRule<any> {
    return () => this.internalTimeFrom !== null
      && this.internalTimeTo !== null
      && this.internalTimeTo.diff(this.internalTimeFrom, 'minutes') / this.timeOptionInterval >= this.minIntervals
      || `Die Dauer muss mindestens ${translateMinutes(this.minIntervals * this.timeOptionInterval)} betragen`;
  }

  notOverMaxIntervalsRule(): InternalValueRule<any> {
    return () => this.internalTimeFrom !== null
      && this.internalTimeTo !== null
      && this.internalTimeTo.diff(this.internalTimeFrom, 'minutes') / this.timeOptionInterval <= this.maxIntervals
      || `Die Dauer darf maximal ${translateMinutes(this.maxIntervals * this.timeOptionInterval)} betragen`;
  }

  allFieldsSelected(): InternalValueRule<any> {
    return () => this.internalTimeFrom !== null
      && this.internalTimeTo !== null
      && this.internalIntervals !== null
      || 'Alle Felder müssen eingetragen werden';
  }

  // -- Form control functions

  validateInternalValue(): boolean {
    this.messages = [
      ...errorMessagesForInternalRules(this.internalRules, {}),
    ];

    this.areInternalValuesValid = this.messages.length === 0;

    return wasValidationSuccessful(this.messages);
  }

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalTimeFrom = this.formControl.value?.timeFrom ?? null;
    this.internalTimeTo = this.formControl.value?.timeTo ?? null;
  }

  formValueFromInternalValues(): FormControlValue<TimeFrame> {
    return this.areInternalValuesValid
      ? {
        timeFrom: this.internalTimeFrom!,
        timeTo: this.internalTimeTo!,
      }
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
