import { defineStore, storeToRefs } from 'pinia';
import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { useAuthenticationStore } from '@/application/authentication/store';
import { createEvent, deleteEvent, deleteEventAttachment, deleteEventImage, deleteEventRegistration, duplicateEvent, getConfiguration, getEvents, getUserGroups, markEventAsHidden, markEventAsVisible, markEventsAsHiddenForRiders, markEventsAsVisibleForRiders, updateEvent } from './service';
import { Configuration, CreateEventAsManagerCommand, DeleteEventAsManagerCommand, DeleteEventAttachmentAsManagerCommand, DeleteEventImageAsManagerCommand, DeleteEventRegistrationAsManagerCommand, DuplicateEventAsManagerCommand, EventEntry, MarkEventAsHiddenAsManagerCommand, MarkEventAsVisibleAsManagerCommand, UpdateEventAsManagerCommand, UserGroup } from './types';

interface EventManagementState {
  events: EventEntry[];
  userGroups: UserGroup[] | null;
  configuration: Configuration | null;

  getEventsStatus: ActionStatus;
  getConfigurationStatus: ActionStatus;
  createEventStatus: ActionStatus;
  updateEventStatus: ActionStatus;
  deleteEventStatus: ActionStatus;
  deleteEventRegistrationStatus: ActionStatus;
  markEventAsHiddenStatus: ActionStatus;
  markEventAsVisibleStatus: ActionStatus;
  markEventsAsVisibleForRidersStatus: ActionStatus;
  markEventsAsHiddenForRidersStatus: ActionStatus;
  getUserGroupsStatus: ActionStatus;
  deleteEventAttachmentStatus: ActionStatus;
  deleteEventImageStatus: ActionStatus;
  duplicateEventStatus: ActionStatus;
}

function initialState(): EventManagementState {
  return {
    events: [],
    userGroups: null,
    configuration: null,

    getEventsStatus: ActionStatus.None,
    getConfigurationStatus: ActionStatus.None,
    createEventStatus: ActionStatus.None,
    updateEventStatus: ActionStatus.None,
    deleteEventStatus: ActionStatus.None,
    deleteEventRegistrationStatus: ActionStatus.None,
    markEventAsHiddenStatus: ActionStatus.None,
    markEventAsVisibleStatus: ActionStatus.None,
    markEventsAsVisibleForRidersStatus: ActionStatus.None,
    markEventsAsHiddenForRidersStatus: ActionStatus.None,
    getUserGroupsStatus: ActionStatus.None,
    deleteEventAttachmentStatus: ActionStatus.None,
    deleteEventImageStatus: ActionStatus.None,
    duplicateEventStatus: ActionStatus.None,
  };
}

export const useEventManagementStore = defineStore('eventManagement', {
  state: (): EventManagementState => initialState(),
  getters: {
    isGetEventsProcessing: (state: EventManagementState): boolean =>
      state.getEventsStatus === ActionStatus.InProgress,
    isGetConfigurationProcessing: (state: EventManagementState): boolean =>
      state.getConfigurationStatus === ActionStatus.InProgress,
    isCreateEventProcessing: (state: EventManagementState): boolean =>
      state.createEventStatus === ActionStatus.InProgress,
    isUpdateEventProcessing: (state: EventManagementState): boolean =>
      state.updateEventStatus === ActionStatus.InProgress,
    isDeleteEventProcessing: (state: EventManagementState): boolean =>
      state.deleteEventStatus === ActionStatus.InProgress,
    isDeleteEventRegistrationProcessing: (state: EventManagementState): boolean =>
      state.deleteEventRegistrationStatus === ActionStatus.InProgress,
    isMarkEventAsHiddenProcessing: (state: EventManagementState): boolean =>
      state.markEventAsHiddenStatus === ActionStatus.InProgress,
    isMarkEventAsVisibleProcessing: (state: EventManagementState): boolean =>
      state.markEventAsVisibleStatus === ActionStatus.InProgress,
    isMarkEventsAsVisibleForRidersProcessing: (state: EventManagementState): boolean =>
      state.markEventsAsVisibleForRidersStatus === ActionStatus.InProgress,
    isMarkEventsAsHiddenForRidersProcessing: (state: EventManagementState): boolean =>
      state.markEventsAsHiddenForRidersStatus === ActionStatus.InProgress,
    isGetUserGroupsProcessing: (state: EventManagementState): boolean =>
      state.getUserGroupsStatus === ActionStatus.InProgress,
    isDeleteEventAttachmentProcessing: (state: EventManagementState): boolean =>
      state.deleteEventAttachmentStatus === ActionStatus.InProgress,
    isDeleteEventImageProcessing: (state: EventManagementState): boolean =>
      state.deleteEventImageStatus === ActionStatus.InProgress,
    isDuplicateEventProcessing: (state: EventManagementState): boolean =>
      state.duplicateEventStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getEvents(): Promise<void> {
      const { getEventsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEventsStatus,
        () => getEvents(attachFarmAndUserProperties({}))
          .then(async (events) => {
            this.events = events;
          })
      );
    },

    getConfiguration(): Promise<void> {
      const { getConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getConfigurationStatus,
        () => getConfiguration(attachFarmAndUserProperties({}))
          .then((configuration) => {
            this.configuration = configuration;
          })
      );
    },

    getUserGroups(): Promise<void> {
      const { getUserGroupsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserGroupsStatus,
        () => getUserGroups(attachFarmAndUserProperties({}))
          .then((userGroups) => {
            this.userGroups = userGroups;
          })
      );
    },

    // -- Commands

    createEvent(command: CreateEventAsManagerCommand): Promise<void> {
      const { createEventStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createEventStatus,
        () => createEvent(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getEvents())
      );
    },

    updateEvent(command: UpdateEventAsManagerCommand): Promise<void> {
      const { updateEventStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEventStatus,
        () => updateEvent(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getEvents())
      );
    },

    deleteEvent(command: DeleteEventAsManagerCommand): Promise<void> {
      const { deleteEventStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteEventStatus,
        () => deleteEvent(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    deleteEventRegistration(command: DeleteEventRegistrationAsManagerCommand): Promise<void> {
      const { deleteEventRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteEventRegistrationStatus,
        () => deleteEventRegistration(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    markEventAsHidden(command: MarkEventAsHiddenAsManagerCommand): Promise<void> {
      const { markEventAsHiddenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markEventAsHiddenStatus,
        () => markEventAsHidden(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    markEventAsVisible(command: MarkEventAsVisibleAsManagerCommand): Promise<void> {
      const { markEventAsVisibleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markEventAsVisibleStatus,
        () => markEventAsVisible(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    deleteEventAttachment(command: DeleteEventAttachmentAsManagerCommand): Promise<void> {
      const { deleteEventAttachmentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteEventAttachmentStatus,
        () => deleteEventAttachment(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    deleteEventImage(command: DeleteEventImageAsManagerCommand): Promise<void> {
      const { deleteEventImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteEventImageStatus,
        () => deleteEventImage(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    duplicateEvent(command: DuplicateEventAsManagerCommand): Promise<void> {
      const { duplicateEventStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        duplicateEventStatus,
        () => duplicateEvent(attachFarmAndUserProperties(command))
          .then(() => this.getEvents())
      );
    },

    markEventsAsVisibleForRiders(): Promise<void> {
      const { markEventsAsVisibleForRidersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markEventsAsVisibleForRidersStatus,
        () => markEventsAsVisibleForRiders(attachFarmAndUserProperties({}))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    markEventsAsHiddenForRiders(): Promise<void> {
      const { markEventsAsHiddenForRidersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markEventsAsHiddenForRidersStatus,
        () => markEventsAsHiddenForRiders(attachFarmAndUserProperties({}))
          .then(() => this.getConfiguration())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

  },
});
