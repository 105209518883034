import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreatePenStatusEntryAsManagerCommand, DeletePenStatusEntryAsManagerCommand, EnablePenExemptionsAsManagerCommand, Configuration, PenStatusEntry, UpdatePenStatusVisibilityAsManagerCommand, UpdatePenStatusEntryAsManagerCommand, PenExemption } from './types';

// -- Queries

export function getPenStatusEntries(query: FarmAndUserProperties): Promise<PenStatusEntry[]> {
  const url = `${apiUrl}/api/pen/get-pen-status-entries-as-manager-query`;
  return performApiRequest<PenStatusEntry[]>({ url, method: 'POST', data: query });
}

export function getConfiguration(query: FarmAndUserProperties): Promise<Configuration> {
  const url = `${apiUrl}/api/pen/get-configuration-as-manager-query`;
  return performApiRequest<Configuration>({ url, method: 'POST', data: query });
}

export function getPenExemptions(query: FarmAndUserProperties): Promise<PenExemption[]> {
  const url = `${apiUrl}/api/pen/get-pen-exemptions-as-manager-query`;
  return performApiRequest<PenExemption[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createPenStatusEntry(command: CreatePenStatusEntryAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/pen/create-pen-status-entry-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePenStatusEntry(command: UpdatePenStatusEntryAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/pen/update-pen-status-entry-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deletePenStatusEntry(command: DeletePenStatusEntryAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/pen/delete-pen-status-entry-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updatePenStatusVisibility(command: UpdatePenStatusVisibilityAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/pen/update-pen-status-visibility-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function enablePenExemptions(command: EnablePenExemptionsAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/pen/enable-pen-exemptions-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function disablePenExemptions(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/pen/disable-pen-exemptions-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
