import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAppStore } from '@/application/app/store';

async function validateAppVersionOnResponse(response: AxiosResponse): Promise<AxiosResponse> {
  const appStore = useAppStore();

  // Our usual requests are not cached as they are dynamic, cached assets might contain an old app version and are therefore ignored.
  if (response.headers['cache-control']
    && response.headers['cache-control'].includes('max-age')
  ) {
    return Promise.resolve(response);
  }

  if (!response.headers['x-app-version']) {
    return Promise.resolve(response);
  }

  return appStore.validateAppVersion(response.headers['x-app-version'])
    .then(() => response);
}

async function validateAppVersionOnError(error: AxiosError): Promise<AxiosError> {
  const isMaintenanceMode = error?.response?.status === 503;

  if (isMaintenanceMode) {
    return Promise.reject(error);
  }

  if (error?.response
    // In edge cases when there is an error, it might happen that the version is not included in the response.
    && error.response.headers['x-app-version']
  ) {
    return useAppStore().validateAppVersion(error.response.headers['x-app-version'])
      .then(() => Promise.reject(error));
  }

  return Promise.reject(error);
}

export default function initAppVersionInterceptor() {
  axios.interceptors.response.use(
    validateAppVersionOnResponse,
    validateAppVersionOnError
  );
}
