
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { Moment, Time } from '@/types';
import { moment } from '@/helpers';
import { useBookingCalendarStore } from '../store';
import { FacilityReservationConfiguration } from '../types';

@Component
export default class ReservationNotPossibleYetDialog extends Vue {

  readonly store = useBookingCalendarStore();

  readonly size = DialogWidth.small;

  @Prop({ type: Object, required: true })
  readonly facilityReservationConfiguration!: FacilityReservationConfiguration;

  isDialogVisible = false;

  get lastPossibleBookingDate(): Moment {
    let lastPossibleDay = moment().add(this.facilityReservationConfiguration.bookingInAdvance, 'days');
    const currentTime = Time.fromMoment(moment());

    if (currentTime.isSameOrAfter(this.facilityReservationConfiguration.timeToGrantAccessForNewDayInAdvance)) {
      lastPossibleDay = lastPossibleDay.add(1, 'day');
    }

    return lastPossibleDay;
  }

  get timeToGrantAccessForNewDayInAdvance(): Time {
    return this.facilityReservationConfiguration.timeToGrantAccessForNewDayInAdvance;
  }

  showDialog() {
    this.isDialogVisible = true;
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
