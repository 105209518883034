import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachUserProperty } from '@/helpers/default-parameter-helper';
import { TokenStorage } from '@/application/authentication/token-storage';
import { FarmId, SortDirection } from '@/types';
import { getFarms, getGlobalUsers, getUserForImpersonationAsAdmin, updateEnabledPermissionsOfUser, updateUserPassword, updateUserRole } from './service';
import { Farm, GetGlobalUsersAsAdminQuery, GetUserForImpersonationAsAdminQuery, SortBy, UpdateEnabledPermissionsOfUserAsAdminWorkflow, UpdateUserPasswordAsAdminCommand, UpdateUserRoleAsAdminWorkflow, User } from './types';

interface GlobalUserManagementState {
  currentFilteredByFarm: FarmId | null;
  currentSearchTerm: string | null;
  currentSortBy: SortBy;
  currentSortDirection: SortDirection
  currentLimit: number;
  totalCount: number | null;

  users: User[];

  farms: Farm[];

  getGlobalUsersStatus: ActionStatus;
  getUserForImpersonationAsAdminStatus: ActionStatus;
  updateUserPasswordStatus: ActionStatus;
  updateUserRoleStatus: ActionStatus;
  updateEnabledPermissionsOfUserStatus: ActionStatus;
  getFarmsStatus: ActionStatus;
}

function initialState(): GlobalUserManagementState {
  return {
    currentSearchTerm: null,
    currentFilteredByFarm: null,
    currentSortBy: SortBy.ROLE,
    currentSortDirection: SortDirection.ASC,
    currentLimit: 50,
    totalCount: null,

    users: [],

    farms: [],

    getGlobalUsersStatus: ActionStatus.None,
    getUserForImpersonationAsAdminStatus: ActionStatus.None,
    updateUserPasswordStatus: ActionStatus.None,
    updateUserRoleStatus: ActionStatus.None,
    updateEnabledPermissionsOfUserStatus: ActionStatus.None,
    getFarmsStatus: ActionStatus.None,
  };
}

export const useGlobalUserManagementStore = defineStore('globalUserManagement', {
  state: (): GlobalUserManagementState => initialState(),
  getters: {
    isGetGlobalUsersProcessing: (state: GlobalUserManagementState): boolean =>
      state.getGlobalUsersStatus === ActionStatus.InProgress,
    isGetUserForImpersonationAsAdminProcessing: (state: GlobalUserManagementState): boolean =>
      state.getUserForImpersonationAsAdminStatus === ActionStatus.InProgress,
    isUpdateUserPasswordProcessing: (state: GlobalUserManagementState): boolean =>
      state.updateUserPasswordStatus === ActionStatus.InProgress,
    isUpdateUserRoleProcessing: (state: GlobalUserManagementState): boolean =>
      state.updateUserRoleStatus === ActionStatus.InProgress,
    isUpdateEnabledPermissionsOfUserProcessing: (state: GlobalUserManagementState): boolean =>
      state.updateEnabledPermissionsOfUserStatus === ActionStatus.InProgress,
    isGetFarmsProcessing: (state: GlobalUserManagementState): boolean =>
      state.getFarmsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    increaseLimit(): Promise<void> {
      this.currentLimit += 50;

      return this.getGlobalUsers();
    },

    updateSearchTerm(searchTerm: string | null): Promise<void> {
      this.currentSearchTerm = searchTerm;
      this.currentLimit = 50;

      return this.getGlobalUsers();
    },

    updateFarmFilter(filteredByFarm: FarmId | null): Promise<void> {
      this.currentFilteredByFarm = filteredByFarm;
      this.currentLimit = 50;

      return this.getGlobalUsers();
    },

    updateSorting(sortBy: SortBy, sortDirection: SortDirection): Promise<void> {
      this.currentSortBy = sortBy;
      this.currentSortDirection = sortDirection;

      return this.getGlobalUsers();
    },

    resetFilters(): Promise<void> {
      this.currentSearchTerm = null;
      this.currentFilteredByFarm = null;
      this.currentSortBy = SortBy.NAME;
      this.currentSortDirection = SortDirection.ASC;
      this.currentLimit = 50;
      this.totalCount = null;

      return Promise.resolve();
    },

    // -- Queries

    getGlobalUsers(): Promise<void> {
      const query: GetGlobalUsersAsAdminQuery = {
        filteredByFarm: this.currentFilteredByFarm,
        searchTerm: this.currentSearchTerm,
        limit: this.currentLimit,
        sortBy: this.currentSortBy,
        sortDirection: this.currentSortDirection,
      };
      const { getGlobalUsersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGlobalUsersStatus,
        () => getGlobalUsers(attachUserProperty(query))
          .then(async (response) => {
            this.totalCount = response.totalCount;
            this.users = response.users;
          })
      );
    },

    getFarms(): Promise<void> {
      const { getFarmsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmsStatus,
        () => getFarms(attachUserProperty({}))
          .then(async (farms) => {
            this.farms = farms;
          })
      );
    },

    getUserForImpersonationAsAdmin(query: GetUserForImpersonationAsAdminQuery): Promise<void> {
      const { getUserForImpersonationAsAdminStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserForImpersonationAsAdminStatus,
        () => getUserForImpersonationAsAdmin(attachUserProperty(query))
          .then(() => TokenStorage.setImpersonateUserFlag())
      );
    },

    // -- Commands

    updateUserPassword(command: UpdateUserPasswordAsAdminCommand): Promise<void> {
      const { updateUserPasswordStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserPasswordStatus,
        () => updateUserPassword(attachUserProperty(command))
      );
    },

    updateUserRole(command: UpdateUserRoleAsAdminWorkflow): Promise<void> {
      const { updateUserRoleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserRoleStatus,
        () => updateUserRole(attachUserProperty(command))
          .then(() => this.getGlobalUsers())
      );
    },

    updateEnabledPermissionsOfUser(command: UpdateEnabledPermissionsOfUserAsAdminWorkflow): Promise<void> {
      const { updateEnabledPermissionsOfUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEnabledPermissionsOfUserStatus,
        () => updateEnabledPermissionsOfUser(attachUserProperty(command))
          .then(() => this.getGlobalUsers())
      );
    },

  },
});
