
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import Cropper from 'cropperjs';
import { DropzoneFile } from 'dropzone';
import { Camera, CameraResultType } from '@capacitor/camera';
import { uuid } from '@/helpers';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import DropzoneUpload from '@/components/dropzone-upload.vue';
import { isNativeApplication } from '@/helpers/detection-helpers';

@Component({
  components: { DropzoneUpload },
  methods: { isNativeApplication, labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class ImageCropperFormControl extends Vue implements FormControlComponent<Blob> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Blob>;

  @Prop({ type: Number, default: 10 })
  readonly maxFileSize!: number;

  @Prop({ type: Number, default: 1 })
  readonly imageAspectRatio!: number;

  @Prop({ type: Boolean, default: true })
  readonly fixedAspectRatio!: boolean;

  @Prop({ type: Number, default: 1000 })
  readonly imageWidth!: number;

  readonly formControlId = createFormControlId();

  dropzoneFile: File | null = null;

  cropper: Cropper | null = null;
  imageSrc: string | ArrayBuffer = '';

  imageId = `image-${uuid()}`;

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: Blob | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  destroyed(): void {
    this.reset();
  }

  reset(): void {
    this.dropzoneFile = null;
    this.imageSrc = '';
    if (this.cropper) {
      this.cropper.destroy();
    }
  }

  dropzoneFileUploaded(file: DropzoneFile) {
    this.dropzoneFile = file;
    this.importImageToCropper(file);
    this.blurred();
  }

  onTakePictureClicked(): void {
    this.focused();
    this.takePicture();
  }

  takePicture(): void {
    Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      quality: 80,
      width: 1200,
      height: 1200,
      allowEditing: false,
      saveToGallery: false,
      correctOrientation: true,
      promptLabelHeader: 'Bild auswählen',
      promptLabelCancel: 'Abbrechen',
      promptLabelPhoto: 'Aus bestehenden Bildern',
      promptLabelPicture: 'Bild aufnehmen',
    })
      .then((image) => {
        if (image.dataUrl) {
          this.urlToFile(image.dataUrl, 'image.jpg', 'image/jpeg')
            .then((file) => {
              this.importImageToCropper(file);
            });
        }
      })
      .catch(() => {
        // The function triggers an error when the user cancels the action which we will just ignore.
        this.blurred();
      });
  }

  importImageToCropper(file: Blob) {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageSrc = e.target!.result!;
      setTimeout(() => {
        this.initCropper();
      }, 200);
    };
    reader.readAsDataURL(file);
  }

  initCropper() {
    // Reset cropper for new image
    if (this.cropper) {
      this.cropper.destroy();
    }

    const image = document.getElementById(this.imageId) as HTMLCanvasElement;
    this.cropper = new Cropper(image, {
      aspectRatio: this.fixedAspectRatio
        ? this.imageAspectRatio
        : undefined,
      zoomable: false,
      scalable: true,
      rotatable: true,
      viewMode: 2,
      autoCropArea: 1,
      ready: this.updateFile,
      cropend: this.updateFile,
    });
  }

  updateFile(): void {
    this.cropper!.getCroppedCanvas({
      width: this.imageWidth,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      fillColor: '#ffffff',
    }).toBlob((blob) => {
      this.internalValue = blob;
      this.blurred();
      internalValuesChanged(this);
    }, 'image/jpeg', 1);
  }

  async urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    // No initialization with existing image
  }

  formValueFromInternalValues(): FormControlValue<Blob> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
