import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { getFarmAnalytics } from './service';
import { AnalyticsData } from './types';

interface AnalyticsState {
  analytics: AnalyticsData | null;

  getFarmAnalyticsStatus: ActionStatus;
}

function initialState(): AnalyticsState {
  return {
    analytics: null,
    getFarmAnalyticsStatus: ActionStatus.None,
  };
}

export const useAnalyticsStore = defineStore('analytics', {
  state: (): AnalyticsState => initialState(),
  getters: {
    isGetFarmAnalyticsProcessing: (state: AnalyticsState): boolean =>
      state.getFarmAnalyticsStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getFarmAnalytics(): Promise<void> {
      const { getFarmAnalyticsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmAnalyticsStatus,
        () => getFarmAnalytics(attachFarmAndUserProperties({}))
          .then(async (analytics) => {
            this.analytics = analytics;
          })
      );
    },

  },
});
