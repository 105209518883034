
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useAuthenticationStore } from '@/application/authentication/store';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { PenExemptionId, Date } from '@/types';
import { moveHorseToTranslations } from '@/helpers/translations';
import { useMyStableStore } from '../store';
import { PenExemption, PenExemptions, DeletePenExemptionCommand } from '../types';
import CreatePenExemptionDialog from './create-pen-exemption-dialog.vue';

@Component({
  components: {
    CreatePenExemptionDialog,
  },
})
export default class PenExemptionsView extends Vue {

  readonly store = useMyStableStore();
  readonly authenticationStore = useAuthenticationStore();

  @Prop({ type: Object, required: true })
  readonly penExemptions!: PenExemptions;

  readonly menuList: Record<PenExemptionId, boolean> = {};

  readonly moveHorseToTranslations = moveHorseToTranslations;

  isActiveMenuVisible = false;

  get isCreatePenExemptionVisible(): boolean {
    return this.store.isCurrentHorseOwn;
  }

  isUpdateMenuVisible(penExemption: PenExemption): boolean {
    return this.store.isCurrentHorseOwn
      && Date.today().isBefore(penExemption.endingAt);
  }

  async deletePenExemptionAction(penExemption: PenExemption): Promise<void> {
    const command: DeletePenExemptionCommand = {
      penExemptionId: penExemption.penExemptionId,
    };

    return this.store.deletePenExemption(command)
      .then(() => showSuccessMessage('Die Koppelausnahme wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
