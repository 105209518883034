import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import HorseTable from './components/horse-table.vue';

export const horseManagementRoutes: RouteConfig[] = [
  {
    name: 'horse-management-horse-table',
    path: 'pferde',
    component: HorseTable,
    meta: {
      title: 'Pferde',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
      requiresPermission: FarmManagerPermission.HORSES_READ,
    },
  },
];
