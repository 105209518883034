import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@/helpers/local-storage-helper';

export class TokenStorage {

  private static readonly LOCAL_STORAGE_USER_IMPERSONATED = 'user_impersonated';

  public static setImpersonateUserFlag(): void {
    setLocalStorageItem(TokenStorage.LOCAL_STORAGE_USER_IMPERSONATED, '1');
  }

  public static clear(): void {
    removeLocalStorageItem(TokenStorage.LOCAL_STORAGE_USER_IMPERSONATED);
  }

  public static isImpersonatedUser(): boolean {
    return getLocalStorageItem(TokenStorage.LOCAL_STORAGE_USER_IMPERSONATED) === '1';
  }

}
