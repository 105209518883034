
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule, notIdenticalToAnyOfStringsRule } from '@/components/form';
import { Option } from '@/types';

interface Controls extends FormControls {
  label: FormControl<string>;
}

@Component
export default class UpdateOptionDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.smallNested;

  @Prop({ type: Object, required: true })
  readonly option!: Option;

  @Prop({ type: Array, required: true })
  readonly options!: Option[];

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        label: {
          label: 'Bezeichnung',
          value: this.option.label,
          isRequired: true,
          rules: [
            maxLengthRule(255),
            notIdenticalToAnyOfStringsRule(
              this.options
                .filter((option) => option.optionId !== this.option.optionId)
                .map((option) => option.label),
              'Eine Option mit dieser Bezeichnung existiert bereits'
            ),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const option: Option = {
      optionId: this.option.optionId,
      label: formValues.label!,
    };

    this.$emit('submitted', option);

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
    this.$emit('closed');
  }

}
