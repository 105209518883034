
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

enum Order {
  ASC = 'ASC',
  DESC = 'DESC',
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class NumberSelectionFormControl extends Vue implements FormControlComponent<number> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<number>;

  @Prop({ type: Number, required: true })
  readonly minNumber!: number;

  @Prop({ type: Number, required: true })
  readonly maxNumber!: number;

  @Prop({ type: String, default: Order.ASC })
  readonly order!: Order;

  @Prop({ type: Boolean, default: false })
  readonly isAllLabelUsedForMaxNumber!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: number | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get items(): VuetifySelectItem<number>[] {
    const items = [];
    if (this.order === Order.ASC) {
      for (let i = this.minNumber; i <= this.maxNumber; i++) {
        items.push(i);
      }
    } else {
      for (let i = this.maxNumber; i >= this.minNumber; i--) {
        items.push(i);
      }
    }

    return items.map((value) => ({
      text: this.isAllLabelUsedForMaxNumber
        && value === this.maxNumber
        ? `Alle (${value})`
        : value.toString(),
      value,
    }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<number> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
