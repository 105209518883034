import isEqual from 'lodash-es/isEqual';
import { Color, FarmManagerPermissions, FeedUnit, Persona } from '@/types';

export enum RouteAccessibility {
  ALWAYS = 'ALWAYS',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
}

export enum DialogWidth {
  small = 500,
  smallNested = 460,
  medium = 650,
  large = 800,
  largeNested = 760,
}

export const dialogWidthTranslation: Record<string, DialogWidth> = {
  small: DialogWidth.small,
  'small-nested': DialogWidth.smallNested,
  medium: DialogWidth.medium,
  large: DialogWidth.large,
};

export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const MONTH_FORMAT = 'MMMM';

export const UserSelectablePersonas: Partial<Record<Persona, Persona>> = {
  [Persona.WITH_HORSE]: Persona.WITH_HORSE,
  [Persona.RIDING_PARTICIPATION]: Persona.RIDING_PARTICIPATION,
};

export type FeedUnitNumerus = `${FeedUnit}-${'singular'|'plural'}`;
export const feedUnitNumerusTranslations: Record<FeedUnitNumerus, string> = {
  'GRAM-singular': 'g',
  'GRAM-plural': 'g',
  'KILOGRAM-singular': 'kg',
  'KILOGRAM-plural': 'kg',
  'SHOVEL-singular': 'Schippe',
  'SHOVEL-plural': 'Schippen',
  'BUCKET-singular': 'Eimer',
  'BUCKET-plural': 'Eimer',
  'PITCHFORK-singular': 'Gabel',
  'PITCHFORK-plural': 'Gabeln',
  'LITER-singular': 'Liter',
  'LITER-plural': 'Liter',
  'WHEELBARROW-singular': 'Schubkarre',
  'WHEELBARROW-plural': 'Schubkarren',
  'MILLILITER-singular': 'Milliliter',
  'MILLILITER-plural': 'Milliliter',
  'PIECE-singular': 'Stück',
  'PIECE-plural': 'Stück',
  'MEASURING_SPOON-singular': 'Messlöffel',
  'MEASURING_SPOON-plural': 'Messlöffel',
  'MEASURING_CUP-singular': 'Messbecher',
  'MEASURING_CUP-plural': 'Messbecher',
  'CAN-singular': 'Dose',
  'CAN-plural': 'Dosen',
  'BOWL-singular': 'Schüssel',
  'BOWL-plural': 'Schüsseln',
};

type DigitString = 1|2|3|4|5|6|7|8|9|0;

type oneToNine = 1|2|3|4|5|6|7|8|9;

type YearString = `19${DigitString}${DigitString}` | `20${DigitString}${DigitString}`;
type MonthString = `0${oneToNine}` | `1${0|1|2}`;
type DayString = `${0}${oneToNine}` | `${1|2}${DigitString}` | `3${0|1}`;

export type Day = `${YearString}-${MonthString}-${DayString}`;

export function arePermissionsEqual(permissionsA: FarmManagerPermissions | null, permissionsB: FarmManagerPermissions | null): boolean {
  if (permissionsA === null && permissionsB === null) {
    return true;
  }

  // Both can't be null, so this is a xor
  if (permissionsA === null || permissionsB === null) {
    return false;
  }

  if (permissionsA.areAllPermissionsEnabled && permissionsB.areAllPermissionsEnabled) {
    return true;
  }

  return isEqual(permissionsA.permissions, permissionsB.permissions);
}
export function arePermissionsNotEqual(permissionsA: FarmManagerPermissions | null, permissionsB: FarmManagerPermissions | null): boolean {
  return !arePermissionsEqual(permissionsA, permissionsB);
}

export const colors: Record<Color, string> = {
  'green-5': '#62942D',
  'yellow-5': '#F9B116',
  'red-5': '#AA0613',
  'green-8': '#E0F2CB',
  'yellow-8': '#FDEBC3',
  'red-8': '#FB8990',
  'grey-8': '#EBECE9',
  'green-3': '#30540D',
  'yellow-3': '#9F6E04',
  'red-3': '#77040C',
};

export const counterColors: Record<Color, string> = {
  'green-5': '#EBECE9',
  'yellow-5': '#EBECE9',
  'red-5': '#EBECE9',
  'green-8': '#3C3D3A',
  'yellow-8': '#3C3D3A',
  'red-8': '#3C3D3A',
  'grey-8': '#3C3D3A',
  'green-3': '#EBECE9',
  'yellow-3': '#EBECE9',
  'red-3': '#EBECE9',
};

export interface BlurHashImage {
  url: string;
  hash: string;
  aspectRatio: number;
}
