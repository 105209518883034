
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { constructForm, Form, FormControl, FormControls, FormControlValue, getFormValues, requiredWhenCheckboxIsCheckedRule } from '@/components/form';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { moment } from '@/helpers';
import { DialogWidth } from '@/helpers/data';
import { Date, HayType } from '@/types';
import { GerblhofFeedingPricing, GerblhofFeedingUpdate, UpdateFeedingCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  startingAt: FormControl<Date>;
  feedingTypeMorning: FormControl<HayType>;
  amountMorning: FormControl<number>;
  specialFeedingEnabled: FormControl<boolean>;
  feedingTypeNoon: FormControl<HayType>;
  amountNoon: FormControl<number>;
  feedingTypeEvening: FormControl<HayType>;
  amountEvening: FormControl<number>;
}

@Component
export default class UpdateGerblhofFeedingDialog extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Array, required: true })
  readonly feedingUpdates!: GerblhofFeedingUpdate[];

  @Prop({ type: Object, required: true })
  readonly feedingPricing!: GerblhofFeedingPricing;

  @Prop({ type: Object, default: null })
  readonly activeFeeding!: GerblhofFeedingUpdate | null;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get maxAmountMorning(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    return formValues.feedingTypeMorning === HayType.HAY
      ? this.feedingPricing.maxHayAmountPerFeeding / 1_000
      : this.feedingPricing.maxHalfHayAmountPerFeeding / 1_000;
  }

  get maxAmountNoon(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    return formValues.feedingTypeMorning === HayType.HAY
      ? this.feedingPricing.maxHayAmountPerFeeding / 1_000
      : this.feedingPricing.maxHalfHayAmountPerFeeding / 1_000;
  }

  get maxAmountEvening(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    return formValues.feedingTypeMorning === HayType.HAY
      ? this.feedingPricing.maxHayAmountPerFeeding / 1_000
      : this.feedingPricing.maxHalfHayAmountPerFeeding / 1_000;
  }

  get isFeedingNoonVisible(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return formValues.specialFeedingEnabled!;
  }

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get startingAtOptions(): { label: string, date: Date }[] {
    const startingAtOptions: { label: string, date: Date }[] = [];
    const currentDayOfMonth = moment().date();
    let nextOption: Date;

    if (currentDayOfMonth >= 15) {
      nextOption = Date.fromMoment(
        moment()
          .startOf('day')
          .startOf('month')
          .add(1, 'month')
      );
    } else {
      nextOption = Date.fromMoment(
        moment()
          .startOf('day')
          .date(15)
      );
    }

    /* eslint-disable no-loop-func */
    for (let i = 0; i < 24; i++) {
      const isDateBlocked = this.feedingUpdates
        .some((feedingUpdate) => feedingUpdate.startingAt.isEqualTo(nextOption));

      if (!isDateBlocked) {
        startingAtOptions.push({
          label: nextOption.format('DD.MM.YYYY'),
          date: nextOption,
        });
      }

      if (nextOption.format('DD') === '15') {
        nextOption = Date.fromMoment(
          moment(nextOption.date)
            .startOf('month')
            .add(1, 'month'),
        );
      } else {
        nextOption = Date.fromMoment(
          moment(nextOption.date)
            .date(15),
        );
      }
    }

    return startingAtOptions;
  }

  get includedHayAmount(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    let includedHayAmount = this.feedingPricing.includedHayAmount;

    if (formValues.feedingTypeMorning === HayType.HAY
      && formValues.feedingTypeEvening === HayType.HAY
    ) {
      includedHayAmount += this.feedingPricing.includedHayAmount;
    }

    return includedHayAmount;
  }

  get additionalHay(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    let totalHayAmount = 0;
    let additionalHay = 0;

    if (formValues.feedingTypeMorning === HayType.HAY) {
      totalHayAmount += formValues.amountMorning! * 1_000;
    }

    if (formValues.specialFeedingEnabled
      && formValues.feedingTypeNoon === HayType.HAY
    ) {
      totalHayAmount += formValues.amountNoon! * 1_000;
    }

    if (formValues.feedingTypeEvening === HayType.HAY) {
      totalHayAmount += formValues.amountEvening! * 1_000;
    }

    if ((totalHayAmount - this.includedHayAmount) > 0) {
      additionalHay = totalHayAmount - this.includedHayAmount;
    }

    return additionalHay;
  }

  get additionalHalfHay(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    let includedHalfHayAmount = this.feedingPricing.includedHalfHayAmount;

    if (formValues.feedingTypeMorning === HayType.HALF_HAY
      && formValues.feedingTypeEvening === HayType.HALF_HAY
    ) {
      includedHalfHayAmount += this.feedingPricing.includedHalfHayAmount;
    }

    let totalHalfHayAmount = 0;
    let additionalHalfHay = 0;

    if (formValues.feedingTypeMorning === HayType.HALF_HAY) {
      totalHalfHayAmount += formValues.amountMorning! * 1_000;
    }

    if (formValues.specialFeedingEnabled
      && formValues.feedingTypeNoon === HayType.HALF_HAY
    ) {
      totalHalfHayAmount += formValues.amountNoon! * 1_000;
    }

    if (formValues.feedingTypeEvening === HayType.HALF_HAY) {
      totalHalfHayAmount += formValues.amountEvening! * 1_000;
    }

    if ((totalHalfHayAmount - includedHalfHayAmount) > 0) {
      additionalHalfHay = totalHalfHayAmount - includedHalfHayAmount;
    }

    return additionalHalfHay;
  }

  get additionalHayCosts(): number {
    return ((this.additionalHay / 1_000) * this.feedingPricing.hayUnitPrice);
  }

  get additionalHalfHayCosts(): number {
    return ((this.additionalHalfHay / 1_000) * this.feedingPricing.halfHayUnitPrice);
  }

  get feedingSwitchMorningCosts(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    return ((this.feedingPricing.defaultAmountHayMorning === 0 && formValues.feedingTypeMorning === HayType.HAY)
      || (this.feedingPricing.defaultAmountHalfHayMorning === 0 && formValues.feedingTypeMorning === HayType.HALF_HAY))
      ? this.feedingPricing.feedingSwitchMorningPrice
      : 0;
  }

  get feedingSwitchEveningCosts(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    return ((this.feedingPricing.defaultAmountHayEvening === 0 && formValues.feedingTypeEvening === HayType.HAY)
      || (this.feedingPricing.defaultAmountHalfHayEvening === 0 && formValues.feedingTypeEvening === HayType.HALF_HAY))
      ? this.feedingPricing.feedingSwitchEveningPrice
      : 0;
  }

  get specialFeedingCosts(): number {
    if (!this.form) {
      return 0;
    }

    const formValues = getFormValues(this.form);

    return formValues.specialFeedingEnabled
      && this.feedingPricing.specialFeedingPrice
      ? this.feedingPricing.specialFeedingPrice
      : 0;
  }

  get additionalCosts(): number {
    return this.additionalHayCosts
      + this.additionalHalfHayCosts
      + this.specialFeedingCosts
      + this.feedingSwitchMorningCosts
      + this.feedingSwitchEveningCosts;
  }


  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        startingAt: {
          label: 'Beginn der Anpassung',
          value: this.startingAtOptions[0].date,
          isRequired: true,
        },
        feedingTypeMorning: {
          label: 'Futtertyp Morgens',
          value: !!this.activeFeeding
              ? this.activeFeeding.hayAmountMorning > 0
                ? HayType.HAY
                : HayType.HALF_HAY
              : HayType.HAY,
          isRequired: true,
          rules: [],
        },
        amountMorning: {
          label: 'Menge Morgens',
          value: !!this.activeFeeding
            ? this.activeFeeding.hayAmountMorning > 0
              ? this.activeFeeding.hayAmountMorning / 1_000
              : this.activeFeeding.halfHayAmountMorning / 1_000
            : 0,
          isRequired: true,
          rules: [],
        },
        specialFeedingEnabled: {
          label: 'Sonderfütterung',
          value: this.activeFeeding?.specialFeedingEnabled ?? false,
          rules: [],
          validateFormControlsOnInput: [
            'feedingTypeNoon',
            'amountNoon',
          ],
          forceMessagesOfFormControlsVisibleOnInput: [
            'feedingTypeNoon',
            'amountNoon',
          ],
        },
        feedingTypeNoon: {
          label: 'Futtertyp Mittags',
          value: !!this.activeFeeding
            && this.activeFeeding.hayAmountNoon !== null
            ? this.activeFeeding.hayAmountNoon > 0
              ? HayType.HAY
              : HayType.HALF_HAY
            : HayType.HAY,
          rules: [],
        },
        amountNoon: {
          label: 'Menge Mittags',
          value: !!this.activeFeeding
          && this.activeFeeding.hayAmountNoon !== null
          && this.activeFeeding.halfHayAmountNoon !== null
            ? this.activeFeeding.hayAmountNoon > 0
              ? this.activeFeeding.hayAmountNoon / 1_000
              : this.activeFeeding.halfHayAmountNoon / 1_000
            : 0,
          rules: [],
        },
        feedingTypeEvening: {
          label: 'Futtertyp Abends',
          value: !!this.activeFeeding
            ? this.activeFeeding.hayAmountEvening > 0
              ? HayType.HAY
              : HayType.HALF_HAY
            : HayType.HAY,
          isRequired: true,
          rules: [],
        },
        amountEvening: {
          label: 'Menge Abends',
          value: !!this.activeFeeding
            ? this.activeFeeding.hayAmountEvening > 0
              ? this.activeFeeding.hayAmountEvening / 1_000
              : this.activeFeeding.halfHayAmountEvening / 1_000
            : 0,
          isRequired: true,
          rules: [],
        },
      },
    });

    form.controls.feedingTypeNoon.rules!.push(requiredWhenCheckboxIsCheckedRule(form.controls.specialFeedingEnabled));
    form.controls.amountNoon.rules!.push(requiredWhenCheckboxIsCheckedRule(form.controls.specialFeedingEnabled));
    form.controls.amountNoon.rules!.push((value: FormControlValue<number>) => value === null
      || form.controls.specialFeedingEnabled.value !== true
      || value > 0
      || 'Die Fütterungsmenge der Sonderfütterung darf nicht 0 kg betragen'
    );

    return form;
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateFeedingCommand = {
      horseId: this.store.currentHorseId!,
      specialFeedingEnabled: formValues.specialFeedingEnabled!,
      startingAt: formValues.startingAt!,
      feedingTypeMorning: formValues.feedingTypeMorning!,
      amountMorning: formValues.amountMorning! * 1_000,
      feedingTypeEvening: formValues.feedingTypeEvening!,
      amountEvening: formValues.amountEvening! * 1_000,
      feedingTypeNoon: formValues.specialFeedingEnabled
        ? formValues.feedingTypeNoon
        : null,
      amountNoon: formValues.specialFeedingEnabled
        ? formValues.amountNoon! * 1_000
        : null,
    };

    this.store.updateFeeding(command)
      .then(() => showSuccessMessage('Fütterung angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
