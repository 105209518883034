
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CalendarRangeType } from '../types';
import UpdatePreferredCalendarViewDialog from './update-preferred-calendar-view-dialog.vue';

@Component({
  components: {
    UpdatePreferredCalendarViewDialog,
  },
})
export default class CalendarNavigation extends Vue {

  @Prop({ type: String, required: true })
  readonly calendarRangeType!: CalendarRangeType;

  get calendarRangeTypeToggleText(): string {
    return this.calendarRangeType === CalendarRangeType.day
      ? 'Wochenansicht'
      : 'Tagesansicht';
  }

  previousButtonClicked(): void {
    this.$emit('previous-clicked');
  }

  todayButtonClicked(): void {
    this.$emit('today-clicked');
  }

  nextButtonClicked(): void {
    this.$emit('next-clicked');
  }

  calendarRangeTypeToggleClicked(): void {
    this.$emit('calendar-range-type-toggle-clicked');
  }

  preferredCalendarViewWasUpdated(): void {
    this.$emit('preferred-calendar-view-was-updated');
  }

}
