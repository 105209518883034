
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useMyStableStore } from '../store';

@Component
export default class MedicalRecordRoot extends Vue {

  readonly store = useMyStableStore();
  readonly appStore = useAppStore();

  mounted(): void {
    this.store.getWeightForHorse()
      .catch((error) => showErrorResponse(error));

    this.store.getDrugsForHorse()
      .catch((error) => showErrorResponse(error));

    this.store.getFarrierAppointmentsForHorse()
      .catch((error) => showErrorResponse(error));

    this.store.getVeterinarianAppointmentsForHorse()
      .catch((error) => showErrorResponse(error));

    this.store.getWormingTreatmentsForHorse()
      .catch((error) => showErrorResponse(error));

    this.store.getVaccinationCertificateForHorse()
      .catch((error) => showErrorResponse(error));

    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Gesundheitsakte`)
      .catch((error) => showErrorResponse(error));
  }

}
