import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole } from '@/types';
import ManageAdminNotifications from './components/manage-admin-notifications.vue';

export const adminNotificationManagementRoutes: RouteConfig[] = [
  {
    name: 'manage-admin-notifications-manage-admin-notifications',
    path: 'admin-benachrichtigungen',
    component: ManageAdminNotifications,
    meta: {
      title: 'Admin Benachrichtigungen',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_ADMIN,
    },
  },
];
