import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole } from '@/types';
import GlobalUserManagement from './components/global-user-management.vue';

export const globalUserManagementRoutes: RouteConfig[] = [
  {
    name: 'user-management-global-user-management',
    path: 'alle-benutzer/:farmId?',
    component: GlobalUserManagement,
    meta: {
      title: 'Alle Benutzer',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_ADMIN,
    },
  },
];
