
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import CreateGerblhofConcentratedFeedDialog from './create-gerblhof-concentrated-feed-dialog.vue';
import UpdateGerblhofConcentratedFeedDialog from './update-gerblhof-concentrated-feed-dialog.vue';
import { GerblhofConcentratedFeeding, GerblhofConcentratedFeedType } from '../types';
import { formatKilogram } from '@/helpers';

@Component({
  components: {
    CreateGerblhofConcentratedFeedDialog,
    UpdateGerblhofConcentratedFeedDialog,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class GerblhofConcentratedFeedingsFormControl extends Vue implements FormControlComponent<GerblhofConcentratedFeeding[]> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<GerblhofConcentratedFeeding[]>;

  @Prop({ type: Array, required: true })
  readonly concentratedFeedTypes!: GerblhofConcentratedFeedType[];

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: GerblhofConcentratedFeeding[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  get notConfiguredConcentratedFeedTypes(): any[] {
    return this.concentratedFeedTypes.filter((type) => {
      return !this.internalValue.some((feeding) => feeding.type === type.concentratedFeedId);
    });
  }

  mounted(): void {
    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  createItem(item: GerblhofConcentratedFeeding): void {
    const items = this.internalValue;
    items.push(item);

    this.internalValue = items.sort((a: GerblhofConcentratedFeeding, b: GerblhofConcentratedFeeding) => a.type.localeCompare(b.type, 'de'));

    this.blurred();

    internalValuesChanged(this);
  }

  updateItem(index: number, item: GerblhofConcentratedFeeding): void {
    const items = this.internalValue;
    items.splice(index, 1, item);

    this.internalValue = items.sort((a: GerblhofConcentratedFeeding, b: GerblhofConcentratedFeeding) => a.type.localeCompare(b.type, 'de'));

    this.blurred();

    internalValuesChanged(this);
  }

  deleteItem(index: number): void {
    this.internalValue.splice(index, 1);

    this.blurred();

    internalValuesChanged(this);
  }

  concentratedFeedTypeName(concentratedFeeding: GerblhofConcentratedFeeding): string {
    return this.concentratedFeedTypes
      .find((type) => type.concentratedFeedId === concentratedFeeding.type)!.name;
  }

  amounts(concentratedFeeding: GerblhofConcentratedFeeding): string {
    const amounts = [];

    if (concentratedFeeding.amountMorning > 0) {
      amounts.push(`${formatKilogram(concentratedFeeding.amountMorning)} Morgens`);
    }
    if (concentratedFeeding.amountNoon > 0) {
      amounts.push(`${formatKilogram(concentratedFeeding.amountNoon)} Mittags`);
    }
    if (concentratedFeeding.amountEvening > 0) {
      amounts.push(`${formatKilogram(concentratedFeeding.amountEvening)} Abends`);
    }

    return amounts.length > 0
      ? amounts.join(', ')
      : 'Keine Fütterung';
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? []
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<GerblhofConcentratedFeeding[]> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
