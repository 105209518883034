
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { Date } from '@/types';
import { useMyStableStore } from '../store';
import { GerblhofFeedingOrConcentratedFeedingUpdate, RemoveConcentratedFeedingUpdateCommand, RemoveFeedingUpdateCommand } from '../types';
import UpdateGerblhofFeedingDialog from './update-gerblhof-feeding-dialog.vue';
import UpdateGerblhofConcentratedFeedingDialog from './update-gerblhof-concentrated-feeding-dialog.vue';

@Component({
  components: {
    UpdateGerblhofFeedingDialog,
    UpdateGerblhofConcentratedFeedingDialog,
  },
})
export default class GerblhofFeeding extends Vue {

  readonly store = useMyStableStore();
  readonly appStore = useAppStore();

  get isVisible(): boolean {
    return this.store.currentHorse !== null
      && this.store.gerblhofFeeding !== null;
  }

  mounted(): void {
    this.store.getGerblhofFeedingForHorse()
      .catch((error) => showErrorResponse(error));

    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Fütterung`)
      .catch((error) => showErrorResponse(error));
  }

  getConcentratedFeedingTypeById(id: string) {
    const concentratedFeedType = this.store.gerblhofFeeding!.concentratedFeedTypes
      .find((type) => type.concentratedFeedId === id);
    return concentratedFeedType && concentratedFeedType.name || '';
  }

  removeUpdateDisabled(update: GerblhofFeedingOrConcentratedFeedingUpdate): boolean {
    return update.details.startingAt.isBeforeOrEqualTo(Date.today());
  }

  async removeFeedingUpdateAction(feedingUpdate: GerblhofFeedingOrConcentratedFeedingUpdate): Promise<void> {
    const command: RemoveFeedingUpdateCommand = {
      horseId: this.store.currentHorseId!,
      startingAt: feedingUpdate.details.startingAt,
    };
    return this.store.removeFeedingUpdate(command)
      .catch((error) => showErrorResponse(error));
  }

  async removeConcentratedFeedingUpdateAction(feedingUpdate: GerblhofFeedingOrConcentratedFeedingUpdate): Promise<void> {
    const command: RemoveConcentratedFeedingUpdateCommand = {
      horseId: this.store.currentHorseId!,
      startingAt: feedingUpdate.details.startingAt,
    };
    return this.store.removeConcentratedFeedingUpdate(command)
      .catch((error) => showErrorResponse(error));
  }

}
