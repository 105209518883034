
import { Component, Vue } from 'vue-property-decorator';
import { RequestPasswordResetCommand } from '@/application/authentication/types';
import { navigate } from '@/helpers/navigation-helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useAuthenticationStore } from '@/application/authentication/store';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { EmailAddress } from '@/types';

interface Controls extends FormControls {
  emailAddress: FormControl<EmailAddress>;
}

@Component
export default class RequestPasswordReset extends Vue {

  readonly authenticationStore = useAuthenticationStore();

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    this.form = this.buildForm();
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        emailAddress: {
          label: 'E-Mail-Adresse',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted() {
    const formValues = getFormValues(this.form!);

    const command: RequestPasswordResetCommand = {
      emailAddress: formValues.emailAddress!,
    };

    this.authenticationStore.requestPasswordReset(command)
      .then(() => showSuccessMessage('Wir haben dir eine E-Mail mit einem Link zum Zurücksetzen deines Passwortes geschickt.', 6000))
      .then(() => navigate({ name: 'root' }))
      .catch((error) => showErrorResponse(error));
  }

  backToLoginClicked(): void {
    navigate({ name: 'root' });
  }

}
