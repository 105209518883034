import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestForFile } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { ActiveHorseInformation, DeleteHorseAsManagerWorkflow, GetActiveInformationForHorseAsManagerQuery, Horse, UpdateHorseNameAsManagerWorkflow, GetBoxSignAsManagerQuery, BoxSignGenerationProcessForAllHorses, DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand, GetBoxSignsForAllHorsesAsManagerQuery } from './types';

// -- Queries

export function getHorses(query: FarmAndUserProperties): Promise<Horse[]> {
  const url = `${apiUrl}/api/horse-management/get-horses-as-manager-query`;
  return performApiRequest<Horse[]>({ url, method: 'POST', data: query });
}

export function getActiveInformationForHorse(
  query: GetActiveInformationForHorseAsManagerQuery & FarmAndUserProperties
): Promise<ActiveHorseInformation> {
  const url = `${apiUrl}/api/horse-management/get-active-information-for-horse-as-manager-query`;
  return performApiRequest<ActiveHorseInformation>({ url, method: 'POST', data: query });
}

export function getBoxSign(query: GetBoxSignAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-box-sign-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getBoxSignGenerationProcessesForAllHorses(query: FarmAndUserProperties): Promise<BoxSignGenerationProcessForAllHorses[]> {
  const url = `${apiUrl}/api/horse-management/get-box-sign-generation-processes-for-all-horses-as-manager-query`;
  return performApiRequest<BoxSignGenerationProcessForAllHorses[]>({ url, method: 'POST', data: query });
}

export function getBoxSignsForAllHorses(query: GetBoxSignsForAllHorsesAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-box-signs-for-all-horses-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

// -- Commands

export function updateHorseName(command: UpdateHorseNameAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-horse-name-as-manager-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteHorseAsFarmManager(command: DeleteHorseAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-horse-as-manager-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function generateBoxSignsForAllHorses(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/generate-box-signs-for-all-horses-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteBoxSignGenerationProcessForAllHorses(
  command: DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-box-sign-generation-process-for-all-horses-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
