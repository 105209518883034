import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CSV } from '@/types';
import { CreateSettingsByUserGroupAsManagerCommand, DeleteSettingsByUserGroupAsManagerCommand, ExportSummaryEntriesAsCsvAsManagerQuery, GetTimeRecordingsAsManagerQuery, LaborServiceSettingsByUserGroup, MarkTimeEntryAsApprovedAsManagerCommand, MarkTimeEntryAsRejectedAsManagerCommand, TimeRecordings, UpdateAnnualNumbersOfHoursAsManagerCommand, UpdateSettingsByUserGroupAsManagerCommand, UserGroup } from './types';

// -- Queries

export function getSettingsByUserGroups(query: FarmAndUserProperties): Promise<LaborServiceSettingsByUserGroup[]> {
  const url = `${apiUrl}/api/labor-service/get-labor-service-settings-by-user-groups-as-manager-query`;
  return performApiRequest<LaborServiceSettingsByUserGroup[]>({ url, method: 'POST', data: query });
}

export function getAvailableUserGroups(query: FarmAndUserProperties): Promise<UserGroup[]> {
  const url = `${apiUrl}/api/labor-service/get-available-user-groups-as-manager-query`;
  return performApiRequest<UserGroup[]>({ url, method: 'POST', data: query });
}

export function getTimeRecordings(query: GetTimeRecordingsAsManagerQuery & FarmAndUserProperties): Promise<TimeRecordings> {
  const url = `${apiUrl}/api/labor-service/get-time-recordings-as-manager-query`;
  return performApiRequest<TimeRecordings>({ url, method: 'POST', data: query });
}

export function exportSummaryEntriesAsCsv(query: ExportSummaryEntriesAsCsvAsManagerQuery & FarmAndUserProperties): Promise<CSV> {
  const url = `${apiUrl}/api/labor-service/export-summary-entries-as-csv-as-manager-query`;
  return performApiRequest<CSV>({ url, method: 'POST', data: query });
}

// -- Commands

export function createSettingsByUserGroup(command: CreateSettingsByUserGroupAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/labor-service/create-labor-service-settings-by-user-group-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateSettingsByUserGroup(command: UpdateSettingsByUserGroupAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/labor-service/update-labor-service-settings-by-user-group-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function deleteSettingsByUserGroup(command: DeleteSettingsByUserGroupAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/labor-service/delete-labor-service-settings-by-user-group-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markTimeEntryAsApproved(command: MarkTimeEntryAsApprovedAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/labor-service/mark-time-entry-as-approved-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function markTimeEntryAsRejected(command: MarkTimeEntryAsRejectedAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/labor-service/mark-time-entry-as-rejected-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function updateAnnualNumbersOfHours(command: UpdateAnnualNumbersOfHoursAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/labor-service/update-annual-number-of-hours-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
