import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { SurveyId } from '@/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { CreateSurveyAsManagerCommand, DeleteSurveyAsManagerCommand, DeleteSurveyAnswerAsManagerCommand, GetSurveyDetailsAsManagerQuery, SurveyForList, Survey, GetSurveyForDuplicationAsManagerQuery, SurveyForDuplication } from './types';
import { createSurvey, deleteSurveyAnswer, getSurveyDetails, getSurveys, deleteSurvey, getSurveyForDuplication } from './service';

interface SurveyManagementState {
  currentSurveyId: SurveyId | null;
  currentSurvey: Survey | null;
  surveyForDuplication: SurveyForDuplication | null;
  surveys: SurveyForList[];

  getSurveysStatus: ActionStatus;
  createSurveyStatus: ActionStatus;
  getSurveyDetailsStatus: ActionStatus;
  deleteSurveyStatus: ActionStatus;
  deleteSurveyAnswerStatus: ActionStatus;
  getSurveyForDuplicationStatus: ActionStatus;
}

function initialState(): SurveyManagementState {
  return {
    currentSurveyId: null,
    currentSurvey: null,
    surveyForDuplication: null,
    surveys: [],

    getSurveysStatus: ActionStatus.None,
    createSurveyStatus: ActionStatus.None,
    getSurveyDetailsStatus: ActionStatus.None,
    deleteSurveyStatus: ActionStatus.None,
    deleteSurveyAnswerStatus: ActionStatus.None,
    getSurveyForDuplicationStatus: ActionStatus.None,
  };
}

export const useSurveyManagementStore = defineStore('surveyManagement', {
  state: (): SurveyManagementState => initialState(),
  getters: {
    isGetSurveysProcessing: (state: SurveyManagementState): boolean =>
      state.getSurveysStatus === ActionStatus.InProgress,
    isCreateSurveyProcessing: (state: SurveyManagementState): boolean =>
      state.createSurveyStatus === ActionStatus.InProgress,
    isGetSurveyDetailsProcessing: (state: SurveyManagementState): boolean =>
      state.getSurveyDetailsStatus === ActionStatus.InProgress,
    isDeleteSurveyProcessing: (state: SurveyManagementState): boolean =>
      state.deleteSurveyStatus === ActionStatus.InProgress,
    isDeleteSurveyAnswerProcessing: (state: SurveyManagementState): boolean =>
      state.deleteSurveyAnswerStatus === ActionStatus.InProgress,
    isGetSurveyForDuplicationProcessing: (state: SurveyManagementState): boolean =>
      state.getSurveyForDuplicationStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentWormingTreatment(surveyId: SurveyId): Promise<void> {
      this.currentSurveyId = surveyId;

      return this.getSurveyDetails();
    },

    // -- Queries

    getSurveys(): Promise<void> {
      const { getSurveysStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSurveysStatus,
        () => getSurveys(attachFarmAndUserProperties({}))
          .then((surveys) => {
            this.surveys = surveys;
          })
      );
    },

    getSurveyDetails(): Promise<void> {
      const { getSurveyDetailsStatus } = storeToRefs(this);
      const query: GetSurveyDetailsAsManagerQuery = {
        surveyId: this.currentSurveyId!,
      };
      return wrapActionWithProgress(
        getSurveyDetailsStatus,
        () => getSurveyDetails(attachFarmAndUserProperties(query))
          .then((survey) => {
            this.currentSurvey = survey;
          })
      );
    },

    getSurveyForDuplication(query: GetSurveyForDuplicationAsManagerQuery): Promise<void> {
      const { getSurveyForDuplicationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getSurveyForDuplicationStatus,
        () => getSurveyForDuplication(attachFarmAndUserProperties(query))
          .then((survey) => {
            this.surveyForDuplication = survey;
          })
      );
    },

    // -- Commands

    createSurvey(command: CreateSurveyAsManagerCommand): Promise<void> {
      const { createSurveyStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createSurveyStatus,
        () => createSurvey(attachFarmAndUserProperties(command))
          .then(() => this.getSurveys())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    deleteSurvey(command: DeleteSurveyAsManagerCommand): Promise<void> {
      const { deleteSurveyStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSurveyStatus,
        () => deleteSurvey(attachFarmAndUserProperties(command))
          .then(() => this.getSurveys())
      );
    },

    deleteSurveyAnswer(command: DeleteSurveyAnswerAsManagerCommand): Promise<void> {
      const { deleteSurveyAnswerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteSurveyAnswerStatus,
        () => deleteSurveyAnswer(attachFarmAndUserProperties(command))
          .then(() => this.getSurveyDetails())
      );
    },

  },
});
