
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { CustomFieldId, CustomFieldType, CustomFieldValue, CustomFieldValues, OptionId, Options } from '@/types';
import { VuetifySelectItem } from '@/application/types';

export interface CustomField {
  customFieldId: CustomFieldId;
  type: CustomFieldType;
  label: string;
  options: Options | null;
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class CustomFieldsFormControl extends Vue implements FormControlComponent<CustomFieldValues> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<CustomFieldValues>;

  @Prop({ type: Array, required: true })
  readonly customFields!: CustomField[];

  @Prop({ type: String, default: null })
  readonly infoText!: string | null;

  readonly formControlId = createFormControlId();

  readonly CustomFieldType = CustomFieldType;

  internalValues: Record<CustomFieldId, CustomFieldValue> = {};

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  get yesNoOptions(): VuetifySelectItem<boolean>[] {
    return [
      { text: 'Ja', value: true },
      { text: 'Nein', value: false },
    ];
  }

  mounted(): void {
    mountFormControl(this);

    // This triggers the internal value update which adds the yes no fields to the values
    internalValuesChanged(this);
  }

  textChanged(customField: CustomField, value: string): void {
    if (customField.type !== CustomFieldType.TEXT
      && customField.type !== CustomFieldType.LONGTEXT
    ) {
      throw new Error('Invalid field type');
    }

    if (value.trim().length > 0) {
      this.internalValues[customField.customFieldId] = {
        customFieldId: customField.customFieldId,
        customFieldType: customField.type,
        value,
      };
    } else {
      delete this.internalValues[customField.customFieldId];
    }

    internalValuesChanged(this);
  }

  selectChanged(customField: CustomField, value: OptionId | null): void {
    if (customField.type !== CustomFieldType.SELECT) {
      throw new Error('Invalid field type');
    }

    if (value !== null) {
      this.internalValues[customField.customFieldId] = {
        customFieldId: customField.customFieldId,
        customFieldType: customField.type,
        value,
      };
    } else {
      delete this.internalValues[customField.customFieldId];
    }

    internalValuesChanged(this);
  }

  multiSelectChanged(customField: CustomField, value: OptionId[]): void {
    if (customField.type !== CustomFieldType.MULTI_SELECT) {
      throw new Error('Invalid field type');
    }

    if (value.length > 0) {
      this.internalValues[customField.customFieldId] = {
        customFieldId: customField.customFieldId,
        customFieldType: customField.type,
        value,
      };
    } else {
      delete this.internalValues[customField.customFieldId];
    }

    internalValuesChanged(this);
  }

  yesNoChanged(customField: CustomField, value: boolean | null): void {
    if (customField.type !== CustomFieldType.YES_NO) {
      throw new Error('Invalid field type');
    }

    this.focused();

    if (value !== null) {
      this.internalValues[customField.customFieldId] = {
        customFieldId: customField.customFieldId,
        customFieldType: customField.type,
        value,
      };
    } else {
      delete this.internalValues[customField.customFieldId];
    }

    internalValuesChanged(this);

    this.blurred();
  }

  fieldCleared(customField: CustomField): void {
    delete this.internalValues[customField.customFieldId];

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    const messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    this.messages.push(...messages);

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    const internalValues: Record<CustomFieldId, CustomFieldValue> = {};
    if (this.formControl.value !== null) {
      this.formControl.value.forEach((customFieldValue) => {
        internalValues[customFieldValue.customFieldId] = customFieldValue;
      })
    }

    this.internalValues = internalValues;
  }

  formValueFromInternalValues(): FormControlValue<CustomFieldValues> {
    return Object.keys(this.internalValues).length > 0
      ? Object.values(this.internalValues)
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
