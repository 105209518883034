import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import Dashboard from './components/dashboard.vue';

export const dashboardRoutes: RouteConfig[] = [
  {
    name: 'dashboard',
    path: '/uebersicht',
    component: Dashboard,
    meta: {
      title: 'Übersicht',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
    },
  },
];
