
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { PhoneNumber } from '@/types';
import { UpdatePhoneNumberCommand } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  phoneNumber: FormControl<PhoneNumber>;
  isPhoneNumberVisibleOnBoxSign: FormControl<boolean>;
}

@Component
export default class UpdatePhoneNumberDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        phoneNumber: {
          label: 'Deine Telefonnummer',
          value: null,
          isRequired: true,
        },
        isPhoneNumberVisibleOnBoxSign: {
          label: 'Telefonnummer auf Boxenschild anzeigen',
          value: false,
        },
      }
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdatePhoneNumberCommand = {
      phoneNumber: formValues.phoneNumber!,
      isPhoneNumberVisibleOnBoxSign: formValues.isPhoneNumberVisibleOnBoxSign!,
    };

    this.store.updatePhoneNumber(command)
      .then(() => showSuccessMessage('Die Telefonnummer wurde angepasst.'))
      .then(() => this.$emit('phone-number-updated'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
