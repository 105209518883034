import { Command, Query } from '@/application/types';
import { EmailAddress, FarmId, FarmManagerPermissions, Feature, Moment, SortDirection, UserId, UserRole } from '@/types';

export interface GetGlobalUsersResponse {
  totalCount: number;
  users: User[];
}

export interface User {
  userId: UserId;
  emailAddress: EmailAddress;
  fullName: string;
  farm: Farm;
  enabledFeaturesOfFarm: Feature[],
  role: UserRole;
  enabledPermissions: FarmManagerPermissions | null;
  isEnabled: boolean;
  createdAt: Moment;
}

export interface Farm {
  farmId: FarmId;
  name: string;
}

export enum SortBy {
  ROLE = 'ROLE',
  NAME = 'NAME',
  CREATED_AT = 'CREATED_AT',
}

// -- Queries

export interface GetGlobalUsersAsAdminQuery extends Query {
  filteredByFarm: FarmId | null;
  searchTerm: string | null;
  limit: number;
  sortBy: SortBy;
  sortDirection: SortDirection;
}

export interface GetUserForImpersonationAsAdminQuery extends Query {
  targetUserId: string;
}

// -- Commands

export interface UpdateUserPasswordAsAdminCommand extends Command {
  farmId: FarmId;
  targetUserId: UserId;
  password: string;
}

export interface UpdateUserRoleAsAdminWorkflow extends Command {
  farmId: FarmId;
  targetUserId: UserId;
  role: UserRole;
  enabledPermissions: FarmManagerPermissions | null;
}

export interface UpdateEnabledPermissionsOfUserAsAdminWorkflow extends Command {
  farmId: FarmId;
  targetUserId: UserId;
  enabledPermissions: FarmManagerPermissions;
}
