import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { TokenStorage } from '@/application/authentication/token-storage';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CSV, NameOrderType, Persona } from '@/types';
import { CreateGroupAsManagerCommand, DisableUserAsManagerCommand, EnableUserAsManagerCommand, GetUserForImpersonationAsManagerQuery, GetUsersAsManagerQuery, GetUsersInCSVFormatAsManagerQuery, Group, InviteUserAsManagerCommand, RegistrationConfiguration, RemoveUserFromFarmAsManagerWorkflow, RequestImpersonationAsManagerCommand, UpdateEnabledPermissionsOfUserAsManagerWorkflow, UpdateGroupNameAsManagerCommand, UpdateGroupsForUserAsManagerCommand, UpdateGroupUsersAsManagerCommand, UpdateRegistrationConfigurationAsManagerCommand, UpdateUserPasswordAsManagerCommand, UpdateUserRoleAsManagerWorkflow, UpdateVisibilityOfHorsesViewAsManagerCommand, User } from './types';
import { createGroup, disableUser, enableUser, getGroups, getRegistrationConfiguration, getUserForImpersonation, getUsers, getUsersInCSVFormat, inviteUser, removeUserFromFarm, requestImpersonation, updateGroupName, updateGroupsForUser, updateGroupUsers, updateRegistrationConfiguration, updateUserPassword, updateEnabledPermissionsOfUser, updateUserRole, updateVisibilityOfHorsesViewOfUser } from './service';

interface UserManagementState {
  users: User[];
  groups: Group[];
  registrationConfiguration: RegistrationConfiguration | null;
  search: string | null;
  selectedPersona: Persona | null;
  selectedNameOrderType: NameOrderType;

  getUsersStatus: ActionStatus;
  getGroupsStatus: ActionStatus;
  createGroupStatus: ActionStatus;
  updateGroupNameStatus: ActionStatus;
  updateGroupUsersStatus: ActionStatus;
  updateGroupsForUserStatus: ActionStatus;
  deleteUserStatus: ActionStatus;
  getRegistrationConfigurationStatus: ActionStatus;
  updateRegistrationConfigurationStatus: ActionStatus;
  updateUserPasswordStatus: ActionStatus;
  inviteUserStatus: ActionStatus;
  getUserForImpersonationStatus: ActionStatus;
  removeUserFromFarmStatus: ActionStatus;
  enableUserStatus: ActionStatus;
  disableUserStatus: ActionStatus;
  requestImpersonationStatus: ActionStatus;
  getUsersInCSVFormatStatus: ActionStatus;
  updateUserRoleStatus: ActionStatus;
  updateEnabledPermissionsOfUserStatus: ActionStatus;
  updateVisibilityOfHorsesViewOfUserStatus: ActionStatus;
}

function initialState(): UserManagementState {
  return {
    users: [],
    groups: [],
    registrationConfiguration: null,
    search: null,
    selectedPersona: null,
    selectedNameOrderType: NameOrderType.FIRST_NAME_FIRST,

    getUsersStatus: ActionStatus.None,
    getGroupsStatus: ActionStatus.None,
    createGroupStatus: ActionStatus.None,
    updateGroupNameStatus: ActionStatus.None,
    updateGroupUsersStatus: ActionStatus.None,
    updateGroupsForUserStatus: ActionStatus.None,
    deleteUserStatus: ActionStatus.None,
    getRegistrationConfigurationStatus: ActionStatus.None,
    updateRegistrationConfigurationStatus: ActionStatus.None,
    updateUserPasswordStatus: ActionStatus.None,
    inviteUserStatus: ActionStatus.None,
    getUserForImpersonationStatus: ActionStatus.None,
    removeUserFromFarmStatus: ActionStatus.None,
    enableUserStatus: ActionStatus.None,
    disableUserStatus: ActionStatus.None,
    requestImpersonationStatus: ActionStatus.None,
    getUsersInCSVFormatStatus: ActionStatus.None,
    updateUserRoleStatus: ActionStatus.None,
    updateEnabledPermissionsOfUserStatus: ActionStatus.None,
    updateVisibilityOfHorsesViewOfUserStatus: ActionStatus.None,
  };
}

export const useUserManagementStore = defineStore('userManagement', {
  state: (): UserManagementState => initialState(),
  getters: {
    isGetUsersProcessing: (state: UserManagementState): boolean =>
      state.getUsersStatus === ActionStatus.InProgress,
    isGetGroupsProcessing: (state: UserManagementState): boolean =>
      state.getGroupsStatus === ActionStatus.InProgress,
    isCreateGroupProcessing: (state: UserManagementState): boolean =>
      state.createGroupStatus === ActionStatus.InProgress,
    isUpdateGroupNameProcessing: (state: UserManagementState): boolean =>
      state.updateGroupNameStatus === ActionStatus.InProgress,
    isUpdateGroupUsersProcessing: (state: UserManagementState): boolean =>
      state.updateGroupUsersStatus === ActionStatus.InProgress,
    isUpdateGroupsForUserProcessing: (state: UserManagementState): boolean =>
      state.updateGroupsForUserStatus === ActionStatus.InProgress,
    isDeleteUserProcessing: (state: UserManagementState): boolean =>
      state.deleteUserStatus === ActionStatus.InProgress,
    isGetRegistrationConfigurationProcessing: (state: UserManagementState): boolean =>
      state.getRegistrationConfigurationStatus === ActionStatus.InProgress,
    isUpdateRegistrationConfigurationProcessing: (state: UserManagementState): boolean =>
      state.updateRegistrationConfigurationStatus === ActionStatus.InProgress,
    isUpdateUserPasswordProcessing: (state: UserManagementState): boolean =>
      state.updateUserPasswordStatus === ActionStatus.InProgress,
    isInviteUserProcessing: (state: UserManagementState): boolean =>
      state.inviteUserStatus === ActionStatus.InProgress,
    isGetUserForImpersonationProcessing: (state: UserManagementState): boolean =>
      state.getUserForImpersonationStatus === ActionStatus.InProgress,
    isRemoveUserFromFarmProcessing: (state: UserManagementState): boolean =>
      state.removeUserFromFarmStatus === ActionStatus.InProgress,
    isEnableUserProcessing: (state: UserManagementState): boolean =>
      state.enableUserStatus === ActionStatus.InProgress,
    isDisableUserProcessing: (state: UserManagementState): boolean =>
      state.disableUserStatus === ActionStatus.InProgress,
    isRequestImpersonationProcessing: (state: UserManagementState): boolean =>
      state.requestImpersonationStatus === ActionStatus.InProgress,
    isGetUsersInCSVFormatProcessing: (state: UserManagementState): boolean =>
      state.getUsersInCSVFormatStatus === ActionStatus.InProgress,
    isUpdateUserRoleProcessing: (state: UserManagementState): boolean =>
      state.updateUserRoleStatus === ActionStatus.InProgress,
    isUpdateEnabledPermissionsOfUserProcessing: (state: UserManagementState): boolean =>
      state.updateEnabledPermissionsOfUserStatus === ActionStatus.InProgress,
    isUpdateVisibilityOfHorsesViewOfUserProcessing: (state: UserManagementState): boolean =>
      state.updateVisibilityOfHorsesViewOfUserStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSearch(search: string | null): Promise<void> {
      this.search = search;

      return this.getUsers();
    },

    updateSelectedPersona(persona: Persona | null): Promise<void> {
      this.selectedPersona = persona;

      return this.getUsers();
    },

    updateSelectedNameOrderType(nameOrderType: NameOrderType): Promise<void> {
      this.selectedNameOrderType = nameOrderType;

      return this.getUsers();
    },

    // -- Queries

    getUsers(): Promise<void> {
      const query: GetUsersAsManagerQuery = {
        search: this.search,
        persona: this.selectedPersona,
        nameOrderType: this.selectedNameOrderType,
      };
      const { getUsersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersStatus,
        () => getUsers(attachFarmAndUserProperties(query))
          .then((users) => {
            this.users = users;
          })
      );
    },

    getGroups(): Promise<void> {
      const { getGroupsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getGroupsStatus,
        () => getGroups(attachFarmAndUserProperties({}))
          .then((groups) => {
            this.groups = groups;
          })
      );
    },

    getRegistrationConfiguration(): Promise<void> {
      const { getRegistrationConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getRegistrationConfigurationStatus,
        () => getRegistrationConfiguration(attachFarmAndUserProperties({}))
          .then((registrationConfiguration) => {
            this.registrationConfiguration = registrationConfiguration;
          })
      );
    },

    getUserForImpersonation(query: GetUserForImpersonationAsManagerQuery): Promise<void> {
      const { getUserForImpersonationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUserForImpersonationStatus,
        () => getUserForImpersonation(attachFarmAndUserProperties(query))
          .then(() => TokenStorage.setImpersonateUserFlag())
      );
    },

    getUsersInCSVFormat(): Promise<CSV> {
      const query: GetUsersInCSVFormatAsManagerQuery = {
        search: this.search,
        persona: this.selectedPersona,
        nameOrderType: this.selectedNameOrderType,
      };
      const { getUsersInCSVFormatStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUsersInCSVFormatStatus,
        () => getUsersInCSVFormat(attachFarmAndUserProperties(query))
      );
    },

    // -- Commands

    enableUser(command: EnableUserAsManagerCommand): Promise<void> {
      const { enableUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableUserStatus,
        () => enableUser(attachFarmAndUserProperties(command))
          .then(() => this.getUsers())
      );
    },

    disableUser(command: DisableUserAsManagerCommand): Promise<void> {
      const { disableUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableUserStatus,
        () => disableUser(attachFarmAndUserProperties(command))
          .then(() => this.getUsers())
      );
    },

    removeUserFromFarm(command: RemoveUserFromFarmAsManagerWorkflow): Promise<void> {
      const { removeUserFromFarmStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        removeUserFromFarmStatus,
        () => removeUserFromFarm(attachFarmAndUserProperties(command))
          .then(() => this.getUsers())
      );
    },

    requestImpersonation(command: RequestImpersonationAsManagerCommand): Promise<void> {
      const { requestImpersonationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        requestImpersonationStatus,
        () => requestImpersonation(attachFarmAndUserProperties(command))
          .then(() => this.getUsers())
      );
    },

    createGroup(command: CreateGroupAsManagerCommand): Promise<void> {
      const { createGroupStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createGroupStatus,
        () => createGroup(attachFarmAndUserProperties(command))
          .then(() => this.getGroups())
      );
    },

    updateGroupName(command: UpdateGroupNameAsManagerCommand): Promise<void> {
      const { updateGroupNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGroupNameStatus,
        () => updateGroupName(attachFarmAndUserProperties(command))
          .then(() => this.getGroups())
      );
    },

    updateGroupUsers(command: UpdateGroupUsersAsManagerCommand): Promise<void> {
      const { updateGroupUsersStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGroupUsersStatus,
        () => updateGroupUsers(attachFarmAndUserProperties(command))
          .then(() => this.getGroups())
      );
    },

    updateGroupsForUser(command: UpdateGroupsForUserAsManagerCommand): Promise<void> {
      const { updateGroupsForUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateGroupsForUserStatus,
        () => updateGroupsForUser(attachFarmAndUserProperties(command))
          .then(() => this.getGroups())
          .then(() => this.getUsers())
      );
    },

    updateRegistrationConfiguration(command: UpdateRegistrationConfigurationAsManagerCommand): Promise<void> {
      const { updateRegistrationConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateRegistrationConfigurationStatus,
        () => updateRegistrationConfiguration(attachFarmAndUserProperties(command))
          .then(() => this.getRegistrationConfiguration())
      );
    },

    updateUserPassword(command: UpdateUserPasswordAsManagerCommand): Promise<void> {
      const { updateUserPasswordStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserPasswordStatus,
        () => updateUserPassword(attachFarmAndUserProperties(command))
      );
    },

    inviteUser(command: InviteUserAsManagerCommand): Promise<void> {
      const { inviteUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        inviteUserStatus,
        () => inviteUser(attachFarmAndUserProperties(command))
      );
    },

    updateUserRole(command: UpdateUserRoleAsManagerWorkflow): Promise<void> {
      const { updateUserRoleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateUserRoleStatus,
        () => updateUserRole(attachFarmAndUserProperties(command))
          .then(() => this.getUsers())
      );
    },

    updateEnabledPermissionsOfUser(command: UpdateEnabledPermissionsOfUserAsManagerWorkflow): Promise<void> {
      const { updateEnabledPermissionsOfUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEnabledPermissionsOfUserStatus,
        () => updateEnabledPermissionsOfUser(attachFarmAndUserProperties(command))
          .then(() => this.getUsers())
      );
    },

    updateVisibilityOfHorsesViewOfUser(command: UpdateVisibilityOfHorsesViewAsManagerCommand): Promise<void> {
      const { updateVisibilityOfHorsesViewOfUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateVisibilityOfHorsesViewOfUserStatus,
        () => updateVisibilityOfHorsesViewOfUser(attachFarmAndUserProperties(command))
          .then(() => this.getUsers())
      );
    },

  },
});
