import { Month, Date } from '@/types';
import { moment } from './moment';

export function currentMonth(): Month {
  return moment()
    .startOf('month')
    .format('YYYY-MM') as Month;
}

export function isMonthEqualTo(month: Month, other: Month): boolean {
  return month === other;
}

export function isMonthAfter(month: Month, other: Month): boolean {
  return moment(month, 'YYYY-MM', 'Europe/Berlin').isAfter(moment(other, 'YYYY-MM', 'Europe/Berlin'));
}

export function firstDayAsDateOfMonth(month: Month): Date {
  return Date.fromMoment(
    moment(month, 'YYYY-MM', 'Europe/Berlin')
  );
}
