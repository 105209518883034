
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Color, Strain } from '@/types';
import { strainTranslation } from '@/helpers/translations';
import { VuetifySelectItem } from '@/application/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class StrainFormControl extends Vue implements FormControlComponent<Strain> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Strain>;

  readonly formControlId = createFormControlId();

  readonly colorMap: Record<Strain, Color> = {
    MINIMAL_STRAIN: Color['green-5'],
    MODERATE_STRAIN: Color['yellow-5'],
    EXTENSIVE_STRAIN: Color['red-5'],
  };

  get items(): VuetifySelectItem<Strain>[] {
    return Object.values(Strain).map((strain) => ({
      text: strainTranslation[strain],
      value: strain,
    }));
  }

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: Strain | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? this.items[0].value
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Strain> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
