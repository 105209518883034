
import { Vue, Component, Inject, Prop } from 'vue-property-decorator';
import { VuetifySelectItem } from '@/application/types';
import { FacilityReservationActivityId } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

export interface FacilityReservationActivity {
  facilityReservationActivityId: FacilityReservationActivityId;
  name: string;
  requiredSpaces: number;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'facilityReservationActivityId')
      && Object.hasOwn(item, 'name')
      && Object.hasOwn(item, 'requiredSpaces'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class FacilityReservationActivityFormControl extends Vue implements FormControlComponent<FacilityReservationActivityId> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<FacilityReservationActivityId>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: FacilityReservationActivity[];

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly areSpacesVisible!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: FacilityReservationActivityId | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get selectItems(): VuetifySelectItem<FacilityReservationActivityId>[] {
    return this.items.map((item) => ({
      text: this.areSpacesVisible
        ? item.requiredSpaces > 1
          ? `${item.name} (benötigt ${item.requiredSpaces} Plätze)`
          : `${item.name} (benötigt 1 Platz)`
        : item.name,
      value: item.facilityReservationActivityId,
    }));
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<FacilityReservationActivityId> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
