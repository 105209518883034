import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { createStation, deleteStation, disablePushNotifications, enablePushNotifications, getFeedProtocolConfiguration, getFeedProtocolOverview, getStationUtilization, pauseMonitoringForHorse, unpauseMonitoringForHorse, updateEquineNumber, updateStation } from './service';
import { CreateStationAsManagerCommand, DeleteStationAsManagerCommand, FeedProtocolConfiguration, FeedProtocolOverview, HorseWithoutEquineNumber, HorseWithoutSignals, LatestSignalForHorse, PauseMonitoringForHorseAsManagerCommand, StationUtilization, UnpauseMonitoringForHorseAsManagerCommand, UpdateEquineNumberAsManagerCommand, UpdateStationAsManagerCommand } from './types';

interface FeedProtocolManagementState {
  feedProtocolOverview: FeedProtocolOverview | null;
  feedProtocolConfiguration: FeedProtocolConfiguration | null;
  stationUtilization: StationUtilization | null;

  getFeedProtocolOverviewStatus: ActionStatus;
  getFeedProtocolConfigurationStatus: ActionStatus;
  getStationUtilizationStatus: ActionStatus;
  pauseMonitoringForHorseStatus: ActionStatus;
  unpauseMonitoringForHorseStatus: ActionStatus;
  updateEquineNumberStatus: ActionStatus;
  enablePushNotificationsStatus: ActionStatus;
  disablePushNotificationsStatus: ActionStatus;
  createStationStatus: ActionStatus;
  updateStationStatus: ActionStatus;
  deleteStationStatus: ActionStatus;
}

function initialState(): FeedProtocolManagementState {
  return {
    feedProtocolOverview: null,
    feedProtocolConfiguration: null,
    stationUtilization: null,

    getFeedProtocolOverviewStatus: ActionStatus.None,
    getFeedProtocolConfigurationStatus: ActionStatus.None,
    getStationUtilizationStatus: ActionStatus.None,
    pauseMonitoringForHorseStatus: ActionStatus.None,
    unpauseMonitoringForHorseStatus: ActionStatus.None,
    updateEquineNumberStatus: ActionStatus.None,
    enablePushNotificationsStatus: ActionStatus.None,
    disablePushNotificationsStatus: ActionStatus.None,
    createStationStatus: ActionStatus.None,
    updateStationStatus: ActionStatus.None,
    deleteStationStatus: ActionStatus.None,
  };
}

export const useFeedProtocolManagementStore = defineStore('feedProtocolManagement', {
  state: (): FeedProtocolManagementState => initialState(),
  getters: {
    isGetFeedProtocolOverviewProcessing: (state: FeedProtocolManagementState): boolean =>
      state.getFeedProtocolOverviewStatus === ActionStatus.InProgress,
    isGetFeedProtocolConfigurationProcessing: (state: FeedProtocolManagementState): boolean =>
      state.getFeedProtocolConfigurationStatus === ActionStatus.InProgress,
    isGetStationUtilizationProcessing: (state: FeedProtocolManagementState): boolean =>
      state.getStationUtilizationStatus === ActionStatus.InProgress,
    isPauseMonitoringForHorseProcessing: (state: FeedProtocolManagementState): boolean =>
      state.pauseMonitoringForHorseStatus === ActionStatus.InProgress,
    isUnpauseMonitoringForHorseProcessing: (state: FeedProtocolManagementState): boolean =>
      state.unpauseMonitoringForHorseStatus === ActionStatus.InProgress,
    isUpdateEquineNumberProcessing: (state: FeedProtocolManagementState): boolean =>
      state.updateEquineNumberStatus === ActionStatus.InProgress,
    isEnablePushNotificationsProcessing: (state: FeedProtocolManagementState): boolean =>
      state.enablePushNotificationsStatus === ActionStatus.InProgress,
    isDisablePushNotificationsProcessing: (state: FeedProtocolManagementState): boolean =>
      state.disablePushNotificationsStatus === ActionStatus.InProgress,
    isCreateStationProcessing: (state: FeedProtocolManagementState): boolean =>
      state.createStationStatus === ActionStatus.InProgress,
    isUpdateStationProcessing: (state: FeedProtocolManagementState): boolean =>
      state.updateStationStatus === ActionStatus.InProgress,
    isDeleteStationProcessing: (state: FeedProtocolManagementState): boolean =>
      state.deleteStationStatus === ActionStatus.InProgress,

    latestSignals: (state: FeedProtocolManagementState): LatestSignalForHorse[] =>
      state.feedProtocolOverview === null
        ? []
        : state.feedProtocolOverview.latestSignals,
    horsesWithoutSignals: (state: FeedProtocolManagementState): HorseWithoutSignals[] =>
      state.feedProtocolOverview === null
        ? []
        : state.feedProtocolOverview.horsesWithoutSignals,
    horsesWithoutEquineNumber: (state: FeedProtocolManagementState): HorseWithoutEquineNumber[] =>
      state.feedProtocolOverview === null
        ? []
        : state.feedProtocolOverview.horsesWithoutEquineNumber,
  },
  actions: {

    // -- Queries

    getFeedProtocolOverview(): Promise<void> {
      const { getFeedProtocolOverviewStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFeedProtocolOverviewStatus,
        () => getFeedProtocolOverview(attachFarmAndUserProperties({}))
          .then((feedProtocolOverview) => {
            this.feedProtocolOverview = feedProtocolOverview;
          })
      );
    },

    getStationUtilization(): Promise<void> {
      const { getStationUtilizationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getStationUtilizationStatus,
        () => getStationUtilization(attachFarmAndUserProperties({}))
          .then((stationUtilization) => {
            this.stationUtilization = stationUtilization;
          })
      );
    },

    getFeedProtocolConfiguration(): Promise<void> {
      const { getFeedProtocolConfigurationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFeedProtocolConfigurationStatus,
        () => getFeedProtocolConfiguration(attachFarmAndUserProperties({}))
          .then((feedProtocolConfiguration) => {
            this.feedProtocolConfiguration = feedProtocolConfiguration;
          })
      );
    },

    // -- Commands

    pauseMonitoringForHorse(command: PauseMonitoringForHorseAsManagerCommand): Promise<void> {
      const { pauseMonitoringForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        pauseMonitoringForHorseStatus,
        () => pauseMonitoringForHorse(attachFarmAndUserProperties(command))
          .then(() => this.getFeedProtocolOverview())
      );
    },

    unpauseMonitoringForHorse(command: UnpauseMonitoringForHorseAsManagerCommand): Promise<void> {
      const { unpauseMonitoringForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpauseMonitoringForHorseStatus,
        () => unpauseMonitoringForHorse(attachFarmAndUserProperties(command))
          .then(() => this.getFeedProtocolOverview())
      );
    },

    updateEquineNumber(command: UpdateEquineNumberAsManagerCommand): Promise<void> {
      const { updateEquineNumberStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEquineNumberStatus,
        () => updateEquineNumber(attachFarmAndUserProperties(command))
          .then(() => this.getFeedProtocolOverview())
      );
    },

    enablePushNotifications(): Promise<void> {
      const { enablePushNotificationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enablePushNotificationsStatus,
        () => enablePushNotifications(attachFarmAndUserProperties({}))
          .then(() => this.getFeedProtocolConfiguration())
      );
    },

    disablePushNotifications(): Promise<void> {
      const { disablePushNotificationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disablePushNotificationsStatus,
        () => disablePushNotifications(attachFarmAndUserProperties({}))
          .then(() => this.getFeedProtocolConfiguration())
      );
    },

    createStation(command: CreateStationAsManagerCommand): Promise<void> {
      const { createStationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createStationStatus,
        () => createStation(attachFarmAndUserProperties(command))
          .then(() => this.getFeedProtocolConfiguration())
      );
    },

    updateStation(command: UpdateStationAsManagerCommand): Promise<void> {
      const { updateStationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateStationStatus,
        () => updateStation(attachFarmAndUserProperties(command))
          .then(() => this.getFeedProtocolConfiguration())
      );
    },

    deleteStation(command: DeleteStationAsManagerCommand): Promise<void> {
      const { deleteStationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteStationStatus,
        () => deleteStation(attachFarmAndUserProperties(command))
          .then(() => this.getFeedProtocolConfiguration())
      );
    },

  },
});
