import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateTimeEntryCommand, GetSummaryForUserQuery, Summary } from './types';

// -- Queries

export function getSummaryForUser(query: GetSummaryForUserQuery & FarmAndUserProperties): Promise<Summary> {
  const url = `${apiUrl}/api/labor-service/get-summary-for-user-query`;
  return performApiRequest<Summary>({ url, method: 'POST', data: query });
}

// -- Commands

export function createTimeEntry(command: CreateTimeEntryCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/labor-service/create-time-entry-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
