import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole } from '@/types';
import ManageFarms from './components/manage-farms.vue';

export const farmManagementRoutes: RouteConfig[] = [
  {
    name: 'farm-management-manage-farms',
    path: 'hoefe',
    component: ManageFarms,
    meta: {
      title: 'Höfe',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresRole: UserRole.ROLE_ADMIN,
    },
  },
];
