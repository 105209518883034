
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { VuetifySelectItem } from '@/application/types';
import { Color } from '@/types';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class ColorFormControl extends Vue implements FormControlComponent<Color> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Color>;

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  readonly formControlId = createFormControlId();

  readonly colors: VuetifySelectItem<Color>[] = [
    { text: 'Normales Grün', value: Color['green-5'] },
    { text: 'Normales Gelb', value: Color['yellow-5'] },
    { text: 'Normales Rot', value: Color['red-5'] },
    { text: 'Helles Grün', value: Color['green-8'] },
    { text: 'Helles Gelb', value: Color['yellow-8'] },
    { text: 'Helles Rot', value: Color['red-8'] },
    { text: 'Dunkles Grün', value: Color['green-3'] },
    { text: 'Dunkles Gelb', value: Color['yellow-3'] },
    { text: 'Dunkles Rot', value: Color['red-3'] },
  ];

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: Color | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? this.colors[0].value
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Color> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
