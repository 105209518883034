import Vue from 'vue';
import VueRouter from 'vue-router';
import { appVersionGuard } from '@/application/app/app-version-guard';
import { accessGuard } from '@/application/authentication/access-guard';
import { routes } from './routes';

Vue.use(VueRouter);

export const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach(accessGuard);
router.afterEach(appVersionGuard);
