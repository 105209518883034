import { User } from './types';

export function sortUser(userA: User, userB: User): number {
  const aName = `${userA.name}`;
  const bName = `${userB.name}`;

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
}
