
import { Component, Vue } from 'vue-property-decorator';
import { ActionStatus } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useAppStore } from '@/application/app/store';
import { useMyStableStore } from '../store';
import { HorseShare, HorseShareInvite, RevokeHorseShareCommand, RevokeHorseShareInviteCommand } from '../types';
import InviteUserAsHorseShareDialog from './invite-user-as-horse-share-dialog.vue';

@Component({
  components: {
    InviteUserAsHorseShareDialog,
  },
})
export default class HorseShares extends Vue {

  readonly store = useMyStableStore();
  readonly appStore = useAppStore();

  get currentHorseId(): string {
    return this.$route.params.id;
  }

  get areNoHorseSharesAvailable(): boolean {
    return this.store.getHorseSharesForHorseStatus === ActionStatus.Successful
      && this.store.horseShares.length === 0;
  }

  get isNoOpenHorseShareInviteAvailable(): boolean {
    return this.store.getHorseShareInvitesForHorseStatus === ActionStatus.Successful
      && this.store.horseShareInvites.length === 0;
  }

  mounted(): void {
    this.store.getHorseSharesForHorse()
      .catch((error) => showErrorResponse(error));

    this.store.getHorseShareInvitesForHorse()
      .catch((error) => showErrorResponse(error));

    this.appStore.updateTitle(`${this.store.currentHorse!.name} - Reitbeteiligungen`)
      .catch((error) => showErrorResponse(error));
  }

  revokeHorseShareInviteAction(horseShareInvite: HorseShareInvite): Promise<void> {
    const command: RevokeHorseShareInviteCommand = {
      horseShareInviteId: horseShareInvite.horseShareInviteId,
    };

    return this.store.revokeHorseShareInvite(command)
      .then(() => showSuccessMessage('Einladung wurde zurückgezogen'))
      .catch((error) => showErrorResponse(error));
  }

  revokeHorseShareAction(horseShare: HorseShare): Promise<void> {
    const command: RevokeHorseShareCommand = {
      horseShareId: horseShare.horseShareId,
      horseId: this.currentHorseId,
    };

    return this.store.revokeHorseShare(command)
      .then(() => showSuccessMessage('Reitbeteiligung wurde zurückgezogen'))
      .catch((error) => showErrorResponse(error));
  }
}
