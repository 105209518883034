
import { Component, Vue } from 'vue-property-decorator';
import { navigate } from '@/helpers/navigation-helpers';
import { ActionStatus } from '@/application/types';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { useMyStableStore } from '../store';
import { AcceptHorseShareInviteCommand, GetHorseShareInviteQuery, RejectHorseShareInviteCommand } from '../types';

@Component
export default class HorseShareInviteDecision extends Vue {

  readonly store = useMyStableStore();

  get isProcessing(): boolean {
    return this.store.isAcceptHorseShareInviteProcessing
      || this.store.isRejectHorseShareInviteProcessing;
  }

  get isLoadingFailed(): boolean {
    return this.store.getHorseShareInviteStatus === ActionStatus.Failed;
  }

  get currentHorseShareInviteId(): string {
    return this.$route.params.horseShareInviteId;
  }

  mounted(): void {
    const query: GetHorseShareInviteQuery = {
      horseShareInviteId: this.currentHorseShareInviteId,
    };
    this.store.getHorseShareInvite(query)
      .catch((error) => showErrorResponse(error));
  }

  acceptHorseShareInviteAction(): Promise<void> {
    const command: AcceptHorseShareInviteCommand = {
      horseShareInviteId: this.currentHorseShareInviteId,
    };

    return this.store.acceptHorseShareInvite(command)
      .then(() => showSuccessMessage('Die Einladung wurde angenommen.'))
      .then(() => this.redirectToMyStable())
      .catch((error) => showErrorResponse(error));
  }

  rejectHorseShareInviteAction(): Promise<void> {
    const command: RejectHorseShareInviteCommand = {
      horseShareInviteId: this.currentHorseShareInviteId,
    };

    return this.store.rejectHorseShareInvite(command)
      .then(() => showSuccessMessage('Die Einladung wurde abgelehnt.'))
      .then(() => this.redirectToMyStable())
      .catch((error) => showErrorResponse(error));
  }

  redirectToMyStable(): void {
    navigate({ name: 'my-stable/horses' });
  }

}
