
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse } from '@/application/snackbar/service';
import { formatCurrency } from '@/helpers/stateful-format';
import { formatDate } from '@/helpers';
import ConfirmationActionDialog from '@/components/design-system/confirmation-action-dialog.vue';
import { FarmServiceBookingType, Date } from '@/types';
import { CancelFarmServiceBookingCommand, FarmService, FarmServiceBooking } from '../types';
import { useMyStableStore } from '../store';

@Component
export default class FarmServiceBookingsDialog extends Vue {

  readonly store = useMyStableStore();

  @Prop({ type: Object, required: true })
  readonly farmService!: FarmService;

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;

  closeDialog(): void {
    this.isDialogVisible = false;
  }

  title(farmServiceBooking: FarmServiceBooking): string {
    return this.farmService.bookingType === FarmServiceBookingType['ONE_OFF']
      ? `Am ${formatDate(farmServiceBooking.startingAt)}`
      : farmServiceBooking.endingAt
        ? `Vom ${formatDate(farmServiceBooking.startingAt)} bis ${formatDate(farmServiceBooking.endingAt)}`
        : Date.today().isAfterOrEqualTo(farmServiceBooking.startingAt)
          ? `Seit ${formatDate(farmServiceBooking.startingAt)}`
          : `Ab dem ${formatDate(farmServiceBooking.startingAt)}`;
  }

  description(farmServiceBooking: FarmServiceBooking): string {
    return farmServiceBooking.comment
      ? `${formatCurrency(farmServiceBooking.additionalCosts)} - ${farmServiceBooking.comment}`
      : formatCurrency(farmServiceBooking.additionalCosts);
  }

  isItemCancellable(farmServiceBooking: FarmServiceBooking): boolean {
    if (!this.store.isCurrentHorseOwn) {
      return false;
    }

    if (this.farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION) {
      return !farmServiceBooking.endingAt;
    } else {
      return farmServiceBooking.lastPossibleCancellation.isAfterOrEqualTo(Date.today());
    }
  }

  cancellationNote(farmServiceBooking: FarmServiceBooking): string | null {
    if (!this.isItemCancellable(farmServiceBooking)) {
      return null;
    }

    return this.farmService.bookingType === FarmServiceBookingType.SUBSCRIPTION
      ? `Kündigung zur aktuellen Laufzeit möglich bis einschließlich ${formatDate(farmServiceBooking.lastPossibleCancellation)}.`
      : `Kündigung ist bis einschließlich ${formatDate(farmServiceBooking.lastPossibleCancellation)} möglich.`;
  }

  openCancelFarmServiceBookingDialog(farmServiceBooking: FarmServiceBooking): void {
    const confirmActionDialog = this.$refs[`cancelBookingDialog-${farmServiceBooking.farmServiceBookingId}`] as ConfirmationActionDialog;
    confirmActionDialog.openDialog();
  }

  async cancelBookingAction(farmServiceBooking: FarmServiceBooking): Promise<void> {
    const command: CancelFarmServiceBookingCommand = {
      horseId: this.store.currentHorseId!,
      farmServiceId: this.farmService.farmServiceId,
      farmServiceBookingId: farmServiceBooking.farmServiceBookingId,
      startingAt: farmServiceBooking.startingAt,
    };

    return this.store.cancelFarmServiceBooking(command)
      .then(() => {
        if (this.farmService.bookings.length === 0) {
          this.closeDialog();
        }
      })
      .catch((error) => showErrorResponse(error));
  }

}
