import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { wrapActionWithProgress } from '@/store';
import { Moment } from '@/types';
import { FeedingPlan, GetFeedingPlanQuery } from './types';
import { getFeedingPlan } from './service';

interface FeedingPlanState {
  selectedDate: Moment | null;
  feedingPlan: FeedingPlan | null;

  getFeedingPlanStatus: ActionStatus;
}

function initialState(): FeedingPlanState {
  return {
    selectedDate: null,
    feedingPlan: null,

    getFeedingPlanStatus: ActionStatus.None,
  };
}

export const useFeedingPlanStore = defineStore('feedingPlan', {
  state: (): FeedingPlanState => initialState(),
  getters: {
    isGetFeedingPlanProcessing: (state: FeedingPlanState): boolean =>
      state.getFeedingPlanStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateFeedingPlanForDay(selectedDate: Moment): Promise<void> {
      this.selectedDate = selectedDate;

      return this.getFeedingPlan();
    },

    // -- Queries

    getFeedingPlan(): Promise<void> {
      const query: GetFeedingPlanQuery = {
        selectedDate: this.selectedDate!,
      };
      const { getFeedingPlanStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFeedingPlanStatus,
        () => getFeedingPlan(attachFarmAndUserProperties(query))
          .then((feedingPlan) => {
            this.feedingPlan = feedingPlan;
          })
      );
    },

  },
});
