
import { Component, Prop, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { FacilityReservationId, Moment } from '@/types';
import { CancelFacilityReservationCommand } from '../types';
import { useBookingCalendarStore } from '../store';

@Component
export default class CancelFacilityReservationDialog extends Vue {

  readonly store = useBookingCalendarStore();

  readonly dialogMaxWidth = DialogWidth.small;

  @Prop({ type: String, required: true })
  readonly facilityReservationId!: FacilityReservationId;

  @Prop({ type: String, required: true })
  readonly horseName!: string;

  @Prop({ type: Object, required: true })
  readonly from!: Moment;

  @Prop({ type: Object, required: true })
  readonly to!: Moment;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  isDialogVisible = false;

  cancelFacilityReservationConfirmed(): void {
    const command: CancelFacilityReservationCommand = {
      facilityReservationId: this.facilityReservationId,
    };

    this.store.cancelFacilityReservation(command)
      .then(() => showSuccessMessage('Die Reservierung wurde storniert'))
      .then(() => this.$emit('facility-reservation-canceled'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
