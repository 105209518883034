import { render, staticRenderFns } from "./gerblhof-concentrated-feedings-form-control.vue?vue&type=template&id=368acd90&scoped=true&"
import script from "./gerblhof-concentrated-feedings-form-control.vue?vue&type=script&lang=ts&"
export * from "./gerblhof-concentrated-feedings-form-control.vue?vue&type=script&lang=ts&"
import style0 from "./gerblhof-concentrated-feedings-form-control.vue?vue&type=style&index=0&id=368acd90&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368acd90",
  null
  
)

export default component.exports