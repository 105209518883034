import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { Feature, SortDirection } from '@/types';
import { attachUserProperty, attachUserPropertyToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { createFarm, deleteFarm, getFarms, updateFarm, updateFarmLogo } from './service';
import { CreateFarmAsAdminWorkflow, DeleteFarmAsAdminCommand, Farm, GetFarmsAsAdminQuery, GetFarmsQuerySortBy, UpdateFarmAsAdminCommand, UpdateFarmLogoAsAdminCommand } from './types';

interface ManageFarmsState {
  farms: Farm[];
  search: string | null;
  selectedFeatures: Feature[] | null;
  sortBy: GetFarmsQuerySortBy;
  sortDirection: SortDirection;
  currentLimit: number;
  totalCount: number | null;

  getFarmsStatus: ActionStatus;
  createFarmStatus: ActionStatus;
  updateFarmStatus: ActionStatus;
  deleteFarmStatus: ActionStatus;
  updateFarmLogoStatus: ActionStatus;
}

function initialState(): ManageFarmsState {
  return {
    farms: [],
    search: null,
    selectedFeatures: null,
    sortBy: GetFarmsQuerySortBy.NAME,
    sortDirection: SortDirection.ASC,
    currentLimit: 50,
    totalCount: null,

    getFarmsStatus: ActionStatus.None,
    createFarmStatus: ActionStatus.None,
    updateFarmStatus: ActionStatus.None,
    deleteFarmStatus: ActionStatus.None,
    updateFarmLogoStatus: ActionStatus.None,
  };
}

export const useFarmManagementStore = defineStore('manageFarms', {
  state: (): ManageFarmsState => initialState(),
  getters: {
    isGetFarmsProcessing: (state: ManageFarmsState): boolean =>
      state.getFarmsStatus === ActionStatus.InProgress,
    isCreateFarmProcessing: (state: ManageFarmsState): boolean =>
      state.createFarmStatus === ActionStatus.InProgress,
    isUpdateFarmProcessing: (state: ManageFarmsState): boolean =>
      state.updateFarmStatus === ActionStatus.InProgress,
    isDeleteFarmProcessing: (state: ManageFarmsState): boolean =>
      state.deleteFarmStatus === ActionStatus.InProgress,
    isUpdateFarmLogoProcessing: (state: ManageFarmsState): boolean =>
      state.updateFarmLogoStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSearch(search: string | null): Promise<void> {
      this.search = search;
      this.currentLimit = 50;

      return this.getFarms();
    },

    updateSelectedFeatures(features: Feature[] | null): Promise<void> {
      this.selectedFeatures = features;
      this.currentLimit = 50;

      return this.getFarms();
    },

    updateSorting(
      sortBy: GetFarmsQuerySortBy,
      sortDirection: SortDirection
    ): Promise<void> {
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;

      return this.getFarms();
    },

    increaseLimit(): Promise<void> {
      this.currentLimit += 50;

      return this.getFarms();
    },

    resetFilters(): Promise<void> {
      this.search = null;
      this.selectedFeatures = null;
      this.sortBy = GetFarmsQuerySortBy.NAME;
      this.sortDirection = SortDirection.ASC;
      this.currentLimit = 50;
      this.totalCount = null;

      return Promise.resolve();
    },

    // -- Queries

    getFarms(): Promise<void> {
      const query: GetFarmsAsAdminQuery = {
        search: this.search,
        features: this.selectedFeatures,
        limit: this.currentLimit,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      };

      const { getFarmsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFarmsStatus,
        () => getFarms(attachUserProperty(query))
          .then(async (response) => {
            this.totalCount = response.totalCount;
            this.farms = response.farms;
          })
      );
    },

    // -- Commands

    createFarm(command: CreateFarmAsAdminWorkflow): Promise<void> {
      const { createFarmStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFarmStatus,
        () => createFarm(attachUserProperty(command))
          .then(() => this.getFarms())
      );
    },

    updateFarm(command: UpdateFarmAsAdminCommand): Promise<void> {
      const { updateFarmStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmStatus,
        () => updateFarm(attachUserProperty(command))
          .then(() => this.getFarms())
      );
    },

    deleteFarm(command: DeleteFarmAsAdminCommand): Promise<void> {
      const { deleteFarmStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteFarmStatus,
        () => deleteFarm(attachUserProperty(command))
          .then(() => this.getFarms())
      );
    },

    updateFarmLogo(command: UpdateFarmLogoAsAdminCommand): Promise<void> {
      const { updateFarmLogoStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateFarmLogoStatus,
        () => updateFarmLogo(attachUserPropertyToCommandWithFiles(command))
          .then(() => this.getFarms())
      );
    },

  },
});
