
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActionStatus } from '@/application/types';
import { uuid } from '@/helpers';

@Component
export default class EmptyList extends Vue {

  @Prop({ type: Number, default: 0 })
  readonly loadingStatus!: ActionStatus;

  @Prop({ type: String, required: true })
  readonly description!: String;

  readonly listId = uuid();

  get isLoading(): boolean {
    return this.loadingStatus === ActionStatus.InProgress;
  }

}
