import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import TimeZone from 'dayjs/plugin/timezone';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Weekday from 'dayjs/plugin/weekday';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import IsSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import IsoWeek from 'dayjs/plugin/isoWeek';
import Duration from 'dayjs/plugin/duration';
import 'dayjs/locale/de';

dayjs.extend(Utc);
dayjs.extend(TimeZone);
dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(Weekday);
dayjs.extend(WeekOfYear);
dayjs.extend(CustomParseFormat);
dayjs.extend(AdvancedFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
dayjs.extend(IsBetween);
dayjs.extend(IsoWeek);
dayjs.extend(Duration);

dayjs.locale('de');
dayjs.tz.setDefault('Europe/Berlin');
