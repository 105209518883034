import { Ref } from 'vue';
import { ActionStatus } from '@/application/types';

type Action<T> = () => Promise<T>;

export function wrapActionWithProgress<T>(
  statusProperty: Ref<ActionStatus>,
  action: Action<T>
): Promise<T> {
  statusProperty.value = ActionStatus.InProgress;
  return action()
    .then((response: T) => {
      statusProperty.value = ActionStatus.Successful;
      return Promise.resolve(response);
    })
    .catch((error) => {
      statusProperty.value = ActionStatus.Failed;
      return Promise.reject(error);
    });
}
