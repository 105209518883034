import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import ProfileLoadingWrapper from './components/profile-loading-wrapper.vue';
import AllowImpersonation from './components/allow-impersonation.vue';

export const profileRoutes: RouteConfig[] = [
  {
    path: '/profil',
    name: 'profile',
    component: ProfileLoadingWrapper,
    meta: {
      title: 'Profil',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
    },
  },
  {
    // Warning - Used in push notifications
    name: 'allow-impersonation',
    path: '/profilzugriff-akzeptiert/:token',
    component: AllowImpersonation,
    meta: {
      title: 'Profilzugriff akzeptiert',
      accessibleFor: RouteAccessibility.ALWAYS,
    },
  },
];
