import { FarmAndUserProperties, FarmAndUserPropertiesForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { CreateDocumentAsManagerCommand, DeleteDocumentAsManagerCommand, FarmDocument, UpdateDocumentFileAsManagerCommand, UpdateDocumentDescriptionAsManagerCommand } from './types';

// -- Queries

export function getDocuments(query: FarmAndUserProperties): Promise<FarmDocument[]> {
  const url = `${apiUrl}/api/farm-documents/get-documents-as-manager-query`;
  return performApiRequest<FarmDocument[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createDocument(command: CreateDocumentAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/create-document-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateDocumentFile(command: UpdateDocumentFileAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/update-document-file-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function updateDocumentDescription(command: UpdateDocumentDescriptionAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/update-document-description-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteDocument(command: DeleteDocumentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/delete-document-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
