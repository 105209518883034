import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { CreateSurveyAsManagerCommand, DeleteSurveyAsManagerCommand, DeleteSurveyAnswerAsManagerCommand, GetSurveyDetailsAsManagerQuery, SurveyForList, Survey, GetSurveyForDuplicationAsManagerQuery, SurveyForDuplication } from './types';

// -- Queries

export function getSurveys(query: FarmAndUserProperties): Promise<SurveyForList[]> {
  const url = `${apiUrl}/api/surveys/get-surveys-as-manager-query`;
  return performApiRequest<SurveyForList[]>({ url, method: 'POST', data: query });
}

export function getSurveyDetails(query: GetSurveyDetailsAsManagerQuery & FarmAndUserProperties): Promise<Survey> {
  const url = `${apiUrl}/api/surveys/get-survey-details-as-manager-query`;
  return performApiRequest<Survey>({ url, method: 'POST', data: query });
}

export function getSurveyForDuplication(
  query: GetSurveyForDuplicationAsManagerQuery & FarmAndUserProperties
): Promise<SurveyForDuplication> {
  const url = `${apiUrl}/api/surveys/get-survey-for-duplication-as-manager-query`;
  return performApiRequest<SurveyForDuplication>({ url, method: 'POST', data: query });
}

// -- Commands

export function createSurvey(command: CreateSurveyAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/surveys/create-survey-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteSurvey(command: DeleteSurveyAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/surveys/delete-survey-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteSurveyAnswer(command: DeleteSurveyAnswerAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/surveys/delete-survey-answer-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
