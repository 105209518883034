import { UserProperty } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { AdminNotification, CreateAdminNotificationAsAdminCommand, UpdateAdminNotificationAsAdminCommand, DeleteAdminNotificationAsAdminCommand } from './types';

// -- Queries

export function getAllAdminNotifications(query: UserProperty): Promise<AdminNotification[]> {
  const url = `${apiUrl}/api/admin-notifications/get-all-admin-notifications-as-admin-query`;
  return performApiRequest<AdminNotification[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function createAdminNotification(command: CreateAdminNotificationAsAdminCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/admin-notifications/create-admin-notification-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateAdminNotification(command: UpdateAdminNotificationAsAdminCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/admin-notifications/update-admin-notification-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteAdminNotification(command: DeleteAdminNotificationAsAdminCommand & UserProperty): Promise<void> {
  const url = `${apiUrl}/api/admin-notifications/delete-admin-notification-as-admin-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
