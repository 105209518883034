import axios, { AxiosError } from 'axios';
import { isNavigationFailure } from 'vue-router';
import { router } from '@/router';
import { useAuthenticationStore } from '@/application/authentication/store';

function handleAuthenticationError(error: any | AxiosError): Promise<any> {
  const isLoginError = error.config?.url.endsWith('/api/unauthorized/application/authentication/login') || false;
  const isAuthenticated = useAuthenticationStore().isAuthenticated;
  const isAuthenticationError = error.response?.status === 401 || false;

  // Login error is also a 401 error where it doesn't make sense to try it again.
  if (isLoginError) {
    return Promise.reject(error);
  }

  // A disabled user should be logged out immediately.
  if (isAuthenticated && isAuthenticationError) {
    router.push({ name: 'logout' })
      .catch((error) => {
        if (!isNavigationFailure(error)) {
          throw error;
        }
      });
    return Promise.reject(error);
  }

  return Promise.reject(error);
}

export default function initAuthenticationInterceptor() {
  axios.interceptors.response.use(
    (response) => response,
    handleAuthenticationError
  );
}
