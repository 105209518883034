import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole } from '@/types';
import FeedingPlanTabs from './components/feeding-plan-tabs.vue';

export const feedingPlanRoutes: RouteConfig[] = [
  {
    name: 'ledger/feeding-plan',
    path: 'fuetterungsplan',
    component: FeedingPlanTabs,
    meta: {
      title: 'Fütterungsplan',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
      requiresFeature: Feature.LEDGER,
      requiresRole: UserRole.ROLE_FARM_MANAGER,
    },
  },
];
