import { RouteConfig, RouteMeta } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import FarmTaskTabs from './components/farm-task-tabs.vue';
import TasksGroupedByRecencyTab from './components/tasks-grouped-by-recency-tab.vue';
import TasksGroupedByUserTab from './components/tasks-grouped-by-user-tab.vue';
import TasksGroupedByHorseTab from './components/tasks-grouped-by-horse-tab.vue';

const taskRouteMeta: RouteMeta = {
  title: 'Hofaufgaben',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresFeature: Feature.FARM_TASKS,
  requiresPermission: FarmManagerPermission.FARM_TASKS_READ,
};

export const farmTaskManagementRoutes: RouteConfig[] = [
  {
    path: 'hofaufgaben',
    component: FarmTaskTabs,
    children: [
      {
        name: 'farm-task-management-task-tabs',
        path: '',
        redirect: { name: 'farm-task-tasks-grouped-by-recency' },
      },
      {
        name: 'farm-task-tasks-grouped-by-recency',
        path: 'aufgabenplanung',
        component: TasksGroupedByRecencyTab,
        meta: taskRouteMeta,
      },
      {
        name: 'farm-task-tasks-grouped-by-user',
        path: 'nach-verwaltern',
        component: TasksGroupedByUserTab,
        meta: taskRouteMeta,
      },
      {
        name: 'farm-task-tasks-grouped-by-horse',
        path: 'nach-pferden',
        component: TasksGroupedByHorseTab,
        meta: taskRouteMeta,
      },
    ],
    meta: taskRouteMeta,
  },
];
