
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Feeding } from '@/types';
import { FeedUnitNumerus, feedUnitNumerusTranslations } from '@/helpers/data';
import { FeedingSettingsHistoryEntry } from '../types';
import { feedingSettingsHistoryEntryTypeTranslation } from '@/helpers/translations';
import { formatNumber } from '@/helpers/stateful-format';

enum TimeOfDay {
  MORNING,
  NOON,
  EVENING,
}

@Component
export default class FeedingHistoryEntry extends Vue {

  @Prop({ type: Object, required: true })
  readonly feedingSettingsHistoryEntry!: FeedingSettingsHistoryEntry;

  @Prop({ type: Object, default: null })
  readonly previousFeedingSettingsHistoryEntry!: FeedingSettingsHistoryEntry | null;

  @Prop({ type: Boolean, required: true })
  readonly isDiffToPreviousShown!: boolean;

  readonly TimeOfDay = TimeOfDay;

  get translatedType(): string {
    return feedingSettingsHistoryEntryTypeTranslation[this.feedingSettingsHistoryEntry.type];
  }

  feedingDescription(feeding: Feeding): string {
    if (feeding.feedUnit) {
      const feedUnitNumerus: FeedUnitNumerus = `${feeding.feedUnit}-${feeding.amount !== 1 ? 'plural' : 'singular'}`;
      const feedUnitTranslation = feedUnitNumerusTranslations[feedUnitNumerus];

      return `${formatNumber(feeding.amount)} ${feedUnitTranslation} ${feeding.feedTypeName}`;
    }

    return feeding.feedTypeName;
  }

  isTextMediumShown(feeding: Feeding, timeOfDay: TimeOfDay): boolean {
    if (!this.isDiffToPreviousShown) {
      return false;
    }

    // All are new, when there is no previous entry
    if (this.previousFeedingSettingsHistoryEntry === null) {
      return true;
    }

    const previousFeeding = this.feedingsForTimeOfDay(this.previousFeedingSettingsHistoryEntry, timeOfDay)
      .find((previousFeeding) => previousFeeding.feedTypeId === feeding.feedTypeId) || null;

    return previousFeeding === null
      || previousFeeding.amount !== feeding.amount;
  }

  wasFeedingAdded(feeding: Feeding, timeOfDay: TimeOfDay): boolean {
    if (!this.isDiffToPreviousShown) {
      return false;
    }

    // All are new, when there is no previous entry
    if (this.previousFeedingSettingsHistoryEntry === null) {
      return true;
    }

    return !this.feedingsForTimeOfDay(this.previousFeedingSettingsHistoryEntry, timeOfDay)
      .some((previousFeeding) => previousFeeding.feedTypeId === feeding.feedTypeId);
  }

  wasFeedingUpdated(feeding: Feeding, timeOfDay: TimeOfDay): boolean {
    if (!this.isDiffToPreviousShown) {
      return false;
    }

    // None are new, when there is no previous entry
    if (this.previousFeedingSettingsHistoryEntry === null) {
      return false;
    }

    const previousFeeding = this.feedingsForTimeOfDay(this.previousFeedingSettingsHistoryEntry, timeOfDay)
      .find((currentFeeding) => currentFeeding.feedTypeId === feeding.feedTypeId);

    return !!previousFeeding
      && previousFeeding.amount !== feeding.amount;
  }

  feedingsForTimeOfDay(previousFeedingSettingsHistoryEntry: FeedingSettingsHistoryEntry, timeOfDay: TimeOfDay): Feeding[] {
    switch (timeOfDay) {
      case TimeOfDay.MORNING:
        return previousFeedingSettingsHistoryEntry.feedingConfiguration.feedingsMorning;
      case TimeOfDay.NOON:
        return previousFeedingSettingsHistoryEntry.feedingConfiguration.feedingsNoon;
      case TimeOfDay.EVENING:
        return previousFeedingSettingsHistoryEntry.feedingConfiguration.feedingsEvening;
    }
  }

  deletedFeedings(timeOfDay: TimeOfDay): Feeding[] {
    if (!this.previousFeedingSettingsHistoryEntry) {
      return [];
    }

    return this.feedingsForTimeOfDay(this.previousFeedingSettingsHistoryEntry, timeOfDay)
      .filter((previousFeeding) => !this.feedingsForTimeOfDay(this.feedingSettingsHistoryEntry, timeOfDay)
        .some((feeding) => feeding.feedTypeId === previousFeeding.feedTypeId));
  }

}
