import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FeedingPlan, GetFeedingPlanQuery } from './types';

// -- Queries

export function getFeedingPlan(query: GetFeedingPlanQuery): Promise<FeedingPlan> {
  const url = `${apiUrl}/api/admin/feeding-plan/get-feeding-plan-query`;
  return performApiRequest<FeedingPlan>({ url, method: 'POST', data: query });
}
