const validNumberStringRegex = /^-?(\d)+([.\d])*(,\d*)?$/;

export function canBeParsedAsNumber(value: string): boolean {
  return validNumberStringRegex.test(value);
}

export function convertStringToNumber(valueAsString: string): number {
  const cleanedValueAsString = valueAsString
    .replace(/\./g, '')
    .replace(/,/g, '.');

  return parseFloat(cleanedValueAsString);
}

export function countDecimals(number: number): number {
  return number === Math.floor(number)
    ? 0
    : number.toString().split('.')[1].length;
}

export function countDecimalsOnString(number: string): number {
  return number.split(',').length === 1
    ? 0
    : number.split(',')[1].length;
}
