import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { RegisterForEventCommand, DeleteEventRegistrationCommand, MarkEventAsSeenCommand, MarkNewsEntryAsSeenCommand, NewsEntry, Event, Survey, AnswerSurveyCommand } from './types';

// -- Queries

export function getNewsEntries(query: FarmAndUserProperties): Promise<NewsEntry[]> {
  const url = `${apiUrl}/api/news/get-news-entries-query`;
  return performApiRequest<NewsEntry[]>({ url, method: 'POST', data: query });
}

export function getEvents(query: FarmAndUserProperties): Promise<Event[]> {
  const url = `${apiUrl}/api/events/get-events-query`;
  return performApiRequest<Event[]>({ url, method: 'POST', data: query });
}

export function getSurveys(query: FarmAndUserProperties): Promise<Survey[]> {
  const url = `${apiUrl}/api/surveys/get-surveys-query`;
  return performApiRequest<Survey[]>({ url, method: 'POST', data: query });
}

export function getUnansweredSurveysShownOnAppStart(query: FarmAndUserProperties): Promise<Survey[]> {
  const url = `${apiUrl}/api/surveys/get-unanswered-surveys-shown-on-app-start-query`;
  return performApiRequest<Survey[]>({ url, method: 'POST', data: query });
}

// -- Commands

export function markNewsEntryAsSeen(command: MarkNewsEntryAsSeenCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/news/mark-news-entry-as-seen-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function markEventAsSeen(command: MarkEventAsSeenCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/mark-event-as-seen-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function registerForEvent(command: RegisterForEventCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/register-for-event-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteEventRegistration(command: DeleteEventRegistrationCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/events/delete-event-registration-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function answerSurvey(command: AnswerSurveyCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/surveys/answer-survey-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
