
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FacilityBlockerCalendarEvent } from '../types';
import { useBookingCalendarStore } from '../store';

@Component
export default class FacilityBlockerEvent extends Vue {

  readonly store = useBookingCalendarStore();

  @Prop({ type: Object, required: true })
  readonly calendarEvent!: FacilityBlockerCalendarEvent;

  isMenuVisible = false;

  get title(): string {
    return this.calendarEvent.reason ?? 'Kein Grund angegeben';
  }

  get areSpacesVisible(): boolean {
    return this.store.currentFacility !== null
      && this.store.currentFacility.spaces !== null
      && this.store.currentFacility.spaces > 1;
  }

  get blockerSpacesDescription(): string {
    if (this.store.currentFacility === null) {
      return '';
    }

    if (!this.calendarEvent.blocksSpaces
      || this.store.currentFacility.spaces === this.calendarEvent.blocksSpaces
    ) {
      return 'Blockiert alle Plätze';
    }

    return `Blockiert ${this.calendarEvent.blocksSpaces} von ${this.store.currentFacility.spaces} Plätzen`;
  }

}
