import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

const themeColors = {
  primary: {
    base: '#005E5A',
    darken4: '#001918',
    darken3: '#002B29',
    darken2: '#003C39',
    darken1: '#014B48',
    lighten1: '#005E5A',
    lighten2: '#228D88',
    lighten3: '#75C5C0',
    lighten4: '#C1E7E6',
    lighten5: '#DEF7F6',
  },
  secondary: {
    base: '#6C6D67',
    darken4: '#0F0F0E',
    darken3: '#262725',
    darken2: '#3C3D3A',
    darken1: '#50514F',
    lighten1: '#6C6D67',
    lighten2: '#93958F',
    lighten3: '#CECFCC',
    lighten4: '#EBECE9',
    lighten5: '#FAFAFA',
  },
  accent: {
    base: '#005E5A',
    darken4: '#001918',
    darken3: '#002B29',
    darken2: '#003C39',
    darken1: '#014B48',
    lighten1: '#005E5A',
    lighten2: '#228D88',
    lighten3: '#75C5C0',
    lighten4: '#C1E7E6',
    lighten5: '#DEF7F6',
  },
  info: {
    base: '#005E5A',
    darken4: '#001918',
    darken3: '#002B29',
    darken2: '#003C39',
    darken1: '#014B48',
    lighten1: '#005E5A',
    lighten2: '#228D88',
    lighten3: '#75C5C0',
    lighten4: '#C1E7E6',
    lighten5: '#DEF7F6',
  },
  warning: {
    base: '#F9B116',
    darken4: '#281C00',
    darken3: '#503601',
    darken2: '#9F6E04',
    darken1: '#DB9706',
    lighten1: '#F9B116',
    lighten2: '#FABD37',
    lighten3: '#FCD073',
    lighten4: '#FDEBC3',
    lighten5: '#FFF8EB',
  },
  error: {
    base: '#AA0613',
    darken4: '#260104',
    darken3: '#4F0308',
    darken2: '#77040C',
    darken1: '#8A050F',
    lighten1: '#AA0613',
    lighten2: '#CD202E',
    lighten3: '#F72635',
    lighten4: '#FB8990',
    lighten5: '#FFE5E6',
  },
  success: {
    base: '#62942D',
    darken4: '#182D01',
    darken3: '#203B02',
    darken2: '#30540D',
    darken1: '#456D19',
    lighten1: '#62942D',
    lighten2: '#90BF61',
    lighten3: '#BFE991',
    lighten4: '#E0F2CB',
    lighten5: '#F0FDE3',
  },
  red: {
    base: '#AA0613',
    darken4: '#260104',
    darken3: '#4F0308',
    darken2: '#77040C',
    darken1: '#8A050F',
    lighten1: '#AA0613',
    lighten2: '#CD202E',
    lighten3: '#F72635',
    lighten4: '#FB8990',
    lighten5: '#FFE5E6',
  },
  green: {
    base: '#62942D',
    darken4: '#182D01',
    darken3: '#203B02',
    darken2: '#30540D',
    darken1: '#456D19',
    lighten1: '#62942D',
    lighten2: '#90BF61',
    lighten3: '#BFE991',
    lighten4: '#E0F2CB',
    lighten5: '#F0FDE3',
  },
  yellow: {
    base: '#F9B116',
    darken4: '#281C00',
    darken3: '#503601',
    darken2: '#9F6E04',
    darken1: '#DB9706',
    lighten1: '#F9B116',
    lighten2: '#FABD37',
    lighten3: '#FCD073',
    lighten4: '#FDEBC3',
    lighten5: '#FFF8EB',
  },
  blue: {
    base: '#005E5A',
    darken4: '#001918',
    darken3: '#002B29',
    darken2: '#003C39',
    darken1: '#014B48',
    lighten1: '#005E5A',
    lighten2: '#228D88',
    lighten3: '#75C5C0',
    lighten4: '#C1E7E6',
    lighten5: '#DEF7F6',
  },
  grey: {
    base: '#6C6D67',
    darken4: '#0F0F0E',
    darken3: '#262725',
    darken2: '#3C3D3A',
    darken1: '#50514F',
    lighten1: '#6C6D67',
    lighten2: '#93958F',
    lighten3: '#CECFCC',
    lighten4: '#EBECE9',
    lighten5: '#FAFAFA',
  },
};

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'faSvg',
  },
  theme: {
    themes: {
      light: themeColors,
    },
  },
});
