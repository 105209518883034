import { moment, momentWithoutTimezone } from '@/helpers/moment';
import { Moment } from './moment';

const DEFAULT_FORMAT = 'DD.MM.YYYY';

export class Date {

  public date: string;

  // -- Construction

  constructor(
    date: string
  ) {
    if (!momentWithoutTimezone(date, 'YYYY-MM-DD', true).isValid()) {
      throw new Error(`${date} is an invalid date`);
    }

    this.date = date;
  }

  static isDate(value: unknown): value is Date {
    return value instanceof Date;
  }

  static today(): Date {
    return new Date(moment().format('YYYY-MM-DD'));
  }

  static fromMoment(moment: Moment): Date {
    return new Date(moment.format('YYYY-MM-DD'));
  }

  // -- Transformations

  add(days: number): Date {
    return Date.fromMoment(
      moment(this.date)
        .add(days, 'day')
    );
  }

  subtract(days: number): Date {
    return new Date(
      moment(this.date)
        .subtract(days, 'day')
        .format('YYYY-MM-DD')
    );
  }

  addMonths(months: number): Date {
    return Date.fromMoment(
      moment(this.date)
        .add(months, 'months')
    );
  }

  startOfMonth(): Date {
    return Date.fromMoment(
      moment(this.date)
        .startOf('month')
    );
  }

  // -- Accessors

  isEqualTo(date: Date): boolean {
    return this.date === date.date;
  }

  isNotEqualTo(date: Date): boolean {
    return this.date !== date.date;
  }

  isBefore(date: Date): boolean {
    return moment(this.date).isBefore(moment(date.date), 'day');
  }

  isNotBefore(date: Date): boolean {
    return moment(this.date).isSameOrAfter(moment(date.date), 'day');
  }

  isBeforeOrEqualTo(date: Date): boolean {
    return moment(this.date).isSameOrBefore(moment(date.date), 'day');
  }

  isAfter(date: Date): boolean {
    return moment(this.date).isAfter(moment(date.date), 'day');
  }

  isNotAfter(date: Date): boolean {
    return moment(this.date).isSameOrBefore(moment(date.date), 'day');
  }

  isAfterOrEqualTo(date: Date): boolean {
    return moment(this.date).isSameOrAfter(moment(date.date), 'day');
  }

  diff(date: Date): number {
    return moment(this.date).diff(date.date, 'days');
  }

  atMidnight(): Moment {
    return moment(this.date).startOf('day');
  }

  format(formatString: string = DEFAULT_FORMAT): string {
    return moment(this.date).format(formatString);
  }

  formattedLabel(datePrefix: string): string {
    const today = moment();
    if (today.isSame(this.date, 'day')) {
      return 'Heute';
    }

    const yesterday = today.subtract(1, 'day');
    if (yesterday.isSame(this.date, 'day')) {
      return 'Gestern';
    }

    const dayBeforeYesterday = yesterday.subtract(1, 'day');
    if (dayBeforeYesterday.isSame(this.date, 'day')) {
      return 'Vorgestern';
    }

    const tomorrow = today.add(1, 'day');
    if (tomorrow.isSame(this.date, 'day')) {
      return 'Morgen';
    }

    const dayAfterTomorrow = tomorrow.add(1, 'day');
    if (dayAfterTomorrow.isSame(this.date, 'day')) {
      return 'Übermorgen';
    }

    return `${datePrefix} ${moment(this.date).format(DEFAULT_FORMAT)}`;
  }

}
